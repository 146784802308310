import * as React from "react"
const MasterBedRoomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -403.92)">
      <rect
        width={40}
        height={40}
        fill="#fff8e7"
        data-name="Image 33"
        rx={12}
        transform="translate(44 403.92)"
      />
      <path
        fill="#c6981f"
        stroke="#e2ae24"
        strokeWidth={0.25}
        d="M53.344 414.42a.857.857 0 0 0-.844.87v16.571a.857.857 0 1 0 1.714 0v-.857h20.571v.857a.857.857 0 1 0 1.714 0v-4.571a2.013 2.013 0 0 0-2-2H61.643v-.571a3.442 3.442 0 0 0-3.429-3.429h-2.286a3.32 3.32 0 0 0-1.714.53v-6.53a.857.857 0 0 0-.871-.869Zm2.585 8.584h2.285a1.7 1.7 0 0 1 1.714 1.714v.571h-5.714v-.571a1.7 1.7 0 0 1 1.714-1.714Zm-1.714 4h6.431a.857.857 0 0 0 .278 0H74.5a.273.273 0 0 1 .286.286v2H54.214Z"
      />
    </g>
  </svg>
)
export default MasterBedRoomIcon
