import { Bold, SemiBold } from "../../utils";

export const AnnouncementCardStyles = {
    root: {
        backgroundColor: "primary.contrastText",
        borderRadius: "4px",
        boxShadow: "0px 0px 4px #00000029",
        padding: "12px",
        cursor: "pointer"
    },
    tagText: {
        color: "primary.Tertiary",
        padding: "2px",
        fontSize: "0.75rem",
        backgroundColor: "border.secondary",
        fontFamily: SemiBold,
        borderRadius: "4px",
        textAlign: "center",
        marginBottom: "2px",
        width: "max-content"
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "text.Teritary",
        marginBottom: "6px"
    },
    subTitle: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "text.secondary",
    },
    avatarIcons: {
        borderRadius: "4px",
        width: "80px",
        height: "80px",
        backgroundColor: "#fbb59f",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}