import React from 'react'
import { Stack, Typography } from "@mui/material"
import { AgreementDetailCardStyle } from './style'

export const AgreementDetailCard = ({
    icon = "",
    title = "",
    subtitle = "",
    hijirTitle = "",
    hijirSubtitle = "",
    hijir = false,
    hijirIcon = "",
    isActiveHijir = false,
    isNormalActive = false
}) => {
    const classes = AgreementDetailCardStyle
    return (
        <>
            {
                !hijir ?
                    <Stack sx={classes.box} spacing={"14px"}>
                        {icon}
                        <Stack spacing={"4px"}>
                            <Typography sx={classes.title}>{title}</Typography>
                            <Typography sx={classes.subtitle}>{subtitle}</Typography>
                        </Stack>
                    </Stack> :

                    <Stack sx={classes.hijirBox} direction={"row"}>
                        {isNormalActive &&
                            <Stack sx={isActiveHijir && classes.rightBorder} p={2} spacing={"14px"}>
                                {icon}
                                <Stack spacing={"4px"}>
                                    <Typography sx={classes.title}>{title}</Typography>
                                    <Typography sx={classes.subtitle}>{subtitle}</Typography>
                                </Stack>
                            </Stack>}
                        {isActiveHijir && <Stack p={2} spacing={"14px"} sx={{ width: "130px" }}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                {hijirIcon}
                                <Typography sx={classes.hijirText}>Hijiri</Typography>
                            </Stack>
                            <Stack spacing={"4px"}>
                                <Typography sx={{...classes.title, whiteSpace: "normal", wordBreak: "break-word"}}>{hijirTitle}</Typography>
                                {/* <Typography sx={classes.subtitle}>{hijirSubtitle}</Typography> */}
                            </Stack>
                        </Stack>}
                    </Stack>
            }
        </>
    )
}