import * as React from "react"
const FurnishingTypeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M11 2.5a4.462 4.462 0 0 0-2.908 1.075 1.133 1.133 0 0 1-.732.3H5.271A2.989 2.989 0 0 0 2.3 6.637 2.532 2.532 0 0 0 0 9.146a2.5 2.5 0 0 0 1.833 2.38v3.12a3.446 3.446 0 0 0 1.833 3.036v1.089a.688.688 0 1 0 1.375 0v-.7c.076.005.152.012.229.012h11.466c.075 0 .148-.007.222-.012v.7a.688.688 0 1 0 1.375 0v-1.086a3.444 3.444 0 0 0 1.84-3.03v-3.127A2.5 2.5 0 0 0 22 9.146a2.532 2.532 0 0 0-2.3-2.509 2.989 2.989 0 0 0-2.968-2.762H14.64a1.132 1.132 0 0 1-.732-.3A4.462 4.462 0 0 0 11 2.5Zm0 1.375a3.072 3.072 0 0 1 2.012.744 2.513 2.513 0 0 0 1.627.631h2.089a1.588 1.588 0 0 1 1.59 1.463A2.991 2.991 0 0 0 16.042 9.6v1.427a2.49 2.49 0 0 0-1.142-.277H7.1a2.49 2.49 0 0 0-1.146.281V9.6a2.991 2.991 0 0 0-2.277-2.891A1.588 1.588 0 0 1 5.271 5.25H7.36a2.51 2.51 0 0 0 1.627-.631A3.072 3.072 0 0 1 11 3.875Zm-3.9 2.75a.688.688 0 1 0 .688.688.687.687 0 0 0-.687-.687Zm3.9 0a.688.688 0 1 0 .688.688.688.688 0 0 0-.688-.688Zm3.9 0a.688.688 0 1 0 .688.688.687.687 0 0 0-.688-.688ZM2.521 8h.346a.688.688 0 0 0 .177.006 1.59 1.59 0 0 1 1.54 1.6v5.042a.688.688 0 0 0 .687.688h11.458a.688.688 0 0 0 .688-.687V9.6a1.59 1.59 0 0 1 1.54-1.6.688.688 0 0 0 .175-.006h.348a1.146 1.146 0 0 1 0 2.292.688.688 0 0 0-.686.688v3.673a2.052 2.052 0 0 1-2.062 2.055H5.271a2.052 2.052 0 0 1-2.062-2.062v-3.661a.688.688 0 0 0-.687-.687 1.146 1.146 0 1 1 0-2.292Zm6.417.458a.688.688 0 1 0 .688.688.688.688 0 0 0-.688-.688Zm4.125 0a.688.688 0 1 0 .688.688.688.688 0 0 0-.688-.688ZM7.1 12.125h7.8a1.136 1.136 0 0 1 1.146 1.146v.688H5.958v-.687A1.136 1.136 0 0 1 7.1 12.125Z"
      data-name="Group 138167"
    />
  </svg>
)
export default FurnishingTypeIcon
