import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    qrSection: {
        position: "relative",
        top: "40%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    },
    
    scanQrBox: {
        backgroundColor: "#E6E6E6",
        position:"relative"
    },
    serviceTitle: {
        color: "#091B29",
        fontSize: "1rem",
        fontFamily: SemiBold
    },
    unitChangerBox: {
        backgroundColor: "#FFFFFF",
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "6px",
    },
    unitChangerText: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "text.Teritary"
    },
    unitChangersubtext: {
        fontFamily: Regular,
        color: "text.secondary",
        fontSize: "0.75rem",
    },
    switchDrawerBox: {
        border: "1px solid #CED3DD",
        borderRadius: "12px",
        padding: "16px"
    },
    applianceText: {
        fontSize: "0.875rem",
        color: "text.main",
        fontFamily: Bold
    },
    divider: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        marginInline: "6px"
    },
    unitDetailsBox: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        padding: "40px"
    },
    change: {
        color: "#F15A29",
        fontSize: "0.75rem",
        fontFamily: Bold,
        cursor: "pointer"
    },
    horizontalDivider: {
        marginTop: "12px",
        marginBottom: "12px"
    },
    image: {
        backgroundColor: "#F2F4F7",
        border: "1px solid #E4E8EE",
        padding: "42px",
        borderRadius: "12px",
        display: "inline-block"
    },
    conition: {
        backgroundColor: "#EEF9EE",
        borderRadius: "6px",
        padding: "8px 16px",
        position: "relative",
        marginTop: "20px",
        display: "inline-block"
    },
    exampleText: {
        color: "#5AC782",
        fontFamily: SemiBold,
        fontSize: "0.75rem"
    },
    assetText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.Teritary"
    },
    drawerDivider: {
        marginTop: "16px",
        marginBottom: "16px"
    },
    assetSubtext: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.main"
    },
    detailsDrawerText: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "text.main",
        marginTop: "20px"
    },
}));
