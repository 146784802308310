import * as React from "react"
const SQRTICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
    <path
      fill="#588218"
      d="M12.972 1.5H4.028A2.531 2.531 0 0 0 1.5 4.028v8.944A2.531 2.531 0 0 0 4.028 15.5h8.944a2.531 2.531 0 0 0 2.528-2.528V4.028A2.531 2.531 0 0 0 12.972 1.5Zm1.361 11.472a1.363 1.363 0 0 1-1.361 1.361H4.028a1.363 1.363 0 0 1-1.361-1.361V4.028a1.363 1.363 0 0 1 1.361-1.361h1.944a.586.586 0 0 1 .339.109L9.034 4.72a.584.584 0 0 1-.678.95l-2.57-1.836H4.028a.2.2 0 0 0-.194.194v8.944a.2.2 0 0 0 .194.194h2.917v-3.5h-.972a.584.584 0 0 1 0-1.167h3.111a.584.584 0 1 1 0 1.167h-.972v3.5h4.861a.2.2 0 0 0 .194-.194V9.667h-1.361a.584.584 0 0 1 0-1.167h1.361V4.028a.2.2 0 0 0-.194-.194h-2.334a.584.584 0 0 1 0-1.167h2.333a1.363 1.363 0 0 1 1.361 1.361Z"
      data-name="Group 137913"
    />
  </svg>
)
export default SQRTICon
