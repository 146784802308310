import * as React from "react"
const TimelineDotIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 138695" transform="translate(-48.5 -8)">
      <g
        fill="#fff"
        stroke="#6c757d"
        data-name="Ellipse 130951"
        transform="translate(48.5 8)"
      >
        <circle cx={10} cy={10} r={10} stroke="none" />
        <circle cx={10} cy={10} r={9.5} fill="none" />
      </g>
      <circle
        cx={5}
        cy={5}
        r={5}
        fill="#6c757d"
        data-name="Ellipse 130952"
        transform="translate(53.5 13)"
      />
    </g>
  </svg>
)
export default TimelineDotIcon
