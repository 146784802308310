
import { Bold, Regular } from "../../utils";



export const KycCardStyles = {
    root:{
        backgroundColor:"text.contrastText",
        cursor:"pointer",

        
    },
    subText:{
        color:"text.secondary",
        fontSize: "0.75rem",
        fontFamily:Regular,
        display:"flex",
        flexWrap:"wrap"
    },
    valid:{
        color:"status.success",
        backgroundColor:"status.light",
        padding:"2px 4px",
        fontFamily:Bold,
        borderRadius:"4px",
        fontSize: "0.75rem",
    },
    divider: {
        margin: "6px 0px 8px",
        borderBottom: "1px solid #5C86CB2E",        
    },
    expired:{
        color:"text.secondary",
        backgroundColor:"border.secondary",
        padding:"2px 4px",
        fontFamily:Bold,
        borderRadius:"4px",
        fontSize: "0.75rem",

    },
    title:{
        color:"text.main",
        fontFamily:Bold,
        fontSize: "0.875rem",
        marginBottom:"6px"
    },
    avatar:{
        position:"relative",
    },
    badgeBox:{
        position:"absolute",
        right:"-4px",
        bottom:"-4px",
        zIndex:1
    },
    avatarOuter: {
        height: "128px",
        width: "128px",
        border: "2px solid",
        borderColor: 'background.main',
        // margin:"0 auto"
    },
    avatarInner: {
        border: "3px solid",
        borderColor: 'primary.contrastText',
        backgroundColor: "border.secondary",
        marginTop: "90px",
        marginLeft: "-25px",
        height: "30px",
        width: "30px",
        cursor:"pointer"
    },
    image: {
        height: "167px",
        width: "167px", 
    },


    }