import {
    Box,
    Grid,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import * as React from "react";
import Slider from "react-slick";
import { KeyboardArrowRight } from "@mui/icons-material";
import { RequestCardStyle } from "./style";
import { ChargesIcon } from "../assets/chargesIcon";
import { ProjectedBOM } from "../assets/projectedBOMIcon";
import { VisitIcon } from "../assets/visitIcon";
import { AssetIcon } from "../assets/assetIcon";
import { LocationIcon } from "../assets/locationIcon";
import { CalendarIcon } from "../assets/calendarIcon";
import { RequestIcon } from "../assets/requestIcon";
import useWindowDimensions from "../../../useDimension";
import { generateImageUrl, img_size } from "../../../utils";
import { RequestPlaceHolder } from "../assets/requestPlaceholder";
import MicIcon from '@mui/icons-material/Mic';

const RequestCard = (props) => {
    const {
        name = "",
        reqType = "",
        date = "",
        unitId = "",
        img = null,
        handleRoute = () => false,
        t = () => false,
        action = [],
        asset_name = "",
        property_name = "",
        request_status = "",
        unit_type="",
        image_type = "",
    } = props;

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };
    const language = localStorage.getItem("i18nextLng")
    const size = useWindowDimensions();
    const classes = RequestCardStyle({ size });
    const switchRequestStatusColor = (val) => {
        switch (val) {
            case "Open": {
                return "#78B1FE"
            }
            case "Closed": {
                return "#5AC782"
            }
            case "Cancelled": {
                return "#FF4B4B"
            }
            case "Assigned": {
                return "#ffd700"
            }
            case "Reopened": {
                return "#FF9340"
            }
            case "Upfront Charges": {
                return "#6DAFB3"
            }
            case "Projected BOM": {
                return "#896DB3"
            }
            case "Upcoming Visit Approval": {
                return "#B3776D"
            }
            default: {
                return "#78B1FE"
            }
        }
    }
    return (
        <Paper elevation={0} sx={{ borderRadius: "12px", boxShadow: "#F6F6F6 0pt 3pt 30pt" }}>
            <Slider {...settings}>
                {action?.length > 0 && action?.map((val) => {
                    return (
                        <>
                            <Box display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: switchRequestStatusColor(val?.name), borderRadius: "12px 13px 0px 0px", padding: "4px 12px" }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    {val?.name === "Upfront Charges" ? <ChargesIcon /> : val?.name === "Projected BOM" ? <ProjectedBOM /> : <VisitIcon />}
                                    <Typography sx={classes.actionText}>{`${val?.name} ${val?.status}`}</Typography>
                                </Stack>
                                <KeyboardArrowRight sx={{ color: "#FFFFFF" }} />
                            </Box>
                        </>
                    )
                })}
            </Slider>
            <Box p={1.5} onClick={() => handleRoute()}>
                <Grid container spacing={2} sx={{ cursor: "pointer" }}>
                    <Grid item xs={3} sm={1.8}>
                        <Box sx={classes.imageBox} style={{ position: "relative",marginLeft:"2px" }}>
                            {img
                                ? image_type === "mp4"
                                    ? (img
                                        ? <video muted autoPlay={false}
                                            style={{
                                                height: "75px",
                                                width: "75px",
                                                borderRadius: "6px",
                                                marginTop: "12px",
                                            }}>
                                            <source src={img + "#t=0.001"} type="video/mp4" />
                                        </video>
                                        : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                                    : ["wav", "mpeg"]?.includes(image_type)
                                        ? (img
                                            ? <Box
                                                style={{
                                                    height: "75px",
                                                    width: "75px",
                                                    borderRadius: "6px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                <MicIcon fontSize="large" htmlColor="#5078e1" />
                                            </Box>
                                            : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                                        : <img
                                            src={generateImageUrl(img, img_size.small_square)}
                                            alt="Repair"
                                            style={img !== null ? classes.image : classes.placeholderImg}
                                        />
                                : <RequestPlaceHolder />}
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={9.8}>
                        <Stack direction={"column"} flexGrow={1}>
                            <Box>
                                <Typography sx={{ ...classes.header, maxWidth: size?.width - 155 }} noWrap>
                                    {name}
                                </Typography>
                            </Box>
                            {asset_name !== null && asset_name?.length > 0 &&
                                <Box mt={0.5}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <AssetIcon />
                                        <Typography sx={classes.subHeader}>{asset_name}</Typography>
                                    </Stack>
                                </Box>
                            }
                               {(asset_name===null||asset_name==="")&&unit_type !== null && unit_type?.length > 0 &&
                                <Box mt={0.5}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <AssetIcon />
                                        <Typography sx={classes.subHeader}>{unit_type}</Typography>
                                    </Stack>
                                </Box>
                            }
                            <Box mt={0.5}>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    <LocationIcon />
                                    <Typography sx={classes.address}>{`${unitId} , ${property_name}`}</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={3} sm={1.8}>
                        <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ ...classes.progress, backgroundColor: switchRequestStatusColor(request_status) }}
                        >
                            {t(request_status)}
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={9.8}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>

                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <CalendarIcon />
                                <Typography sx={classes.subdetails}>&#x202a;{format(new Date(date), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}&#x202c;</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <RequestIcon />
                                <Typography sx={classes.subdetails}>{reqType}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

            </Box>
        </Paper>
    );
};

export default RequestCard;
