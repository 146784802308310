import { TextBox } from "./textBox"
import { Box, Grid, IconButton, InputAdornment, Typography } from "@mui/material"
import { welcomePageStyle } from "./style"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import React from "react"
import { DialogDrawer, FooterComponent } from "../../components";
export const LoginForm = ({
    t = () => false,
    updateState,
    login,
    onClick = "",
    forgotdata = {}

}) => {

    const [showPassword, setShowPassword] = React.useState();
    const [drawer, setDrawer] = React.useState(false);

    const handleDrawerClose = () => {
        setDrawer(false);
    };

    const handleDrawerOpen = () => {
        setDrawer(!drawer);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        if ((forgotdata?.isError || forgotdata?.isSuccess)) {
            setDrawer(false);
        }
    }, [forgotdata?.isError, forgotdata?.isSuccess])


    return (
        <Box sx={welcomePageStyle?.root}>
            <Box>
                <TextBox
                    type="text"
                    value={login?.email ?? ""}
                    onChange={(e) => updateState("email", e.target.value)}
                    label={t("Email ID")}
                    placeholder={t("Enter your Email ID")}
                    helperText={login?.error?.email}
                    isError={login?.error?.email?.length > 0}
                    errorMessage={login?.error?.email}
                    isRequired
                    sx={welcomePageStyle.textbox}
                />
            </Box>
            <Box sx={welcomePageStyle?.heightBox} />
            <Box>
                <TextBox
                    value={login?.password ?? ""}
                    onChange={(e) => updateState("password", e.target.value)}
                    label={t("Password")}
                    placeholder={t("Enter Password")}
                    helperText={login?.error?.password}
                    isError={login?.error?.password?.length > 0}
                    errorMessage={login?.error?.password}
                    isRequired
                    sx={welcomePageStyle.textbox}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? (
                                    <RemoveRedEyeOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }

                />
                <Box sx={welcomePageStyle?.heightBox} />
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        justifyContent={"end"}
                        alignItems={"center"}
                    >
                        <Typography
                            sx={welcomePageStyle?.forgetPasswordTextStyle}
                            onClick={handleDrawerOpen}
                        >
                            {t("Forgetpassword")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {/* forgot password drawer */}
            <DialogDrawer
                maxWidth="sm"
                open={drawer}
                onClick={handleDrawerClose}
                header={t("Reset Password")}
                height_style={
                    { height: "auto" }
                }
                component={
                    <>
                        <Box>
                            <TextBox
                                type="text"
                                value={login?.forgotEmail ?? ""}
                                onChange={(e) => updateState("forgotEmail", e.target.value)}
                                label={t("Enter your email")}
                                placeholder={t("Enter your email")}
                                helperText={login?.error?.forgotEmail}
                                isError={login?.error?.forgotEmail?.length > 0}
                                errorMessage={login?.error?.forgotEmail}
                                isRequired
                                sx={welcomePageStyle.textbox}
                            />
                        </Box>
                    </>
                }
                footer={
                    <>
                        {/* footer Component */}
                        <FooterComponent
                            containedText={t("SUBMIT")}
                            single={true}
                            containedonClick={onClick}
                            btnLoading={forgotdata?.isLoading}
                        />

                    </>

                }

                onClose={() => handleDrawerOpen(true)}
            />
        </Box>
    )
}
