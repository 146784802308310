import { Button, Stack } from "@mui/material"
import { useEffect, useState } from "react";

export const ReplayVideo = ({
  t = () => false,
  styles = {},
  videoBlob = {},
  handleRetakeVideo = () => false,
  handleReplayVideoDialogClose = () => false,
  handleAddVideo = () => false,
}) => {
  const [posterImage, setPosterImage] = useState("");

  useEffect(() => {
    videoBlob && blobToImage?.(videoBlob)?.then?.((image) => {
      document?.body?.appendChild?.(image);
      const canvas = document?.createElement?.('canvas');
      canvas.width = image?.width;
      canvas.height = image?.height;
      const ctx = canvas?.getContext?.('2d');
      ctx?.drawImage?.(image, 0, 0);
      const pngDataUrl = canvas?.toDataURL?.('image/png');
      setPosterImage(pngDataUrl);
    });
  }, [videoBlob])

  const blobToImage = (blob) => {
    return new Promise((resolve) => {
      const url = URL?.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL?.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;
      img.style.display = "none";
    });
  };

  return (
    <Stack rowGap={"16px"}>
      {videoBlob &&
        <video controls style={{ width: '100%' }} playsInline poster={posterImage}>
          <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
        </video>}
      <Stack direction={"row"} columnGap={"8px"} p={"16px"} justifyContent={"space-between"} alignItems={"center"}>
        <Button sx={styles.cancelButton} fullWidth
          onClick={handleReplayVideoDialogClose}>
          {t("Cancel")}
        </Button>
        <Button fullWidth sx={styles.reTakeButton}
          onClick={handleRetakeVideo}>
          {t("Re-Take")}
        </Button>
        <Button fullWidth sx={styles.addVideoButton}
          onClick={handleAddVideo}>
          {t("Add")}
        </Button>
      </Stack>
    </Stack>
  )
}