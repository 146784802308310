
import { SemiBold } from "../../utils";



export const FooterComponentStyles = {



    root: {
        padding: "16px",
        backgroundColor: "primary.contrastText",
        borderColor: "border.Teritary",
        width:"100% !important"
    },
    root1: {
        
        backgroundColor: "primary.contrastText",
        width:"100% !important"
    },
    outlinedBtn: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.main",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "border.Teritary",
        width: "100% !important",
        padding: "16px 0px",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "primary.contrastText",
            borderColor: "border.secondary",
        },
        "& p": {
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            color: "text.main",
        }
    },
    customOutlinedBtn:{
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.main",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "#F15A29",
        width: "100% !important",
        padding: "16px 0px",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "primary.contrastText",
            borderColor: "border.secondary",
        },
        "& p": {
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            color: "#F15A29",
        }
    },
    outlinedSingleBtn: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "primary.contrastText",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "primary.main",
        width: "100% !important",
        padding: "16px 0px",
        textTransform: "capitalize",
        "& p": {
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            color: "primary.contrastText",
        }
    },
    containedBtn: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "primary.contrastText",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "primary.main",
        width: "100% !important",
        padding: "16px 8px",
        textTransform: "capitalize",
        marginInlineStart: "16px",
        "&:hover": {
            backgroundColor: "primary.main",
            color: "error.contrastText",
            border: "1px solid",
            borderColor: "primary.main"
        },
        "& p": {
            fontSize: "0.875rem",
            fontFamily: SemiBold,
            color: "primary.contrastText",
        }
    }
}