import { Box, Container, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterComponent, FormGenerator, TopNavBar } from "../../components";
import { useLazyGetKycCountryQuery, useLazyGetKycCreationQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { SemiBold, errorRoute } from "../../utils";
import { KycInformationStyle } from './style';


const initial = {
  name: "",
  number: "",
  start_date: "",
  end_date: "",
  country: "",
  type: [{
    label: "",
    value: ""
  }],
  images: [],
  error: {
    name: "",
    number: "",
    start_date: "",
    end_date: "",
    country: "",
    type: "",
    images: [],
  }


}

export const KycAddId = ({
  t = () => false,
}) => {

  const [data, setData] = useState({ ...initial });
  const [loading1, setLoading1] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const { enqueueSnackbar  , closeSnackbar} = useSnackbar()
  const navigate = useNavigate();
  let location = useLocation();
  const [KycCreation, { error }] = useLazyGetKycCreationQuery();
  const [KycCountry, { error: isError }] = useLazyGetKycCountryQuery();

  // updateState
  const updateState = (k, v) => {
    let error = data?.error;
    error[k] = "";
    setData({ ...data, [k]: v, error });
  };
  // add proof btn
  const save = async () => {
    if (validate() && !isDisableBtn) {
      setIsDisableBtn(true)
      const payload = {
        name: data?.name,
        number: data?.number,
        valid_to: data?.end_date,
        valid_from: data?.start_date,
        is_mandatory: false,
        country_id: data?.country?.value,
        assets: data?.images?.map((x) => {
          return {
            ...x,
            // file_meta: JSON.stringify(x?.file_meta),
            src: x?.url,
            name: x?.file_meta?.name,
            type: x?.file_meta?.type
          }
        }) ?? [],
        proof_id: location?.state,
        proof_type: "Contact",
        kyc_type: "Contact"
      }
      let kycProofRes = await KycCreation(payload)
      if (kycProofRes?.data?.type === "success") {
        setIsDisableBtn(false)
        enqueueSnackbar(t(`Kyc Created Successfully`), {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
          className: { fontFamily: SemiBold }
        })
        setTimeout(()=>{
          closeSnackbar()
      },2000)
      } else {
        setIsDisableBtn(false)
        enqueueSnackbar(t(`Kyc Created Unsuccessfully`), {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
          className: { fontFamily: SemiBold }
        })
        setTimeout(()=>{
          closeSnackbar()
      },2000)
      }
      navigate(AppRoutes.kycInformation)

    }
  }
  // validation
  const validate = () => {
    let isValid = true;
    let error = data.error
    if (data?.name.length === 0) {
      isValid = false
      error.name = t("Proof Name is required")
    }
    if (data?.number.length === 0) {
      isValid = false
      error.number = t("Proof Number is required")
    }
    if (data?.start_date.length === 0) {
      isValid = false
      error.start_date = t("Valid Date is required")
    }
    if (data?.end_date.length === 0) {
      isValid = false
      error.end_date = t("End Date is required")
    }
    if (data?.country.length === 0) {
      isValid = false
      error.country = t("Country is required")
    }
    // if (data?.images.length === 0) {
    //   isValid = false
    //   error.images = "Proof is required"
    // }


    setData({ ...data, error })
    return isValid
  }
  // country loading functions
  const loadOptions = async (search = "", array, type) => {

    setLoading1(type);
    let result,
      offset = 0;

    if (!array?.length) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    // switch (type) {
    //   case "country":
    result = await KycCountry({
      offset,
      limit: 10,
      search
    })

    setLoading1(null);
    return {
      options: [...result?.data?.data?.map((x) => {
        return {
          value: x?.id,
          label: x?.country_name
        }
      })],
      hasMore:
        (array?.length + result?.data?.data?.length) < result?.data?.count,
    };


    //   default:
    //     return { options: [] };

    // }
  };
  React.useEffect(() => {
    if (error?.status === 423 || isError?.status === 423) return errorRoute()
  }, [error, isError])

  return (
    <Container
      sx={KycInformationStyle.container}
      maxWidth="sm"
    >
      {/* topNavbar */}
      <Box sx={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>

        <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
          <TopNavBar title={t("Add ID Proof")} isBack t={t} />
        </Container>
      </Box>

      {/* Kyc Add Id Proof */}
      <Box sx={KycInformationStyle.root}>
        <Box sx={KycInformationStyle.detailedBox}>
          <Typography sx={KycInformationStyle.title}>{t("Proof Details")}</Typography>
          <FormGenerator
            components={[
              {
                isActive: true,
                component: "text",
                label: t("Name As In Proof"),
                value: data?.name,
                placeholder: t("Name As In Proof"),
                onChange: (e) => updateState("name", e?.target?.value),
                error: data?.error?.name,
                isRequired: true,
                size: {
                  xs: 12,
                },
              },
              {
                isActive: true,
                component: "text",
                label: t("ID Proof Number"),
                value: data?.number,
                placeholder: t("ID Proof Number"),
                onChange: (e) => updateState("number", e?.target?.value),
                error: data?.error?.number,
                size: {
                  xs: 12,
                },
              },
              {
                isActive: true,
                component: "date",
                label: t("Valid From"),
                value: data?.start_date,
                placeholder: t("Valid From"),
                onChange: (value) => updateState("start_date", value),
                error: data?.error?.start_date,
                maxDate: new Date(),
                isRequired: true,
                size: {
                  xs: 6,
                },
                sx: {
                  border: "1.5px solid #e2e2e2",
                  borderRadius: "4px"
                }
              },
              {
                isActive: true,
                component: "date",
                label: t("Valid To"),
                value: data?.end_date,
                placeholder: t("Valid To"),
                onChange: (value) => updateState("end_date", value),
                error: data?.error?.end_date,
                minDate: new Date(),
                isRequired: true,
                size: {
                  xs: 6,
                },
                sx: {
                  border: "1.5px solid #e2e2e2",
                  borderRadius: "4px"
                }
              },
              {
                isActive: true,
                component: "select",
                label: t("Issuing Country"),
                value: data?.country,
                placeholder: t("Issuing Country"),
                onChange: (value) => updateState("country", value),
                error: data?.error?.country,
                loading: loading1 === "country",
                isRequired: true,
                isPaginate: true,
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(search, array, "country"),
                size: {
                  xs: 12,
                },
              },
            ]}

          />

        </Box>
        <Divider />
        <Box sx={KycInformationStyle.detailedBox2}>
          <Typography sx={KycInformationStyle.title}>{t("Proof Attachment")}</Typography>
          <FormGenerator
            components={[
              {
                t,
                isActive: true,
                component: "multiImage",
                value: data?.images,
                onChange: (e) => updateState("images", e),
                onDelete: (e) => updateState("images", e),
                error: data?.error?.images,
                multiline: true,
                text:t("Upload Image/PDF"),
                size: {
                  xs: 12,
                },
              },
            ]}

          />
        </Box>


      </Box>

      <Box sx={{ position: "fixed", bottom: "0px", left: 0, right: 0, zIndex: 999 }}>

<Container maxWidth="sm" sx={{ padding: "0px !important" }}>
<FooterComponent
        containedText={t("Add Proof")}
        containedonClick={() => save()}
        single={true}
        isDisableBtn={isDisableBtn}
        containedDisabled={isDisableBtn}
      />
</Container>
</Box>
      {/* FooterComponent */}
    


    </Container>

  );
};