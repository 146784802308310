import * as React from "react"
const PropBlockIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 137942">
      <path
        fill="#276add"
        d="M4.725 0A2.485 2.485 0 0 0 2.25 2.475v14.4A1.135 1.135 0 0 0 3.375 18h3.6A1.135 1.135 0 0 0 8.1 16.875v-1.8a.215.215 0 0 1 .225-.225h1.35a.215.215 0 0 1 .225.225v1.8A1.135 1.135 0 0 0 11.025 18h3.6a1.135 1.135 0 0 0 1.125-1.125v-14.4A2.485 2.485 0 0 0 13.275 0Zm0 1.35h8.55A1.115 1.115 0 0 1 14.4 2.475V16.65h-3.15v-1.575A1.585 1.585 0 0 0 9.675 13.5h-1.35a1.585 1.585 0 0 0-1.575 1.575v1.575H3.6V2.475A1.115 1.115 0 0 1 4.725 1.35Zm.9 1.35a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm-6.3 2.7a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm-6.3 2.7a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm-6.3 2.7a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Zm3.15 0a.675.675 0 1 0 0 1.35h.45a.675.675 0 1 0 0-1.35Z"
        data-name="icons8-building (4)"
      />
    </g>
  </svg>
)
export default PropBlockIcon
