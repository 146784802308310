import { Avatar, Box, Container, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { withTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate } from "react-router-dom"
import { LoderSimmer, TopNavBar } from "../../components"
import { useLazyGetAllApprovalsQuery } from "../../redux/services"
import { AppRoutes } from "../../router/routes"
import useWindowDimensions from "../../useDimension"
import { landlordApprovalstyle } from "./style"
import NoData from "../../components/noData"

const ApprovalList = ({ t = () => false, val = "" }) => {
    const navigate = useNavigate()
    const size = useWindowDimensions()
    const [data, setData] = React.useState([])
    const [offSet, setOffSet] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const [getAllApprovals] = useLazyGetAllApprovalsQuery()
    const getAllLandLordApprovals = async (offset = 0, limit = 10, search = "", fetchMore = false) => {

        const payload = {
            offset: offset,
            limit: limit,
            search: search
        }
        const response = await getAllApprovals(payload)
        const result = response?.data?.data?.data
        setLoading(false)
        if(fetchMore){
            setData([...data,...result])
        }
        else{
        setData(response?.data?.data?.data)
        }
    }

    React.useEffect(() => {
        getAllLandLordApprovals(0, 10, "")
        //eslint-disable-next-line
    }, [])
    const handleView = (data) => {
        navigate(AppRoutes?.landlordApprovalView, { state: data })
    }
    const fetchMoreData = () => {
        setOffSet(offSet + 10);
        getAllLandLordApprovals(offSet + 10, 10, "", true)
    }
    return (
        <Box>
            <Container
                maxWidth="sm"
                sx={landlordApprovalstyle.container}>
                {/* topNavbar */}
                <Box sx={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                        <TopNavBar isDirect title={t("Agreement Approvals")} isBack t={t} />
                    </Container>
                </Box>
                {loading ?
                    <Box height={size?.height - 100} m={2} sx={landlordApprovalstyle?.listCard}>
                        <LoderSimmer card count={9} size={70} variant={"rectangular"} animation={"wave"} />
                    </Box>
                    :
                    <InfiniteScroll
                        dataLength={data?.length ?? 0}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 100}
                    >
                        <Box m={2} sx={landlordApprovalstyle?.listCard}>
                            {data?.length > 0 ? data?.map((val, index) => {
                                return (
                                    <>
                                        <Box display={"flex"} justifyContent={"space-between"} onClick={() => handleView(val)}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Avatar variant="square" sx={landlordApprovalstyle?.approvalImage} src={val?.logo} />
                                                <Box>
                                                    <Typography sx={landlordApprovalstyle?.agreementTitle}>{val?.agreement_no}</Typography>
                                                    <Typography sx={landlordApprovalstyle?.approvalDate} noWrap>
                                                        {val?.city ? val?.city : ""} {(val?.state && val?.city) ? "," : ""}{val?.state ? val?.state : ""}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Box sx={landlordApprovalstyle?.approvalStatusBox} style={{ backgroundColor: val?.status === "Approved" ? "#EEF9EE" : val?.status === "Declined" ? "#FFECEC" : "#F1F7FF" }}>
                                                <Typography sx={landlordApprovalstyle?.approvalStatus} style={{ color: val?.status === "Approved" ? "#5AC782" : val?.status === "Declined" ? "#FF4B4B" : "#78B1FE" }}>{val?.status !== null ? val?.status : t("Waiting for approval")}</Typography>
                                            </Box>

                                        </Box>
                                        {data?.length - 1 !== index &&
                                            <Box mt={2} mb={2}><Divider /></Box>
                                        }
                                    </>
                                )
                            }) : <NoData />}


                        </Box>
                    </InfiniteScroll>
                }
            </Container>
        </Box>
    )
}
export default withTranslation('approval')(ApprovalList)