import * as React from "react"
const MarketPriceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.399}
    height={16.25}
    {...props}
  >
    <path
      fill="#0944a8"
      stroke="#0944a8"
      strokeWidth={0.25}
      d="M9.875.125a2.381 2.381 0 0 0-1.7.7l-1.48 1.479a6.248 6.248 0 0 1 .531 1.166l1.8-1.8a1.19 1.19 0 0 1 .849-.345h4.4a.8.8 0 0 1 .8.8v4.4a1.2 1.2 0 0 1-.352.849l-7.2 7.2a1.228 1.228 0 0 1-1.7 0l-4-4a1.2 1.2 0 0 1 0-1.7l.22-.22a3.993 3.993 0 0 1-1.077-.618 2.4 2.4 0 0 0 .009 3.389l4 4a2.4 2.4 0 0 0 3.393 0l7.2-7.2a2.384 2.384 0 0 0 .7-1.7v-4.4a2 2 0 0 0-2-2Zm-6.427 0a.6.6 0 0 0-.339.123 9.967 9.967 0 0 0-1.393 1.366A5.325 5.325 0 0 0 .275 4.925a3.2 3.2 0 1 0 6.4 0 5.325 5.325 0 0 0-1.441-3.311A9.966 9.966 0 0 0 3.841.249a.6.6 0 0 0-.393-.124Zm.027 1.435a5.221 5.221 0 0 1 .841.826 4.371 4.371 0 0 1 1.159 2.539 1.99 1.99 0 0 1-.9 1.67 1.192 1.192 0 0 0 .1-.47 3.1 3.1 0 0 0-.917-1.883.4.4 0 0 0-.566 0 3.1 3.1 0 0 0-.917 1.883 1.192 1.192 0 0 0 .1.47 1.99 1.99 0 0 1-.9-1.67 4.371 4.371 0 0 1 1.159-2.539 5.22 5.22 0 0 1 .841-.826Zm8.8 1.365a1.2 1.2 0 1 0 1.2 1.2 1.2 1.2 0 0 0-1.2-1.2Z"
    />
  </svg>
)
export default MarketPriceIcon
