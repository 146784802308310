import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { getHeader } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const data = getHeader()
        if (headers) {
            data?.map((x) => {
                return headers.set(x?.label, x?.value);
            })
        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const projectAPI = createApi({
    reducerPath: "projectAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Projects"],
    endpoints: (builder) => ({
        // Get All User API
        getAllUsers: builder.query({
            query: (payload) => ({ url: `user`, method: "GET" }),
            transformResponse: (response, meta, arg) => {
                if (response.code === 200 && response.is_success) {
                    return response.data;
                }
                return [];
            },
        }),

        // Get User API
        getUser: builder.query({
            query: (payload) => ({
                url: `${payload.id ? `/${payload.id}/` : "/"}`,
                method: "GET",
            })
        }),

        // get all owner units
        getAllProjects: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `owner/get_all_owner_units`, method: "POST", body: payload,
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),


        // get all owner units
        getAllUnitCategory: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `unit-category-master/getUnitCategory`, method: "POST", body: payload,
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // get owner units
        getOwnerUnit: builder.query(

            {
                query: (payload) => {

                    return {
                        url: `owner/get_owner_unit`, method: "POST", body: payload
                    }
                },


                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),


        // Create User API
        createUser: builder.mutation({
            query: ({ email = "", name = "", country_code = "", contact_number = "" }) => ({
                url: `user/`,
                method: "POST",
                headers: getHeader(),
                body: { email, name, country_code, contact_number }
            }),
        }),

        // Update User API
        updateUser: builder.mutation({
            query: (payload) => ({
                url: `user/${payload.id}/`,
                method: "PATCH",
                body: payload.body,
            }),
        }),

        // Delete User API
        deleteUser: builder.mutation({
            query: ({ id }) => ({ url: `user/${id}/`, method: "DELETE" }),
        }),

        getAllPayment: builder.query(

            {
                query: (payload) => {

                    return {
                        url: `owner/get_payment_details`, method: "POST", body: payload
                    }
                },


                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        getInvoicePdf: builder.query(
            {
                query: (payload) => {
                    return {
                        url: "invoice/template", method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    return response?.data
                }
            }
        ),

        getOwnerProperty: builder.query(

            {
                query: (payload) => {

                    return {
                        url: `owner/get_property_details`, method: "POST", body: payload
                    }
                },


                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        getProjectDetails: builder.query(

            {
                query: (payload) => {

                    return {
                        url: `owner/get_project_details`, method: "POST", body: payload
                    }
                },


                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        getAgreementPdf: builder.query(
            {
                query: (payload) => {
                    return {
                        url: "agreement/template", method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data };
                    }
                    return { data: [], totalCount: 0 };
                }
            }
        ),
        getAgreementAsset: builder.query(
            {
                query: (payload) => {
                    return {
                        url: "agreement/get_asset", method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    // if (response.type === "success") {

                    // }
                    // return { data: [], totalCount: 0 };

                    return { data: response };
                }
            }
        ),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetAllProjectsQuery,
    useLazyGetOwnerUnitQuery,
    useLazyGetAllUnitCategoryQuery,
    useLazyGetAllPaymentQuery,
    useLazyGetInvoicePdfQuery,
    useLazyGetOwnerPropertyQuery,
    useLazyGetProjectDetailsQuery,
    useLazyGetAgreementPdfQuery,
    useLazyGetAgreementAssetQuery
} = projectAPI;
