import React from 'react'
import { DialogDrawer, TopNavBar } from '../../components'
import {
    Container, Box, Stack, Divider, Typography, Grid,
    Avatar,
    // Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineContent, TimelineConnector
} from '@mui/material'
import { ImageSlider } from '../../components/imageSlider'
import { useLocation, useNavigate } from "react-router-dom";
import { PropertyUnitNewStyle } from './style';
import PropertyDateIcon from './utils/propertyDateIcon';
import TimelineDotIcon from './utils/timelineDotIcon';
import {
    Timeline, TimelineConnector, TimelineContent, TimelineDot,
    TimelineItem, TimelineSeparator,
    timelineItemClasses
} from "@mui/lab";
import NoTimelineImg from './utils/noTimelineImg';
// import TickIcon from './utils/tickIcon';
// import ScrollContainer from 'react-indiana-drag-scroll';
import { useLazyGetProjectDetailsQuery } from '../../redux/services';
import moment from 'moment';
import { stringAvatar } from '../../utils';
import { withTranslation } from 'react-i18next';
import { PropertyDetailSimmerPage } from './propertyDetailSimmerPage';
// import ReactQuill from 'react-quill';
import { AppRoutes } from '../../router/routes';

const PropertyDetails = ({
    t = () => false
}) => {
    const navigate = useNavigate();
    const classes = PropertyUnitNewStyle
    const location = useLocation()
    const [getProjectDetails] = useLazyGetProjectDetailsQuery()
    const [loading, setLoading] = React.useState(false)
    const [view, setView] = React.useState(false)
    const [data, setData] = React.useState("")
    const [viewDesc, setViewDesc] = React.useState({
        bool: false,
        data: ""
    })

    React.useEffect(() => {
        getProjectDetailsData()
        // eslint-disable-next-line
    }, [])

    const getProjectDetailsData = async () => {
        setLoading(true)
        const res = await getProjectDetails({
            id: location?.state?.id,
            property_id: location?.state?.data?.property_id,
            unit_id: location?.state?.data?.unit_id,
            block_id: location?.state?.data?.block_id,
        })
        await setLoading(false)
        await setData(res?.data?.data?.[0])
    }

    const handleProgressViewAllAsset = (images) => {
        navigate(AppRoutes.assetViewPage, { state: { assets: images, hideVideoTab: true } })
    }

    const handlePropertyViewAllAsset = () => {
        navigate(AppRoutes.assetViewPage, { state: { assets: data?.project_progress?.[0]?.property_details?.assets } })
    }

    return (
        <>
            <TopNavBar title={location?.state?.type === "project" ? t("Project Progress Update") : t("Property Details")} isDirect route={() => navigate(-1)} t={t} />
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: "0px !important" }}>
                {
                    loading ?
                        <Box p={2}>
                            <PropertyDetailSimmerPage />
                        </Box> :
                        <Box p={2} sx={{ ...classes.body, backgroundColor: "#fff" }}>
                            <ImageSlider hidelabel image={data?.project_progress?.[0]?.property_details?.assets?.filter((i)=> (i?.file_meta?.type !== "pdf" && i?.file_meta?.type !== undefined))} 
                                handleViewAll={handlePropertyViewAllAsset}/>

                            <Stack spacing={"4px"} mt={2}>
                                <Typography sx={classes.unitTitle}>{data?.project_progress?.[0]?.property_details?.name}</Typography>
                                {
                                    data?.project_progress?.[0]?.property_details?.address ?
                                        <Typography sx={classes.secText}>
                                            {data?.project_progress?.[0]?.property_details?.address?.street_1 ?? ""}{data?.project_progress?.[0]?.property_details?.address?.city && ", "}{data?.project_progress?.[0]?.property_details?.address?.city ?? ""}{data?.project_progress?.[0]?.property_details?.address?.state && ", "} {data?.project_progress?.[0]?.property_details?.address?.state ?? ""}{data?.project_progress?.[0]?.property_details?.address?.zipcode && " - "}{data?.project_progress?.[0]?.property_details?.address?.zipcode}
                                        </Typography>
                                        :
                                        <Typography sx={classes.secText}>-</Typography>
                                }
                            </Stack>

                            <Box py={"12px"}><Divider></Divider></Box>

                            <Stack spacing={"4px"}>
                                <Typography sx={classes.secText}>{data?.title}</Typography>
                                <Typography sx={classes.secItalicText}>{data?.project_no}</Typography>
                            </Stack>
                            <Box py={"12px"}><Divider></Divider></Box>
                            <Stack spacing={"4px"}>
                                <Typography sx={classes.propertySubTitle}>{t("Project description")}</Typography>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.descText}>{data?.description}</Typography>
                                    <Typography sx={classes.viewallText} onClick={() => setView(true)}>{t("View More")}</Typography>
                                </Stack>
                            </Stack>

                            {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={classes.propertyBox} mt={2}>
                        <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                            <MaskPropertyIcon />
                            <Stack spacing={"4px"}>
                                <Typography sx={classes.unitAndSqrtText}>Casa Grande - Premium</Typography>
                                <Typography sx={classes.secText}>Neelangarai, Chennai</Typography>
                            </Stack>
                        </Stack>
                        <PropertyRedirectIcon />
                    </Stack> */}


                            <Grid container spacing={2} mt={"12px"}>
                                <Grid item xs={6}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"8px"} sx={classes.propertyBox}>
                                        <PropertyDateIcon />
                                        <Stack spacing={"4px"}>
                                            <Typography sx={classes.secText}>{t("Project start date")}</Typography>
                                            <Typography sx={classes.unitAndSqrtText}>{data?.planned_start_date ? moment(data?.planned_start_date).format("DD MMM YY") : "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={"8px"} sx={classes.propertyBox}>
                                        <PropertyDateIcon />
                                        <Stack spacing={"4px"}>
                                            <Typography sx={classes.secText}>{t("Tentative end date")}</Typography>
                                            <Typography sx={classes.unitAndSqrtText}>{data?.planned_end_date ? moment(data?.planned_end_date).format("DD MMM YY") : "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {/* <Stack mt={2}>
                        <Typography sx={classes.secondaryText}>Facilities</Typography>
                        <Box mt={"12px"}>
                            <ScrollContainer className="scroll-container">
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    {
                                        [1, 2, 4, 5, 3, 3, 5].map((e) => {
                                            return (
                                                <Stack direction={"row"} spacing={1} alignItems={"center"} sx={classes.facilityBox}>
                                                    <TickIcon />
                                                    <Typography sx={classes.propertyTitle}>Swimming Pool</Typography>
                                                </Stack>
                                            )
                                        })
                                    }
                                </Stack>
                            </ScrollContainer>
                        </Box>
                    </Stack> */}

                            <Box py={"12px"}><Divider></Divider></Box>

                            <Typography sx={classes.propertySubTitle}>{t("Timeline")}</Typography>
                            {
                                data?.project_progress?.length === 0 ?
                                    <Box sx={{ ...classes.normalBox, display: "flex", justifyContent: "center", alignItems: "center", marginTop: "16px" }}>
                                        <Stack spacing={1} alignItems={"center"}>
                                            <NoTimelineImg />
                                            <Typography sx={classes.noDataText}>{t("No Data Found")}</Typography>
                                        </Stack>
                                    </Box>
                                    :
                                    <Timeline
                                        sx={{
                                            paddingRight: "0px !important",
                                            [`& .${timelineItemClasses.root}:before`]: {
                                                flex: 0,
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        {
                                            data?.project_progress?.map((e, i, length) => {
                                                return (
                                                    <TimelineItem>
                                                        <TimelineSeparator>
                                                            <TimelineDot sx={{
                                                                margin: "0px !important",
                                                                boxShadow: "none",
                                                                padding: "0px !important",
                                                                background: "transparent !important"
                                                            }}>
                                                                <TimelineDotIcon />
                                                            </TimelineDot>
                                                            {length?.length - 1 !== i && <TimelineConnector sx={{ border: "2px dashed #6C757D !important", background: "none" }} />}
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ padding: "0px 16px 6px 16px" }}>
                                                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                                <Avatar sx={classes.timeLineAvatar} src={e?.user?.image_url} {...stringAvatar(e?.user?.first_name, 12)}></Avatar>
                                                                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                                    <Typography sx={{ ...classes.propertyTitle, ...classes.nameWidth }}>{e?.user?.first_name}</Typography>
                                                                    <Box sx={classes.dot}></Box>
                                                                    <Typography sx={classes.secText}>{t("On")} {moment(e?.progress_date).format("DD MMM YY")}</Typography>
                                                                </Stack>
                                                            </Stack>

                                                            <Box sx={classes.normalBox} mt={1} mb={2}>
                                                                <Box>
                                                                    <ImageSlider height={"160px"}
                                                                        image={e?.images?.map((e) => { return { ...e, url: e?.asset_url } })?.filter((i) => i?.meta_data?.type !== "pdf")}
                                                                        type='normal'
                                                                        handleViewAll={() => handleProgressViewAllAsset(e?.images?.map((e) => { return { ...e, url: e?.asset_url } }))}
                                                                        label={e?.progress_date ? moment(e?.progress_date).format("MMM YY") : ""} />
                                                                </Box>
                                                                <Stack mt={4}>
                                                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                                        <Typography sx={{ ...classes.tertiaryTextReg, ...classes.tertiaryTextWidth }}>{e?.block_details ? `${e?.block_details?.name} - ${e?.block_details?.block_no}` : `${e?.property_details?.name} - ${e?.property_details?.property_no ?? ""}`}</Typography>
                                                                        {e?.description?.length > 0 && <Typography sx={classes.viewMoreText} onClick={() => {
                                                                            setViewDesc({
                                                                                bool: true,
                                                                                data: e?.description?.length > 0 ? JSON.parse(e?.description) : ""
                                                                            })
                                                                        }}>{t("View")}</Typography>}
                                                                    </Stack>
                                                                    <Typography sx={{ ...classes.propertySubTitle, ...classes.propertySubTitleWidth }}>{e?.progress_title}</Typography>
                                                                </Stack>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                )
                                            })
                                        }
                                    </Timeline>

                            }

                        </Box>
                }
            </Container>
            <DialogDrawer
                maxWidth="sm"
                open={view}
                header={t("View More")}
                padding="0px"
                onClose={() => setView(false)}
                height={300}
                component={
                    <Box p={2} sx={{ minHeight: "300px", overflow: "auto" }}>
                        <Typography sx={{ ...classes.secText, whiteSpace: "normal" }}
                            dangerouslySetInnerHTML={{ __html: data?.description?.replace(/\n+/g, "<br />") ?? "" }}></Typography>
                    </Box>
                }
            />
            <DialogDrawer
                maxWidth="sm"
                open={viewDesc?.bool}
                // header={t("View")}
                padding="0px"
                onClose={() => setViewDesc({
                    bool: false,
                    data: ""
                })}
                height="350px"
                component={
                    <Box p={2}>
                        <Typography
                        dangerouslySetInnerHTML={{ __html: viewDesc?.data }}
                        sx={classes.quillText}
                        ></Typography>

                        {/* <ReactQuill
                            readOnly
                            theme="bubble"
                            value={viewDesc?.data ?? ""}
                            modules={{
                                toolbar: false,
                            }}
                            className={classes.reactQuil}
                        /> */}
                    </Box>
                }
            />
        </>
    )
}

export default withTranslation('propertyUnitNew')(PropertyDetails)