import { Box, Typography } from "@mui/material";
import React from "react";
import { TickIcon } from "../../assets";
import { CurrencySwitchwer_styles } from './style'
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../useDimension";
import { TextBox } from "../textField";
import { UseDebounce } from "../useDebounce";

export const CurrencySwitcher = ({
    selectedCurrency = false,
    updateCurrency = "",
    Currency,
    t = () => false,
    fetchMoreData = () => false,
    searchFunction= () =>false
}) => {
    const size = useWindowDimensions();
    const debounce = UseDebounce();

    const [state , setState] = React.useState("")

    const onSearchChange = (e) => {
        setState(e?.target?.value)
        debounce(
          async () => {
            searchFunction(e?.target?.value)
          },
          800
        );
    
      }
    return (
        <Box pt={2}>
            <TextBox value={state} onChange={onSearchChange}/>
            <InfiniteScroll
                dataLength={Currency?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                style={CurrencySwitchwer_styles?.root}
                height={size?.height - 150}
            >

                {
                    Currency?.map((item) => {
                        return (
                            <>
                                <Box
                                    sx={CurrencySwitchwer_styles?.list2}
                                    onClick={() => updateCurrency(item)}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {/* language name */}
                                        <Box>
                                            <div>
                                                <Typography sx={CurrencySwitchwer_styles?.Tittle}>
                                                    {item?.code}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {item?.id ===
                                            selectedCurrency?.id && (
                                                <TickIcon />
                                            )}
                                    </Box>
                                </Box>
                            </>
                        )
                    })


                }
            </InfiniteScroll>
        </Box>
    )
}
