import { Box, Container } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterComponent, FormGenerator, SingleImageUpload, TopNavBar } from "../../components";
import { useLazyGetUsersProfileUpdateQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { SemiBold, errorRoute } from "../../utils";
import { CreateInvestmentStyle } from './style';


const initial = {
    name: "",
    primaryContact: "",
    mail: "",
    alternativeContact: "",
    editImage: "",
    address: "",
    error: {
        alternativeContact: "",
        primaryContact: "",
        name: "",
        mail: "",
        editImage: "",
        address: "",
    }


}

export const ProfileEdit = ({
    t = () => false
}) => {

    const [data, setData] = useState({ ...initial })
    let location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [ProfileUpdation , {error}] = useLazyGetUsersProfileUpdateQuery()

    // updateState
    const updateState = (k, v) => {
        let error = data?.error;
        error[k] = "";
        setData({ ...data, [k]: v, error });
    };


    // save btn
    const save = async () => {
        if (validate()) {
        const payload = {
            email_id: data?.mail ? data?.mail : null,
            mobile_no: data?.primaryContact?.mobile ? data?.primaryContact?.mobile : null,
            mobile_no_country_code: data?.primaryContact?.mobile_code ? data?.primaryContact?.mobile_code : null,
            alternative_mobile_no: data?.alternativeContact?.mobile ? data?.alternativeContact?.mobile: null,
            alternative_mobile_no_country_code: data?.alternativeContact?.mobile_code ? data?.alternativeContact?.mobile_code : null,
            first_name: data?.name ? data?.name : null,
            street_1: data?.street_1 ? data?.street_1 : null,
            street_2: data?.street_2 ? data?.street_2 : null,
            street_3: data?.street_3 ? data?.street_3 : null,
            district: data?.district ? data?.district : null,
            city: data?.state ? data?.state : null,
            zipcode: data?.zipcode ? data?.zipcode : null,
            country: data?.country ? data?.country : null,
            image_url: data?.editImage
        }
        let ProfileUpdationRes = await ProfileUpdation(payload)
        if (ProfileUpdationRes?.data?.type === "success") {
            enqueueSnackbar(t(`Profile Updated Successfully`), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        } else {

            enqueueSnackbar(t(`Profile Updated Unsuccessfully`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        }
        navigate(AppRoutes.profileView)
        }
    }


    // validation
    const validate = () => {
        let isValid = true;
        let error = data.error
        
        if (data?.name?.length === 0) {
            isValid = false
            error.name = t("Name is Required")
        }

        if (data?.mail.length === 0) {
            isValid = false
            error.mail = t("Mail- ID is required")
        }
        // if (data?.mail.length > 0 && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data?.mail)) {
        //     isValid = false
        //     error.mail = "Please Fill Valid Mail ID"

        // }
        if (data?.alternativeContact?.length === 0) {
            isValid = false
            error.alternativeContact = t("Alternative Contact is Required")
        }
        if (data?.primaryContact?.length === 0) {
            isValid = false
            error.primaryContact = t("Primary Contact is Required")
        }
        if (data?.address?.length === 0) {
            isValid = false
            error.address = t("Address is Required")
        }

        setData({ ...data, error })
        return isValid
    }

    // Edit Functions
    const editFunctions = () => {
        if (location?.state) {
            const editData = location?.state
            const address = location?.state?.is_address
            setData({
                id: editData?.id,
                name: address ? editData?.name : editData?.first_name,
                primaryContact: {
                    mobile_code: address ? editData?.primaryContact?.mobile_code : editData?.mobile_no_country_code,
                    mobile: address ? editData?.primaryContact?.mobile : editData?.mobile_no,
                },
                mail: address ? editData?.mail : editData?.email_id,
                alternativeContact: {
                    mobile_code: address ? editData?.alternativeContact?.mobile_code : editData?.alternative_mobile_no_country_code,
                    mobile: address ? editData?.alternativeContact?.mobile : editData?.alternative_mobile_no,
                },
                editImage: address ? editData?.editImage : editData?.image_url,
                address: `${editData?.street_1 ? editData?.street_1 : ""} ${editData?.street_2 ? ",": ""} ${editData?.street_2 ? editData?.street_2 : ""} ${editData?.street_3 ? ",": ""} ${editData?.street_3 ? editData?.street_3 : ""} ${editData?.district ? ",": ""} ${editData?.district ? editData?.district : ""} ${editData?.state ? ",": ""} ${editData?.state ? editData?.state : ""} ${editData?.country ? ",": ""} ${editData?.country ? editData?.country : ""} ${editData?.zipcode ? ",": ""} ${editData?.zipcode ? editData?.zipcode : ""}`,
                street_1: editData?.street_1,
                street_2: editData?.street_2,
                street_3: editData?.street_3,
                district: editData?.district,
                state: editData?.state,
                country: editData?.country,
                zipcode: editData?.zipcode,
                error: {
                    name: "",
                    primaryContact: "",
                    mail: "",
                    alternativeContact: "",
                    editImage: "",
                    address: "",
                }
            })


        }


    }

    useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error ])
    useEffect(() => {
        editFunctions()
        // eslint-disable-next-line
    }, [location])
    return (
        <Container
        sx={{padding:"0px !important"}}
        maxWidth="sm"
        >
            <Box sx={{
        padding: '3.5rem 0rem 5.3rem', // Default style
        '@media screen and (min-width: 600px)': {
            padding: '4rem 0rem 5.3rem', // Media query style for `md` breakpoint
        },
      }}>
   <Box sx={{position:"fixed" , top:0 , left:0 , right:0 ,zIndex:1}}>
            <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <TopNavBar title={t("Profile Edit")} isDirect route={()=>navigate(AppRoutes.profileView)} t={t} />
            </Container>
         </Box>
            <Box sx={CreateInvestmentStyle.root}>
                <Box sx={CreateInvestmentStyle.profilePictureEditIcon}>
                    <SingleImageUpload
                        value={data?.editImage}
                        onChange={(e) => updateState("editImage", e)}
                    />
                </Box>
                <FormGenerator
                    components={[
                        {
                            isActive: true,
                            component: "text",
                            label: t("Name"),
                            value: data?.name,
                            placeholder: t("Enter Name"),
                            onChange: (e) => updateState("name", e?.target?.value),
                            error: data?.error?.name,
                            endAdornment: data?.type,
                            isReadonly: true,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "mobileNumber",
                            label: t("Primary Contact"),
                            value: data?.primaryContact,
                            placeholder: t("Enter Primary Contact"),
                            onChange: (e) => updateState("primaryContact", e),
                            error: data?.error?.primaryContact,
                            endAdornment: data?.type,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "mobileNumber",
                            label: t("Alternative Contact"),
                            value: data?.alternativeContact,
                            placeholder: t("Enter Alternative Contact"),
                            onChange: (e) => updateState("alternativeContact", e),
                            error: data?.error?.alternativeContact,
                            endAdornment: data?.type,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("Email Address"),
                            value: data?.mail,
                            placeholder: t("Enter Email Address"),
                            onChange: (e) => updateState("mail", e?.target?.value),
                            error: data?.error?.mail,
                            endAdornment: data?.type,
                            isReadonly: true,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "address",
                            value: {
                                street_1: data?.street_1,
                                street_2: data?.street_2,
                                street_3: data?.street_3,
                                district: data?.district,
                                state: data?.state,
                                country: data?.country,
                                zipcode: data?.zipcode,
                            },
                            placeholder: t("Address"),
                            label: t("Address"),
                            onChange: (e) => updateState("address", e?.target?.value),
                            error: data?.error?.address,
                            Edit: t("Edit"),
                            // route:AppRoutes.profileEdit,
                            endAdornment: data?.type,
                            data: data,
                            size: {
                                xs: 12,
                            },
                        },
                    ]}

                />
            </Box>
            <Box sx={{position:"fixed" , bottom:0 , left:0 , right:0 ,zIndex:999}}>
            <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <Box sx={CreateInvestmentStyle.footer}>
                {/* footer Component */}
                <FooterComponent
                    containedonClick={() => save()}
                    containedText={t("Save & Update")}
                    single={true}
                    btn_styles={{
                        marginInlineStart:"0px !important"
                    }}

                />
            </Box>
            </Container>
         </Box>
           
      </Box>
         
        </Container>

    );
};
