import * as React from "react"
const ShareHolderIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={81} height={43.027} {...props}>
    <defs>
      <filter
        id="a"
        width={24}
        height={23}
        x={12}
        y={20.027}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx={-1} />
        <feGaussianBlur result="blur" stdDeviation={1} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="b"
        width={24}
        height={23}
        x={45}
        y={20.027}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx={1} />
        <feGaussianBlur result="blur-2" stdDeviation={1} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur-2" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="c"
        width={24}
        height={31}
        x={28}
        y={12.027}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx={-1} />
        <feGaussianBlur result="blur-3" stdDeviation={1} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur-3" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 138204">
      <path
        fill="#93b4ee"
        d="M4 31.027h14v9H0v-5a4 4 0 0 1 4-4Z"
        data-name="Rectangle 58992"
      />
      <path
        fill="#93b4ee"
        d="M63 31.027h14a4 4 0 0 1 4 4v5H63v-9Z"
        data-name="Rectangle 58998"
      />
      <g filter="url(#a)" transform="translate(-.004)">
        <path
          fill="#ffd158"
          d="M20 23.03h14v17H16v-13a4 4 0 0 1 4-4Z"
          data-name="Rectangle 58993"
        />
      </g>
      <g filter="url(#b)" transform="translate(-.004)">
        <path
          fill="#ff9d6c"
          d="M47 23.03h14a4 4 0 0 1 4 4v13H47v-17Z"
          data-name="Rectangle 58997"
        />
      </g>
      <g filter="url(#c)" transform="translate(-.004)">
        <path
          fill="#b0ce83"
          d="M36 15.03h10a4 4 0 0 1 4 4v21H32v-21a4 4 0 0 1 4-4Z"
          data-name="Rectangle 58995"
        />
      </g>
      <path
        fill="#5d8fe5"
        d="M8.809 17.799a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 2.25a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25Zm2.25 4.134c0 .715-.912 1.365-2.25 1.365s-2.25-.649-2.25-1.365v-.169a.466.466 0 0 1 .465-.465h3.569a.466.466 0 0 1 .465.465ZM71.809 17.799a5 5 0 1 1-5 5 5.006 5.006 0 0 1 5-5Zm0 2.25a1.25 1.25 0 1 0 1.25 1.25 1.25 1.25 0 0 0-1.25-1.25Zm-2.25 4.134c0 .715.912 1.365 2.25 1.365s2.25-.651 2.25-1.365v-.169a.466.466 0 0 0-.465-.465h-3.57a.466.466 0 0 0-.465.465Z"
        data-name="icons8-user (6)"
      />
      <path
        fill="#ffca40"
        d="M24.533 7.416a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 2.25a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25Zm2.25 4.134c0 .715-.912 1.365-2.25 1.365s-2.25-.649-2.25-1.365v-.169a.466.466 0 0 1 .465-.465h3.569a.466.466 0 0 1 .465.465Z"
        data-name="icons8-user (6)"
      />
      <path
        fill="#ff8d53"
        d="M56.089 7.416a5 5 0 1 1-5 5 5.006 5.006 0 0 1 5-5Zm0 2.25a1.25 1.25 0 1 0 1.25 1.25 1.25 1.25 0 0 0-1.25-1.25Zm-2.25 4.134c0 .715.912 1.365 2.25 1.365s2.25-.651 2.25-1.365v-.169a.466.466 0 0 0-.465-.465h-3.57a.466.466 0 0 0-.465.465Z"
        data-name="icons8-user (6)"
      />
      <path
        fill="#81b138"
        d="M40.311 0a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5Zm0 2.25a1.25 1.25 0 1 1-1.25 1.25 1.25 1.25 0 0 1 1.25-1.25Zm2.25 4.134c0 .715-.912 1.365-2.25 1.365s-2.25-.649-2.25-1.365v-.169a.466.466 0 0 1 .465-.465h3.569a.466.466 0 0 1 .465.465Z"
        data-name="icons8-user (6)"
      />
    </g>
  </svg>
)
export default ShareHolderIcon
