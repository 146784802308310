import React, { useState, useEffect } from 'react'
import { AgreementCard, MonthlyInvoiceCard, SimpleAccordion, TopDrawerTab, TopNavBar } from "../../components";
import { UtilitiesStyle } from "./style";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useLazyGetUtilitiesViewQuery, useLazyGetUtilitiesQuery } from '../../redux/services';
import moment from 'moment-timezone';
import { TopDrawerTab_style } from '../../components/topdrawertab/style';
import { withTranslation } from 'react-i18next';
import { errorRoute, getMonth } from '../../utils';
import NoDataShow from '../../assets/noData';



const UtilitiesList = ({
    t = () => false
}) => {

    // const [data, setData] = useState({});
    const location = useLocation();

    // const t = location?.state?.t
    const [GetUtilitiesView, { error }] = useLazyGetUtilitiesViewQuery();
    const [GetUtilities, { error: isError }] = useLazyGetUtilitiesQuery();

    const [drawer, setDrawer] = useState(false)
    const [selected, setSelected] = useState({})
    const [utilsData, setUtilsData] = useState([])
    const [datas, setDatas] = useState({
        utilitiesViewing: [],
        bool: true,
        selectedutilities: {}
    })
    // get utilities List
    const getutilitiesView = async (payload) => {
        const data = await GetUtilitiesView({
            unit_utlities_id: payload?.unit_utlities_id?.value
        })
        setDatas({
            ...datas,
            utilitiesViewing: data?.data?.data?.response,
            bool: false,
            selectedutilities: payload?.unit_utlities_id
        })
    }
    const initialData = async () => {
        return new Promise(async () => {
            try {
                const value = {
                    utility_id: location?.state?.unit_utlities_id,
                    unit_id: location?.state?.unit_id
                }
                const utilitiesRes = await GetUtilities({ ...value })
                await getutilitiesView({
                    unit_utlities_id: utilitiesRes?.data?.data?.response?.[0]
                })
                const array = utilitiesRes?.data?.data?.response?.map((e) => {
                    return {
                        account_no: e?.label,
                        id: e?.value
                    }
                })
                setUtilsData(array)
                setSelected(array?.[0])
                // if(array?.length > 0){
                // await getutilitiesView({
                //     unit_utlities_id: array?.[0]?.id
                // })}
            }
            catch (err) {
                console.log(err, 'err')
            }
        })
    }

    useEffect(() => {
        initialData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423) return errorRoute()
    }, [error, isError])

    const updateUtilities = async (e) => {
        setSelected(e)
        setDrawer(false)
        await getutilitiesView({
            unit_utlities_id: { value: e?.id, label: e?.label }
        })
    }

    const handleDrawerOpen = () => {
        setDrawer(true);
    };

    const handleDrawerClose = () => {
        setDrawer(false);
    };

    return (
        <Container sx={UtilitiesStyle.container} maxWidth="sm" >
            <Box sx={{
                paddingTop: '59px', // Default style
                '@media screen and (min-width: 600px)': {
                    paddingTop: '67px', // Media query style for `md` breakpoint
                },
            }}>
                <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1 }}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                        <TopNavBar title={t("Utilities")} isDirect isBack t={t} />
                    </Container>
                </Box>
                <Box sx={UtilitiesStyle.root2}>
                    {/* Meter switch */}
                    <Box sx={UtilitiesStyle?.switcherBox}>
                        <TopDrawerTab
                            title={selected?.label}
                            selectedAccount={selected}
                            handleDrawerClose={handleDrawerClose}
                            handleDrawerOpen={handleDrawerOpen}
                            drawer={drawer}
                            header={t("Utilities")}
                            component={
                                <>
                                    <Box sx={TopDrawerTab_style?.drawerSx}>
                                        <Typography sx={TopDrawerTab_style?.title} noWrap>
                                            {`${t("Utilities")} (${utilsData?.length})`}</Typography>
                                    </Box>
                                    <Box >
                                        <AgreementCard
                                            data={utilsData}
                                            selectedAccount={selected}
                                            checked={datas?.checked}
                                            updateAccounts={updateUtilities}
                                            t={t}
                                        />
                                    </Box>
                                </>
                            }
                            t={t} />

                    </Box>
                    <Box>
                        {
                            datas?.utilitiesViewing?.length === 0 ?
                                <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: "calc(100vh - 150px)" }}>
                                    <NoDataShow />
                                </Stack>
                                :
                                <Box>
                                    {
                                        datas?.utilitiesViewing?.map((val, index) => {
                                            return (
                                                <div>
                                                    <Box sx={UtilitiesStyle?.accordianBox}>
                                                        <SimpleAccordion
                                                            defaultExpanded={index === 0 ? true : false}

                                                            headText={
                                                                val?.created_at && getMonth(new Date(val?.created_at).getMonth())
                                                            }
                                                            root_style={{ backgroundColor: "#fff", boxShadow: "0px 3px 30px #5C86CB2E" }}
                                                            tag_style={{ backgroundColor: "background.background", color: "error.Teritary" }}
                                                            content={
                                                                <Grid container spacing={1.3}>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <MonthlyInvoiceCard
                                                                                t={t}
                                                                                price={val?.bill_total}
                                                                                consumedUnits={val?.units_consumed}
                                                                                fromDate={moment(new Date(val?.usage_period_from)).format("DD-MMM-YY")}
                                                                                toDate={moment(new Date(val?.usage_period_to)).format("DD-MMM-YY")}
                                                                                meterValue={val?.present_meter_readings}
                                                                                previousMeter={val?.previous_meter_readings}
                                                                                currency={val?.symbol}
                                                                            />
                                                                        </Box>

                                                                    </Grid>

                                                                </Grid>

                                                            }

                                                        />
                                                    </Box>
                                                </div>

                                            )
                                        })
                                    }
                                </Box>
                        }
                    </Box>
                </Box>
            </Box>

            {/* footer Component */}
            {/* <Box sx={UtilitiesStyle.footer}>
                <FooterComponent
                    single={true}
                    containedText="Pay Now ($300.00)"

                />
            </Box> */}
        </Container>
    );
};
export default withTranslation('utilities')(UtilitiesList);
