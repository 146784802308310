import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });
export const StatisticAPI = createApi({
    reducerPath: "StatisticAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ['statistic-category-master'],
    endpoints: (builder) => ({
        // maintenence API
        Statistic: builder.query({
            query: ({ offset = 0, limit = 10, search , type}) => ({
                url: `statistics-category-master`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search, type
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),


        SubCategory: builder.query({
            query: ({ offset = 0, limit = 10, search , category_id , type}) => ({
                url: `statistics-subcategory-master`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search,
                    category_id, type
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),

        GetOwnerUnitNumber: builder.query({
            query: ({ offset = 0, limit = 10, search }) => ({
                url: `owner/get_all_owner_units`,
                method: "POST",
                body: {
                    offset,
                    limit,
                    search
                }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),


        UsageType: builder.query({
            query: () => ({
                url: `statistics-usage-types`,
                method: "POST"
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),

        Currency: builder.query({
            query: (payload) => ({
                url: `currency`,
                method: "POST",
                body:payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),


        investment: builder.mutation({
            query: (payload) => ({
                url: `login`,
                method: "POST",
                body: { ...payload },
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response?.data
                }
                return ""
            },
        }),


        spending: builder.mutation({
            query: (payload) => ({
                url: `login`,
                method: "POST",
                body: { ...payload }
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response?.data
                }
                return ""
            },
        }),

        statisticsCreation: builder.query({
            query: (payload) => ({
                url: `statistics/create`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),

        GetRevenue: builder.query({
            query: (payload) => ({
                url: `revenue`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
    }),
})

// Export hooks for usage in functional components
export const { useLazyStatisticQuery, useLazySubCategoryQuery, useLazyGetRevenueQuery,useLazyGetOwnerUnitNumberQuery, useLazyUsageTypeQuery, useLazyCurrencyQuery, useLazySpendingQuery, useLazyStatisticsCreationQuery } = StatisticAPI;
