import * as React from "react"
const GuestRoomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404)">
      <rect
        width={40}
        height={40}
        fill="#d4faf9"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404)"
      />
      <path
        fill="#41c9d3"
        stroke="#41c9d3"
        strokeWidth={0.25}
        d="M57.674 412.42a3.678 3.678 0 0 0-3.663 3.663v6.116a3.642 3.642 0 0 0-2 3.207v7.827a1 1 0 0 0 0 .324v1.838a1 1 0 1 0 2 0v-1h19.978v1a1 1 0 1 0 2 0v-1.834a1 1 0 0 0 0-.324v-7.831a3.642 3.642 0 0 0-2-3.207v-6.116a3.678 3.678 0 0 0-3.662-3.663Zm0 2h12.653a1.65 1.65 0 0 1 1.665 1.665v5.66h-1.333v-.335a3.678 3.678 0 0 0-3.663-3.663h-5.993a3.678 3.678 0 0 0-3.663 3.663v.333h-1.331v-5.66a1.65 1.65 0 0 1 1.665-1.663Zm3.33 5.327h5.993a1.65 1.65 0 0 1 1.665 1.665v.333h-9.323v-.335a1.65 1.65 0 0 1 1.664-1.665Zm-5.327 4H72.32a1.65 1.65 0 0 1 1.665 1.665v6.992H54.011v-6.998a1.65 1.65 0 0 1 1.665-1.665Z"
        data-name="icons8-bed (3)"
      />
    </g>
  </svg>
)
export default GuestRoomIcon
