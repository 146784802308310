import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const CorrespondenceAPI = createApi({
    reducerPath: "CorrespondenceAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Correspondence"],
    endpoints: (builder) => ({
        // Get All User API
        casemanage: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `correspondences/list_for_resident`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        viewPdf: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `correspondences/view_pdf`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        markAsRead: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `/correspondences/mark_as_read`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        messageSubmit: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `/correspondences/comment`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        filterEnum: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `enum`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        typeMaster: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `correspondences/type_master`, method: "POST"
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),


    }),
});

// Export hooks for usage in functional components
export const { useLazyCasemanageQuery, useLazyMarkAsReadQuery, useLazyMessageSubmitQuery, useLazyFilterEnumQuery, useLazyTypeMasterQuery ,useLazyViewPdfQuery} = CorrespondenceAPI;
