import * as React from "react"
const AmountLabelIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#98a0ac"
      d="M10.723 2a1.834 1.834 0 0 0-1.3.537l-.13.13H7.39a1.834 1.834 0 0 0-1.3.533L.534 8.763a1.841 1.841 0 0 0 0 2.593L3.976 14.8a1.842 1.842 0 0 0 2.594 0l.7-.7.037.037a1.842 1.842 0 0 0 2.594 0l5.559-5.559a1.834 1.834 0 0 0 .537-1.3V3.5a1.509 1.509 0 0 0-1.5-1.5Zm0 1h3.776a.493.493 0 0 1 .5.5v3.777a.835.835 0 0 1-.245.589l-5.557 5.558a.826.826 0 0 1-1.179 0L4.575 9.982a.826.826 0 0 1 0-1.179l5.559-5.559A.832.832 0 0 1 10.723 3Zm-3.333.667h.907L3.868 8.1a1.841 1.841 0 0 0 0 2.593l2.7 2.7-.7.7a.826.826 0 0 1-1.179 0l-3.448-3.444a.826.826 0 0 1 0-1.179L6.8 3.911a.832.832 0 0 1 .59-.244ZM12.999 4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z"
      data-name="Group 138287"
    />
  </svg>
)
export default AmountLabelIcon
