import * as React from "react"
const ViewAllIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      fill="#fff"
      d="M1.833 0A1.833 1.833 0 0 0 0 1.833V7.8l2.5-2.42a1.378 1.378 0 0 1 1.876 0l2.059 1.992.683-.66a1.382 1.382 0 0 1 1.876 0l3 2.906V1.833A1.833 1.833 0 0 0 10.167 0Zm6.333 2.333a1.5 1.5 0 1 1-1.5 1.5 1.5 1.5 0 0 1 1.501-1.5Zm0 1a.5.5 0 1 0 .5.5.5.5 0 0 0-.499-.5ZM3.439 6a.347.347 0 0 0-.242.1L0 9.192v.975a1.833 1.833 0 0 0 1.663 1.825l4.055-3.923L3.682 6.1a.348.348 0 0 0-.243-.1Zm4.618 1.333a.347.347 0 0 0-.242.1L3.092 12h7.074a1.833 1.833 0 0 0 1.691-1.127L8.3 7.432a.349.349 0 0 0-.242-.095Z"
    />
  </svg>
)
export default ViewAllIcon
