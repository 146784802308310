import * as React from "react"
const NoAgreementImg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={163.48}
    height={163.489}
    data-name="Group 108873"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 0h163.48v163.489H0z"
          data-name="Rectangle 56007"
        />
      </clipPath>
      <clipPath id="b">
        <path
          fill="none"
          d="M0 0h23.738v4.249H0z"
          data-name="Rectangle 55997"
        />
      </clipPath>
      <clipPath id="c">
        <path fill="none" d="M0 0h3.825v5.923H0z" data-name="Rectangle 55998" />
      </clipPath>
      <clipPath id="d">
        <path fill="none" d="M0 0h10.85v6.515H0z" data-name="Rectangle 56001" />
      </clipPath>
      <clipPath id="e">
        <path fill="none" d="M0 0h2.459v4.122H0z" data-name="Rectangle 56002" />
      </clipPath>
      <clipPath id="f">
        <path fill="none" d="M0 0h1.09v7.074H0z" data-name="Rectangle 56003" />
      </clipPath>
      <clipPath id="g">
        <path
          fill="none"
          d="M0 0h4.204v29.709H0z"
          data-name="Rectangle 56004"
        />
      </clipPath>
      <clipPath id="h">
        <path
          fill="none"
          d="M0 0h6.755v11.588H0z"
          data-name="Rectangle 56005"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Group 108872">
      <g data-name="Group 108854">
        <g clipPath="url(#a)" data-name="Group 108853">
          <path
            fill="#ebebeb"
            d="M163.467 28.523c.2-1.429-1.825-2.6-3.352-2.724a8.676 8.676 0 0 1-2.164-.186c-1.49-.52-2.086-2.435-3.539-3.043a4.163 4.163 0 0 0-3.386.475 8.854 8.854 0 0 1-3.27 1.269c-1.021.063-2.134-.312-3.028.186-.667.371-1.017 1.136-1.631 1.588a3.9 3.9 0 0 1-2.131.575 6.069 6.069 0 0 0-2.2.371 1.734 1.734 0 0 0-1.1 1.759Z"
            data-name="Path 99195"
          />
          <path
            fill="#fafafa"
            d="M143.017 4.2c.138-.98-1.248-1.781-2.287-1.856a5.807 5.807 0 0 1-1.49-.126c-1.017-.371-1.423-1.663-2.417-2.082a2.855 2.855 0 0 0-2.317.327 6.108 6.108 0 0 1-2.235.868c-.7.045-1.456-.215-2.071.126-.454.252-.693.776-1.117 1.087a2.69 2.69 0 0 1-1.456.393 4.108 4.108 0 0 0-1.49.245 1.18 1.18 0 0 0-.745 1.2Z"
            data-name="Path 99196"
          />
          <path
            fill="#f5f5f5"
            d="M118.784 12.761c.171-1.24-1.587-2.264-2.909-2.368a7.556 7.556 0 0 1-1.885-.163c-1.3-.449-1.81-2.115-3.077-2.646a3.62 3.62 0 0 0-2.946.416 7.956 7.956 0 0 1-2.842 1.113c-.89.056-1.862-.275-2.637.16-.577.323-.883.987-1.415 1.381a3.361 3.361 0 0 1-1.862.5 5.264 5.264 0 0 0-1.915.312 1.511 1.511 0 0 0-.957 1.529Z"
            data-name="Path 99197"
          />
          <path
            fill="#ebebeb"
            d="M66.275 59.66h28.279v37.161H66.275z"
            data-name="Rectangle 55942"
          />
          <path
            fill="#e0e0e0"
            d="M81.294 59.66h13.26v37.161h-13.26z"
            data-name="Rectangle 55943"
          />
          <path
            fill="#f5f5f5"
            d="M72.205 64.692h2.544v6.153h-2.544z"
            data-name="Rectangle 55944"
          />
          <path
            fill="#f5f5f5"
            d="M72.205 88.778h2.544v6.153h-2.544z"
            data-name="Rectangle 55945"
          />
          <path
            fill="#f5f5f5"
            d="M81.293 73.28c0 .071-3.352.126-7.509.126s-7.509-.056-7.509-.126 3.352-.126 7.509-.126 7.509.037 7.509.126"
            data-name="Path 99198"
          />
          <path
            fill="#f5f5f5"
            d="M72.205 76.828h2.544v6.153h-2.544z"
            data-name="Rectangle 55946"
          />
          <path
            fill="#f5f5f5"
            d="M81.293 85.397c0 .07-3.352.126-7.509.126s-7.509-.056-7.509-.126 3.352-.126 7.509-.126 7.509.056 7.509.126"
            data-name="Path 99199"
          />
          <path
            fill="#f5f5f5"
            d="M84.11 64.692h2.544v6.153H84.11z"
            data-name="Rectangle 55947"
          />
          <path
            fill="#f5f5f5"
            d="M89.626 64.692h2.544v6.153h-2.544z"
            data-name="Rectangle 55948"
          />
          <path
            fill="#f5f5f5"
            d="M84.11 76.372h2.544v6.153H84.11z"
            data-name="Rectangle 55949"
          />
          <path
            fill="#f5f5f5"
            d="M89.626 76.372h2.544v6.153h-2.544z"
            data-name="Rectangle 55950"
          />
          <path
            fill="#f5f5f5"
            d="M84.11 88.047h2.544V94.2H84.11z"
            data-name="Rectangle 55951"
          />
          <path
            fill="#f5f5f5"
            d="M89.626 88.047h2.544V94.2h-2.544z"
            data-name="Rectangle 55952"
          />
          <path
            fill="#e0e0e0"
            d="M64.148 58.365h32.529v1.34H64.148z"
            data-name="Rectangle 55953"
          />
          <path
            fill="#ebebeb"
            d="M94.751 59.83c0 .071-6.332.126-14.117.126s-14.113-.056-14.113-.126 6.332-.122 14.113-.122 14.117.056 14.117.122"
            data-name="Path 99200"
          />
          <path
            fill="#f5f5f5"
            d="M14.583 27.268h19.369v61.165H14.583z"
            data-name="Rectangle 55954"
          />
          <path
            fill="#ebebeb"
            d="M24.267 27.268h9.684v60.994h-9.684z"
            data-name="Rectangle 55955"
          />
          <path
            fill="#f5f5f5"
            d="M16.795 21.263h6.839v6.005h-6.839z"
            data-name="Rectangle 55956"
          />
          <path
            fill="#ebebeb"
            d="M22.002 21.263h1.631v6.005h-1.631z"
            data-name="Rectangle 55957"
          />
          <path
            fill="#e0e0e0"
            d="M12.97 27.272v-.353h21.548v.353"
            data-name="Path 99201"
          />
          <path
            fill="#fafafa"
            d="M18.404 29.888h1.639v3.444h-1.639z"
            data-name="Rectangle 55958"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 34.683c0 .033-2.168.063-4.842.063s-4.842-.03-4.842-.063 2.168-.067 4.842-.067 4.842.033 4.842.067"
            data-name="Path 99202"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 29.888h1.639v3.444h-1.639z"
            data-name="Rectangle 55959"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 34.683c0 .033-2.168.063-4.842.063s-4.842-.03-4.842-.063 2.168-.067 4.842-.067 4.842.033 4.842.067"
            data-name="Path 99203"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 36.728h1.639v3.444h-1.639z"
            data-name="Rectangle 55960"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 41.523c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.063 4.842-.063 4.842.026 4.842.063"
            data-name="Path 99204"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 43.772h1.639v3.444h-1.639z"
            data-name="Rectangle 55961"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 48.571c0 .033-2.168.063-4.842.063s-4.842-.03-4.842-.063 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99205"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 50.82h1.639v3.444h-1.639z"
            data-name="Rectangle 55962"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 55.615c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99206"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 57.663h1.639v3.444h-1.639z"
            data-name="Rectangle 55963"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 62.458c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99207"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 64.533h1.639v3.444h-1.639z"
            data-name="Rectangle 55964"
          />
          <path
            fill="#e0e0e0"
            d="M28.174 71.495h1.639v3.444h-1.639z"
            data-name="Rectangle 55965"
          />
          <path
            fill="#e0e0e0"
            d="M34.037 69.33c0 .033-2.168.063-4.842.063s-4.842-.03-4.842-.063 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99208"
          />
          <path
            fill="#fafafa"
            d="M18.404 36.832h1.639v3.444h-1.639z"
            data-name="Rectangle 55966"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 41.627c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.063 4.842-.063 4.842.03 4.842.063"
            data-name="Path 99209"
          />
          <path
            fill="#fafafa"
            d="M18.404 43.776h1.639v3.444h-1.639z"
            data-name="Rectangle 55967"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 48.57c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.063 4.842-.063 4.842.026 4.842.063"
            data-name="Path 99210"
          />
          <path
            fill="#fafafa"
            d="M18.404 50.719h1.639v3.444h-1.639z"
            data-name="Rectangle 55968"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 55.514c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.067 4.842-.067 4.842.019 4.842.067"
            data-name="Path 99211"
          />
          <path
            fill="#fafafa"
            d="M18.404 57.663h1.639v3.444h-1.639z"
            data-name="Rectangle 55969"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 62.458c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99212"
          />
          <path
            fill="#fafafa"
            d="M18.404 64.603h1.639v3.444h-1.639z"
            data-name="Rectangle 55970"
          />
          <path
            fill="#e0e0e0"
            d="M24.267 69.402c0 .037-2.168.067-4.842.067s-4.842-.03-4.842-.067 2.168-.067 4.842-.067 4.842.03 4.842.067"
            data-name="Path 99213"
          />
          <path
            fill="#fafafa"
            d="M18.404 71.547h1.639v3.444h-1.639z"
            data-name="Rectangle 55971"
          />
          <path
            fill="#ebebeb"
            d="M31.003 99.797h-28.5l-.13-44.884h28.506Z"
            data-name="Path 99214"
          />
          <path
            fill="#e0e0e0"
            d="M16.624 54.913h14.251v44.884H16.624z"
            data-name="Rectangle 55972"
          />
          <path
            fill="#ebebeb"
            d="M5.628 46.077h10.064v8.836H5.628z"
            data-name="Rectangle 55973"
          />
          <path
            fill="#e0e0e0"
            d="M13.294 46.077h2.399v8.836h-2.399z"
            data-name="Rectangle 55974"
          />
          <path
            fill="#e0e0e0"
            d="M0 54.397h31.713v.516H0z"
            data-name="Rectangle 55975"
          />
          <path
            fill="#fff"
            d="M8.001 58.769h2.414v5.07H8.001z"
            data-name="Rectangle 55976"
          />
          <path
            fill="#e0e0e0"
            d="M16.624 65.824c0 .056-3.188.1-7.126.1s-7.126-.045-7.126-.1 3.192-.1 7.126-.1 7.126.045 7.126.1"
            data-name="Path 99215"
          />
          <path
            fill="#f5f5f5"
            d="M22.375 58.769h2.414v5.07h-2.414z"
            data-name="Rectangle 55977"
          />
          <path
            fill="#ebebeb"
            d="M31.001 65.824c0 .056-3.192.1-7.126.1s-7.126-.045-7.126-.1 3.188-.1 7.126-.1 7.126.045 7.126.1"
            data-name="Path 99216"
          />
          <path
            fill="#f5f5f5"
            d="M22.375 68.83h2.414v5.07h-2.414z"
            data-name="Rectangle 55978"
          />
          <path
            fill="#ebebeb"
            d="M31.001 75.877c0 .052-3.192.1-7.126.1s-7.126-.045-7.126-.1 3.188-.1 7.126-.1 7.126.056 7.126.1"
            data-name="Path 99217"
          />
          <path
            fill="#f5f5f5"
            d="M22.375 79.199h2.414v5.07h-2.414z"
            data-name="Rectangle 55979"
          />
          <path
            fill="#ebebeb"
            d="M31.001 86.269c0 .052-3.192.1-7.126.1s-7.126-.045-7.126-.1 3.188-.1 7.126-.1 7.126.033 7.126.1"
            data-name="Path 99218"
          />
          <path
            fill="#f5f5f5"
            d="M22.375 89.565h2.414v5.07h-2.414z"
            data-name="Rectangle 55980"
          />
          <path
            fill="#ebebeb"
            d="M31.001 96.623c0 .052-3.192.1-7.126.1s-7.126-.045-7.126-.1 3.188-.1 7.126-.1 7.126.045 7.126.1"
            data-name="Path 99219"
          />
          <path
            fill="#fff"
            d="M8.001 68.986h2.414v5.07H8.001z"
            data-name="Rectangle 55981"
          />
          <path
            fill="#e0e0e0"
            d="M16.624 76.041c0 .056-3.188.1-7.126.1s-7.126-.045-7.126-.1 3.192-.1 7.126-.1 7.126.045 7.126.1"
            data-name="Path 99220"
          />
          <path
            fill="#fff"
            d="M8.001 79.203h2.414v5.07H8.001z"
            data-name="Rectangle 55982"
          />
          <path
            fill="#e0e0e0"
            d="M16.624 86.269c0 .052-3.188.1-7.126.1s-7.126-.045-7.126-.1 3.192-.1 7.126-.1 7.126.037 7.126.1"
            data-name="Path 99221"
          />
          <path
            fill="#fff"
            d="M8.001 89.42h2.414v5.07H8.001z"
            data-name="Rectangle 55983"
          />
          <path
            fill="#e0e0e0"
            d="M16.624 96.479c0 .052-3.188.1-7.126.1s-7.126-.045-7.126-.1 3.192-.1 7.126-.1 7.126.045 7.126.1"
            data-name="Path 99222"
          />
          <path
            fill="#f5f5f5"
            d="M151.149 74.932a10.261 10.261 0 0 1-6.332-4.724 9.06 9.06 0 0 1-.663-7.508c.689-1.908 2.015-3.589 2.585-5.533.644-2.2.261-4.546-.138-6.8s-.8-4.591-.2-6.806a5.38 5.38 0 0 1 10.284-1.113 11.466 11.466 0 0 1 .745 6.246 39.421 39.421 0 0 0-.518 6.335c.276 4.286 2.853 8.321 2.551 12.618a8.089 8.089 0 0 1-2.674 5.337 6.354 6.354 0 0 1-5.658 1.952"
            data-name="Path 99223"
          />
          <path
            fill="#e0e0e0"
            d="M151.938 96.164c-.026 0-.045-9.493-.045-21.2s.019-21.2.045-21.2.041 9.49.041 21.2-.019 21.2-.041 21.2"
            data-name="Path 99224"
          />
          <path
            fill="#e0e0e0"
            d="M151.816 67.524a2.771 2.771 0 0 1-.395-.345c-.235-.219-.551-.531-.887-.887s-.644-.672-.868-.906a3.2 3.2 0 0 1-.346-.371 2.9 2.9 0 0 1 .391.345c.235.219.555.531.887.887s.644.675.868.906a3.511 3.511 0 0 1 .35.371"
            data-name="Path 99225"
          />
          <path
            fill="#e0e0e0"
            d="M144.943 94.404h14.229v1.733h-14.229z"
            data-name="Rectangle 55984"
          />
          <path
            fill="#e0e0e0"
            d="M152.073 58.643a2.946 2.946 0 0 0 .868-.371 2.359 2.359 0 0 0 .7-.7 2.293 2.293 0 0 0 .372-1.113v-.037l.048.041a1.865 1.865 0 0 0-1.047.575 3.715 3.715 0 0 0-.585.779 8.891 8.891 0 0 0-.305.579 1.266 1.266 0 0 1-.108.215 1.623 1.623 0 0 1 .078-.226 5.161 5.161 0 0 1 .283-.594 3.813 3.813 0 0 1 .577-.805 1.923 1.923 0 0 1 1.1-.6h.052v.089a2.4 2.4 0 0 1-1.117 1.856 2.191 2.191 0 0 1-.648.275 1.446 1.446 0 0 1-.186.033Z"
            data-name="Path 99226"
          />
          <path
            fill="#e0e0e0"
            d="M152.072 58.643a5.064 5.064 0 0 1 .6-.742 5.452 5.452 0 0 1 .659-.687 5.571 5.571 0 0 1-.6.742 5.744 5.744 0 0 1-.659.687"
            data-name="Path 99227"
          />
          <path
            fill="#455a64"
            d="m73.994 115.885-38.775-.022a8.429 8.429 0 0 1-8.44-8.417V10.582a8.429 8.429 0 0 1 8.444-8.413h.007l38.775.022a8.433 8.433 0 0 1 8.44 8.417l-.019 96.864a8.429 8.429 0 0 1-8.433 8.413"
            data-name="Path 99228"
          />
          <path
            fill="#fff"
            d="M74.298 5.617h-7.517a1.858 1.858 0 0 0-1.825 1.891v1.366a1.859 1.859 0 0 1-1.817 1.9H48.478a1.859 1.859 0 0 1-1.826-1.892v-1.38a1.858 1.858 0 0 0-1.817-1.9h-9.234a5.912 5.912 0 0 0-5.919 5.886l-.019 94.537a5.9 5.9 0 0 0 5.915 5.893l38.693.022a5.9 5.9 0 0 0 5.919-5.882v-.011l.019-94.533a5.908 5.908 0 0 0-5.911-5.9"
            data-name="Path 99229"
          />
          <path
            fill="#263238"
            d="M74.299 5.617h.2a4.265 4.265 0 0 1 .6.045 5.919 5.919 0 0 1 3.568 1.856 5.837 5.837 0 0 1 1.117 1.759 6.072 6.072 0 0 1 .451 2.4v2.772c0 7.853 0 19.217.019 33.256s0 30.755 0 49.319v8.833a6.388 6.388 0 0 1-.227 1.8 5.989 5.989 0 0 1-.745 1.663 6.4 6.4 0 0 1-1.233 1.362 6.016 6.016 0 0 1-3.416 1.321c-1.24.037-2.473 0-3.725 0H36.027a6.274 6.274 0 0 1-3.851-1.061 6.043 6.043 0 0 1-2.347-3.229 6.447 6.447 0 0 1-.246-1.993V11.581c0-.238.022-.479.034-.716a3.221 3.221 0 0 1 .123-.7 5.975 5.975 0 0 1 6.086-4.628h8.85a1.893 1.893 0 0 1 1.893 1.271 3.189 3.189 0 0 1 .123 1.162v.575q-.014.28 0 .56a1.854 1.854 0 0 0 .443.987 1.737 1.737 0 0 0 .905.56 1.934 1.934 0 0 0 .533.052h14.724a1.7 1.7 0 0 0 .723-.256 1.856 1.856 0 0 0 .831-1.188 3.262 3.262 0 0 0 .045-.713v-.705a3.853 3.853 0 0 1 .03-.69 1.954 1.954 0 0 1 .223-.631 1.859 1.859 0 0 1 .942-.831 2.994 2.994 0 0 1 1.177-.122 2.851 2.851 0 0 0-1.155.126 1.809 1.809 0 0 0-.916.809 1.893 1.893 0 0 0-.212.612 4.059 4.059 0 0 0-.03.679v.705a3.218 3.218 0 0 1-.045.742 1.948 1.948 0 0 1-.857 1.232 1.834 1.834 0 0 1-.745.267c-.276.022-.54 0-.812 0H48.602a2.036 2.036 0 0 1-.559-.056 1.834 1.834 0 0 1-.954-.5 2 2 0 0 1-.469-1.039 4.3 4.3 0 0 1-.019-.575v-.594a3.1 3.1 0 0 0-.115-1.113 1.858 1.858 0 0 0-.685-.894 1.8 1.8 0 0 0-1.117-.3h-8.84a5.842 5.842 0 0 0-5.945 4.539c-.06.223-.078.456-.119.687a3.99 3.99 0 0 0-.03.7v94.125a6.354 6.354 0 0 0 .238 1.948 5.91 5.91 0 0 0 2.28 3.136 6.109 6.109 0 0 0 3.758 1.032h34.9c1.24 0 2.488.019 3.706 0a5.854 5.854 0 0 0 3.326-1.284 6.271 6.271 0 0 0 1.2-1.329 5.761 5.761 0 0 0 .745-1.614 6.222 6.222 0 0 0 .22-1.755v-8.825c0-18.556 0-35.279.03-49.322s.022-25.4.03-33.253v-2.773a6.127 6.127 0 0 0-.432-2.375 5.8 5.8 0 0 0-1.117-1.752 5.9 5.9 0 0 0-3.539-1.856 4.719 4.719 0 0 0-.6-.056Z"
            data-name="Path 99230"
          />
          <path
            fill="#263238"
            d="M75.434 16.39c0 .026-.98.056-2.183.063s-2.186 0-2.186-.026.98-.056 2.183-.063 2.186 0 2.186.026"
            data-name="Path 99231"
          />
          <path
            fill="#263238"
            d="M75.442 17.503c0 .026-.976.056-2.183.063s-2.183 0-2.183-.026.976-.056 2.183-.063 2.183 0 2.183.026"
            data-name="Path 99232"
          />
          <path
            fill="#263238"
            d="M75.453 18.617c0 .026-.98.056-2.183.063s-2.186 0-2.186-.026.98-.056 2.183-.063 2.186 0 2.186.026"
            data-name="Path 99233"
          />
          <path
            fill="#263238"
            d="M35.65 18.962h.346l.931-.022-.026.026v-1.043a.372.372 0 0 1 .313-.286h.447a.372.372 0 0 1 .305.371v.983l-.041-.041h1.173l-.045.048v-1.832a.372.372 0 0 0-.164-.278l-.514-.5-.5-.486-.238-.238c-.082-.078-.149-.163-.246-.182a.315.315 0 0 0-.268.052c-.071.059-.145.145-.22.215l-.421.423c-.264.275-.544.527-.745.765a.831.831 0 0 0-.056.438v1.5a1.3 1.3 0 0 1 0 .152v-1.883a.483.483 0 0 1 .063-.245c.22-.267.477-.5.745-.783l.421-.427c.074-.074.138-.148.227-.226a.408.408 0 0 1 .339-.067.644.644 0 0 1 .283.2l.153.16.5.486.514.5a.809.809 0 0 1 .13.141.411.411 0 0 1 .063.2v1.879h-1.264v-1.03a.286.286 0 0 0-.238-.282h-.428a.29.29 0 0 0-.253.23v1.069h-1.223a.261.261 0 0 1-.063.011"
            data-name="Path 99234"
          />
          <path
            fill="#f15a29"
            d="M43.636 77.593h23.452a4.291 4.291 0 0 1 4.3 4.283v1.188a4.291 4.291 0 0 1-4.3 4.283H43.636a4.291 4.291 0 0 1-4.3-4.283v-1.188a4.291 4.291 0 0 1 4.3-4.283"
            data-name="Path 99235"
          />
          <path
            fill="#ebebeb"
            d="M76.135 39.915c.086-.631-.8-1.147-1.49-1.2a3.9 3.9 0 0 1-.954-.082c-.656-.226-.916-1.073-1.557-1.34a1.827 1.827 0 0 0-1.49.212 3.972 3.972 0 0 1-1.442.557c-.447.03-.939-.137-1.333.082-.291.163-.447.5-.715.7a1.709 1.709 0 0 1-.939.256 2.641 2.641 0 0 0-.968.156.743.743 0 0 0-.484.776Z"
            data-name="Path 99236"
          />
          <path
            fill="#f5f5f5"
            d="M41.964 45.041h28.834V69.03H41.964z"
            data-name="Rectangle 55985"
          />
          <path
            fill="#263238"
            d="M41.964 69.034c0-.675 0-9.6-.03-23.99v-.033h28.9v24.06H70.8l-28.837-.033 28.837-.033-.037.033v-23.99l.041.041H41.975l.034-.033c-.03 14.377-.041 23.3-.045 23.978"
            data-name="Path 99237"
          />
          <path
            fill="#455a64"
            d="M48.404 62.054h2.995v6.977h-2.995z"
            data-name="Rectangle 55986"
          />
          <path
            fill="#fafafa"
            d="M48.717 65.98a.152.152 0 0 0 .153.152.154.154 0 0 0 0-.308.156.156 0 0 0-.156.156"
            data-name="Path 99238"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 55.537s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99239"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 56.386s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99240"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 57.236s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99241"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 58.086s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.022-14.381-.022-14.381 0-14.381.022"
            data-name="Path 99242"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 58.936s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99243"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 59.786s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99244"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 60.636s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99245"
          />
          <path
            fill="#e0e0e0"
            d="M41.964 61.486s6.436.026 14.381.026 14.381 0 14.381-.026-6.44-.026-14.381-.026-14.381.011-14.381.026"
            data-name="Path 99246"
          />
          <path
            fill="#fff"
            d="M57.172 48.292h2.227v4.346h-2.227z"
            data-name="Rectangle 55987"
          />
          <path
            fill="#263238"
            d="M57.172 52.638a.056.056 0 0 0 0-.022v-4.324l-.026.026h2.235s-.052-.048-.026-.026v4.338h.022v-4.36l-.026-.026h-2.254v4.372Z"
            data-name="Path 99247"
          />
          <path
            fill="#263238"
            d="M58.278 52.634s.026-.976.026-2.182 0-2.186-.026-2.186-.026.98-.026 2.186.011 2.182.026 2.182"
            data-name="Path 99248"
          />
          <path
            fill="#263238"
            d="M57.198 50.615s.5.026 1.117.026a8.6 8.6 0 0 0 1.117-.026 8.6 8.6 0 0 0-1.117-.026c-.615 0-1.117.011-1.117.026"
            data-name="Path 99249"
          />
          <path
            fill="#fafafa"
            d="M44.467 62.235h2.227v4.346h-2.227z"
            data-name="Rectangle 55988"
          />
          <path
            fill="#263238"
            d="M44.467 66.6v-4.346l-.026.026h2.235l-.026-.026v4.338h.063v-4.331c.026.022-.03-.033-.026-.026h-2.235v4.364Z"
            data-name="Path 99250"
          />
          <path
            fill="#263238"
            d="M45.573 66.6s.026-.98.026-2.186 0-2.186-.026-2.186-.026.98-.026 2.186.011 2.186.026 2.186"
            data-name="Path 99251"
          />
          <path
            fill="#263238"
            d="M44.493 64.559s.5.026 1.117.026a8.6 8.6 0 0 0 1.117-.026 8.6 8.6 0 0 0-1.117-.026c-.615 0-1.117.011-1.117.026"
            data-name="Path 99252"
          />
          <path
            fill="#e0e0e0"
            d="M60.319 54.045a.394.394 0 0 0 0-.059v-.75h2.235l-.026-.026v.824l.026-.026h.037v-.768s-.048-.052-.026-.03h-2.253v.772a.278.278 0 0 0 .007.063"
            data-name="Path 99253"
          />
          <path
            fill="#e0e0e0"
            d="M46.314 47.435a.344.344 0 0 0 0-.056v-.694h1.613l-.026-.026v.742l.022-.026H46.31a.6.6 0 0 0 .115 0h1.546v-.783s-.052-.052-.03-.026h-1.639v.72a.223.223 0 0 0 .007.148"
            data-name="Path 99254"
          />
          <path
            fill="#e0e0e0"
            d="M58.889 54.045h2.164v.787h-2.164z"
            data-name="Rectangle 55989"
          />
          <path
            fill="#e0e0e0"
            d="M56.23 46.908h2.164v.787H56.23z"
            data-name="Rectangle 55990"
          />
          <path
            fill="#e0e0e0"
            d="M45.774 53.328h2.164v.787h-2.164z"
            data-name="Rectangle 55991"
          />
          <path
            fill="#e0e0e0"
            d="M42.06 50.419h1.702v.787H42.06z"
            data-name="Rectangle 55992"
          />
          <path
            fill="#f15a29"
            d="m46.854 37.069 19.823.141 6.384 7.831H40.548Z"
            data-name="Path 99255"
          />
          <path
            fill="#263238"
            d="M46.854 37.069s-.145.193-.428.557l-1.248 1.585-4.608 5.864-.022-.045h32.514l-.03.063c-2.276-2.795-4.421-5.433-6.381-7.834h.026l-14.333-.119-4.049-.037h4.038l14.343.082 6.388 7.831.052.059h-32.62l.034-.041 4.634-5.842 1.255-1.574Z"
            data-name="Path 99256"
          />
          <path
            fill="#f5f5f5"
            d="m56.807 40.506-1.523 2.527h-6.865v-2.527l2.995-5.418 3.87 5.374Z"
            data-name="Path 99257"
          />
          <path
            fill="#fff"
            d="M51.689 38.435a1.117 1.117 0 1 1-1.121 1.117 1.119 1.119 0 0 1 1.121-1.117"
            data-name="Path 99258"
          />
          <path
            fill="#263238"
            d="M50.579 39.552a.852.852 0 0 1 .022-.275 1.113 1.113 0 0 1 .406-.631 1.148 1.148 0 1 1 0 1.815 1.113 1.113 0 0 1-.406-.631.874.874 0 0 1-.022-.278 1.033 1.033 0 0 0 .052.271 1.1 1.1 0 0 0 1.34.773c.033-.009.066-.019.1-.031a1.084 1.084 0 1 0-1.032-1.882 1.114 1.114 0 0 0-.406.6 1.087 1.087 0 0 0-.052.271"
            data-name="Path 99259"
          />
          <path
            fill="#fff"
            d="M47.651 46.604h7.211v7.452h-7.211z"
            data-name="Rectangle 55993"
          />
          <path
            fill="#263238"
            d="M47.655 54.062V46.61l-.022.022h7.211l-.026-.026v7.452l.022-.026h.019v-7.5h-7.264v7.445a.115.115 0 0 0 .06.089"
            data-name="Path 99260"
          />
          <path
            fill="#263238"
            d="M51.123 53.956s.026-1.633.026-3.652 0-3.652-.026-3.652-.026 1.637-.026 3.652.011 3.652.026 3.652"
            data-name="Path 99261"
          />
          <path
            fill="#263238"
            d="M47.733 50.571s1.579.026 3.527.026 3.527 0 3.527-.026-1.579-.026-3.527-.026-3.527.011-3.527.026"
            data-name="Path 99262"
          />
          <path
            fill="#fafafa"
            d="M43.513 48.203h2.227v4.346h-2.227z"
            data-name="Rectangle 55994"
          />
          <path
            fill="#263238"
            d="M43.502 52.549a.113.113 0 0 1 0-.022v-4.325l-.026.026h2.235l-.026-.026v4.338h.041v-4.361c.022.026-.034-.033-.026-.026h-2.235v4.372Z"
            data-name="Path 99263"
          />
          <path
            fill="#263238"
            d="M44.619 52.549s.026-.98.026-2.186 0-2.186-.026-2.186-.026.98-.026 2.186.026 2.186.026 2.186"
            data-name="Path 99264"
          />
          <path
            fill="#263238"
            d="M43.539 50.526s.5.026 1.117.026a8.608 8.608 0 0 0 1.117-.026 8.638 8.638 0 0 0-1.117-.026c-.611 0-1.117.011-1.117.026"
            data-name="Path 99265"
          />
          <path
            fill="#f5f5f5"
            d="M39.177 60.532h1.099v8.499h-1.099z"
            data-name="Rectangle 55995"
          />
          <path
            fill="#263238"
            d="M39.177 69.031v-8.54h1.136c.078.078 0 .026.037.041v5.058c0 1.436 0 2.62-.019 3.437l-.019.019v-8.5c.019 0-.037-.041.041.037h-1.116l.037-.037v8.324a.165.165 0 0 1-.1.156"
            data-name="Path 99266"
          />
          <path
            fill="#f5f5f5"
            d="M52.885 60.532h1.099v8.499h-1.099z"
            data-name="Rectangle 55996"
          />
          <path
            fill="#263238"
            d="M52.885 69.031v-8.54h1.12c.078.078.022.026.037.041v8.5h-.019v-8.476l.037.037h-1.1l.041-.037c0 2.375 0 4.483-.019 6.031v2.294a.165.165 0 0 1-.1.156"
            data-name="Path 99267"
          />
          <path
            fill="#f15a29"
            d="M61.478 60.572H37.74l5.163-4.249H55.75Z"
            data-name="Path 99268"
          />
          <g data-name="Group 108849" opacity={0.3}>
            <g data-name="Group 108848">
              <g
                clipPath="url(#b)"
                data-name="Group 108847"
                transform="translate(37.74 56.323)"
              >
                <path
                  d="M23.738 4.249H0L5.163 0H18.01Z"
                  data-name="Path 99269"
                />
              </g>
            </g>
          </g>
          <path
            fill="#f15a29"
            d="m55.753 56.323-4.5 4.249h10.258Z"
            data-name="Path 99270"
          />
          <path
            fill="#f15a29"
            d="M48.419 40.506h-.831l3.445-5.923.38.5Z"
            data-name="Path 99271"
          />
          <path
            fill="#263238"
            d="M48.415 40.51h-.819l3.449-5.938.372.5Zm-.823 0h.819l2.98-5.415-.372-.5Z"
            data-name="Path 99272"
          />
          <g data-name="Group 108852" opacity={0.3}>
            <g data-name="Group 108851">
              <g
                clipPath="url(#c)"
                data-name="Group 108850"
                transform="translate(47.588 34.583)"
              >
                <path d="M.831 5.923H0L3.445 0l.38.5Z" data-name="Path 99273" />
              </g>
            </g>
          </g>
          <path
            fill="#ebebeb"
            d="M55.283 43.031v-2.568h1.546Z"
            data-name="Path 99274"
          />
          <path
            fill="#f15a29"
            d="M56.806 40.506h1.24l-4.3-5.949-2.715.026 4.25 5.879Z"
            data-name="Path 99275"
          />
          <path
            fill="#263238"
            d="M56.811 40.506h-.4l-1.117-.026-4.269-5.867-.041-.059h2.809l4.284 5.964.022.03h-.037v.03l-4.317-5.938h-2.682l.03-.063 4.239 5.886 1.117.045Z"
            data-name="Path 99276"
          />
          <path
            fill="#263238"
            d="M63.932 45.075c.019 0 .037 5.366.037 11.984s0 11.984-.037 11.984-.041-5.363-.041-11.984.019-11.984.041-11.984"
            data-name="Path 99277"
          />
          <path
            fill="#fafafa"
            d="M56.405 62.365h2.227v4.346h-2.227z"
            data-name="Rectangle 55999"
          />
          <path
            fill="#263238"
            d="M56.408 66.716v-4.35l-.026.026h2.235l-.026-.026v4.338l.019-.022h.022v-4.338l-.03-.03h-2.235v4.35a.1.1 0 0 1 .041.052"
            data-name="Path 99278"
          />
          <path
            fill="#263238"
            d="M57.515 66.707s.026-.98.026-2.186 0-2.182-.026-2.182-.03.976-.03 2.182.015 2.186.03 2.186"
            data-name="Path 99279"
          />
          <path
            fill="#263238"
            d="M56.434 64.689s.5.026 1.117.026a8.6 8.6 0 0 0 1.117-.026 21.177 21.177 0 0 0-2.235 0"
            data-name="Path 99280"
          />
          <path
            fill="#263238"
            d="M72.928 69.015s-8.068.037-18.017.037-18.017-.019-18.017-.037 8.064-.041 18.017-.041 18.017.019 18.017.041"
            data-name="Path 99281"
          />
          <path
            fill="#263238"
            d="M64.13 24.117c0 .056-4.149.1-9.267.1s-9.267-.041-9.267-.1 4.149-.1 9.267-.1 9.267.045 9.267.1"
            data-name="Path 99282"
          />
          <path
            fill="#263238"
            d="M59.977 25.575c0 .052-2.116.1-4.73.1s-4.73-.045-4.73-.1 2.119-.1 4.73-.1 4.73.041 4.73.1"
            data-name="Path 99283"
          />
          <path
            fill="#263238"
            d="M72.499 94.1c0 .052-7.368.1-16.456.1s-16.456-.045-16.456-.1 7.368-.1 16.456-.1 16.456.059 16.456.1"
            data-name="Path 99284"
          />
          <path
            fill="#263238"
            d="M72.499 98.004c0 .056-7.368.1-16.456.1s-16.456-.041-16.456-.1 7.368-.1 16.456-.1 16.456.044 16.456.1"
            data-name="Path 99285"
          />
          <path
            fill="#263238"
            d="M72.499 101.968c0 .052-7.368.1-16.456.1s-16.456-.045-16.456-.1 7.368-.1 16.456-.1 16.456.041 16.456.1"
            data-name="Path 99286"
          />
          <path
            fill="#ebebeb"
            d="M46.854 33.94c.086-.631-.8-1.147-1.49-1.2a3.693 3.693 0 0 1-.954-.085c-.656-.226-.916-1.069-1.557-1.34a1.827 1.827 0 0 0-1.49.212 3.912 3.912 0 0 1-1.441.557c-.451.03-.939-.137-1.333.082-.294.163-.447.5-.719.7a1.727 1.727 0 0 1-.939.252 2.622 2.622 0 0 0-.965.16.742.742 0 0 0-.484.772Z"
            data-name="Path 99287"
          />
        </g>
      </g>
      <text
        fill="#fff"
        data-name="Book now"
        fontFamily="Nunito-SemiBold, Nunito"
        fontSize={4}
        fontWeight={600}
        letterSpacing=".092em"
        transform="translate(45.24 83.406)"
      >
        <tspan x={0} y={0}>
          {"Book now"}
        </tspan>
      </text>
      <g data-name="Group 108871">
        <g clipPath="url(#a)" data-name="Group 108870">
          <path
            fill="#f15a29"
            d="M25.511 131.666a16.849 16.849 0 0 1 16.017-4.383 29.272 29.272 0 0 1-16.218 5.047"
            data-name="Path 99288"
          />
          <path
            fill="#f15a29"
            d="M30.945 112.486a16.174 16.174 0 0 1 4.7-13.42 21.825 21.825 0 0 1-4.7 13.42"
            data-name="Path 99289"
          />
          <path
            fill="#f15a29"
            d="M28.119 121.374a25.2 25.2 0 0 1 11.584-11.03 15.891 15.891 0 0 1-11.625 11.082"
            data-name="Path 99290"
          />
          <path
            fill="#f15a29"
            d="M27.534 123.089a29.432 29.432 0 0 0 8.5-.742 14.189 14.189 0 0 0 7.237-4.3 28.433 28.433 0 0 0-15.693 4.773"
            data-name="Path 99291"
          />
          <path
            fill="#f15a29"
            d="M28.975 117.444c-1.449-5.407-3.837-9.148-8.105-12.177a11.249 11.249 0 0 0 8.105 12.177"
            data-name="Path 99292"
          />
          <path
            fill="#f15a29"
            d="M18.215 126.77a8.008 8.008 0 0 1 4.879-7.422 10.588 10.588 0 0 1-4.879 7.422"
            data-name="Path 99293"
          />
          <path
            fill="#f15a29"
            d="M16.851 122.206a11.935 11.935 0 0 0-7.841-10.173 13.946 13.946 0 0 0 7.841 10.173"
            data-name="Path 99294"
          />
          <path
            fill="#f15a29"
            d="M18.918 130.296a7.564 7.564 0 0 0-4.343-2.769 22.386 22.386 0 0 0-5.215-.52 12.232 12.232 0 0 0 9.442 3.919"
            data-name="Path 99295"
          />
          <path
            fill="#f15a29"
            d="M20.945 141.823a13.424 13.424 0 0 0-16.356 7.719 34.948 34.948 0 0 0 16.356-7.719"
            data-name="Path 99296"
          />
          <path
            fill="#f15a29"
            d="M27.463 137.815a9.088 9.088 0 0 0 1.4 8.926 10.63 10.63 0 0 0-1.4-8.926"
            data-name="Path 99297"
          />
          <path
            fill="#f15a29"
            d="M31.318 136.201a10.94 10.94 0 0 0 6.064 8.874.682.682 0 0 0 .536.089c.264-.108.291-.471.257-.742-.428-3.711-3.2-7.326-6.857-8.206"
            data-name="Path 99298"
          />
          <path
            fill="#f15a29"
            d="M33.836 135.821a9.023 9.023 0 0 0 12.717.492q.223-.205.431-.426a51.132 51.132 0 0 0-13.148-.066"
            data-name="Path 99299"
          />
          <path
            fill="#263238"
            d="M34.461 102.532a.613.613 0 0 1-.037.122c-.034.085-.075.2-.127.371-.119.315-.291.768-.507 1.347s-.484 1.3-.786 2.138c-.149.419-.313.865-.484 1.34l-.521 1.507c-.745 2.108-1.561 4.639-2.451 7.456s-1.784 5.938-2.686 9.23-1.676 6.443-2.347 9.319-1.211 5.485-1.617 7.682c-.1.549-.2 1.069-.294 1.566s-.168.961-.242 1.4c-.149.876-.279 1.629-.372 2.249s-.171 1.087-.22 1.421l-.063.371a.667.667 0 0 1-.026.126 1.018 1.018 0 0 1 0-.126l.045-.371.19-1.429c.082-.613.2-1.373.343-2.253.071-.438.145-.906.227-1.4s.183-1.021.283-1.57c.372-2.2.942-4.806 1.583-7.69s1.449-6.038 2.339-9.334 1.821-6.406 2.7-9.23 1.728-5.344 2.484-7.448l.533-1.5c.175-.475.339-.92.5-1.336.309-.831.577-1.548.812-2.13l.529-1.336c.063-.148.108-.263.145-.345a.589.589 0 0 1 .071-.141"
            data-name="Path 99300"
          />
          <path
            fill="#263238"
            d="m11.506 114.445.071.067.2.212c.171.182.425.46.745.828a29.107 29.107 0 0 1 2.462 3.27 21.86 21.86 0 0 1 2.574 5.485 53.348 53.348 0 0 1 1.542 7.259c.849 5.2 1.892 9.842 2.648 13.2l.894 3.982c.1.464.183.828.238 1.084.026.119.045.212.06.286a.465.465 0 0 1 0 .1.626.626 0 0 1-.03-.093l-.074-.282c-.063-.256-.156-.616-.272-1.076-.227-.946-.551-2.3-.954-3.971-.782-3.359-1.862-8.009-2.693-13.2a55.121 55.121 0 0 0-1.49-7.244 22.173 22.173 0 0 0-2.529-5.467 31.594 31.594 0 0 0-2.429-3.292 38.977 38.977 0 0 0-.719-.85l-.186-.219c-.045-.044-.063-.074-.06-.074"
            data-name="Path 99301"
          />
          <path
            fill="#263238"
            d="M35.084 135.648a.632.632 0 0 1-.149.026l-.432.048-.313.03-.372.056c-.268.041-.57.078-.9.152l-.521.1-.555.137c-.372.089-.786.23-1.211.371a17.923 17.923 0 0 0-2.648 1.165 14.922 14.922 0 0 0-2.373 1.64 11.377 11.377 0 0 0-.909.872 9.263 9.263 0 0 0-.723.828 8.351 8.351 0 0 0-.533.742 5.4 5.4 0 0 0-.372.579l-.223.371a.608.608 0 0 1-.086.122.616.616 0 0 1 .06-.137l.194-.39a4.922 4.922 0 0 1 .346-.6 7.465 7.465 0 0 1 .518-.742 9.119 9.119 0 0 1 .715-.85 11.536 11.536 0 0 1 .909-.891 14.426 14.426 0 0 1 2.391-1.67 17.176 17.176 0 0 1 2.678-1.162c.432-.126.834-.264 1.225-.345l.562-.134.525-.093c.335-.067.641-.1.905-.126l.372-.044h.749c.115-.082.168-.082.168-.063"
            data-name="Path 99302"
          />
          <path
            fill="#263238"
            d="M36.276 114.545a2.135 2.135 0 0 1-.3.289l-.86.742c-.745.623-1.747 1.484-2.876 2.409s-2.16 1.77-2.909 2.371l-.894.7a1.673 1.673 0 0 1-.339.241 2.136 2.136 0 0 1 .3-.29l.86-.742c.745-.623 1.747-1.484 2.876-2.4s2.157-1.77 2.905-2.371l.894-.705a2.078 2.078 0 0 1 .343-.241"
            data-name="Path 99303"
          />
          <path
            fill="#263238"
            d="M37.304 120.041c0 .041-2.183.742-4.9 1.525s-4.917 1.444-4.947 1.384 2.179-.724 4.9-1.525 4.932-1.414 4.947-1.384"
            data-name="Path 99304"
          />
          <path
            fill="#263238"
            d="M29.199 117.86a2.006 2.006 0 0 1-.227-.312 83.777 83.777 0 0 0-.577-.88c-.492-.742-1.188-1.748-2.011-2.824s-1.617-2.008-2.2-2.676l-.7-.776a1.962 1.962 0 0 1-.242-.3 1.55 1.55 0 0 1 .287.256c.179.174.432.434.745.742.607.653 1.419 1.581 2.235 2.657s1.512 2.1 1.982 2.861c.231.371.413.687.536.906a1.807 1.807 0 0 1 .171.345"
            data-name="Path 99305"
          />
          <path
            fill="#263238"
            d="M21.578 120.918a6.986 6.986 0 0 1-.514.842c-.328.516-.775 1.228-1.233 2.034s-.849 1.555-1.117 2.1a6.923 6.923 0 0 1-.466.872 5.231 5.231 0 0 1 .372-.92c.253-.557.626-1.314 1.088-2.127s.927-1.518 1.281-2.019a5.332 5.332 0 0 1 .589-.779"
            data-name="Path 99306"
          />
          <path
            fill="#263238"
            d="M18.971 130.433c0 .022-.428-.182-1.117-.49s-1.643-.709-2.723-1.087-2.082-.657-2.812-.842a11.9 11.9 0 0 1-1.177-.312 1.433 1.433 0 0 1 .328.033c.209.033.507.089.875.167a24.175 24.175 0 0 1 2.849.82 24.635 24.635 0 0 1 2.719 1.132c.335.167.607.312.79.416a1.22 1.22 0 0 1 .268.163"
            data-name="Path 99307"
          />
          <path
            fill="#263238"
            d="M21.023 141.838a.885.885 0 0 1-.134.048l-.395.1-1.46.334a48.676 48.676 0 0 0-4.764 1.255 21.219 21.219 0 0 0-4.451 2.079c-.533.338-.946.635-1.225.85a4.585 4.585 0 0 1-.443.327.761.761 0 0 1 .1-.1c.071-.063.168-.16.305-.271a12.487 12.487 0 0 1 1.2-.894 19.578 19.578 0 0 1 4.47-2.141 39.73 39.73 0 0 1 4.8-1.21l1.468-.289a4.019 4.019 0 0 1 .533-.078"
            data-name="Path 99308"
          />
          <path
            fill="#263238"
            d="M28.446 144.647c-.045 0-.305-1.529-.585-3.425s-.469-3.44-.425-3.448.305 1.525.585 3.425.469 3.44.425 3.448"
            data-name="Path 99309"
          />
          <path
            fill="#263238"
            d="M36.176 141.852a8.551 8.551 0 0 1-.7-.824c-.417-.52-1-1.232-1.65-2.008s-1.263-1.484-1.7-1.967a7.371 7.371 0 0 1-.693-.828 1.171 1.171 0 0 1 .22.189c.134.13.324.323.559.56a43.55 43.55 0 0 1 3.356 3.982c.2.267.372.49.458.642a1.073 1.073 0 0 1 .149.252"
            data-name="Path 99310"
          />
          <path
            fill="#455a64"
            d="M15.722 148.993v3.8h.954l3.4 10.677h7.528l3.285-10.57 1.274-.108v-3.8Z"
            data-name="Path 99311"
          />
          <path
            fill="#263238"
            d="M31.523 152.905c0 .045-3.323.078-7.424.078s-7.416-.033-7.416-.078 3.323-.082 7.424-.082 7.416.037 7.416.082"
            data-name="Path 99312"
          />
          <path
            fill="#263238"
            d="M36.939 128.429a3.25 3.25 0 0 1-.484.13l-1.319.319c-1.117.275-2.645.69-4.3 1.243s-3.133 1.147-4.187 1.6l-1.244.542a2.84 2.84 0 0 1-.462.186 3.3 3.3 0 0 1 .436-.241c.283-.148.7-.353 1.225-.59a43.334 43.334 0 0 1 4.177-1.643 44 44 0 0 1 4.328-1.206 28.895 28.895 0 0 1 1.333-.267 2.941 2.941 0 0 1 .5-.07"
            data-name="Path 99313"
          />
          <path
            fill="#fafafa"
            d="M29.023 158.832c0 .044-2.332.082-5.215.082s-5.215-.037-5.215-.082 2.332-.078 5.215-.078 5.215.033 5.215.078"
            data-name="Path 99314"
          />
          <path
            fill="#fafafa"
            d="M29.318 157.949c0 .045-2.473.082-5.52.082s-5.52-.037-5.52-.082 2.473-.078 5.52-.078 5.52.026 5.52.078"
            data-name="Path 99315"
          />
          <path
            fill="#455a64"
            d="M141.717 128.455a.367.367 0 0 1 0 .085v.245c0 .212.033.531.056.939l.231 3.459c.2 2.928.48 6.959.786 11.408s.577 8.48.775 11.412l.22 3.459c.022.4.041.713.052.943v.245a.269.269 0 0 1 0 .082.275.275 0 0 1 0-.082.913.913 0 0 0-.026-.245c-.019-.226-.045-.538-.082-.939-.063-.828-.156-2-.272-3.455-.209-2.928-.5-6.959-.819-11.408s-.57-8.491-.745-11.416a206.404 206.404 0 0 1-.179-3.463c0-.408-.03-.724-.026-.943v-.245c0-.063.026-.082.03-.082"
            data-name="Path 99316"
          />
          <path
            fill="#263238"
            d="M154.248 88.496a14.6 14.6 0 0 0-2.2-3.978 27.55 27.55 0 0 1-2.492-4.739c-.372-.95-.883-2.063-1.9-2.227l-8.232-1.021a4.536 4.536 0 0 0-3.758 3.155 14.639 14.639 0 0 0-.536 5.118 11.762 11.762 0 0 1-.745 5.1 4.538 4.538 0 0 0-.626 1.711 2.053 2.053 0 0 0 .7 1.663c.6.653-.294 2.383.3 2.75.849.523 3.323-.349 4.317-.278l3.1.23a5.375 5.375 0 0 0 2.652-.252c.745-.341 1.311-1.013 2.1-1.2.95-.226 1.907.312 2.883.393a3.165 3.165 0 0 0 3.226-2.327 3.676 3.676 0 0 0 1.207-4.094"
            data-name="Path 99317"
          />
          <path
            fill="#f15a29"
            d="m136.893 112.619-1.147-.16c-2.19-.1-4.675-.093-6.194 1.484a7.335 7.335 0 0 0-1.572 3.633q-.477 2.16-.8 4.342a2.886 2.886 0 0 0 .119 1.8 2.786 2.786 0 0 0 1.788 1.147 18.465 18.465 0 0 0 6.332.705 1.92 1.92 0 0 0 1.207-.371 2 2 0 0 0 .469-1.392 67.221 67.221 0 0 0-.2-11.193"
            data-name="Path 99318"
          />
          <path
            fill="#f15a29"
            d="m140.474 156.041.183 4.765s-4.8 1.789-4.842 2.672l9.286-.026v-7.422Z"
            data-name="Path 99319"
          />
          <path
            fill="#263238"
            d="M143.528 160.394a.357.357 0 0 1-.164.694.4.4 0 0 1-.276-.456.373.373 0 0 1 .473-.226"
            data-name="Path 99320"
          />
          <path
            fill="#263238"
            d="M145.093 163.463v-.638l-8.921.193s-.413.182-.372.471Z"
            data-name="Path 99321"
          />
          <path
            fill="#263238"
            d="M140.597 160.777c0 .045.231.063.454.208s.343.334.373.315-.026-.278-.3-.449-.533-.119-.525-.074"
            data-name="Path 99322"
          />
          <path
            fill="#263238"
            d="M139.602 161.211c0 .045.186.122.343.312s.2.393.246.39.071-.256-.127-.49-.462-.252-.462-.212"
            data-name="Path 99323"
          />
          <path
            fill="#263238"
            d="M138.987 162.45c.041 0 .1-.215 0-.464s-.332-.345-.35-.3.112.167.2.371.1.393.145.4"
            data-name="Path 99324"
          />
          <path
            fill="#263238"
            d="M140.663 159.645c0 .041.227-.022.484 0s.454.1.48.056-.171-.2-.473-.215-.514.122-.492.16"
            data-name="Path 99325"
          />
          <path
            fill="#f15a29"
            d="m143.807 156.041.186 4.765s-4.8 1.789-4.842 2.672l9.279-.015v-7.422Z"
            data-name="Path 99326"
          />
          <path
            fill="#263238"
            d="M146.865 160.394a.357.357 0 0 1-.164.694.4.4 0 0 1-.276-.456.373.373 0 0 1 .473-.226"
            data-name="Path 99327"
          />
          <path
            fill="#263238"
            d="M148.43 163.463v-.638l-8.921.193s-.413.182-.372.471Z"
            data-name="Path 99328"
          />
          <path
            fill="#263238"
            d="M143.934 160.777c0 .045.231.063.454.208s.343.334.373.315-.026-.278-.3-.449-.533-.119-.525-.074"
            data-name="Path 99329"
          />
          <path
            fill="#263238"
            d="M142.924 161.211c0 .045.186.122.343.312s.2.393.246.39.071-.256-.127-.49-.458-.252-.462-.212"
            data-name="Path 99330"
          />
          <path
            fill="#263238"
            d="M142.324 162.45c.041 0 .1-.215 0-.464s-.331-.345-.35-.3.112.167.2.371.1.393.145.4"
            data-name="Path 99331"
          />
          <path
            fill="#263238"
            d="M144.003 159.645c.019.041.227-.022.484 0s.454.1.48.056-.171-.2-.473-.215-.514.122-.492.16"
            data-name="Path 99332"
          />
          <path
            fill="#263238"
            d="m151.923 122.209-18.084.334s-.745 15.327-.745 19.722l7.416 18.467 3.3-.809.06.809h5.241l2.317-31.5Zm-11.376 18.927c0-.237.629-4.428 1.281-8.8l1.259 17.576c-1.333-4.119-2.578-8.15-2.54-8.784Z"
            data-name="Path 99333"
          />
          <path
            fill="#ffbe9d"
            d="M136.45 81.374a4.553 4.553 0 0 1 4.47-4.082l6.876 1.184v1.139l-.186 14.1s-.51 4.561-4.369 4.368c-3.464-.178-3.352-3.823-3.352-3.826l.06-2.661s-2.8-.063-3.419-3.585a37.15 37.15 0 0 1-.078-6.639"
            data-name="Path 99334"
          />
          <path
            fill="#263238"
            d="M137.318 84.138a.446.446 0 0 0 .443.449.435.435 0 0 0 .462-.407v-.009a.454.454 0 0 0-.443-.449.432.432 0 0 0-.461.4v.016"
            data-name="Path 99335"
          />
          <path
            fill="#263238"
            d="M137.106 83.241c.06.056.4-.2.887-.208s.853.23.905.167-.034-.13-.194-.241a1.237 1.237 0 0 0-.723-.208 1.187 1.187 0 0 0-.7.238c-.149.119-.2.226-.171.252"
            data-name="Path 99336"
          />
          <path
            fill="#263238"
            d="M141.628 84.138a.45.45 0 0 0 .443.449.435.435 0 0 0 .461-.407v-.009a.454.454 0 0 0-.443-.449.432.432 0 0 0-.461.4v.016"
            data-name="Path 99337"
          />
          <path
            fill="#263238"
            d="M141.606 83.241c.06.056.4-.2.887-.208s.853.23.905.167-.034-.13-.194-.241a1.237 1.237 0 0 0-.723-.208 1.187 1.187 0 0 0-.7.238c-.149.119-.2.226-.171.252"
            data-name="Path 99338"
          />
          <path
            fill="#263238"
            d="M140.197 86.804a3.569 3.569 0 0 0-.8-.115c-.127 0-.246-.03-.268-.1a.533.533 0 0 1 .074-.312l.35-.809c.13-.293.771-1.948.775-2.1a12.712 12.712 0 0 0-.976 2.045c-.115.286-.227.553-.332.813a.525.525 0 0 0-.056.416.321.321 0 0 0 .209.152 1.243 1.243 0 0 0 .216.022 4.106 4.106 0 0 0 .8-.007"
            data-name="Path 99339"
          />
          <path
            fill="#eb996e"
            d="M139.974 91.628a9.04 9.04 0 0 0 4.723-1.343 4.634 4.634 0 0 1-4.719 2.227Z"
            data-name="Path 99340"
          />
          <path
            fill="#263238"
            d="M141.661 86.823c-.078 0-.071.523-.518.909s-1.013.334-1.017.408.127.1.372.1a1.3 1.3 0 0 0 .849-.308 1.146 1.146 0 0 0 .4-.761c.011-.223-.052-.353-.089-.349"
            data-name="Path 99341"
          />
          <path
            fill="#263238"
            d="M141.606 81.923c.056.148.477.03.987.056s.935.163.987.019c.022-.067-.063-.2-.246-.338a1.407 1.407 0 0 0-.745-.256 1.279 1.279 0 0 0-.745.2c-.186.119-.264.249-.238.319"
            data-name="Path 99342"
          />
          <path
            fill="#263238"
            d="M137.128 81.954c.086.134.443.019.872.037s.778.13.868 0c.041-.067 0-.2-.16-.334a1.151 1.151 0 0 0-1.4-.03c-.156.126-.212.26-.179.327"
            data-name="Path 99343"
          />
          <path
            fill="#455a64"
            d="m144.679 104.878 5.684.572 1.4-11.412a4.817 4.817 0 0 1 3.233 3.192 58.324 58.324 0 0 1 .924 5.8 15.982 15.982 0 0 1 .615 4.082c-.067 1.726-.089 3.359-.659 4.1s-2.525 1.225-3.352 1.2-5.807-1.024-5.807-1.024l-.6-.4-4.284-3.266Z"
            data-name="Path 99344"
          />
          <path
            fill="#455a64"
            d="m132.592 122.543 20.486-.148-1.214-15.09-.093-13.268c-1.088-.572-4.157-1.737-4.157-1.737a5.82 5.82 0 0 1-4.876 2.65 2.743 2.743 0 0 1-2.779-2.2l-4 .861a2.417 2.417 0 0 0-1.415.894l-7.126 9.3-7.319-2.668-3.01 3.845s6.112 5.7 9.815 6.242c2.838.408 6.284-3.711 7.744-5.645l-.458 6.669Z"
            data-name="Path 99345"
          />
          <path
            fill="#f15a29"
            d="m148.72 92.519-14.247 15.528-.361 4.673 17.649-18.683Z"
            data-name="Path 99346"
          />
          <path
            fill="#ffbe9d"
            d="m140.76 100.903-.968-.138a.955.955 0 0 0-.648.742l.082.371a.7.7 0 0 0-.629.323.555.555 0 0 0 .06.638.7.7 0 0 0-.644.742l.093.323a1.9 1.9 0 0 0-.272.646 1.172 1.172 0 0 0 .6.939c.261.078 3.136 1.444 3.136 1.444l.637.494a10.213 10.213 0 0 1 .946-.995c.507-.449 1.527-1.548 1.527-1.548l-.566-.523-.1-2.769-.656-1.7-1.333-.371a.629.629 0 0 0-.089.664.836.836 0 0 0 .439.5l.216.932-.272.371a.7.7 0 0 1-.745.022 3.925 3.925 0 0 1-.931-.713Z"
            data-name="Path 99347"
          />
          <path
            fill="#263238"
            d="M135.073 98.888a1.589 1.589 0 0 1 0 .371c0 .234 0 .572-.034.987-.033.828-.1 1.978-.183 3.244s-.168 2.412-.238 3.24c-.037.416-.071.742-.1.98a1.49 1.49 0 0 1-.06.371 1.217 1.217 0 0 1 0-.371c0-.234 0-.568.034-.983.037-.831.1-1.978.183-3.244s.168-2.412.242-3.244c.034-.412.067-.742.093-.98a1.2 1.2 0 0 1 .06-.371"
            data-name="Path 99348"
          />
          <g data-name="Group 108857" opacity={0.3}>
            <g data-name="Group 108856">
              <g
                clipPath="url(#d)"
                data-name="Group 108855"
                transform="translate(141.486 107.68)"
              >
                <path
                  d="M10.638 4.684c-2.142-.033-4.47-.119-6.161-1.466-.682-.549-1.363-1.184-2.2-1.778C1.566.94.698.59.37 0 .325.059-.003.479-.003.5a5.1 5.1 0 0 1 2.142 1.24 8.612 8.612 0 0 1 1.393 1.737 7.377 7.377 0 0 0 7.316 2.91c-.071-.568-.138-1.139-.212-1.707"
                  data-name="Path 99349"
                />
              </g>
            </g>
          </g>
          <path
            fill="#263238"
            d="M144.876 104.674a1.486 1.486 0 0 1-.2.237l-.607.657-2.164 2.227v-.122a14.5 14.5 0 0 0 1.583 1.139 13.375 13.375 0 0 1 1.766 1.321 8.75 8.75 0 0 0 1.307 1.061 9.18 9.18 0 0 0 1.43.646 9.346 9.346 0 0 0 2.626.456 33.21 33.21 0 0 0 1.81.063 4.685 4.685 0 0 1 .667.026.667.667 0 0 1-.171.022c-.112 0-.279.019-.492.03-.432 0-1.054.03-1.821 0a7.114 7.114 0 0 1-5.487-2.16c-1.117-1.017-2.421-1.644-3.33-2.475l-.067-.059.063-.063 2.235-2.2.633-.609a1.343 1.343 0 0 1 .216-.2"
            data-name="Path 99350"
          />
          <path
            fill="#263238"
            d="M152.426 108.184c-.03 0-.2-.49-.585-1.247a11.058 11.058 0 0 0-.767-1.269c-.168-.219-.317-.5-.562-.586a4.255 4.255 0 0 0-.916-.045 33.408 33.408 0 0 1-3.352-.167 16.878 16.878 0 0 1-1.009-.123 1.446 1.446 0 0 1-.372-.074 11.753 11.753 0 0 1 1.386.063c.853.048 2.03.1 3.334.108h.481a1.432 1.432 0 0 1 .5.059.863.863 0 0 1 .395.3 4.064 4.064 0 0 1 .253.371 9.1 9.1 0 0 1 .745 1.31 8.157 8.157 0 0 1 .372.946 1.255 1.255 0 0 1 .1.349"
            data-name="Path 99351"
          />
          <path
            fill="#263238"
            d="M150.463 99.003a44.969 44.969 0 0 1 0 5.9 45.011 45.011 0 0 1 0-5.9"
            data-name="Path 99352"
          />
          <g data-name="Group 108860" opacity={0.3}>
            <g data-name="Group 108859">
              <g
                clipPath="url(#e)"
                data-name="Group 108858"
                transform="translate(150.53 104.904)"
              >
                <path
                  d="M0 0a5.005 5.005 0 0 1 2.455 3.912.226.226 0 0 1-.034.171.157.157 0 0 1-.209 0 .439.439 0 0 1-.115-.2A12.433 12.433 0 0 0-.003.001"
                  data-name="Path 99353"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 108863" opacity={0.3}>
            <g data-name="Group 108862">
              <g
                clipPath="url(#f)"
                data-name="Group 108861"
                transform="translate(133.997 98.672)"
              >
                <path
                  d="M.556 7.074A8.238 8.238 0 0 1 1.089 0C.851 2.464.758 4.791.608 7.011"
                  data-name="Path 99354"
                />
              </g>
            </g>
          </g>
          <path
            fill="#263238"
            d="M126.859 106.708a3.816 3.816 0 0 1 .48-2.78c.052.026-.19.612-.317 1.377a13.857 13.857 0 0 1-.164 1.4"
            data-name="Path 99355"
          />
          <path
            fill="#263238"
            d="M149.287 80.74h-.152a5.752 5.752 0 0 0-.428-2.564 5.1 5.1 0 0 0-3.49-2.743 9.953 9.953 0 0 0-4.47.115c-.693.148-1.49.445-1.672 1.173a1.852 1.852 0 0 0 .171 1.113 5.04 5.04 0 0 0 3.9 3.058 2.414 2.414 0 0 1 1.117.282 2.352 2.352 0 0 1 .8 1.236 5.107 5.107 0 0 1 .119.657 10.793 10.793 0 0 0 .209 1.9c.231.742.95 1.355.983 2.149.056 1.269-1.643 2.089-1.613 3.359a3.274 3.274 0 0 0 .641 1.507 1.844 1.844 0 0 1 .3 1.57 7.463 7.463 0 0 1-.48.713 1.684 1.684 0 0 0 .973 2.267 2.614 2.614 0 0 0 2.581-.672 5.885 5.885 0 0 0 1.378-2.4 16.646 16.646 0 0 0-.868-12.715"
            data-name="Path 99356"
          />
          <path
            fill="#ffbe9d"
            d="M74.418 72.286 70.5 70.278l-5.632.108s-.186.742.968 1.113a14.848 14.848 0 0 0 3.215.419s-.827-.063.954 1.273a6.58 6.58 0 0 0 4 1.173Z"
            data-name="Path 99357"
          />
          <path
            fill="#ffbe9d"
            d="M65.344 70.682a19.811 19.811 0 0 0-3.259.88c-.585.353-.451.794-.3.932a11.493 11.493 0 0 0 2.537-.189c.3-.045 1.441-.033 1.441-.033s-1.408.423-1.557.976.637.85.637.85-.417.824-.108 1.217a.973.973 0 0 0 .659.412s-.443.575 0 .965 1.993 0 1.993 0a2.588 2.588 0 0 0 1.33.1c.4-.193 5.326-.7 5.326-.7l.272-3.31-7.613-1.856Z"
            data-name="Path 99358"
          />
          <path
            fill="#eb996e"
            d="M66.663 73.462c-.067-.022-.03-.293-.041-.616s-.056-.594 0-.62.235.238.242.612-.138.649-.2.623"
            data-name="Path 99359"
          />
          <path
            fill="#eb996e"
            d="M64.845 74.101c0-.037.156-.067.372-.126a1.12 1.12 0 0 0 .372-.167.523.523 0 0 0 .22-.371.426.426 0 0 0-.171-.371.942.942 0 0 0-.346-.171c-.231-.059-.391-.085-.372-.122s.156-.071.421-.052a.926.926 0 0 1 .454.167.623.623 0 0 1 .279.56.742.742 0 0 1-.339.523 1.029 1.029 0 0 1-.454.152c-.279.045-.432.011-.436-.022"
            data-name="Path 99360"
          />
          <path
            fill="#eb996e"
            d="M64.845 74.1a1.343 1.343 0 0 1 .622-.13 1.688 1.688 0 0 1 .7.089 1.718 1.718 0 0 1 .372.2 1.037 1.037 0 0 1 .294.371.791.791 0 0 1 .082.494.658.658 0 0 1-.283.371 1.75 1.75 0 0 1-.667.249c-.4.067-.641 0-.637-.03s.242-.041.6-.141a1.925 1.925 0 0 0 .574-.263.45.45 0 0 0 .179-.249.6.6 0 0 0-.067-.33.856.856 0 0 0-.22-.293 1.739 1.739 0 0 0-.317-.174 1.811 1.811 0 0 0-.618-.13c-.35 0-.607.011-.611-.03"
            data-name="Path 99361"
          />
          <path
            fill="#eb996e"
            d="M66.221 76.861c0-.03.179-.048.443-.2l.22-.115a1.006 1.006 0 0 0 .223-.122c.086-.082.034-.2-.186-.308a3.926 3.926 0 0 0-1.1-.33c-.291-.041-.48-.026-.488-.063s.179-.108.5-.111a2.774 2.774 0 0 1 1.207.289 1.226 1.226 0 0 1 .156.1.421.421 0 0 1 .149.171.348.348 0 0 1-.093.427 1.032 1.032 0 0 1-.287.137l-.238.089c-.3.115-.51.074-.51.033"
            data-name="Path 99362"
          />
          <path
            fill="#eb996e"
            d="M66.782 73.922c0 .071-.223.119-.484.167s-.48.082-.507 0 .171-.208.462-.264.533.03.529.1"
            data-name="Path 99363"
          />
          <path
            fill="#eb996e"
            d="M68.555 76.127a4.566 4.566 0 0 0-.212-1.915 2.718 2.718 0 0 0-.317-.583l-.03-.045v-.048a1.645 1.645 0 0 0-.041-.872c-.071-.212-.142-.327-.119-.345s.134.074.25.289a1.48 1.48 0 0 1 .138.976l-.019-.093a2.475 2.475 0 0 1 .372.624 2.739 2.739 0 0 1 .183 1.444c-.06.371-.179.575-.2.568"
            data-name="Path 99364"
          />
          <path
            fill="#eb996e"
            d="M71.542 74.85c0 .03-.2.063-.529 0a2.7 2.7 0 0 1-1.162-.531 2.743 2.743 0 0 1-.79-1c-.13-.3-.145-.5-.115-.512a4.174 4.174 0 0 0 1.062 1.318 6.946 6.946 0 0 0 1.535.727"
            data-name="Path 99365"
          />
          <path
            fill="#eb996e"
            d="M72.772 74.335a.843.843 0 0 1-.339-.048 5.241 5.241 0 0 1-.875-.3 6.351 6.351 0 0 1-2.436-1.9l.108.048h-.328a7.526 7.526 0 0 1-3.241-.668 1.688 1.688 0 0 1-.745-.69.518.518 0 0 1-.078-.371 2.174 2.174 0 0 0 .164.315 1.72 1.72 0 0 0 .745.579 8.314 8.314 0 0 0 3.159.579h.384l.041.052a6.768 6.768 0 0 0 2.309 1.889c.678.356 1.143.471 1.132.512"
            data-name="Path 99366"
          />
          <path
            fill="#f15a29"
            d="m109.285 151.733.294 7.474s-7.528 2.809-7.6 4.2l14.568-.048.019-11.668Z"
            data-name="Path 99367"
          />
          <path
            fill="#e0e0e0"
            d="M114.084 158.557a.594.594 0 0 1 .413.675.57.57 0 0 1-.667.416.623.623 0 0 1-.436-.716.6.6 0 0 1 .745-.371"
            data-name="Path 99368"
          />
          <path
            fill="#263238"
            d="m116.542 163.355-.045-1.177-13.953.486s-.644.282-.57.742Z"
            data-name="Path 99369"
          />
          <path
            fill="#263238"
            d="M109.345 159.132c0 .071.372.1.708.33s.54.523.6.494-.041-.434-.469-.7-.857-.186-.842-.122"
            data-name="Path 99370"
          />
          <path
            fill="#263238"
            d="M107.818 159.752c0 .071.294.189.536.49s.313.616.372.612.115-.4-.194-.772-.715-.382-.715-.33"
            data-name="Path 99371"
          />
          <path
            fill="#263238"
            d="M106.958 161.607c.063 0 .16-.338-.022-.742s-.518-.538-.548-.482.179.267.324.586.175.616.246.638"
            data-name="Path 99372"
          />
          <path
            fill="#f15a29"
            d="m94.946 151.588.294 7.478s-7.528 2.809-7.61 4.194l14.571-.051.019-11.668Z"
            data-name="Path 99373"
          />
          <path
            fill="#e0e0e0"
            d="M99.747 158.408a.59.59 0 0 1 .413.675.57.57 0 0 1-.667.416.627.627 0 0 1-.436-.72.593.593 0 0 1 .745-.353"
            data-name="Path 99374"
          />
          <path
            fill="#263238"
            d="m102.201 163.199-.045-1.176-13.957.486s-.648.286-.574.742Z"
            data-name="Path 99375"
          />
          <path
            fill="#263238"
            d="M95.005 158.98c0 .07.372.1.708.327s.536.527.6.5-.041-.438-.473-.705-.857-.186-.838-.119"
            data-name="Path 99376"
          />
          <path
            fill="#263238"
            d="M93.478 159.615c0 .07.291.189.536.486s.313.616.372.612.112-.4-.194-.768-.715-.4-.715-.33"
            data-name="Path 99377"
          />
          <path
            fill="#263238"
            d="M92.617 161.441c.063 0 .16-.341-.022-.742s-.518-.542-.548-.482.175.267.324.586.175.631.246.638"
            data-name="Path 99378"
          />
          <path
            fill="#263238"
            d="M105.475 105.887c-2.235.52-2.607 28.009-2.607 28.009l-.06 24.973-8.63.074s-1.259-10.948-1.743-21.737c-.313-6.97-.745-12.952-.745-12.952s-.86-9.018-.924-16.552.745-18.137.745-18.137l12.828 2.991Z"
            data-name="Path 99379"
          />
          <path
            fill="#263238"
            d="M117.48 95.384s.44 7.868.6 9.835-.939 53.175-.939 53.175h-8.433s-3.08-29.467-3.233-34.143-1.736-17.846-1.736-17.846v-11.4l13.309.442"
            data-name="Path 99380"
          />
          <path
            fill="#455a64"
            d="M103.258 98.765a5.72 5.72 0 0 0-.078 1.058c0 .657 0 1.562.074 2.561s.2 1.9.313 2.546a5.394 5.394 0 0 0 .238 1.035 7.362 7.362 0 0 0-.112-1.054c-.078-.649-.183-1.548-.257-2.538s-.112-1.9-.127-2.55a7.433 7.433 0 0 0-.052-1.058"
            data-name="Path 99381"
          />
          <path
            fill="#455a64"
            d="M113.122 98.194a2.078 2.078 0 0 0 .332.69 5.213 5.213 0 0 0 2.943 2.171 2 2 0 0 0 .745.115 6.431 6.431 0 0 1-4.034-2.969Z"
            data-name="Path 99382"
          />
          <path
            fill="#455a64"
            d="M92.003 102.785a2.7 2.7 0 0 0 .887-.186 5.954 5.954 0 0 0 3.293-2.758 2.592 2.592 0 0 0 .339-.839c-.03 0-.16.308-.447.776a6.882 6.882 0 0 1-1.4 1.607 6.809 6.809 0 0 1-1.833 1.091c-.51.2-.846.278-.842.308"
            data-name="Path 99383"
          />
          <path
            fill="#455a64"
            d="M105.509 98.517a.274.274 0 0 0-.127-.089.633.633 0 0 0-.428 0 .665.665 0 0 0 .117 1.3.641.641 0 0 0 .792-.674.649.649 0 0 0-.142-.4c-.067-.074-.123-.093-.127-.085a.856.856 0 0 1 .138.486.6.6 0 0 1-.242.449.563.563 0 0 1-.761-.229.556.556 0 0 1-.062-.338.613.613 0 0 1 .335-.371.851.851 0 0 1 .507-.041"
            data-name="Path 99384"
          />
          <path
            fill="#455a64"
            d="M107.915 130.433a5.274 5.274 0 0 0 .745.8 22.154 22.154 0 0 0 4.157 3.166 5.693 5.693 0 0 0 .965.494 8.25 8.25 0 0 0-.9-.6c-.559-.371-1.322-.88-2.131-1.484s-1.49-1.214-2.008-1.659a8.261 8.261 0 0 0-.831-.709"
            data-name="Path 99385"
          />
          <path
            fill="#455a64"
            d="M94.923 127.776a5.528 5.528 0 0 0 .667.828c.432.494 1.054 1.154 1.773 1.856s1.408 1.292 1.915 1.707a5.621 5.621 0 0 0 .853.635 6.4 6.4 0 0 0-.767-.742c-.488-.442-1.155-1.05-1.862-1.741s-1.352-1.336-1.807-1.8a6.841 6.841 0 0 0-.771-.738"
            data-name="Path 99386"
          />
          <path
            fill="#ffbe9d"
            d="m117.79 98.394-.425 1.325a13.479 13.479 0 0 0-1.754.924 22.432 22.432 0 0 0-1.144 2.4 10.31 10.31 0 0 0 .279 1.971c.142.085.555.249.663-.349s.171-1.614.171-1.614l1-.909.242 1.34-.648 2s-1.46.371-1.471.7a.52.52 0 0 0 .428.6.514.514 0 0 0 .067.007 7.393 7.393 0 0 0 1.117-.045s.089.876.857.7l.767-.182s.272.776.745.787a.981.981 0 0 0 .745-.4l1.516-2.227a6.158 6.158 0 0 0 .849-1.885l.9-3.463Z"
            data-name="Path 99387"
          />
          <path
            fill="#ffbe9d"
            d="M116.363 102.265a2.166 2.166 0 0 1 .819-.679c.242-.115.585-.16.745.059a.432.432 0 0 1 0 .445.894.894 0 0 1-.335.319c.22 0 .477.03.581.219a.422.422 0 0 1-.134.5 1.222 1.222 0 0 1-.518.223c.168-.056.328.148.3.319a.7.7 0 0 1-.309.412 1.462 1.462 0 0 1-.667.338.6.6 0 0 1-.644-.293.371.371 0 0 1 .32-.542.436.436 0 0 1-.481-.3.787.787 0 0 1-.03-.627 1.518 1.518 0 0 1 .354-.4"
            data-name="Path 99388"
          />
          <path
            fill="#eb996e"
            d="M116.363 102.265a4.527 4.527 0 0 0-.291.371.57.57 0 0 0-.019.5.562.562 0 0 0 .149.278.332.332 0 0 0 .3.089v.148a.309.309 0 0 0-.257.371.469.469 0 0 0 .484.3 1.178 1.178 0 0 0 .663-.3c.216-.148.425-.371.264-.553a.1.1 0 0 0-.119-.045l-.052-.186c.3-.067.589-.193.618-.445a.237.237 0 0 0-.153-.256.876.876 0 0 0-.372-.063h-.309l.264-.156a.69.69 0 0 0 .346-.371.289.289 0 0 0-.156-.371.691.691 0 0 0-.425 0 2.146 2.146 0 0 0-.372.171 4.246 4.246 0 0 0-.592.494.782.782 0 0 1 .108-.178 1.891 1.891 0 0 1 .428-.4 1.042 1.042 0 0 1 .89-.23.424.424 0 0 1 .235.208.506.506 0 0 1 .03.323.845.845 0 0 1-.414.5l-.048-.163a1.168 1.168 0 0 1 .451.074.431.431 0 0 1 .261.445.617.617 0 0 1-.291.434 1.659 1.659 0 0 1-.466.174l-.056-.186a.31.31 0 0 1 .328.1.429.429 0 0 1 .1.256.506.506 0 0 1-.074.256 1.254 1.254 0 0 1-.324.319 1.347 1.347 0 0 1-.778.327.64.64 0 0 1-.652-.456.456.456 0 0 1 .35-.543.465.465 0 0 1 .082-.01l.682.03-.67.119a.543.543 0 0 1-.6-.482.643.643 0 0 1 .063-.583 1.185 1.185 0 0 1 .253-.267c.1-.041.134-.063.138-.056"
            data-name="Path 99389"
          />
          <path
            fill="#eb996e"
            d="M118.881 104.172a11.5 11.5 0 0 1-.883 1.8 1.4 1.4 0 0 1-.5.646 1.241 1.241 0 0 1-.663.074 2.175 2.175 0 0 0-.417 0c-.1 0-.149.037-.156.022s.041-.048.138-.085a1.466 1.466 0 0 1 .44-.059 1.183 1.183 0 0 0 .587-.092c.186-.1.287-.341.421-.586a11.456 11.456 0 0 1 1.036-1.722"
            data-name="Path 99390"
          />
          <path
            fill="#eb996e"
            d="M119.707 105.315a2.549 2.549 0 0 1-.663 1.288 1.862 1.862 0 0 1-.834.638c-.276.074-.447-.022-.436-.041a1.777 1.777 0 0 0 .4-.074 2.2 2.2 0 0 0 .745-.635c.5-.594.741-1.2.79-1.177"
            data-name="Path 99391"
          />
          <path
            fill="#eb996e"
            d="M117.734 102.399a5.812 5.812 0 0 1-.9.237 6.467 6.467 0 0 1-.887.289 1.493 1.493 0 0 1 .831-.475 1.518 1.518 0 0 1 .957-.052"
            data-name="Path 99392"
          />
          <path
            fill="#eb996e"
            d="M117.696 103.286c.019.052-.264.212-.648.315s-.708.108-.715.056.294-.141.667-.245.678-.174.7-.126"
            data-name="Path 99393"
          />
          <path
            fill="#f15a29"
            d="M116.013 52.66a11.074 11.074 0 0 1 5.3 6.78c1.576 5.1 5.587 24.472 4.526 30.714 0 0-2.4 9.594-2.786 9.924l-5.427-1.9-2.957-35.06Z"
            data-name="Path 99394"
          />
          <path
            fill="#f15a29"
            d="m96.461 52.233 9.405-1.655s9.468 1.232 10.143 2.082 1.058 33.271 1.348 34.99c.372 2.156 0 9.2 0 9.2l-26.327.846-.253-4.658.44-38.281Z"
            data-name="Path 99395"
          />
          <path
            fill="#263238"
            d="M113.581 58.509a.286.286 0 0 1-.026.1 2.542 2.542 0 0 0-.06.289c-.026.119-.06.286-.093.468s-.052.408-.082.649a16.307 16.307 0 0 0 .119 4.149 49.091 49.091 0 0 0 1.378 6.008 53.71 53.71 0 0 1 1.6 7.352c.373 2.646.57 5.2.674 7.5s.149 4.4.175 6.153.041 3.158.052 4.153c0 .479.019.857.026 1.113v.293a.5.5 0 0 0 0 .1.3.3 0 0 0 0-.1v-5.561c0-1.755-.033-3.841-.134-6.161s-.291-4.865-.667-7.519a52.072 52.072 0 0 0-1.613-7.371 49.777 49.777 0 0 1-1.412-5.983 16.98 16.98 0 0 1-.175-4.123c.022-.245.045-.46.063-.649s.056-.338.078-.468l.045-.293a.408.408 0 0 0 .052-.1"
            data-name="Path 99396"
          />
          <path
            fill="#263238"
            d="M96.461 82.929a.916.916 0 0 0 .089.134l.287.371c.246.315.622.742 1.117 1.28s1.091 1.132 1.8 1.767a29.264 29.264 0 0 0 2.406 1.934 28.489 28.489 0 0 0 2.607 1.663 24.31 24.31 0 0 0 2.254 1.136c.659.289 1.192.512 1.572.646l.436.152c.1.033.153.048.156.041a.714.714 0 0 0-.145-.074l-.421-.182c-.372-.152-.9-.371-1.546-.694s-1.4-.7-2.235-1.165a29.341 29.341 0 0 1-2.581-1.663c-.887-.631-1.691-1.292-2.4-1.911s-1.319-1.217-1.814-1.729-.887-.943-1.147-1.243l-.309-.341a.7.7 0 0 0-.119-.119"
            data-name="Path 99397"
          />
          <path
            fill="#263238"
            d="M96.2 70.523a.8.8 0 0 0 .037.174l.138.482c.119.416.32 1.017.589 1.748s.626 1.588 1.058 2.524.961 1.941 1.568 2.969a31.636 31.636 0 0 0 1.862 2.8c.622.835 1.192 1.555 1.706 2.145s.939 1.05 1.252 1.358l.372.371a.629.629 0 0 0 .13.115.715.715 0 0 0-.108-.137l-.332-.371a43.614 43.614 0 0 1-2.876-3.556c-.604-.831-1.222-1.781-1.829-2.8s-1.117-2.023-1.572-2.947-.8-1.778-1.088-2.5-.5-1.314-.641-1.726l-.168-.471a.69.69 0 0 0-.1-.186"
            data-name="Path 99398"
          />
          <g data-name="Group 108866" opacity={0.3}>
            <g data-name="Group 108865">
              <g
                clipPath="url(#g)"
                data-name="Group 108864"
                transform="translate(113.159 65.438)"
              >
                <path
                  d="M.235 0C3.647 9.475 4.556 19.67 4.09 29.709a6.547 6.547 0 0 1-1.672-4.825c.015-1.741.4-3.485.473-5.237C3.167 12.989-1.02 6.535.235 0"
                  data-name="Path 99399"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 108869" opacity={0.3}>
            <g data-name="Group 108868">
              <g
                clipPath="url(#h)"
                data-name="Group 108867"
                transform="translate(96.716 72.102)"
              >
                <path
                  d="M.009.013c-.108-.3.745 4.869 1.933 6.814a14.151 14.151 0 0 0 4.812 4.762A40.152 40.152 0 0 1 .008.014"
                  data-name="Path 99400"
                />
              </g>
            </g>
          </g>
          <path
            fill="#f15a29"
            d="M88.628 57.422c-.648 1.588-3.6 16.021-3.6 16.021l-10.772-2.8s-2.652 7.3-3.01 7.17 13.975 5.875 16.788 5.671c2.749-.2 3.695-4.261 3.695-4.261l4.295-26.457s-6.008 1.247-7.394 4.654"
            data-name="Path 99401"
          />
          <path
            fill="#263238"
            d="M90.78 62.94a1.037 1.037 0 0 1 .026.2 4.98 4.98 0 0 0 .026.583c.019.5.037 1.236.052 2.138.03 1.8.052 4.294.063 7.051s0 5.248 0 7.051c0 .88 0 1.6-.022 2.138v.579a.892.892 0 0 1 0 .2.739.739 0 0 1-.022-.2c0-.152 0-.345-.019-.579 0-.534-.022-1.254-.041-2.138 0-1.83-.034-4.309-.056-7.051s0-5.218 0-7.051v-2.717a.8.8 0 0 1-.007-.2"
            data-name="Path 99402"
          />
          <path
            fill="#ffbe9d"
            d="M105.967 52.594a2.235 2.235 0 0 0 .2-1.113l-1.006-14.5c0-4.049-2.335-7.423-6.4-7.341l-.372.033a7.248 7.248 0 0 0-6.675 7.233c0 3.511.056 7.385.231 9.256.372 3.867 4.3 4.149 4.3 4.149l.186 1.507a3.4 3.4 0 0 0 2.883 3.407c2.369.594 4.883-.976 5.919-1.733a2.28 2.28 0 0 0 .723-.9"
            data-name="Path 99403"
          />
          <path
            fill="#eb996e"
            d="M96.23 50.298a9.483 9.483 0 0 0 4.969-1.9s-.968 2.887-4.876 2.854Z"
            data-name="Path 99404"
          />
          <path
            fill="#263238"
            d="M92.908 40.283a.562.562 0 0 0 .581.516.532.532 0 0 0 .533-.53v-.015a.562.562 0 0 0-.581-.516.535.535 0 0 0-.533.538v.007"
            data-name="Path 99405"
          />
          <path
            fill="#263238"
            d="M92.717 38.718c.074.067.469-.278 1.065-.323s1.062.215 1.117.134-.052-.156-.257-.282a1.515 1.515 0 0 0-.9-.2 1.462 1.462 0 0 0-.842.349c-.168.156-.231.289-.182.319"
            data-name="Path 99406"
          />
          <path
            fill="#263238"
            d="M98.398 39.975a.562.562 0 0 0 .581.516.535.535 0 0 0 .533-.538v-.007a.562.562 0 0 0-.581-.516.532.532 0 0 0-.533.53v.015"
            data-name="Path 99407"
          />
          <path
            fill="#263238"
            d="M98.596 38.35c.074.067.469-.278 1.069-.327s1.058.215 1.117.137-.052-.16-.253-.282a1.536 1.536 0 0 0-.905-.2 1.458 1.458 0 0 0-.842.349c-.153.152-.223.289-.186.319"
            data-name="Path 99408"
          />
          <path
            fill="#263238"
            d="M96.793 42.94a3.667 3.667 0 0 0-.983-.1c-.156 0-.3-.022-.335-.122a.777.777 0 0 1 .063-.46c.115-.371.231-.776.373-1.191a17.035 17.035 0 0 0 .745-3.11 17.675 17.675 0 0 0-1.006 3.032l-.339 1.2a.9.9 0 0 0-.03.6.4.4 0 0 0 .272.2 1.094 1.094 0 0 0 .261 0 4.106 4.106 0 0 0 .98-.056"
            data-name="Path 99409"
          />
          <path
            fill="#263238"
            d="M98.584 43.219c-.1 0-.045.649-.562 1.15s-1.214.49-1.211.579.164.115.454.1a1.633 1.633 0 0 0 1.013-.449 1.394 1.394 0 0 0 .432-.961c0-.286-.082-.419-.127-.419"
            data-name="Path 99410"
          />
          <path
            fill="#263238"
            d="M98.257 36.03c.074.156.663.033 1.371.059s1.289.171 1.371.019c.037-.074-.074-.219-.317-.371a2.41 2.41 0 0 0-2.086-.059c-.257.141-.376.278-.339.353"
            data-name="Path 99411"
          />
          <path
            fill="#263238"
            d="M92.801 36.981c.115.126.514-.048 1.009-.1s.92 0 1.006-.134c.037-.071-.041-.2-.235-.315a1.494 1.494 0 0 0-1.624.186c-.164.152-.209.289-.156.36"
            data-name="Path 99412"
          />
          <path
            fill="#ffbe9d"
            d="M104.856 40.795a1.059 1.059 0 0 1 1-1.112h.054c.745 0 1.568.353 1.646 1.856a2.011 2.011 0 0 1-2.578 2.164c-.007-.063-.078-1.8-.127-2.906"
            data-name="Path 99413"
          />
          <path
            fill="#eb996e"
            d="M105.508 42.673a.648.648 0 0 0 .127.063.452.452 0 0 0 .346 0 1.131 1.131 0 0 0 .48-1.05 1.478 1.478 0 0 0-.164-.657.535.535 0 0 0-.372-.338.239.239 0 0 0-.268.141c-.03.074 0 .13-.026.134s-.06-.041-.045-.152a.312.312 0 0 1 .119-.193.373.373 0 0 1 .253-.082.647.647 0 0 1 .507.371 1.561 1.561 0 0 1 .2.742 1.211 1.211 0 0 1-.618 1.176.493.493 0 0 1-.428-.048c-.1-.041-.119-.1-.108-.108"
            data-name="Path 99414"
          />
          <path
            fill="#263238"
            d="M91.727 36.78c-.019.538-.778-1.251-.518-1.722a1.4 1.4 0 1 1-.492-2.731 1.251 1.251 0 0 1 .175-1.529 1.865 1.865 0 0 1 .775-.338 1.934 1.934 0 0 1 .186-.954 2.367 2.367 0 0 1 3.635-.824 2.347 2.347 0 0 1 3.866 0 2.235 2.235 0 0 1 3.289.98 1.744 1.744 0 0 1 2.082.238 6.149 6.149 0 0 0 .607.7 5.608 5.608 0 0 0 .987.442 1.881 1.881 0 0 1 .544 2.821 4.413 4.413 0 0 1 1.032.969 1.48 1.48 0 0 1 .231 1.347c-.2.531-.79.842-.991 1.377-.089.245-.089.516-.179.765a1.514 1.514 0 0 1-.9.865c-.212 1.08-.946 1.4-.957 1.715-.093 3.31-1.531 1.444-2.484-5.489-.223-1.629.346-2.487-.987-3.44a6.367 6.367 0 0 0-.451.668 2.214 2.214 0 0 1-3.1.381 2.184 2.184 0 0 1-.163-.14 1.9 1.9 0 0 1-3.021.419c-.462.445-1.55 1.206-2.421.471a7.077 7.077 0 0 0-.745 3.01"
            data-name="Path 99415"
          />
          <path
            fill="#fafafa"
            d="m132.309 50.062-.838-5.359a12.723 12.723 0 1 1 4.488.742Z"
            data-name="Path 99416"
          />
          <path
            fill="#263238"
            d="m132.308 50.063 3.684-4.587a.033.033 0 0 0 0-.047v.074a13.1 13.1 0 0 0 3.021-.39 12.639 12.639 0 0 0 3.352-1.381A11.364 11.364 0 0 0 144 42.57c.261-.23.529-.456.79-.7l.745-.79a12.711 12.711 0 0 0 3.006-8.892 12.527 12.527 0 0 0-1.367-5.151 12.951 12.951 0 0 0-3.624-4.312 12.541 12.541 0 0 0-5.371-2.386l-.745-.122c-.253-.045-.51-.045-.764-.067a7.5 7.5 0 0 0-.764-.033l-.745.022a5.111 5.111 0 0 0-.745.063l-.745.093-.745.163a5.792 5.792 0 0 0-.719.186 12.942 12.942 0 0 0-5 2.839 12.512 12.512 0 0 0-3.129 4.453 12.812 12.812 0 0 0-.112 9.512 13.622 13.622 0 0 0 2.134 3.559 12.536 12.536 0 0 0 2.7 2.4 13 13 0 0 0 2.693 1.343l-.022-.03c.559 3.433.834 5.118.872 5.363l-.8-5.374a.034.034 0 0 0-.022-.026 12.747 12.747 0 0 1-2.671-1.351 12.55 12.55 0 0 1-2.671-2.386 13.6 13.6 0 0 1-2.1-3.541 12.66 12.66 0 0 1 .127-9.415 12.347 12.347 0 0 1 3.1-4.383 12.818 12.818 0 0 1 4.943-2.806 5.1 5.1 0 0 1 .711-.182l.723-.16.745-.093a5.057 5.057 0 0 1 .745-.063l.745-.022c.25 0 .5.026.745.033a5.007 5.007 0 0 1 .745.067l.745.122a12.419 12.419 0 0 1 5.308 2.357 12.745 12.745 0 0 1 3.591 4.242 12.442 12.442 0 0 1 1.36 5.1 12.637 12.637 0 0 1-3.013 8.8l-.745.783c-.257.241-.525.468-.778.694a11.465 11.465 0 0 1-1.62 1.162 12.794 12.794 0 0 1-3.311 1.381 12.979 12.979 0 0 1-2.98.4.037.037 0 0 0-.037.037.033.033 0 0 0 .026.033v-.059Z"
            data-name="Path 99417"
          />
          <path
            fill="#f15a29"
            d="M130.502 32.412a3.26 3.26 0 0 1 .745-1.807 3.112 3.112 0 0 1 1.579-1.091 2.537 2.537 0 0 1 2.853 1.143 2.543 2.543 0 0 1 2.663-1.347 3.151 3.151 0 0 1 1.687.913 3.266 3.266 0 0 1 .935 1.722 3.935 3.935 0 0 1-.372 2.457 5.592 5.592 0 0 1-1.833 1.911c-1.225.828-2.835 1.856-2.835 1.856s-1.464-.972-2.771-1.659a5.678 5.678 0 0 1-2.034-1.7 3.976 3.976 0 0 1-.618-2.4"
            data-name="Path 99418"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default NoAgreementImg
