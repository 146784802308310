

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, IconButton, Stack, Typography } from "@mui/material"
import React from "react";
import { BathDrawer, BedDrawer, BuildingDrawer, FurnitureDrawer, MeterDrawer } from "../../assets";
import { ContractDetailsStyle } from "./style";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withTranslation } from 'react-i18next';
import { AssetView } from "./assetView";



const UnitDrawerDetails = ({
    data = {},
    t = () => false }) => {
    return (
        <>
            <Box sx={ContractDetailsStyle?.drawerBox}>
            <AssetView
                asset={data?.unit?.unit_assets
                  ?.filter((x) => x?.asset_type !== 3)
                  ?.filter((x) => x?.asset_type !== 4)
                  ?.map((x) => x)}
                assetAll={data?.unit?.unit_assets}
              />
             <Box display={'flex'} alignItems={'center'} gap={'16px'} style={{borderBottom: "2px solid #E4E8EE"}}>
             <Box >
                    <Avatar src={data?.unit?.logo}/>
                </Box>
                <Stack direction="row" alignItems="center" justifyContent="space-between"
                    sx={ContractDetailsStyle?.topItem}>
                    <Box>
                        <Stack direction="row" alignItems="center">
                            <Typography sx={ContractDetailsStyle?.category}>{data?.unit?.unit_no}</Typography>
                            <Typography sx={ContractDetailsStyle?.badgeOne}>{data?.unit?.category?.name}</Typography>
                            <Typography sx={ContractDetailsStyle?.badgeTwo}>{data?.unit?.revenue_type}</Typography>
                        </Stack>
                        <Typography sx={ContractDetailsStyle?.KTown}>
                            {`${data?.unit?.address?.street_1}, ${data?.unit?.address?.street_2}`}</Typography>
                    </Box>
                    <IconButton></IconButton>
                </Stack>
             </Box>
                <Grid container sx={ContractDetailsStyle?.svgContainer} >
                    <Grid item xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="start">
                            <BedDrawer></BedDrawer>
                            <Typography sx={ContractDetailsStyle?.svgItem}>
                                {`${data?.unit?.total_bed_rooms ? data?.unit?.total_bed_rooms : 0} beds`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="center" >
                            <BathDrawer></BathDrawer>
                            <Typography sx={ContractDetailsStyle?.svgItem}>
                                {`${data?.unit?.total_baths ? data?.unit?.total_baths : 0} baths`}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <BuildingDrawer></BuildingDrawer>
                            <Typography sx={ContractDetailsStyle?.svgItem}>
                                {`${data?.unit?.total_rooms ? data?.unit?.total_rooms : 0} BHK`}</Typography>
                        </Stack>

                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="start">
                            <FurnitureDrawer></FurnitureDrawer>
                            <Typography sx={ContractDetailsStyle?.svgItem}>{`${data?.unit?.furnishing}`}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <MeterDrawer></MeterDrawer>
                            <Stack direction="row" alignItems="baseline">
                                <Typography sx={ContractDetailsStyle?.svgItem}>
                                    {data?.unit?.total_area}
                                </Typography>
                                <Typography sx={ContractDetailsStyle?.Sqft}>
                                    {`${data?.unit?.uom?.name ?? "-"}`}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack direction="row" alignItems="center" justifyContent="space-between"
                    sx={ContractDetailsStyle?.handBookDiv}>
                    <Stack direction="row" alignItems="center">
                        <Box sx={ContractDetailsStyle?.handbookIMg}>
                            <img alt="img" src="images/Handbook.png"></img></Box>
                        <Typography>{t("Handbook")}</Typography>
                    </Stack>
                    <Typography>{t("View / Download")}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between"
                    sx={ContractDetailsStyle?.mapDiv}>
                    <Stack direction="row" alignItems="center" sx={ContractDetailsStyle?.mapText}>
                        <Box><img alt="img" src="images/map.png"></img></Box>
                        <Typography noWrap sx={ContractDetailsStyle.address}>
                            {data?.unit?.address?.street_1 ? data?.unit?.address?.street_1 : ""} {(data?.unit?.address?.street_2 && data?.unit?.address?.street_1) ? "," : ""} {data?.unit?.address.street_2 ? data?.unit?.address?.street_2 : ""} {(data?.unit?.address?.street_2 && data?.unit?.address?.city) ? "," : ""}  {data?.unit?.address?.city ? data?.unit?.address?.city : ""}{(data?.country && data?.unit?.address?.city) ? "," : ""} {data?.unit?.address?.country ? data?.unit?.address?.country : ""}{(data?.unit?.address?.state && data?.unit?.address?.city) ? "," : ""}{data?.unit?.address?.state ? data?.unit?.address?.state : ""}{(data?.unit?.address?.zipcode && data?.unit?.address?.state) ? "," : ""}{data?.unit?.address?.state ? data?.unit?.address?.zipcode : ""}
                        </Typography>
                    </Stack>
                    <Typography>{t("Map")}</Typography>
                </Stack>
            </Box>
            {
                data?.unit?.contact_account?.account_name && <Box sx={ContractDetailsStyle?.accordionSection}>
                    <Typography sx={ContractDetailsStyle?.ownerHeading}> {t("OWNER DETAILS")}</Typography>
                    <Accordion sx={ContractDetailsStyle?.accordionHead}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >   <Box sx={{ width: "40px", height: "40px" }}>
                                                        <Avatar src={data?.account_logo}       alt="img"
                                    width="100%" height="100%"/>

                            </Box>
                            <Box sx={ContractDetailsStyle?.ownerAcc}>
                                <Typography sx={ContractDetailsStyle?.owner}>
                                    {data?.unit?.contact_account?.account_name}
                                </Typography>
                                <Typography sx={ContractDetailsStyle?.ownerMail}>
                                    {data?.unit?.contact_account?.email_id}</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={ContractDetailsStyle?.accordionDetailsParent}>
                            <Typography>
                                {data?.unit?.contact_account?.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            }

        </>
    )
}
export default withTranslation('contract')(UnitDrawerDetails);