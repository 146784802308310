import * as React from "react"
const MessageIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 138119">
      <path
        fill="#ced3dd"
        d="M2.7 2A2.7 2.7 0 0 0 .009 4.475L9 9.33l8.991-4.855A2.7 2.7 0 0 0 15.3 2ZM0 6v7.7a2.7 2.7 0 0 0 2.7 2.7h12.6a2.7 2.7 0 0 0 2.7-2.7V6l-8.68 4.693a.673.673 0 0 1-.64 0Z"
        data-name="icons8-mail (6)"
      />
    </g>
  </svg>
)
export default MessageIcon
