import * as React from "react"
const UnitIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
    <g data-name="Group 137913">
      <path
        fill="#8d6c16"
        d="M11.575 1.5h-6.65A1.925 1.925 0 0 0 3 3.425v11.2a.875.875 0 0 0 .875.875h2.45a.875.875 0 0 0 .875-.875V13.05a.35.35 0 0 1 .35-.35h1.4a.35.35 0 0 1 .35.35v1.575a.875.875 0 0 0 .875.875h2.45a.875.875 0 0 0 .875-.875v-11.2A1.925 1.925 0 0 0 11.575 1.5Zm-5.6 9.45h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm2.45 6.3h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 0 1 0 1.05Zm0-2.1h-.35a.525.525 0 0 1 0-1.05h.35a.525.525 0 0 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 0 1 0-1.05h.35a.525.525 0 0 1 0 1.05Zm2.45 6.3h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Zm0-2.1h-.35a.525.525 0 1 1 0-1.05h.35a.525.525 0 1 1 0 1.05Z"
        data-name="icons8-building (3)"
      />
    </g>
  </svg>
)
export default UnitIcon
