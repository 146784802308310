import { Avatar, Box, Button, Grid, Tooltip, Typography } from "@mui/material"
import moment from "moment-timezone"
import React from "react"
import { landlordApprovalStyle } from "./style"

export const LandlordApprovalRequest = ({
    data = {},
    onClick = () => false,
    button = false,
    approved = "",
    declined = "",
    t = () => false,
    marginLeft = "auto",
    isDetails = false,
    wrapAddress=false
}) => {
    const [isInfoOpen, setIsInfoOpen] = React.useState(false)
    return (
        <Box sx={landlordApprovalStyle.landlordBox}>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Avatar sx={landlordApprovalStyle.image} variant="rounded" src={data?.unit?.logo ?? data?.logo} />
                {data?.status === null &&
                    <Typography sx={landlordApprovalStyle.waiting} style={{ marginLeft: marginLeft }}>Waiting For Approval</Typography>
                }
                {data?.status === "Approved" && <Typography sx={landlordApprovalStyle.approved} style={{ marginLeft: marginLeft }}>Approved</Typography>}
                {data?.status === "Declined" && <Typography sx={landlordApprovalStyle.declined} style={{ marginLeft: marginLeft }}>Declined</Typography>}
            </Box>
            <Typography sx={landlordApprovalStyle.approval}>{t("Landlord Approval")}</Typography>
            <Typography sx={landlordApprovalStyle.unitName}>{data?.agreements?.agreement_no ?? data?.agreement_no}</Typography>
            <Tooltip title={`${data?.street_1 ? data?.street_1 : ""} ${(data?.street_2 && data?.street_1) ? "," : ""} ${data?.street_2 ? data?.street_2 : ""} ${(data?.street_2 && data?.city) ? "," : ""}  ${data?.city ? data?.city : ""}${(data?.state && data?.city) ? "," : ""} ${data?.state ? data?.state : ""}${(data?.country && data?.city) ? "," : ""}${data?.country ? data?.country : ""}${(data?.zipcode && data?.state) ? "," : ""}${data?.state ? data?.zipcode : ""}`} placement="top" open={isInfoOpen} arrow>
                <Box onClick={() => setIsInfoOpen(!isInfoOpen)}
                    onMouseEnter={() => setIsInfoOpen(true)}
                    onMouseLeave={() => setIsInfoOpen(false)}
                >
                    <Typography sx={wrapAddress?landlordApprovalStyle.address:landlordApprovalStyle.address1}>
                        {data?.street_1 ? data?.street_1 : ""} {(data?.street_2 && data?.street_1) ? "," : ""} {data?.street_2 ? data?.street_2 : ""} {(data?.street_2 && data?.city) ? "," : ""}  {data?.city ? data?.city : ""}{(data?.state && data?.city) ? "," : ""} {data?.state ? data?.state : ""}{(data?.country && data?.city) ? "," : ""}{data?.country ? data?.country : ""}{(data?.zipcode && data?.state) ? "," : ""}{data?.state ? data?.zipcode : ""}
                    </Typography>
                </Box>
            </Tooltip>
            {isDetails && data?.status !== null &&
                <>
                    <Box mt={1}>
                        <Typography sx={landlordApprovalStyle?.detailText} style={{ color: data?.status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{`Landlord ${data?.status} on ${moment(data?.updated_at).format("DD MMM YYYY")}`}</Typography>
                    </Box>
                    {data?.status === "Declined" && data?.remarks !== "null" &&
                        <Box mt={1} sx={landlordApprovalStyle?.remarksBox}>
                            <Typography sx={landlordApprovalStyle?.remarksText}>{t("Remarks")}</Typography>
                            <Box mt={0.5}>
                                <Typography sx={landlordApprovalStyle.remarksSubtext}>{data?.remarks}</Typography>
                            </Box>
                        </Box>
                    }
                </>
            }
            {/* {approved && <Typography sx={landlordApprovalStyle.approvalDate}>Landlord Approved on 20 Jan 22</Typography>}
            {declined &&
                <>
                    <Typography sx={landlordApprovalStyle.declinedDate}>Landlord Declined on 20 Jan 22</Typography>
                    <Box sx={landlordApprovalStyle.remarksBox}>
                        <Typography sx={landlordApprovalStyle.remarksText}>Remarks</Typography>
                        <Typography sx={landlordApprovalStyle.remarksSubtext}>I Dont Like Tenant</Typography>
                    </Box>
                </>
            } */}
            {button &&
                <Grid container mt={2}>
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={() => onClick()} sx={landlordApprovalStyle.button} fullWidth>{t("Update")}</Button>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}