import * as React from "react"
const ElevatorIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      fill="#9165d3"
      d="M10.327 0a.409.409 0 0 0-.314.671l1.177 1.611a.408.408 0 0 0 .628 0L12.99.671A.409.409 0 0 0 12.671 0ZM6.59.026a.406.406 0 0 0-.314.147l-1.172 1.61a.409.409 0 0 0 .314.671h2.344a.409.409 0 0 0 .314-.671L6.904.173A.406.406 0 0 0 6.59.026ZM3.931 3.273A1.85 1.85 0 0 0 2.09 5.114v11.045A1.85 1.85 0 0 0 3.931 18h10.227a1.85 1.85 0 0 0 1.841-1.841V5.114a1.85 1.85 0 0 0-1.841-1.841Zm0 1.227h10.227a.6.6 0 0 1 .614.614v11.045a.6.6 0 0 1-.614.614H3.931a.6.6 0 0 1-.614-.614V5.114a.6.6 0 0 1 .614-.614Zm2.659.818a1.227 1.227 0 1 0 1.227 1.227A1.227 1.227 0 0 0 6.59 5.318Zm4.909 0a1.227 1.227 0 1 0 1.227 1.227 1.227 1.227 0 0 0-1.227-1.227ZM6.39 8.182a1.85 1.85 0 0 0-1.841 1.841v1.636a.614.614 0 0 0 .409.586v3.1a.614.614 0 1 0 1.227 0l.409-3.068.409 3.068a.614.614 0 1 0 1.227 0v-3.1a.614.614 0 0 0 .409-.586v-1.636A1.85 1.85 0 0 0 6.79 8.182Zm4.909 0a1.85 1.85 0 0 0-1.841 1.841v1.636a.614.614 0 0 0 .409.586v3.1a.614.614 0 1 0 1.227 0l.409-3.068.409 3.068a.614.614 0 1 0 1.227 0v-3.1a.614.614 0 0 0 .409-.586v-1.636a1.85 1.85 0 0 0-1.841-1.841Z"
      data-name="Group 138418"
    />
  </svg>
)
export default ElevatorIcon
