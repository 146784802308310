import React from 'react'
import { ImageSliderStyle } from './style'
import { Avatar, Box, Stack, Typography } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ViewAllIcon from '../../screens/propertyUnitNew/utils/viewAllIcon';

export const ImageSlider = ({
    hidelabel = false,
    height = "180px",
    type = "",
    image = [],
    hideViewAll = false,
    handleViewAll = () => false,
    label = "",
    onCurrentImage = () => false,
    customSelectedImage = 0
}) => {

    const [selected, setSelected] = React.useState(customSelectedImage ?? 0)
    const language = localStorage.getItem("i18nextLng")

    const handleNext = () => {
        setSelected(selected + 1)
        onCurrentImage(image?.[selected + 1])
    }

    const handlePrev = () => {
        setSelected(selected - 1)
        onCurrentImage(image?.[selected - 1])
    }

    const typeStyle = {
        "Occupied": {
            ...ImageSliderStyle.occupeidText,
            borderTopRightRadius: language !== "ar" ? "50px" : "0px",
            borderBottomRightRadius: language !== "ar" ? "50px" : "0px",
            borderTopLeftRadius: language === "ar" ? "50px" : "0px",
            borderBottomLeftRadius: language === "ar" ? "50px" : "0px",
            width: language === "ar" ? "100px" : "95px",
        },
        "Vacant": {
            ...ImageSliderStyle.vacantText,
            borderTopRightRadius: language !== "ar" ? "50px" : "0px",
            borderBottomRightRadius: language !== "ar" ? "50px" : "0px",
            borderTopLeftRadius: language === "ar" ? "50px" : "0px",
            borderBottomLeftRadius: language === "ar" ? "50px" : "0px",
            width: language === "ar" ? "100px" : "90px",
        },
        "Purchased": {
            ...ImageSliderStyle.occupeidText,
            borderTopRightRadius: language !== "ar" ? "50px" : "0px",
            borderBottomRightRadius: language !== "ar" ? "50px" : "0px",
            borderTopLeftRadius: language === "ar" ? "50px" : "0px",
            borderBottomLeftRadius: language === "ar" ? "50px" : "0px",
            width: language === "ar" ? "100px" : "95px",
        },
        "normal": {
            ...ImageSliderStyle.occupeidText,
            borderTopRightRadius: language !== "ar" ? "50px" : "0px",
            borderBottomRightRadius: language !== "ar" ? "50px" : "0px",
            borderTopLeftRadius: language === "ar" ? "50px" : "0px",
            borderBottomLeftRadius: language === "ar" ? "50px" : "0px",
            width: language === "ar" ? "100px" : "90px",
        },
        "Sold": {
            ...ImageSliderStyle.occupeidText,
            borderTopRightRadius: language !== "ar" ? "50px" : "0px",
            borderBottomRightRadius: language !== "ar" ? "50px" : "0px",
            borderTopLeftRadius: language === "ar" ? "50px" : "0px",
            borderBottomLeftRadius: language === "ar" ? "50px" : "0px",
            width: language === "ar" ? "70px" : "60px",
        }
    }

    return (
        <>
            <Box sx={{ ...ImageSliderStyle.imageBox, height: height }}>
                <Avatar sx={{ ...ImageSliderStyle.image, height: height, width: "100%", objectFit: "cover" }} src={image?.length > 0 ? image?.[selected]?.url : "/images/noImagePropertyUnit.svg"}> </Avatar>
                <Stack sx={ImageSliderStyle.arrowBox} justifyContent={"space-between"}>
                    {!hidelabel ? <Typography sx={typeStyle[type]}>{label ? label : type}</Typography> : <Box></Box>}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} py={1}>
                        {selected > 0 ? <>{
                            language === "ar" ?
                                <ArrowForwardIosIcon sx={{ color: "#fff" }} onClick={() => handlePrev()} /> :
                                <ArrowBackIosNewIcon sx={{ color: "#fff" }} onClick={() => handlePrev()} />}</> : <Box></Box>}
                        {image?.length - 1 > selected ? <>{
                            language === "ar" ?
                                <ArrowBackIosNewIcon sx={{ color: "#fff" }} onClick={() => handleNext()} /> :
                                <ArrowForwardIosIcon sx={{ color: "#fff" }} onClick={() => handleNext()} />}
                        </> : <Box></Box>}
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {/* <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            {
                                image?.map((e, i) => {
                                    return (
                                        <Box sx={i === selected ? ImageSliderStyle.selectedDot : ImageSliderStyle.dot}></Box>
                                    )
                                })
                            }
                        </Stack> */}

                    </Box>
                </Stack>
                {!hideViewAll &&
                    <Stack sx={ImageSliderStyle.viewAllBox} direction={"row"} columnGap={1} alignItems={"center"} onClick={handleViewAll}>
                        <ViewAllIcon />
                        <Typography sx={ImageSliderStyle.viewAllText}>View All</Typography>
                    </Stack>
                }
            </Box>
        </>
    )
}