import * as React from "react"
const ServiceReqIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      fill="#f15a29"
      d="M15.075 0H2.925A2.931 2.931 0 0 0 0 2.925v8.55A2.931 2.931 0 0 0 2.925 14.4H3.6v2.475a1.126 1.126 0 0 0 .621 1.008 1.18 1.18 0 0 0 .5.117 1.127 1.127 0 0 0 .675-.225L9.9 14.4h5.175A2.931 2.931 0 0 0 18 11.475v-8.55A2.928 2.928 0 0 0 15.075 0ZM12.6 8.1h1.885a.45.45 0 0 1 .389.675 3.144 3.144 0 0 1-5.567-.225H4.5a1.35 1.35 0 0 1 0-2.7h4.808a3.144 3.144 0 0 1 5.567-.225.45.45 0 0 1-.389.675H12.6a.9.9 0 1 0 0 1.8Z"
    />
  </svg>
)
export default ServiceReqIcon
