import * as React from "react"
const AgreementIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137975" transform="translate(-32 -105)">
      <rect
        width={40}
        height={40}
        fill="#f6f1fe"
        data-name="Image 33"
        rx={8}
        transform="translate(32 105)"
      />
      <path
        fill="#896db3"
        d="M45.8 115a3 3 0 0 0-3 3v1.25a.75.75 0 0 0 .75.75h2a3.239 3.239 0 0 1 2.34 1h9.16a.75.75 0 1 1 0 1.5h-8.341a3.225 3.225 0 0 1 .091.75v.75h3.25a.75.75 0 1 1 0 1.5H48.8v5.745a2.76 2.76 0 0 1-.336 1.316L47.135 135H58.55a2.253 2.253 0 0 0 2.25-2.25V118a3 3 0 0 0-3-3Zm0 1.5h9.4a2.977 2.977 0 0 0-.4 1.5v.5H44.3v-.5a1.5 1.5 0 0 1 1.5-1.5Zm-.25 4.5a2.25 2.25 0 0 0-2.25 2.25v8a1.754 1.754 0 0 0 .214.838l1.378 2.526a.75.75 0 0 0 1.316 0l1.378-2.527a1.748 1.748 0 0 0 .214-.842v-8A2.25 2.25 0 0 0 45.55 121Zm10.75 5a2 2 0 1 1-2 2 1.989 1.989 0 0 1 2-2Zm-2 4.865a3.468 3.468 0 0 0 4 0v1.885a.75.75 0 0 1-1.122.651l-.878-.5-.878.5a.75.75 0 0 1-1.122-.651Z"
        data-name="icons8-agreement (8)"
      />
    </g>
  </svg>
)
export default AgreementIcon
