import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { stopCamrecorder } from './utils';

export const CamRecorder = ({
  t = () => false,
  styles = {},
  videoRef = {},
  mediaRecorderRef = {},
  recording = false,
  setRecording = () => false,
  setVideoBlob = () => false,
  setIsCameraDialogOpen = () => false,
  setIsReplayVideoDialogOpen = () => false,
  handleCameraDialogClose = () => false,
}) => {
  let interval = null;
  let timeout = null;
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' }, audio: true, })
      .then((stream) => {
        const videoElement = videoRef.current;
        videoElement.srcObject = stream;
        const track = stream.getVideoTracks()[0];
        track.applyConstraints({ advanced: [{ torch: false }] });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        let videoChunks = [];
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            videoChunks.push(event.data);
          }
        };
        mediaRecorder.onstop = () => {
          const videoBlob = new Blob(videoChunks, { type: 'video/mp4' });
          setVideoBlob(videoBlob);
        };
      })
      .catch((error) => console.error('Error accessing webcam:', error));

    return () => {
      const stream = videoRef?.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.srcObject = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start();
      setRecording(true);
      timeout = setTimeout(stopRecording, 31000);
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setRecording(false);
      stopCamrecorder({ videoRef, mediaRecorderRef });
      setIsCameraDialogOpen(false);
      setIsReplayVideoDialogOpen(true);
      clearInterval(interval);
      clearTimeout(timeout);
    }
  };

  return (
    <Box>
      <video ref={videoRef} style={{ width: '100%' }} muted autoPlay playsInline />
      <Stack direction={"row"} columnGap={"8px"} p={"16px"} justifyContent={"space-between"} alignItems={"center"}>
        <Button sx={styles.cancelButton} fullWidth
          onClick={handleCameraDialogClose}>
          {t("Cancel")}
        </Button>
        <Button fullWidth sx={styles.recordStopButton}
          onClick={() => recording ? stopRecording() : startRecording()}>
          {recording
            ? `${t("Stop")} (00:${timer > 9 ? timer?.toString() : `0${timer?.toString()}`})`
            : t("Record")}
        </Button>
      </Stack>
    </Box>
  );
};