import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import jwt_decode from "jwt-decode";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");
            headers.set("x-api", config?.public_listing_api_key)

        }
        return headers;
    },

});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const ApprovalAPI = createApi({
    reducerPath: "ApprovalAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Approval"],
    endpoints: (builder) => ({
        // LandLord Approval
        getApprovalDetails: builder.query({
            query: (payload) => {
                return ({ url: `${config?.api_url}/owner/get_landlord_details`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
        submitApproval: builder.query({
            query: (payload) => {
                return ({ url: `${config?.api_url}/agreement_tracker/landlord_approval`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },
        }),
        agreementTemplate: builder.query({
            query: (payload) => {
                return ({ url: `${config?.api_url}/agreement/template`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },
        }),
        getAllApprovals: builder.query({
            query: (payload) => {
                return ({ url: `${config?.api_url}/owner/get_all_landlords`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetApprovalDetailsQuery,
    useLazySubmitApprovalQuery,
    useLazyAgreementTemplateQuery,
    useLazyGetAllApprovalsQuery

} = ApprovalAPI;
