import React from 'react'
import { ProjectProgressCardStyle } from './style'
import { Box, Stack, Typography } from "@mui/material"
import { ImageSlider } from '../../../components/imageSlider'
import moment from 'moment'


export const ProjectProgressCard = ({
    data = {},
    property = "",
    t = () => false,
    onNavigate = () => false,
    handleViewAllAsset = () => false
}) => {
    const classes = ProjectProgressCardStyle
    return (
        <>
            <Box sx={classes.box}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={classes.dateBox} mb={"12px"} spacing={2} onClick={onNavigate}>
                    <Box sx={classes.diamond}></Box>
                    <Typography sx={classes.date}>{moment(data?.progress_date).format("DD MMM YY")} <span style={classes.dateText}>{t("Project Progress Updated")}</span></Typography>
                    <Box sx={classes.diamond}></Box>
                </Stack>
                <ImageSlider hidelabel image={data?.images?.map((e) => { return { ...e, url: e?.asset_url } })?.filter((i) => i?.meta_data?.type !== "pdf")}
                    handleViewAll={handleViewAllAsset} />

                <Stack spacing={1} mt={"12px"} onClick={onNavigate}>
                    <Typography sx={classes.title}>{data?.progress_title}</Typography>
                    {
                        property?.address && <Typography sx={classes.subtitle}>
                            {property?.address?.street_1 ?? ""}{property?.address?.city && ", "}{property?.address?.city ?? ""}{property?.address?.state && ", "} {property?.address?.state ?? ""}{property?.address?.zipcode && " - "}{property?.address?.zipcode}
                        </Typography>
                    }
                    <Typography sx={classes.subtitle}>{data?.project?.title}</Typography>
                </Stack>
            </Box>
        </>
    )
}