import * as React from "react"
const DocumentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      fill="#f15a29"
      d="M10.929 0A1.931 1.931 0 0 0 9 1.929v.429h2.143a2.788 2.788 0 0 1 2.469 1.5h1.6a.643.643 0 0 1 0 1.286h-1.283v1.285h1.286a.643.643 0 0 1 0 1.286h-1.286v3.857h2.143A1.931 1.931 0 0 0 18 9.643V1.929A1.931 1.931 0 0 0 16.071 0ZM6 3.214a1.931 1.931 0 0 0-1.929 1.929v.429h1.286v-.429A.644.644 0 0 1 6 4.5h5.143a.644.644 0 0 1 .643.643v7.714a.644.644 0 0 1-.643.643H9.857v1.286h1.286a1.931 1.931 0 0 0 1.929-1.929V5.143a1.931 1.931 0 0 0-1.929-1.929ZM1.929 6.429A1.931 1.931 0 0 0 0 8.357v7.714A1.931 1.931 0 0 0 1.929 18h5.143A1.931 1.931 0 0 0 9 16.071V8.357a1.931 1.931 0 0 0-1.929-1.929Zm.857 3.857h3.428a.643.643 0 1 1 0 1.286H2.786a.643.643 0 0 1 0-1.286Zm0 2.571h3.428a.643.643 0 1 1 0 1.286H2.786a.643.643 0 1 1 0-1.286Z"
    />
  </svg>
)
export default DocumentIcon
