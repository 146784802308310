import { Box, Typography } from "@mui/material";
// import { CurrentAgreement } from "../../../assets";
import { LeaseCard, ProfileCard } from "../../components";
import { CurrentAgreementCard_Style } from "./style";
export const CurrentAgreementCard = ({ date = [], details = {}, t = () => false }) => {
  return (
    <>

      <Box textAlign={"left"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={CurrentAgreementCard_Style?.agreementBox}
        >
          {/* <CurrentAgreement /> */}

        </Box>
        <LeaseCard
          t={t}
          title={details?.agreement_details?.[0]?.agreement_type}
          tag={details?.agreement?.[0]?.unit_type}
          sub_tag={details?.agreement_details?.[0]?.agreement_no}
        />
        {/*  */}
        <Box display={"flex"} alignItems={"center"}>
          {date?.map((x, index) => {
            return (
              <>
              {
                !x?.is_active ? 
                <Box padding={x?.padding} key={index}>
                <Typography sx={CurrentAgreementCard_Style?.dateTitle}>{x?.title}</Typography>
                <Box display="flex" alignItems="center">
                  <img src="/images/calender.png" alt="calander" />
                  <Typography sx={CurrentAgreementCard_Style?.date}> {x?.date}</Typography>
                </Box>
              </Box>
              :
              ""
              }</>
            );
          })}
        </Box>
        <Typography sx={CurrentAgreementCard_Style?.tenantText}>{t('Tenants')}</Typography>
        <ProfileCard
          name={details?.residents?.[0]?.first_name}
          number={`${details?.residents?.[0]?.mobile_no_country_code}${details?.residents?.[0]?.mobile_no}`}
          mail={details?.residents?.[0]?.email_id}
          icon={details?.residents?.[0]?.image_url}
          number1={`${details?.residents?.[0]?.mobile_no_country_code}${details?.residents?.[0]?.mobile_no}`}

        />
      </Box>
    </>
  );
};
