import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { generateImageUrl, img_size } from '../../utils';
import { AddOnsPriceCardStyles } from "./style"
import { NoImage } from '../../assets';
import Slider from 'react-slick';
import PurpleLocation from '../../assets/purpleLocation';
import SerialNumber from '../../assets/serialNumber';
import ModelNumber from '../../assets/modelNumber';
import LastService from '../../assets/lastService';
import moment from "moment-timezone"

export const AddOnsPriceCard = ({
    title = "",
    subTitle = "",
    image = "",
    service = false,
    price = "",
    available = "",
    status = "",
    statusUnit = false,
    serviceOnclick = () => false,
    hidden = false,
    subTitle1 = "",
    subTitle2 = "",
    item_condition = "",
    t = () => false,
    oty,
    data,
    hideDetails = false
}) => {
    const item_condition_class = {
        default: AddOnsPriceCardStyles.default,
        New: AddOnsPriceCardStyles.new,
        Refurbished: AddOnsPriceCardStyles.used,
        Used: AddOnsPriceCardStyles.used,
        Repaired: AddOnsPriceCardStyles.repaired,
        Obselete: AddOnsPriceCardStyles.repaired,
        Damaged: AddOnsPriceCardStyles.damaged,
        Poor: AddOnsPriceCardStyles.damaged,
        EndOfLife: AddOnsPriceCardStyles.damaged
    }
    const language = localStorage.getItem("i18nextLng")

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };

    return (

        <>
            {service &&
                <>
                    <Box sx={AddOnsPriceCardStyles?.root} onClick={() => serviceOnclick()}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack direction={"row"} alignItems={"center"}>
                                {image ? <Avatar sx={AddOnsPriceCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Img' />
                                    : <NoImage />
                                }
                                <Stack sx={{ marginInlineStart: "16px" }}>
                                    <Typography sx={AddOnsPriceCardStyles?.title}>{title}</Typography>
                                    <Typography sx={AddOnsPriceCardStyles?.subTitle}>{subTitle} {oty} </Typography>
                                </Stack>
                            </Stack>
                            <Stack sx={AddOnsPriceCardStyles?.valueText}>
                                <Typography sx={AddOnsPriceCardStyles?.price}>{price} </Typography>
                                <Typography sx={AddOnsPriceCardStyles?.available}>{available} </Typography>
                            </Stack>
                        </Stack>

                    </Box>
                    {hidden &&
                        <Divider sx={AddOnsPriceCardStyles?.divider} />
                    }
                </>
            }
            {statusUnit &&
                <>
                    <Box sx={AddOnsPriceCardStyles?.root}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack direction={"row"} alignItems={"center"}>
                                {image ? <Avatar sx={AddOnsPriceCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Image' />
                                    : <NoImage />
                                }
                                <Stack sx={{ marginInlineStart: "16px" }}>
                                    <Typography sx={AddOnsPriceCardStyles?.title}>{title}</Typography>
                                    {
                                        !hideDetails &&
                                        <Stack direction={"row"} alignItems={"center"} spacing={1} mt="6px">
                                            <Typography sx={language === "ar" ? AddOnsPriceCardStyles?.subTitle2 : AddOnsPriceCardStyles?.subTitle1}>{t("Model")} : {subTitle1} </Typography>
                                            <Divider sx={{ marginInline: "16px", height: "15px" }} orientation="vertical" variant="middle" flexItem />
                                            <Typography sx={AddOnsPriceCardStyles?.subTitle1}>{t("Serial")} : {subTitle2} </Typography>
                                        </Stack>
                                    }
                                </Stack>
                            </Stack>
                            <Stack sx={AddOnsPriceCardStyles?.valueText}>
                                {/* <Typography sx={status === "Good" ? AddOnsPriceCardStyles?.statusGood : AddOnsPriceCardStyles?.statusDamage}>{status} </Typography> */}
                                <Typography sx={item_condition_class[item_condition] ?? item_condition_class?.default}>{item_condition}</Typography>
                            </Stack>
                        </Stack>
                        <Box py={1}><Divider></Divider></Box>
                        <Box pt={1}>
                            <Slider {...settings}>
                                {data?.location_name !== null &&
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Stack direction="row" alignItems="center">
                                            <Box style={{ marginInlineEnd: "8px" }}><PurpleLocation /></Box>
                                            <Box>
                                                <Typography sx={AddOnsPriceCardStyles.text} noWrap>{data?.location_name ?? "-"}</Typography>
                                                <Typography sx={AddOnsPriceCardStyles.number} noWrap>{t("Location")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                }

                                <Grid item xs={6} sm={6} md={6}>
                                    <Stack direction="row" alignItems="center">
                                        <Box style={{ marginInlineEnd: "8px" }}><SerialNumber /></Box>
                                        <Box>
                                            <Typography sx={AddOnsPriceCardStyles.text} noWrap>{data?.serial_number ?? "-"}</Typography>
                                            <Typography sx={AddOnsPriceCardStyles.number}>{t("Serial Number")}</Typography>
                                        </Box>
                                    </Stack>
                                </Grid>


                                <Grid item xs={6} sm={6} md={6}>
                                    <Stack direction="row" alignItems="center">
                                        <Box style={{ marginInlineEnd: "8px" }}> <ModelNumber /></Box>
                                        <Box>
                                            <Typography sx={AddOnsPriceCardStyles.text} noWrap>{data?.model_number ?? "-"}</Typography>
                                            <Typography sx={AddOnsPriceCardStyles.number}>{t("Model Number")}</Typography>
                                        </Box>
                                    </Stack>
                                </Grid>

                                {data?.last_service_date !== null &&
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Stack direction="row" alignItems="center">
                                            <Box style={{ marginInlineEnd: "8px" }}><LastService /></Box>
                                            <Box>
                                                <Typography sx={AddOnsPriceCardStyles.text} noWrap>{data?.last_service_date ? moment(data?.last_service_date).format("DD-MM-YY") : "-"}</Typography>
                                                <Typography sx={AddOnsPriceCardStyles.number} noWrap>{t("Last Service")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                }
                                {data?.item_condition &&
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Typography sx={AddOnsPriceCardStyles.condition}>{data?.item_condition}</Typography>
                                    </Grid>
                                }
                            </Slider>
                        </Box>

                    </Box>

                    {/* {hidden &&
                        <Divider sx={AddOnsPriceCardStyles?.divider} />
                    } */}
                </>

            }
        </>

    );
}

