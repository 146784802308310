import { Bold, FontSizeCalc, Regular, SemiBold } from "../../../utils";

export const ApprovalCardStyle = {
    box: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "border.Teritary",
        padding: "16px",
        boxShadow: "0px 0px 12px #0000001A"
    },
    title: {
        fontSize: FontSizeCalc(12),
        color: "text.Teritary",
        fontFamily: SemiBold
    },
    header: {
        fontSize: FontSizeCalc(18),
        color: "#000",
        fontFamily: Bold
    },
    subtitle: {
        fontSize: FontSizeCalc(12),
        color: "text.Teritary",
        fontFamily: Regular
    },
    button: {
        height: "40px",
        borderRadius: "8px",
        fontSize: FontSizeCalc(12),
        fontFamily: Bold
    },
    waitForApproval: {
        backgroundColor: "backgroundcard.avatarbg",
        color: "text.infoDark",
        padding: "2px 8px 8px",
        borderRadius: "12px",
        height: "22px",
        fontSize: FontSizeCalc(12),
        fontFamily: Bold
    },
    avatar: {
        height: "48px",
        width: "48px",
        borderRadius: "6px"
    }
}

export const AgreementDetailCardStyle = {
    box: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "border.Teritary",
        padding: "16px",
        width: "130px"
    },
    title: {
        fontSize: FontSizeCalc(12),
        color: "#000",
        fontFamily: Bold
    },
    subtitle: {
        fontSize: FontSizeCalc(12),
        color: "text.secondary",
        fontFamily: Regular
    },
    rightBorder: {
        borderRight: "1px solid",
        borderColor: "border.Teritary",
    },
    hijirBox: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "border.Teritary",
        // padding: "16px",
    },
    hijirText: {
        color: "#4C7015",
        backgroundColor: "#D0E2B4",
        borderRadius: "8px",
        padding: "0px 4px 4px 4px",
        height: "20px",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold
    }
}

export const ProjectProgressCardStyle = {
    box: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 0px 20px #0000001A",
        padding: "16px",
        '@media screen and (min-width: 320px)': {
            width: "300px"
        },
        '@media screen and (min-width: 375px)': {
            width: "335px"
        },
        '@media screen and (min-width: 425px)': {
            width: "400px"
        },
        '@media screen and (min-width: 768px)': {
            width: "560px"
        },
    },
    diamond: {
        height: "8px",
        width: "8px",
        rotate: "45deg",
        backgroundColor: "text.generalDark"
    },
    date: {
        fontSize: FontSizeCalc(14),
        color: "text.generalDark",
        fontFamily: SemiBold
    },
    dateText: {
        fontSize: FontSizeCalc(14),
        color: "text.generalDark",
        fontFamily: Regular
    },
    dateBox: {
        padding: "12px 22px",
        backgroundColor: "backgroundcard.avatarbg",
        borderRadius: "12px"
    },
    title: {
        fontSize: FontSizeCalc(14),
        color: "#000",
        fontFamily: SemiBold
    },
    subtitle: {
        fontSize: FontSizeCalc(12),
        color: "text.secondary",
        fontFamily: Regular
    },
}

export const ContactCardStyle = {
    box: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: "border.Teritary"
    },
    dot: {
        height: "6px",
        width: "6px",
        backgroundColor: "background.light",
        borderRadius: "50%"
    },
    phoneMsgIcon: {
        fontSize: FontSizeCalc(12),
        color: "#000",
        fontFamily: SemiBold
    },
    name: {
        fontSize: FontSizeCalc(14),
        color: "#000",
        fontFamily: Bold
    },
    subtitle: {
        fontSize: FontSizeCalc(12),
        color: "text.secondary",
        fontFamily: Regular
    },
    avatar: {
        height: "40px",
        width: "40px"
    },
    widthCorrection: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "165px"
        },
        '@media screen and (min-width: 375px)': {
            width: "225px"
        },
        '@media screen and (min-width: 425px)': {
            width: "265px"
        },
        '@media screen and (min-width: 768px)': {
            width: "445px"
        },
    },
}