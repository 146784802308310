import * as React from "react"
const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 138121">
      <path
        fill="#ced3dd"
        d="m8.271 2.92.6 1.419a1.622 1.622 0 0 1-.36 1.8L7.389 7.202a.531.531 0 0 0-.164.467 5.714 5.714 0 0 0 .721 1.948 6.135 6.135 0 0 0 1.356 1.634.535.535 0 0 0 .513.091l1.311-.438a1.424 1.424 0 0 1 1.631.574l.863 1.267a1.625 1.625 0 0 1-.181 2.041l-.573.575a2.114 2.114 0 0 1-2.136.545q-2.666-.832-4.9-4.938T4.25 3.991a2.282 2.282 0 0 1 1.5-1.683l.754-.241a1.444 1.444 0 0 1 1.767.853Z"
        data-name="icons8-call (2)"
      />
    </g>
  </svg>
)
export default PhoneIcon
