import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { AuthContext, ThemeContext } from "./contexts";
import { Themes } from "./utils";

export const AppTheme = (props) => {
  const auth = React.useContext(AuthContext);
  const [theme, setTheme] = React.useState(() => {
    const storedLng = localStorage.getItem("i18nextLng") || "en-GB"; // Defaulting to 'en-GB' if not found
    return {
      name: Themes.default,
      language: storedLng,
    };
  });

  React.useEffect(() => {
    const i18nextLng = localStorage.getItem("i18nextLng") || "en-GB"; // Fetch the current value from localStorage

    // Set the theme language if it's not already set or has changed
    if (i18nextLng && i18nextLng !== theme.language) {
      setTheme((prevTheme) => ({
        ...prevTheme,
        language: i18nextLng,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const giveMeTheme = () => {
    let currentThemeJson;

    // Get the theme JSON for the selected theme
    switch (theme.name) {
      case Themes.default:
        currentThemeJson = require("./themes/default.json");
        break;
      case Themes.dark:
        currentThemeJson = require("./themes/dark.json");
        break;
      default:
        currentThemeJson = require("./themes/default.json");
        break;
    }

    // Create a theme with JSON
    let currentTheme = createTheme(currentThemeJson);

    // Make theme work with responsive font size
    currentTheme = responsiveFontSizes(currentTheme);

    return currentTheme;
  };

 

  React.useEffect(() => {
    if (theme.language === "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
  }, [theme.language]);

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={giveMeTheme()}>{props.children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};