import * as React from "react"
const ParkingSlotIconView = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19.692}
    data-name="Group 138060"
    {...props}
  >
    <path fill="none" d="M0 0h20v19.692H0z" data-name="Rectangle 58952" />
    <path
      fill="#f15a29"
      d="M15.217.84a3.453 3.453 0 0 0-3.281 2.465 2.579 2.579 0 0 0-1.5 2.317 2.54 2.54 0 0 0 .451 1.379 2.719 2.719 0 0 0-.451 1.432 2.747 2.747 0 0 0 .91 1.983 2.783 2.783 0 0 0 1.785 2.368 2.4 2.4 0 0 0 1.653 1.864v3.365a.652.652 0 1 0 1.3 0v-3.354a2.4 2.4 0 0 0 1.735-2.278 2.487 2.487 0 0 0 1.492-3.463A3.447 3.447 0 0 0 20 6.927a3.456 3.456 0 0 0-1.309-2.636A3.485 3.485 0 0 0 15.217.84ZM4.031 9.101a2.4 2.4 0 0 0-2.238 1.552l-.708 1.887a2.4 2.4 0 0 0-1.085 2v3.261a.869.869 0 0 0 .87.87h.43a.869.869 0 0 0 .87-.87v-.435H8.7v.435a.869.869 0 0 0 .87.87H10a.869.869 0 0 0 .87-.87V14.54a2.4 2.4 0 0 0-1.085-2l-.707-1.884A2.4 2.4 0 0 0 6.84 9.105Zm0 1.3h2.807a1.082 1.082 0 0 1 1.017.706l.389 1.033H2.626l.388-1.033a1.083 1.083 0 0 1 1.017-.702Zm-1.205 3.917a.652.652 0 1 1-.652.652.651.651 0 0 1 .652-.652Zm5.217 0a.652.652 0 1 1-.652.652.651.651 0 0 1 .652-.652Z"
      data-name="icons8-parking (3)"
    />
  </svg>
)
export default ParkingSlotIconView