import * as React from "react"
const CarpetIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404)">
      <rect
        width={40}
        height={40}
        fill="#f1f7ff"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404)"
      />
      <path
        fill="#78b1fe"
        stroke="#78b1fe"
        strokeWidth={0.25}
        d="M63.69 412a3.236 3.236 0 0 0-2.005.692l-7.347 5.789a4.635 4.635 0 0 0-1.766 3.639.927.927 0 1 0 1.853 0 2.776 2.776 0 0 1 1.059-2.182l7.347-5.788a1.38 1.38 0 0 1 1.718 0l7.347 5.788a2.776 2.776 0 0 1 1.059 2.182.927.927 0 1 0 1.853 0 4.636 4.636 0 0 0-1.766-3.639l-7.347-5.788A3.236 3.236 0 0 0 63.69 412Zm-10.206 12.324a.927.927 0 0 0-.912.94v.618a.927.927 0 1 0 1.853 0v-.618a.927.927 0 0 0-.941-.94Zm20.384 0a.927.927 0 0 0-.912.94v.618a.927.927 0 1 0 1.853 0v-.618a.927.927 0 0 0-.941-.94Zm-20.561 4.194-.633.485-.1.422v1.181l.343.719.776.187.633-.484.1-.422v-1.181l-.343-.72Zm20.384 0-.633.484-.1.422v1.181l.343.72.776.186.633-.484.1-.422v-1.18l-.343-.72Zm-19.911 4.635-.717.35-.18.777.189.39.072.088.125.126.183.151.147.1.21.115.168.07.232.072.182.036.249.025h.193l.719-.343.187-.776-.485-.633-.421-.1h-.053l-.108-.009-.058-.019-.052-.029-.046-.037-.014-.018-.016-.007-.036-.03-.007.01Zm19.445.077-.346.261-.014.018-.046.037-.052.029-.054.017-.111.011h-.053l-.719.343-.187.776.484.633.422.1h.193l.248-.025.182-.036.232-.072.168-.07.21-.115.147-.1.183-.151.126-.126.071-.088.194-.773-.479-.637Zm-14.853.373-.719.343-.187.776.485.633.421.1h1.181l.72-.343.187-.776-.485-.633-.422-.1Zm4.724 0-.719.343-.187.776.485.633.421.1h1.181l.72-.343.187-.776-.485-.633-.422-.1Zm4.724 0-.719.343-.187.776.485.633.421.1h1.181l.72-.343.187-.776-.485-.633-.422-.1Z"
        data-name="Group 138185"
      />
    </g>
  </svg>
)
export default CarpetIcon
