import { Stack, Box, Avatar, Typography, Grid } from "@mui/material";
import { InvestmentSvg } from "../../assets";
import moment from "moment-timezone";
import { generateImageUrl, img_size } from "../../utils";
import { Regular, Bold, SemiBold, ExtraBold } from "../../utils/constants";



export const contractStyle = {
    parentStack: {
        justifyContent: "space-between",
        display: 'flex',
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    contractSepBox: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "16px 0px",
        backgroundColor: "#fff",
        cursor: "pointer"

    },
    dividerBottom: {
        marginBottom: "12px",
        height: "1px"
    },
    childStack: {
        marginLeft: "12px"
    },
    heading: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: 'color.primary'
    },
    year: {
        fontFamily: SemiBold,
        backgroundColor: 'primary.main',
        color: "white",
        padding: "7px 11px",
        fontSize: "0.75rem",
        borderRadius: "15px"
    },
    month: {
        fontSize: "0.75rem",
        padding: "7px 5px 7px 0",
    },
    percentage: {
        backgroundColor: "#EE5188",
        color: "white",
        fontSize: "0.75rem",
        padding: "2px 9px",
        borderRadius: "4px"
    },
    tenantId: {
        fontFamily: SemiBold,
        color: "color.secondary",
        fontSize: "0.875rem"
    },
    price: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: 'text.main',

    },
    divider: {
        borderColor: "#5C86CB2E",
        height: "1px",
        margin: 0,
    },
    activity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "2px 8px",
        fontSize: "0.75rem",
        borderRadius: "4px",
        fontFamily: SemiBold,
    },
    work: {
        color: 'color.secondary',
        fontFamily: Regular,
        fontSize: "0.75rem",
        whiteSpace: {
            xs: "nowrap"
        }
    },
    totalAmount: {
        fontFamily: Regular,
        color: 'color.secondary',
        fontSize: "0.875rem"
    },
    money: {
        color: 'color.primary',
        fontSize: "0.78125",
        fontFamily: ExtraBold
    },
    descirption: {
        color: 'primary.Tertiary',
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    drawerActivity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "8px 12px",
        fontSize: "0.75rem",
        borderRadius: "4px",
        fontFamily: ExtraBold
    },
    information: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'primary.Tertiary'
    },
    assist: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: 'primary.Tertiary'
    },
    label: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: Bold,
    },
    textArea: {
        width: "100%",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
    },
    primaryButton: {
        padding: "15px 60px",
        border: "1px solid #CED3DD",
        color: 'color.primary',
        fontFamily: Bold,
        borderRadius: "8px"
    },
    secondaryButton: {
        padding: "15px 60px",
        backgroundColor: "#5078e1",
        border: "none",
        color: "white",
        fontFamily: Bold,
        borderRadius: "8px"
    },
    uploadButton: {
        border: "2px dashed #E4E8EE",
        borderRadius: "4px",
        padding: "25px",
        backgroundColor: "white",
        color: 'color.primary'
    },
    field: {
        [`& fieldset`]: {
            borderRadius: "4px",
            border: "1.5px solid  #E4E8EE",
            boxShadow: "none",
            backgroundColor: "white"
        },
    },
    dotTwo: {
        padding: '3px',
        borderRadius: '50%',
        margin: "0px 5px",
        backgroundColor: 'background.light'
    },
    rightSection: {
        display: "block",
        justifyContent: "end",
        alignItems: "center",

        "& div": {
            display: "flex",
            alignItems: "center",
            marginBottom: "9px",
            "& p": {
                marginRight: "5px",
                fontFamily: Regular,
            }
        },
        "& p": {
            display: "flex",
            alignItems: "baseline",
            fontSize: "0.75rem",
            fontFamily: Regular,
            "& p": {
                color: 'color.primary',
                fontFamily: Bold,
                fontSize: "0.75rem",
                marginLeft: "2px"
            }
        }
    },
    imgDiv: {
        backgroundColor: 'backgroundcard.secondary',
        height: "42px",
        width: "42px"
    },
    marginTop: {
        display: "flex", alignItems: 'center', marginTop: "3px"
    },
    activityCard: {
        flexWrap: 'wrap',
        justifyContent: 'end'
    },
    line: {
        margin: "0 4px"
    }
}


export const ContractCard = ({
    image = "",
    contract_no = "",
    isBadge = false,
    activity = "",
    account_no = "",
    start_date,
    end_date,
    currValue = "",
    rightSec = false,
    InflationcolorUp = "#00B542",
    InflationcolorDown = "#F60000",
    t = () => false,
    handleContractDetails = () => false,
    hidden = false,
    gainLoss = ""
}) => {
    return (
        <Box
            onClick={handleContractDetails}
            sx={{
                ...contractStyle?.contractSepBox,
                borderBottom: !hidden ? '1px solid #fff' : "1px solid #5c86cb2e"
            }}>
            <Box sx={contractStyle?.parentStack}>
                <Grid container>
                    <Grid item xs={1}>
                        <Box display={'flex'} alignItems={'center'} mr={1}>
                            {
                                image === String ? generateImageUrl(image, img_size?.small_square) :
                                    <Avatar variant="rounded" sx={contractStyle?.imgDiv}>
                                        <InvestmentSvg />
                                    </Avatar>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={11}>
                        <Box sx={{ marginInlineStart: '29px' }}>
                            <Stack direction="row" spacing={2}>
                                <span>
                                    <Typography sx={contractStyle?.price} noWrap>{contract_no}</Typography>
                                </span>
                                {
                                    isBadge && <span>
                                        <Typography sx={contractStyle?.activity} noWrap>
                                            {activity}
                                        </Typography>
                                    </span>
                                }
                            </Stack>
                            <Box
                                sx={contractStyle?.marginTop}
                                spacing={1}>
                                <Typography sx={contractStyle?.work} noWrap>{account_no}</Typography>
                                <Box sx={contractStyle?.dotTwo}></Box>
                                <Typography sx={contractStyle?.work} noWrap>
                                &#x202a;{start_date ? moment(start_date).format(
                                        "DD-MMM-YY"
                                    ) : "-"}&#x202c;</Typography>
                                <Typography sx={contractStyle?.line}> - </Typography>
                                <Typography sx={contractStyle?.work} noWrap>
                                &#x202a;{end_date ? moment(end_date).format(
                                        "DD-MMM-YY"
                                    ) : "-"}&#x202c;</Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

            </Box>
        </Box>

    );
};