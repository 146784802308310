import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { FooterComponent, FormGenerator, TopNavBar } from "../../components";
import { CreateInvestmentStyle } from './style'
import { useLazyGetOwnerUnitNumberQuery, useLazyStatisticQuery, useLazySubCategoryQuery, useLazyUsageTypeQuery, useLazyStatisticsCreationQuery } from "../../redux/services";
import { Bold, LocalStorageKeys, SemiBold, errorRoute, wrapLabelsInT } from "../../utils";
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";



const initial = (enums) => {
  return {
    date: "",
    category: "",
    unitNumber: "",
    amount: 0,
    description: "",
    currentValue: "",
    notes: "",
    images: [],
    subCategory: "",
    type: enums?.[0]?.value,
    error: {
      date: "",
      category: "",
      unitNumber: "",
      amount: "",
      currentValue: "",
      notes: "",
      subCategory: "",
      images: []
    }
  }
}

export const CreateInvestment = ({
  t = () => false,
  type = "investment",
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  let location = useLocation();
  const [data, setData] = useState({ ...initial() })
  const [loading1, setLoading1] = React.useState(null);
  const [enums, setEnums] = React.useState([]);
  const [Statistic , {error}] = useLazyStatisticQuery()
  const [SubCategory, {error:isError}] = useLazySubCategoryQuery()
  const [GetOwnerUnit , {error:isError1}] = useLazyGetOwnerUnitNumberQuery()
  const [UsageType] = useLazyUsageTypeQuery()
  const [StatisticsCreation, { isLoading }] = useLazyStatisticsCreationQuery()
  const [isDisableBtn,setIsDisableBtn]=React.useState(false)

  const currency = localStorage.getItem(LocalStorageKeys?.currency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))?.symbol : ''




  // updateState
  const updateState = (k, v) => {

    let error = data?.error
    error[k] = "";
    setData({ ...data, [k]: v, error });
  };

  // save btn
  const Save = async () => {
    if (validate()) {
      setIsDisableBtn(true)
      const payload = {
        date: data?.date,
        category_id: data?.category?.value,
        sub_category_id: data?.subCategory?.value,
        unit: data?.unitNumber?.value,
        amount: data?.amount,
        currency_id: currency ?? null,
        usage_type: data?.type ? data?.type : null,
        description: data?.description,
        type: type === "spending" ? "Spendings" : "Investments",

        assets: data?.images?.map((x) => {
          return {
            ...x,
            file_meta: JSON.stringify(x?.file_meta)
          }
        }) ?? []

      }
      if (type !== "spending") {
        payload['current_value'] = data?.currentValue
        payload['notes'] = data?.notes
      }
      if (data.id) { payload['id'] = data?.id }
      let StatisticsCreationRes = await StatisticsCreation(payload)
      if (StatisticsCreationRes?.data?.type === "success") {
        setIsDisableBtn(false)
        enqueueSnackbar(type === "spending" ?
          t("Spendings Created successfully") : t("Investments Created successfully"), {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
          className: { fontFamily: SemiBold }
        })
        navigate(AppRoutes?.statistics, { state: type === "investment" ? 2 : 1 })
      } else {
        setIsDisableBtn(false)
        enqueueSnackbar(type === "spending" ? t("Spendings Created Unsuccessfully") : t("Investments Created Unsuccessfully"), {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
          className: { fontFamily: SemiBold }
        })
      }
    } else { }

  }

  // cancel btn
  const cancel = () => {
    setData({ ...initial() })
    navigate(-1)
  }

  const getEnum = async () => {
    const data = await UsageType()
    setEnums(data?.data?.data ?? [])
  }
  const editFunctions = () => {
    if (location?.state) {
      const editData = location?.state
      setData({
        id: editData?.id,
        date: new Date(editData?.created_at),
        category: {
          label: editData?.category_name,
          value: editData?.category_id
        },
        unitNumber:
        {
          label: editData?.unit_number,
          value: editData?.unit_number
        }
        ,
        amount: editData?.amount,
        description: editData?.description,
        currentValue: editData?.current_value,
        notes: editData?.notes,
        images: editData?.assets,
        subCategory:
        {
          label: editData?.sub_category_name,
          value: editData?.sub_category_id
        },
        type: editData?.usage_id,
        error: {
          date: "",
          category: "",
          unitNumber: "",
          amount: "",
          currentValue: "",
          notes: "",
          subCategory: "",
          images: []
        }
      })

    }

  }
  React.useEffect(() => {
    getEnum()
    editFunctions()
    // eslint-disable-next-line
  }, [])


  // validation
  const validate = () => {

    let isValid = true;
    let error = data.error
    if (data?.category.length === 0) {
      isValid = false
      error.category = t("Category is required")
    }
    if (data?.date?.length === 0) {
      isValid = false
      error.date = t("Date is Required")
    }

    if (data?.subCategory?.length === 0) {
      isValid = false
      error.subCategory = t("Spending Type is Required")
    }

    if (data?.unitNumber?.length === 0) {
      isValid = false
      error.unitNumber = t("Unit Number is Required")
    }
    if (data?.amount?.length === 0) {
      isValid = false
      error.amount = t("Amount is Required")
    }
    if (data?.currentValue?.length === 0 && type === "investment") {
      isValid = false
      error.currentValue = t("Amount is Required")
    }
    // if (data?.notes?.length === 0 && type === "investment") {
    //   isValid = false
    //   error.notes = "Notes is Required"
    // }
    setData({ ...data, error })
    return isValid
  }
  const loadOptions = async (search = "", array, key) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (key) {
      case "category":
        result = await Statistic({
          offset,
          limit: 10, search , type:type === "spending" ? "Spendings" : "Investments"
        })
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };

      case "sub-category":
        if(!data?.category?.value) return {
          options: [],
          hasMore:
            array?.length,
        }
        result = await SubCategory({
          offset,
          limit: 10, search , category_id:data?.category?.value ?? undefined , type:type === "spending" ? "Spendings" : "Investments"
        })
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };
      case "get-owner-unit":
        result = await GetOwnerUnit({
          offset,
          limit: 10,
          search
        })
        setLoading1(null);
        return {
          options: [...result?.data?.data?.map((x) => {
            return {
              value: x?.unit_no,
              label: x?.unit_name
            }
          })],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.count,
        };

      default:
        return { options: [] };

    }
  };

  React.useEffect(()=>{
    if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423) return errorRoute() 
 },[error , isError , isError1])
  return (
    <Container
      sx={CreateInvestmentStyle.container}
      maxWidth="sm"
    >
      {/* topNavbar */}

      <Box sx={CreateInvestmentStyle.investmentTab}>
        <Container maxWidth="sm" sx={{ padding: '0px!important' }}>
        <TopNavBar
        title={t(type === "spending" ? t("Create Spending") : t("Create Investment"))} isDirect isBack t={t} />
        </Container>
    
      </Box>

      {/* CreateInvestment */}
      <Box sx={CreateInvestmentStyle.root}>
        <Box sx={CreateInvestmentStyle.FormGeneratorBox}>
          <FormGenerator
            components={[
              {
                isActive: true,
                component: "date",
                label: t("Date"),
                value: data?.date,
                placeholder: t("Date"),
                onChange: (value) => updateState("date", value),
                error: data?.error?.date,
                isRequired: false,
                sx: {
                  borderBottom: "1px solid #d6dae3 !important",
                  mt: "2px",
                },
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                size: {
                  xs: 6,
                },
                right: "0px important",
                customStyle: {
                  marginLeft: "12px !important"
                }
              },
              {
                isActive: true,
                component: "select",
                label: t("Unit Number"),
                value: data?.unitNumber,
                placeholder: t("Unit Number"),
                onChange: (e) => updateState("unitNumber", e),
                error: data?.error?.unitNumber,
                isRequired: false,
                loading: loading1 === "get-owner-unit",
                isPaginate: true,
                variant: "standard",
                noSearch: true,
                size: {
                  xs: 6,
                },
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(search, array, "get-owner-unit"),
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                styles: {
                  borderBottom: "1px solid !important",
                  border: "none"
                },
                border: "none",
                radius: "0px !important",
                padding: "0px !important",


              },
              {
                isActive: true,
                component: "select",
                label: t("Category"),
                value: data?.category,
                placeholder: t("Category"),
                onChange: (value) => {
                  setData({
                    ...data,
                    category:value,
                    subCategory:""
                  })
                },
                error: data?.error?.category,
                isRequired: false,
                loading: loading1 === "category",
                isPaginate: true,
                noSearch: true,
                size: {
                  xs: 12,
                },
                customStyle: {
                  "& .css-1k1pnh9-control": {
                    borderBottom: "1px solid !important"
                  }
                },
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(search, array, "category"),
                noBorder: "0px !important",
                padding: "0px !important",
                border: "none",
                radius: "0px !important"


              },
              {
                isActive: true,
                component: "select",
                label: type === "spending" ? t("Sub Category") : t("Sub Category"),
                value: data?.subCategory,
                placeholder: type === "spending" ? t("Sub Category") : t("Sub Category"),
                onChange: (value) => updateState("subCategory", value),
                error: data?.error?.subCategory,
                isRequired: false,
                loading: loading1 === "sub-category",
                isPaginate: true,
                noSearch: true,
                size: {
                  xs: 12,
                },
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                key:JSON.stringify(data?.category),
                loadOptions: (search, array, handleLoading) =>
                  loadOptions(search, array, "sub-category"),
                styles: {
                  borderBottom: "1px solid #d6dae3 ! important",
                  borderRadius: "0px !important"
                },
                // borderBottom:"2px solid #d6dae3 !important"
                borderRadius: "0px !important",
                borderStyle: "0px !important ",
                noBorder: "0px !important",
                padding: "0px !important",
                border: "none",
                radius: "0px !important"
              },

              {
                isActive: true,
                component: "text",
                label: t("Amount"),
                type: 'number',
                value: data?.amount,
                placeholder: t("Amount"),
                onChange: (e) => updateState("amount", e?.target?.value),
                isRequired: false,
                endAdornment: currency,
                error: data?.error?.amount,
                variant: "standard",
                labelStyle: {
                  color: "#f15a29 !important",
                  fontSize: "0.75rem",
                  fontFamily: Bold
                },
                size: {
                  xs: type === "spending" ? 12 : 6,
                },
                sx: {
                  borderBottom: "1px solid #d6dae3 ! important",
                  borderRadius: "0px !important"
                }
              },
              {
                isActive: type === "spending" ? false : true,
                component: "text",
                label: t("Current Value"),
                type: 'number',
                value: data?.currentValue,
                placeholder: t("Current Value"),
                onChange: (e) => updateState("currentValue", e?.target?.value),
                isRequired: false,
                endAdornment: currency,
                error: data?.error?.currentValue,
                variant: "standard",
                size: {
                  xs: type === "spending" ? 12 : 6,
                },
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                sx: {
                  borderBottom: "1px solid #d6dae3 ! important",
                  borderRadius: "0px !important"
                }
              },

              // {
              //   isActive: type === "spending" ? false : true,
              //   component: "text",
              //   label: t("Notes"),
              //   value: data?.notes,
              //   placeholder: t("Notes"),
              //   onChange: (e) => updateState("notes", e?.target?.value),
              //   error: data?.error?.notes,
              //   isRequired: false,
              //   multiline: true,
              //   variant: "standard",
              //   size: {
              //     xs: 12,
              //   },
              //   labelStyle: {
              //     color: "#f15a29 ",
              //     fontSize: "12px !important",
              //     fontFamily: Bold

              //   },
              // },
              {
                isActive: type === "spending" ? true : false,
                component: "toggleBlockButton",
                value: data?.type,
                onChange: (e) => updateState("type", e),
                error: data?.error?.type,
                multiline: true,
                options: wrapLabelsInT(enums, t) ?? [],
                size: {
                  xs: 12,
                },
                spacing: 2,
                isRequired: false,
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                toogle_style: {
                  backgroundColor: "#fff4eb !important",
                  color: '#f15a2a !important'
                },
                toogle_style_unselect: {
                  backgroundColor: "#f8fafc !important",
                  color: '#000 !important',
                  border: "none"
                },
              },
              {
                isActive: true,
                component: "text",
                label: t("Description"),
                value: data?.description,
                // placeholder: t("Description"),
                onChange: (e) => updateState("description", e?.target?.value),
                multiline: true,
                variant: "standard",
                size: {
                  xs: 12,
                },
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold

                },
                sx: {
                  borderBottom: "1px solid #d6dae3 ! important",
                  borderRadius: "0px !important"
                },
              },

              {
                isActive: type === "spending" ? true : false,
                component: "multiImage",
                error: data?.error?.images,
                isRequired: false,
                value: data?.images,
                text:t("Upload Bill/Invocie"),
                onChange: (e) => updateState("images", e),
                onDelete: (e, index) => updateState("images", e),
                multiline: true,
                onlyFiveFilesUpload: true,
                size: {
                  xs: 12,
                },
                sizexs: 12,
                labelStyle: {
                  color: "#f15a29 ",
                  fontSize: "0.75rem !important",
                  fontFamily: Bold,
                  border: "1px solid",
                },
                customStyle: {
                  border: "1px solid #ced3dd !important",
                  padding: "12px !important",
                  borderRadius: "8px"
                }
              },
            ]}

          />
        </Box>

      </Box>
      <Box sx={CreateInvestmentStyle.footer}>
      <Container maxWidth="sm" sx={{ padding: '0px!important' }}>

<Box>
  {/* footer Component */}
  <FooterComponent
    containedonClick={Save}
    outlinedonClick={() => cancel()}
    loading={true}
    outlinedText={t("Cancel")}
    containedText={t("Save")}
    btnLoading={isLoading}
    isDisableBtn={isDisableBtn}
  />
</Box>
</Container>
      </Box>
   
    </Container>

  );
}
