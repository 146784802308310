import React from "react";
import { Fab, Box } from "@mui/material";
import {
  InspectionOrdersStyle,
} from "./style";
import { DialogDrawer, RaisedOnCard, LoderSimmer, NoData, SearchBarFilter } from "../../components";
import { AppRoutes } from "../../router/routes";
import { ReqTypeCard } from "./reqTypeCard";
import { RequestData, LocalStorageKeys, errorRoute } from "../../utils";
import AddIcon from '@mui/icons-material/Add';
import { useLazyGetAllRequestQuery, useLazyGetRequestStatusEnumQuery, useLazyGetUnitEnumQuery } from "../../redux/services";
import InfiniteScroll from "react-infinite-scroll-component";
import Filter from "../../components/filter";
import { useNavigate, useLocation } from "react-router";
import { Container } from "@mui/system";
import useWindowDimensions from "../../useDimension";
import RequestCard from "./components/requestCard";





export const Request = ({
  t = () => false
}) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [offSet, setOffSet] = React.useState(0);
  const [reqdata, setReqData] = React.useState({
    bool: true,
    reqCount: {
      generalReq: "",
      MaintanenceReq: ""
    },
    data: [],
    totalCount: 0
  })

  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    unit: [],
  })
  const [filterData, setFilterData] = React.useState({
    type: ["General", "Maintenance"],
    statusType: [],
    units: [],

  })
  const [drawer, setDrawer] = React.useState({
    addReq: false,
    filterReq: false
  })

  const [GetRequestAPI, { error }] = useLazyGetAllRequestQuery();
  const [GetRequestStatusAPI, { error: isError }] = useLazyGetRequestStatusEnumQuery();
  const [GetUnitAPI, { error: isError1 }] = useLazyGetUnitEnumQuery();

  const enum_payload = {
    enumName: ["general_maintenance_type", "unit_status_type"]
  }


  const company = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
    ? JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
      .filter((val) => val.name === "Owner")
      .map((item) => item?.company_id)
    : []


  const reqClient = async (data, filter = false) => {
    let client = await GetRequestAPI({ ...data, company_id: company ?? [] })
    setReqData({
      ...reqdata,
      reqCount: {
        generalReq: client?.data?.generalCount,
        MaintanenceReq: client?.data?.maintenanceCount
      },
      data: filter ? [...reqdata?.data, ...client?.data?.data] : client?.data?.data,
      bool: false,
      totalCount: client?.data?.totalCount
    })
  }

  const reqTypeClient = async (data) => {

    let client = await GetRequestStatusAPI(data)
    let unit = await GetUnitAPI()
    const tempStatus = ["Open", "Reopened","Assigned"]
    
    if(location?.state || unit?.data?.data?.map(val => val?.id)?.length){
      reqClient({
        request_status: tempStatus ?? tempStatus,
        request_units: location?.state ? [location?.state] : unit?.data?.data?.map(val => val?.id),
        offset: offSet,
        limit: 10,
        request_type: ["Maintenance", "General"]
      })
  
    }
    else{
      setReqData({
        ...reqdata,
        bool:false
      })
    }

    setEnumValue({
      ...enumValue,
      type: filterData?.type?.map(val => {
        return {
          label: val,
          value: val
        }
      }),
      status: client?.data?.data?.general_maintenance_type,
      unit: unit?.data?.data?.map(val => {
        return {
          label: val?.unit_name,
          value: val?.id,
          profileId: val?.property_id
        }
      })
    })

    setFilterData({
      ...filterData,
      units: location?.state ? [location?.state] : unit?.data?.data?.map(val => val?.id),
      statusType: tempStatus
    })
  }



  React.useEffect(() => {
    reqTypeClient(enum_payload)
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (error?.status === 423 || isError?.status === 423 || isError1?.status === 423) return errorRoute()
  }, [error, isError, isError1])

  const filterDataJson = [
    {
      id: "1",
      title: t("Request Type"),
      key: "request_type",
      filterType: "CHECKBOX",
      values: enumValue?.type,
    },
    {
      id: "2",
      title: t("Units"),
      key: "units",
      filterType: "CHECKBOX",
      values: enumValue?.unit,
    },
    {
      id: "3",
      title: t("Request Status"),
      key: "general",
      filterType: "CHECKBOX",
      values: enumValue?.status,
    },
  ];

  const handleRoute = (e) => {
    navigate(AppRoutes?.requestView, {
      state: e
    });
  }
  const createIcon = () => {
    setDrawer({
      ...drawer, addReq: true
    });
  }
  const drawerClose = () => {
    setDrawer({
      ...drawer, addReq: false
    });
  }
  const filterdraweropen = () => {
    setDrawer({
      ...drawer, filterReq: true
    });
  };
  const filterdrawerclose = () => {
    setDrawer({
      ...drawer, filterReq: false
    });
  };
  const fetchMoreData = () => {
    reqClient({
      company_id: company,
      request_status: filterData?.statusType ?? [],
      request_units: filterData?.units ?? [],
      offset: offSet + 10,
      limit: 5,
      request_type: ["Maintenance", "General"]
    }, true)
    setOffSet(offSet + 10);
  }

  const applyFilter = async (data) => {
    setDrawer({ ...drawer, filterReq: false });
    const payload = {
      request_units: data?.units,
      request_type: data?.request_type,
      request_status: data?.general,
      offSet: offSet + 10,
      limit: 10
    }
    await reqClient(payload)
    setFilterData({
      ...filterData,
      units: data?.units,
      statusType: data?.general,
      type: data?.request_type
    })

  }

  const size = useWindowDimensions();

  const chooseAppliance = () => {
    navigate(AppRoutes.chooseAppliance)
  }

  const QrCodeScanner = () => {
    navigate(AppRoutes.qrCode)
  }

  return (
    <Box maxWidth={'sm'} padding={0} sx={InspectionOrdersStyle?.main}>
      {reqdata?.bool ?
        <LoderSimmer card count={1} size={120} />
        :
        <>
          <Box sx={InspectionOrdersStyle?.reqTop}>
            <SearchBarFilter text={`${t("Facility Management")}(${reqdata?.totalCount ?? 0})`} handleFilterOpen={filterdraweropen} filterIcon />

            {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography sx={InspectionOrdersStyle?.username}> {`${t("Facility Management ")}(02)`}</Typography>
              <Box sx={InspectionOrdersStyle?.filterIcon} onClick={filterdraweropen}>
                <FilterIcon />
              </Box>
            </Stack> */}
          </Box>
          <Box height={'10px'} />
          <ReqTypeCard t={t} reqdata={reqdata} />
        </>
      }

      {reqdata?.bool ?
        <LoderSimmer card count={5} size={130} variant={"rectangular"} animation={"wave"} />
        :
        <Box sx={InspectionOrdersStyle?.reqCard}>
          <InfiniteScroll
            dataLength={reqdata?.data?.length ?? null}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 291}
            style={InspectionOrdersStyle?.infiniteScroll}
          >
            {reqdata?.data?.length > 0 ?
              reqdata?.data?.map((e, i) => {
                return (
                  <Box key={i} mb={reqdata?.length - 1 !== i ? 2 : 0}>
                    {/* <ReqCard
                      t={t}
                      key={i}
                      name={e?.category}
                      reqId={e?.sub_category}
                      reqType={e?.request_type}
                      status={e?.request_status}
                      date={e?.raised_on}
                      unitId={e?.units?.name}
                      img={e?.image_url ? e?.image_url : null}
                      handleRoute={() => handleRoute(e)}
                    /> */}
                    <RequestCard
                      t={t}
                      key={i}
                      name={e?.subject}
                      reqId={e?.sub_category}
                      reqType={e?.category}
                      status={e?.request_status}
                      date={e?.raised_on}
                      unitId={e?.units?.name}
                      property_name={e?.property_name}
                      img={e?.image_url ? e?.image_url : null}
                      asset_name={e?.asset}
                      handleRoute={() => handleRoute(e)}
                      request_status={e?.request_status}
                      action={e?.approval_status}
                      unit_type={e?.units?.unit_type_name}
                      image_type={e?.image_url ? (e?.image_url?.split('.')?.pop()?.toLowerCase() ?? null) : null}
                    />

                  </Box>
                )
              }) :
              <Box mt={4}>
                <NoData style={{
                  display: "flex", alignItems: "center", backgroundColor: "#fff",
                  padding: "16px"
                }} />
              </Box>

            }
          </InfiniteScroll>

        </Box>
      }
      <Box sx={InspectionOrdersStyle.AddIcon}>
        <Container maxWidth="sm" style={{ padding: "0px !important" }}>
          <Fab onClick={createIcon} sx={InspectionOrdersStyle.AddIconChild} style={{ marginBottom: "10px" }}>
            <AddIcon />
          </Fab>
        </Container>
      </Box>

      <DialogDrawer
        headerStyle={{ border: "none !important", padding: "16px 24px 0px 24px !important" }}
        maxWidth="sm"
        open={drawer?.addReq}
        height_style={
          { height: "290px !important" }
        }
        header={t("Service & Maintenance")}
        component={
          <Box>
            {RequestData(t)?.map((item, index) => {
              return (
                <Box key={index}>
                  <RaisedOnCard
                    t={t}
                    QrCodeScanner={() => QrCodeScanner()}
                    chooseAppliance={() => chooseAppliance()}
                    title={item?.title}
                    subTitle={item?.subTitle}
                    exampleTitle={item?.exampleTitle}
                    icon={item?.icon}
                    router={item?.onClick}
                  />
                </Box>
              )
            })}
          </Box>
        }
        onClose={() => drawerClose()}
      />
      {/* filter Drawer */}
      <DialogDrawer
        maxWidth="sm"
        open={drawer?.filterReq}
        header={t("Filter By")}
        padding="0px"
        component={
          <Box>
            <Filter
              t={t}
              filters={{
                request_type: filterData?.type,
                general: filterData?.statusType,
                units: filterData?.units
              }}
              filterDataJson={filterDataJson}
              onGoBack={filterdrawerclose}
              selectedList={filterDataJson[0]}
              onApply={applyFilter}
              onClose={filterdrawerclose}
            />

          </Box>
        }
        onClose={() => filterdrawerclose()}
      />
    </Box>

  );
};