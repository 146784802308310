import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { PdfIcon } from "../../../assets"
import { Bold, Regular, SemiBold } from "../../../utils";

export const useStyles = {
    folderName: {
        fontFamily: Bold,
        fontSize: "0.75rem",
    },
    createdAt: {
        fontFamily: Regular,
        fontSize: "0.75rem",
    },
    folderParent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

    },
    more: {
        fontSize: "0.78125",
        color: "gray",
        cursor: "pointer",
    },
    menuItem: {
        margin: "0px 4px",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        "&:last-child": {
            border: 0,
        },
    },
    chip: {
        backgroundColor: "#5078E1",
        padding: "3px 10px",
        borderRadius: "4px",
        color: "white",
        position: "absolute",
        top: "10px",
        right: "10px",
        fontSize: "0.75rem",
        fontFamily: Bold,
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            backgroundColor: "white",
        },
    },
    moreBtn: {
        position: (props) => props?.type === 2 ? "absolute" : "relative",
        top: "3px",
        right: "0px",
        display: "flex",
        marginLeft: "auto"
    },
    listFolderView: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    card: {
        padding: "9px 14px",
        background: "white",
        borderRadius: "4px",
        position: "relative",
    },
    flag: {
        backgroundColor: "primary.main",
        padding: "2px 8px",
        position: "absolute",
        top: "14px",
        left: "-11px",
        borderRadius: "4px",
        boxShadow: '0px 3px 6px #00000029'
    },
    flagChild: {
        height: "10px",
        width: "11px",
        backgroundColor: "primary.main",
        bottom: "-8px",
        left: "-8px",
        position: "absolute",
        borderBottomLeftRadius: '195px'
    },
    flagName: {
        color: "white",
        fontSize: "0.75rem",
        fontFamily: Bold,
    },
    icon: {
        cursor: "pointer",
        marginTop: "16px "
    }
}

export const FileComponent = ({
    data = {},
    t = () => false,
    type = 2,
    viewDetails = () => false,
    is_divider = true,
    download = () => false,
    viewPdf = () => false }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            {type === 2 ? (
                <>
                    <Box sx={useStyles.card} onClick={() => viewPdf(data?.data)}>
                        <IconButton
                            sx={useStyles.moreBtn}
                            onClick={(e) => {
                                e.stopPropagation()
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <MoreVertIcon sx={useStyles.more} />
                        </IconButton>
                        <Box sx={useStyles.folderParent}  >

                            <Box >
                                <PdfIcon />
                            </Box>
                        </Box>
                        <Box height={"6px"} />
                        <Typography sx={useStyles.folderName} noWrap>
                            {data?.data?.file_meta?.file_name ?? data?.data?.file_meta?.name}
                        </Typography>
                        <Box height={"2px"} />
                        <Typography sx={useStyles.createdAt} noWrap>
                            {data?.createdAt}
                        </Typography>
                        {
                            <Box sx={useStyles.flag}>
                                <Box position={"relative"}>
                                    <Typography sx={useStyles.flagName} noWrap>
                                        {data?.data?.file_meta?.file_ext ?? data?.data?.file_meta?.type}
                                    </Typography>
                                    <Box sx={useStyles.flagChild} />
                                </Box>
                            </Box>
                        }

                    </Box>
                </>
            ) : (
                <>
                    {/* list view */}
                    {
                        !is_divider && <Box height={'7px'} />
                    }
                    <Box sx={useStyles.listFolderView} onClick={() => viewPdf()}>
                        <Box display="flex" alignItems={"center"}>
                            <Box sx={useStyles.icon} >
                                <PdfIcon />
                            </Box>
                            <Box width={"12px"} />
                            <Box>
                                <Typography sx={useStyles.folderName} noWrap>
                                    {data?.data?.name}
                                </Typography>
                                <Box height={"2px"} />
                                <Typography sx={useStyles.createdAt} noWrap>
                                    {data?.createdAt}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <IconButton
                                sx={useStyles.moreBtn}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setAnchorEl(e.currentTarget);
                                }}
                            >
                                <MoreVertIcon sx={useStyles.more} />
                            </IconButton>
                        </Box>
                    </Box>

                    {
                        is_divider &&
                        <>
                            <Box height={'6px'} />
                            <Divider />
                        </>
                    }

                </>
            )
            }
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={useStyles.menuList}
            >

                <MenuItem
                    onClick={(e) => {
                        
                        handleClose();
                        viewDetails(data?.data)
                    }}
                    sx={useStyles.menuItem}
                >
                    {t("Details")}
                </MenuItem>
                {
                    data?.data?.url?.length > 0 && <MenuItem
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClose();
                            download(data?.data)
                        }}
                        sx={useStyles.menuItem}
                    >
                        {t("Download")}
                    </MenuItem>
                }

            </Menu>
        </>
    );
};
