import { Bold } from "../../utils";

export const SliderStyles = {
    cardHead: {
        padding: "12px",
    },
    title: {
        fontSize: "0.75rem",
        color: "color.secondary",
        textTransform: "upperCase",
        fontFamily: Bold,
        marginBottom: "16px",
        marginTop: "4px"
    },
    arrowBtn: {
        backgroundColor: "#F15A29 !important",
        border: "1px solid",
        borderColor: "primary.main",
        position:"absolute",
        color:"#fff !important",
        left:"10px",
        zIndex: 999,
    },
    arrowBtnRight:{
        backgroundColor: "#F15A29 !important",
        border: "1px solid",
        borderColor: "primary.main",
        position:"absolute",
        color:"#fff !important",
        right:"10px",
        zIndex: 999,
    },
    caroselRoot: {
        borderRadius: '4px',
        backgroundColor: (props) => props?.backgroundColor ?? "",
        padding: (props) => props?.padding ?? "",
        cursor: 'pointer',
        position:"relative"
    },

}