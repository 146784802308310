import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { LoderSimmer } from "../../components";

export const classes = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
    },
    borderBox: {
        borderRadius: "12px",
        border: "1px solid #CED3DD",
        padding: "16px 16px 0px 16px"
    }
}

export const PropertyDetailSimmerPage = () => {
    return (
        <Box>
            <Box sx={classes?.bg}>
                <LoderSimmer card count={1} width={"auto"} size={150} variant={"rounded"} animation={"wave"} />
                <LoderSimmer card count={2} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                <Divider></Divider>
                <Box mt={2}>
                    <LoderSimmer card count={2} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                </Box>
                <Divider></Divider>
                <Box mt={2}>
                    <LoderSimmer card count={2} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                </Box>

                <Stack sx={classes.borderBox} direction={"row"} alignItems={"center"} spacing={1}>
                    <LoderSimmer card count={1} width={"40px"} size={40} variant={"rounded"} animation={"wave"} />
                    <Stack>
                        <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                        <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                    </Stack>
                </Stack>
            </Box>

            <Box mt={2}>
                <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
            </Box>
            <Box sx={classes?.bg}>
                <LoderSimmer card count={1} width={"auto"} size={250} variant={"rounded"} animation={"wave"} />
            </Box>
        </Box>
    )
}