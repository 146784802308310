import * as React from "react"
const RefIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 138287">
      <path
        fill="#98a0ac"
        d="M2.196 0a1.8 1.8 0 0 0-1.8 1.8v12.4a1.8 1.8 0 0 0 1.8 1.8h9.2a1.8 1.8 0 0 0 1.8-1.8v-4.124l-1.2 1.2V14.2a.6.6 0 0 1-.6.6h-9.2a.6.6 0 0 1-.6-.6V1.8a.6.6 0 0 1 .6-.6h4.6v3.4a1.8 1.8 0 0 0 1.8 1.8h.924l1.2-1.2H8.596a.6.6 0 0 1-.6-.6V2.048l2.94 2.94.588-.592a2.573 2.573 0 0 1 .276-.24L7.82.176A.6.6 0 0 0 7.396 0ZM14.78 3.992a.8.8 0 0 0-.55.242l-.264.266a1.767 1.767 0 0 0-.773-.08 1.9 1.9 0 0 0-1.1.541L8.266 8.788a1.4 1.4 0 0 0-.348.578l-.693 2.259a.6.6 0 0 0 .749.749l2.259-.693a1.4 1.4 0 0 0 .578-.348l3.859-3.859a1.809 1.809 0 0 0 .426-1.84l.267-.267a.8.8 0 0 0-.581-1.373Zm-1.451 1.62a.562.562 0 0 1 .394.084.706.706 0 0 1 .095.079l.027.026a.58.58 0 0 1-.024.823l-3.859 3.859a.2.2 0 0 1-.083.05l-1.177.361.361-1.177a.2.2 0 0 1 .05-.083l3.827-3.827a.688.688 0 0 1 .39-.194ZM4.196 8.4a.6.6 0 0 0 0 1.2h2.812l.145-.468a2.115 2.115 0 0 1 .388-.732Zm0 2.8a.6.6 0 1 0 0 1.2h2.336a1.4 1.4 0 0 1-.076-1.012l.06-.188Z"
        data-name="icons8-sign (1)"
      />
    </g>
  </svg>
)
export default RefIcon
