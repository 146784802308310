import React from "react"
import { Avatar, Box, Container, Divider, IconButton, InputAdornment, Radio, Stack, Typography } from "@mui/material"
import { chooseApplianceStyle } from "./style"
import { DialogDrawer, FooterComponent, LoderSimmer, Slider, TextBox, TopNavBar, UseDebounce } from "../../components"
import UnitChanger from "../../assets/unit"
import { SerachIMG } from "../../assets"
import { Appliance } from "./component"
import PurpleUnit from "../../assets/purpleUnits"
import GreyUnit from "../../assets/greyUnit"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes"
import UnitAssetInfo from "../../assets/unitAssetInfo"
import { useLazyGetAllProductsQuery, useLazyGetUnitEnumQuery } from "../../redux/services"
import moment from "moment-timezone"
import NoData from "../../components/noData"
import useWindowDimensions from "../../useDimension"
import InfiniteScroll from "react-infinite-scroll-component"
import NoDataShow from "../../assets/noData"


export const ChooseAppliance = ({
    t = () => false
}) => {

    const navigate = useNavigate();
    const [unitSwitcherDrawer, setUnitSwticherDrawer] = React.useState(false);
    const [unitDetailsDrawer, setUnitDetailsDrawer] = React.useState(false);
    const [assetDetailsDrawer, setAssetDetailsDrawer] = React.useState(false);
    const [getUnit] = useLazyGetUnitEnumQuery();
    const [getProducts] = useLazyGetAllProductsQuery();
    const [products, setProducts] = React.useState([])
    const [productDrawer, setProductDrawer] = React.useState({})
    const [unit, setUnit] = React.useState([]);
    const [selectedUnit, setSelectedUnit] = React.useState(null)
    const size = useWindowDimensions();
    const [offset, setOffSet] = React.useState(0)
    const debounce = UseDebounce();
    const [search, setSearch] = React.useState("")
    const [assetView, setAssetView] = React.useState(false)
    const [bool , setBool] = React.useState(true)

    React.useEffect(() => {
        getAllUnits({ offset: 0, limit: 10 , initial:true})
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (selectedUnit) {
            getAllProducts({ offset: 0, limit: 10 })
        }
        // eslint-disable-next-line
    }, [selectedUnit]);


    const getAllUnits = (payload) => {
        getUnit({
            limit: payload?.limit,
            offset: payload?.offset,
            is_live:true
        })
            .then((res) => {
                setUnit(res?.data?.data)
                if(!res?.data?.data?.length){
                    setBool(false)
                }
                setSelectedUnit(res?.data?.data?.[0] ?? null)
                
            })
            .catch((err)=>{
                setBool(false)
            })
    }

    const getAllProducts = (payload) => {
        setBool(true)
        getProducts({
            limit: payload?.limit,
            offset: payload?.offset,
            search: payload?.search,
            active: null,
            item_type: ["Inventory", "Product", "Services"],
            unit_id: selectedUnit?.id,
        })
            .then((res) => {
                const result = res?.data?.data?.map((x)=>{
                    return{
                        ...x,
                        agreement_unit_id:selectedUnit?.agreement_unit_id,
                        unit_name:selectedUnit?.unit_name
                    }
                })
                setBool(false)
                setProducts(payload?.filter ? products?.concat(result) : result)
            })
            .catch((err)=>{
                setBool(false)
            })
    }

    const clickedUnit = (data) => {
        setUnitSwticherDrawer(false)
        setSelectedUnit(data)
    }

    // SWITCHER DRAWER
    const unitSwitcher = () => {
        setUnitSwticherDrawer(true)
    }

    const handleUnitSwitcherDrawerClose = () => {
        setUnitSwticherDrawer(false)
    }

    // DETAILS DRAWER
    const unitDetailsDrawerOpen = () => {
        setUnitDetailsDrawer(true)
    }

    const handleUnitDetailsDrawerClose = () => {
        setUnitDetailsDrawer(false)
    }

    //ASSET DETAILS
    const assetDetails = (data) => {
        setAssetDetailsDrawer(true)
        setProductDrawer(data)
    }

    const handleAssetDetailsDrawerClose = () => {
        setAssetDetailsDrawer(false)
    }

    const fetchMoreData = () => {
        getAllProducts({
            offset: offset + 10,
            limit: 10,
            search: search ?? "",
            filter: true
        })
        getUnit({
            offset: offset + 10,
            limit: 10,
        })
        setOffSet(offset + 10);
    }

    const fetchMoreDataUnits = () => {
        getUnit({
            offset: offset + 10,
            limit: 10,
        })
        setOffSet(offset + 10);
    }

    const handleSearch = (e) => {
        setSearch(e?.target?.value)
        debounce(() => {
            getAllProducts({ offset: 0, limit: 10, search: e.target.value })
        }, 800)
    }

    // assetDrawerOpen
    const assetDrawerOpen = () => {
        setAssetView(true)
    }

    const assetDrawerClose = () => {
        setAssetView(false)
    }


    return (
        <>
            <Container
                maxWidth="sm"
                sx={chooseApplianceStyle.container}>
                {/* topNavbar */}
                <Box sx={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                        <TopNavBar noBorder isDirect title={("Choose Asset")} isBack t={t} />
                        {!bool ?
                            selectedUnit ? <Box sx={chooseApplianceStyle.root}>
                                <Box sx={chooseApplianceStyle.changerBox}>
                                    <Stack alignItems={"center"} direction={"row"} sx={chooseApplianceStyle.unitChangerBox}>
                                        <UnitChanger />
                                        <Stack flexGrow={1} marginInlineStart={"12px"} direction={"column"}>
                                            <Typography sx={chooseApplianceStyle.unitChangerText}>{selectedUnit?.unit_name}</Typography>
                                            <Typography sx={chooseApplianceStyle.unitChangersubtext}>{selectedUnit?.unit_no}</Typography>
                                        </Stack>
                                        <Typography onClick={() => unitSwitcher()} sx={chooseApplianceStyle.change}>{t("Change")}</Typography>
                                    </Stack>
                                </Box>
                            </Box> :"" :
                            <Container
                                maxWidth="sm">
                                <LoderSimmer card count={1} size={70} variant={"rectangular"} animation={"wave"} />
                            </Container>
                        }
                    </Container>
                </Box>
                {!bool ?
                selectedUnit ?
                    <>
                        <Box sx={chooseApplianceStyle?.search}>
                            <TextBox
                                paddingRight
                                sx={chooseApplianceStyle?.boxShadow}
                                placeholder={t("Search")}
                                onChange={(e) => handleSearch(e)}
                                value={search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SerachIMG />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box px={2} pb={1}>
                            <Appliance
                                chooseRequest
                                t={t}
                                greenText
                                onClick={unitDetailsDrawerOpen}
                                unit
                                unit_name={selectedUnit?.unit_name ?? "-"}
                                unit_no={selectedUnit?.unit_no ?? "-"} />
                        </Box>
                        {products?.length > 0 ?
                            <>
                                <InfiniteScroll
                                    dataLength={products?.length ?? 0}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 160}
                                >
                                    {products?.map((item) => {
                                        const temp_asset = JSON.parse(item?.assets)
                                        return (
                                            <>
                                                <Box px={2} mb={1}>
                                                    <Appliance
                                                        chooseRequest
                                                        asset={temp_asset?.[0]?.url}
                                                        location_name={item?.location_name}
                                                        t={t}
                                                        unit_name={item?.name ?? "-"}
                                                        unit_no={item?.manufaturer_name ?? "-"}
                                                        serial_number={item?.serial_number ?? "-"}
                                                        model_number={item?.model_number ?? "-"}
                                                        onClick={() => assetDetails(item)}
                                                        last_service={item?.last_service_date}
                                                        condition={item?.item_condition}
                                                        product />
                                                </Box>
                                            </>
                                        )
                                    })}
                                </InfiniteScroll> </>
                            :
                            <Box mt={2}>
                                <NoData />
                            </Box>
                        } </> : <Box sx={{textAlign:"center" , marginTop:10}}><NoDataShow/></Box> :
                    <Container
                        maxWidth="sm">
                        <Box p={2}>
                            <LoderSimmer card count={8} size={100} variant={"rectangular"} animation={"wave"} />
                        </Box>
                    </Container>}
            </Container>

            {/* SWITCHER DRAWER */}
            <DialogDrawer
                maxWidth="sm"
                open={unitSwitcherDrawer}
                onClick={handleUnitSwitcherDrawerClose}
                header={t("Unit Swithcer")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Box sx={chooseApplianceStyle.switchDrawerBox}>
                            <InfiniteScroll
                                dataLength={unit?.length ?? 0}
                                next={fetchMoreDataUnits}
                                hasMore={true}
                                height={size?.height - 160}
                            >
                                {unit?.map((item, index) => {
                                    return (
                                        <>
                                            <Stack onClick={() => clickedUnit(item)} direction={"row"}>
                                                <PurpleUnit />
                                                <Stack flexGrow={1} marginInlineStart={"12px"} direction={"column"}>
                                                    <Typography sx={chooseApplianceStyle.applianceText}>{item?.unit_name}</Typography>
                                                    <Stack width={"80%"} alignItems={"center"} divider={<Divider sx={chooseApplianceStyle.divider} />} direction={"row"}>
                                                        <Typography sx={chooseApplianceStyle.unitChangersubtext}>{item?.unit_no}</Typography>
                                                        <Typography noWrap sx={chooseApplianceStyle.unitChangersubtext}>{item?.property_name}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Radio
                                                    checked={selectedUnit?.id === item?.id} />
                                            </Stack>
                                            {index !== unit.length - 1 && <Divider sx={chooseApplianceStyle.horizontalDivider} />}
                                        </>
                                    )
                                })}
                            </InfiniteScroll>
                        </Box>
                    </>
                }
                onClose={() => handleUnitSwitcherDrawerClose()}
            />
            {/* DETAILS DRAWER */}
            <DialogDrawer
                headerStyle={{ border: "none !important", padding: "16px 24px 0px 24px !important" }}
                maxWidth="sm"
                open={unitDetailsDrawer}
                onClick={handleUnitDetailsDrawerClose}
                header={t("Unit Details")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Box textAlign={"center"}>
                            <Box sx={chooseApplianceStyle.image}>
                                <GreyUnit />
                            </Box>
                            <Typography sx={chooseApplianceStyle.detailsDrawerText}>{selectedUnit?.unit_name}</Typography>
                            <Typography sx={chooseApplianceStyle.detailsDrawersubtext}>{selectedUnit?.unit_no}</Typography>
                            <Box sx={{ zIndex: 1, position: "relative" }}>
                            <UnitAssetInfo />
                            </Box>
                            <Box sx={chooseApplianceStyle.example} mt={-2}>
                                <Typography sx={chooseApplianceStyle.exampleText}>{t("With this type you can raise maintenance request")}</Typography>
                                <Typography sx={chooseApplianceStyle.exampleText}>{t("against any unit level problem")}</Typography>
                                <Typography mt={1} sx={chooseApplianceStyle.exampleText}>{t("Ex: Water leakage, Switch etc")}</Typography>
                            </Box>
                        </Box>
                    </>
                }
                footer={
                    <>
                        < FooterComponent
                            single={true}
                            containedText={t("Raise Request")}
                            containedonClick={() => navigate(AppRoutes.maintanceRequest,
                                {
                                    state: {
                                        unit: selectedUnit
                                    }
                                })}
                        />

                    </>
                }
                onClose={() => handleUnitDetailsDrawerClose()}
            />
            {/* ASSET DETAILS DRAWER */}
            <DialogDrawer
                headerStyle={{ border: "none !important", padding: "16px 24px 0px 24px !important" }}
                maxWidth="sm"
                open={assetDetailsDrawer}
                onClick={handleAssetDetailsDrawerClose}
                header={t("Assets Details")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Box textAlign={"center"} mb={"20px"}>
                            {productDrawer?.assets ? 
                            <Avatar onClick={assetDrawerOpen} sx={chooseApplianceStyle?.realImage} src={JSON.parse(productDrawer.assets)[0]?.url} /> :
                                <Box sx={chooseApplianceStyle.image}>
                                    <GreyUnit />
                                </Box>}
                            <br />
                            {productDrawer?.item_condition !== null &&
                                <Box sx={chooseApplianceStyle.condition}>
                                    <Typography sx={chooseApplianceStyle.exampleText}>
                                        {productDrawer?.item_condition}
                                    </Typography>
                                </Box>
                            }
                            <Typography sx={chooseApplianceStyle.detailsDrawerText}>{productDrawer?.name}</Typography>
                        </Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={chooseApplianceStyle.assetText}>{t("Manufacturer")}</Typography>
                            <Typography sx={chooseApplianceStyle.assetSubtext}>{productDrawer?.manufaturer_name ?? "-"}</Typography>
                        </Stack>
                        <Divider sx={chooseApplianceStyle.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={chooseApplianceStyle.assetText}>{t("Model Number")}</Typography>
                            <Typography sx={chooseApplianceStyle.assetSubtext}>{productDrawer?.model_number ?? "-"}</Typography>
                        </Stack>
                        <Divider sx={chooseApplianceStyle.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={chooseApplianceStyle.assetText}>{t("Serial Number")}</Typography>
                            <Typography sx={chooseApplianceStyle.assetSubtext}>{productDrawer?.serial_number ?? "-"}</Typography>
                        </Stack>
                        <Divider sx={chooseApplianceStyle.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={chooseApplianceStyle.assetText}>{t("Last Service")}</Typography>
                            <Typography sx={chooseApplianceStyle.assetSubtext}>{productDrawer?.last_service_date === null ? "-" : moment(productDrawer?.last_service_date).format("DD-MMM-YY")}</Typography>
                        </Stack>
                    </>
                }
                footer={
                    <>
                        < FooterComponent
                            single={true}
                            containedText={"Raise Request"}
                            containedonClick={() => navigate(AppRoutes.maintanceRequest,
                                {
                                    state: {
                                        product: productDrawer,
                                    }
                                })}
                        />

                    </>
                }
                onClose={() => handleAssetDetailsDrawerClose()}
            />
            {/* ASSET VIEW DRAWER */}
            <DialogDrawer
                maxWidth="sm"
                open={assetView}
                header={t("Images")}
                padding={"16px 16px 0px"}
                height_style={
                    { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                }
                component={
                    <>
                        <Slider
                            // title={t("Images")}
                            height={"100% !important"}
                            borderRadius={"4px"}
                            imageHeigth={"300px !important"}
                            assets={productDrawer.assets ? JSON.parse(productDrawer.assets)?.map((x) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    url: x?.url,
                                }
                            }) : ""}
                            onClose={() => assetDrawerClose()}
                        />
                    </>
                }

                onClose={() => assetDrawerClose()} 
                />
        </>
    )
}
