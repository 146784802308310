import * as React from "react"
const EMIIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50.03} height={50} {...props}>
    <defs>
      <filter
        id="a"
        width={50.03}
        height={50}
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 138290">
      <path
        fill="#eff5e6"
        d="M23 1h4a22 22 0 0 1 22 22v20a6 6 0 0 1-6 6H7a6 6 0 0 1-6-6V23A22 22 0 0 1 23 1Z"
        data-name="Rectangle 58896"
      />
      <g transform="translate(9 9)">
        <g filter="url(#a)" transform="translate(-9 -9)">
          <path
            fill="#91bb51"
            d="M27.293 10.017a3.057 3.057 0 0 0 3.189.878l.127-.04a3.058 3.058 0 0 1 3.9 2.25l.017.075a3.059 3.059 0 0 0 2.311 2.32l.088.02a3.058 3.058 0 0 1 2.253 3.88l-.046.148a3.056 3.056 0 0 0 .875 3.171l.013.011a3.057 3.057 0 0 1 0 4.542l-.013.011a3.059 3.059 0 0 0-.875 3.171l.046.148a3.057 3.057 0 0 1-2.258 3.883l-.088.02a3.059 3.059 0 0 0-2.312 2.315l-.017.075a3.058 3.058 0 0 1-3.9 2.25l-.127-.04a3.059 3.059 0 0 0-3.189.878 3.057 3.057 0 0 1-4.554 0 3.057 3.057 0 0 0-3.189-.878l-.127.04a3.058 3.058 0 0 1-3.9-2.25l-.007-.075a3.059 3.059 0 0 0-2.315-2.32l-.088-.019a3.058 3.058 0 0 1-2.252-3.881l.046-.148a3.056 3.056 0 0 0-.875-3.171l-.013-.011a3.057 3.057 0 0 1 0-4.542l.013-.011a3.059 3.059 0 0 0 .875-3.171l-.046-.146a3.057 3.057 0 0 1 2.253-3.884l.088-.02a3.059 3.059 0 0 0 2.315-2.315l.017-.075a3.058 3.058 0 0 1 3.9-2.25l.127.04a3.059 3.059 0 0 0 3.189-.878 3.056 3.056 0 0 1 4.549-.001Z"
            data-name="Path 123716"
          />
        </g>
        <circle
          cx={12.191}
          cy={12.191}
          r={12.191}
          fill="#c0d89b"
          data-name="Ellipse 131002"
          transform="translate(3.824 3.809)"
        />
        <path
          fill="#4c7015"
          d="M12.246 16.528H9.919v2.011h2.764v1.27h-4.3v-7.583h4.285v1.276H9.919v1.792h2.327Z"
          data-name="Path 123717"
        />
        <path
          fill="#4c7015"
          d="m15.754 12.226 1.432 5.484 1.427-5.484h2v7.583h-1.535v-2.052l.141-3.161-1.517 5.213h-1.041l-1.515-5.214.141 3.161v2.053h-1.532v-7.583Z"
          data-name="Path 123718"
        />
        <path
          fill="#4c7015"
          d="M23.634 19.809H22.11v-7.583h1.524Z"
          data-name="Path 123719"
        />
      </g>
    </g>
  </svg>
)
export default EMIIcon
