import { Bold, Regular, SemiBold } from "../../utils";

export const ToolTipCustom_style = {

    sx:{
        backgroundColor:'primary.contrastText',
        height:'auto',
        width:'auto',
        padding:'12px',
        borderRadius:'12px',
        boxShadow: "0px 3px 30px #5C86CB2E",
        zIndex:1
    },
    amt:{
        fontFamily:Bold,
        fontSize:"0.75rem",
    },
    text:{
        fontFamily: Regular,
        fontSize:"0.75rem",

    },
    dot: {
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        padding: '4px',
       // margin: '5px'
    },
    category:{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"0.75rem",
        paddingLeft: '7.5px'
    },
    pieName:{
        color:"#4E5A6B",
        fontSize: "0.75rem",
        fontFamily:SemiBold,

    },
    amount:{
        color:'#091B29',
        fontSize: "0.75rem",
        fontFamily:SemiBold,
    }
}