import { FooterComponentStyles } from "./style"
import { Stack, Typography, Button, Box } from "@mui/material";
import animation from '../../assets/buttonlader.json'
import Lottie from "react-lottie";

export const FooterComponent = ({
  containedonClick = () => false,
  outlinedonClick = () => false,
  outlinedText = "",
  containedText = "",
  single = false,
  border = true,
  btn_styles = {},
  custom_styles=false,
  outlinedDisabled = false,
  containedDisabled = false,
  btnLoading = false,
  padding,
  backgroundColor,
  contained,
  isDisableBtn=false

}) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    // here is where we will declare lottie animation
    // "animation" is what we imported before animationData: animation,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (

    <Box sx={border ? FooterComponentStyles?.root : FooterComponentStyles?.root1}
      style={{ padding: padding, backgroundColor: backgroundColor }}>
      {single ?
        // single btn
        <Stack >
          <Button sx={{ ...FooterComponentStyles?.containedBtn, ...btn_styles , marginInlineStart:"0px !important" }}
            variant="contained"
            onClick={btnLoading ? () => false : containedonClick}>{btnLoading ? <Lottie options={defaultOptions} height={30} width={90} /> : <Typography noWrap>{containedText}</Typography>}
          </Button>
        </Stack > :
        // dual btn
        <Stack direction={"row"} justifyContent={"space-between"} >
          <Button
            disabled={outlinedDisabled} sx={custom_styles?FooterComponentStyles?.customOutlinedBtn:FooterComponentStyles?.outlinedBtn} variant="outlined" onClick={outlinedonClick}>
            <Typography noWrap>{outlinedText}</Typography>
          </Button>
          <Button disabled={containedDisabled || isDisableBtn} sx={{...FooterComponentStyles?.containedBtn , ...contained}} variant="contained" onClick={containedonClick} loading={btnLoading}>
            {btnLoading ?
              <Lottie options={defaultOptions} height={30} width={90} /> :
              <Typography noWrap>{containedText}</Typography>}
          </Button>
        </Stack>
      }




    </Box >




  );
}
