import { Box, Typography } from "@mui/material";
import React, { forwardRef } from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from '../label'
import { useStyles } from "./style";
import { Calander } from '../../assets'

export const DatePickerNew = (props) => {
      const classes = useStyles(props)
      // const language = localStorage.getItem("i18nextLng")
      const CustomInput = forwardRef(({ value, onClick }, ref) => (
            
            <div className={classes.Datepicker}
                  onClick={onClick} ref={ref}>
                  <Typography className={value?classes.input:classes.placeholderCustom} >
                        &#x202a;{value ? value : props?.placeholder}&#x202c;
                  </Typography>
                  <div style={{ display: "flex", alignItems: "end" }}>
                        <Calander/>
                  </div>
            </div>
      ));

      return (
            <>
                  <div style={{ position: 'relative' }}>
                        {props?.label && <Label label={props?.label} labelStyle={props?.labelStyle} isRequired={props?.isRequired} />}
                        <Box sx={props?.sx}>
                              <Datepicker
                                    timeInputLabel="Time:"
                                    dateFormat={props?.isTime ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyy"}
                                    showTimeInput={props?.isTime}
                                    selected={props?.value}
                                    onChange={date => props?.onChange(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className={{ ...classes.textbox, ...props?.customStyle }}
                                    // style={props?.customStyle}
                                    placeholderText={props?.placeholder}
                                    minDate={props?.minDate ?? false}
                                    maxDate={props?.maxDate ?? false}
                                    onKeyDown={(e) => {
                                          e.preventDefault();
                                    }}
                                    disabled={props?.isReadonly}
                                    onFocus={(e) => e.target.readOnly = true}
                                    popperPlacement={props?.placement ?? 'auto-start'}
                                    popperProps={{ strategy: 'fixed' }}
                                    customInput={<CustomInput />}
                                    calendarIcon={ <Calander />}
                                    

                              />
                              {/* <div className={language === "ar" ? classes.calendarArabic : classes.calander}>
                                    <Calander />
                              </div> */}
                        </Box>
                  </div>
                  {props.isError && (
                        <Typography variant={"caption"} color={"error"} noWrap className={classes.calendarText}>
                              {props.errorMessage}
                        </Typography>
                  )}
            </>
      )
}