import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { PropertyUnitNewStyle } from './style'
import EMIIcon from './utils/emiIcon'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineItemClasses } from '@mui/lab'
import PaymentTimeLineIcon from './utils/paymentTimelineIcon'
import { useLazyGetAllPaymentQuery } from '../../redux/services'
import moment from 'moment'
import InvoiceIcon from './utils/invoiceIcon'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DialogDrawer, DocumentViewer, LoderSimmer } from '../../components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { config } from '../../config'
import { NetWorkCallMethods, NetworkCall } from '../../utils'
import NoDataShow from '../../assets/noData'

export const PaymentPlan = ({
    agreement = "",
    t = () => false
}) => {
    const classes = PropertyUnitNewStyle
    const language = localStorage.getItem("i18nextLng")
    const [selectedTab, setSelectedTab] = React.useState(1)
    const [paymentData, setPaymentData] = React.useState({
        initialData: "",
        data: []
    })
    const [paymentDetail, setPaymentDetail] = React.useState({
        bool: false,
        data: ""
    })
    const [invoiceData, setInvoiceData] = React.useState({
        bool: false,
        data: ""
    })
    const [offSet, setOffSet] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const tab = [
        {
            label: t("Upcoming"),
            value: 1
        },
        {
            label: t("Previous"),
            value: 2
        }
    ]

    const [getPaymentPlan] = useLazyGetAllPaymentQuery()
    React.useEffect(() => {
        setSelectedTab(1)
        getPaymentPlanData(0, false, "future")
        // eslint-disable-next-line
    }, [])

    const getPaymentPlanData = async (offset = 0, bool = false, dataType = "future") => {
        if (!bool) { setLoading(true) }
        const response = await getPaymentPlan({
            agreement_id: agreement?.id,
            date_type: dataType,
            limit: 10,
            offset: offset
        })
        if (bool) {
            setPaymentData({
                ...paymentData,
                data: [
                    ...paymentData?.data,
                    ...response?.data?.data?.quotation_payment_schedule,
                ]
            })
        } else {
            if (dataType === "future") {
                setPaymentData({
                    ...paymentData,
                    initialData: response?.data?.data?.quotation_payment_schedule?.[0],
                    data: response?.data?.data?.quotation_payment_schedule?.filter((e, i) => i !== 0)
                })
            } else {
                setPaymentData({
                    ...paymentData,
                    data: response?.data?.data?.quotation_payment_schedule?.filter((e, i) => i !== 0)
                })
            }
            await setLoading(false)
        }
    }

    const getInvoicePdfData = async (id) => {
        setInvoiceData({
            bool: true,
            data: ""
        })
        const payload = {
            "invoice_id": id,
        }
        NetworkCall(
            `${config.api_url}invoice/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setInvoiceData({
                bool: true,
                data: res?.data ?? ""
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    const fetchMoreData = () => {
        getPaymentPlanData(offSet + 10, true, selectedTab === 2 ? "past" : "future")
        setOffSet(offSet + 10);
    };

    const handleChangeTab = (value) => {
        setSelectedTab(value)
        getPaymentPlanData(0, false, value === 2 ? "past" : "future")
        setOffSet(0);
    }
    return (
        <>

            <Box p={"24px 16px 16px 16px"} sx={{ height: "550px", overflow: "hidden" }}>
                <Stack sx={{ ...classes.propertyBox, position: "relative" }} alignItems={"center"} justifyContent={"center"}>
                    <Typography sx={classes.upcomingText}>{t("Upcoming")}</Typography>
                    <Box mt={2}>
                        <EMIIcon />
                    </Box>
                    <Stack spacing={"4px"} alignItems={"center"} mt={1}>
                        <Typography sx={classes.boldTitle}>{paymentData?.initialData?.total} {paymentData?.initialData?.currency?.symbol}</Typography>
                        <Typography sx={classes.tertiaryTextReg}>{t("Schedule Amount")}</Typography>
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} mt={"12px"} columnGap={1}>
                        <Typography sx={classes.secItalicText}>{t("Scheduled on")} <span style={classes.iconText}>{moment(paymentData?.initialData?.due_date).format("DD MMM YY")}</span></Typography>
                        <Box sx={classes.dot}></Box>
                        <Typography sx={classes.secItalicText}>{paymentData?.initialData?.description}</Typography>
                    </Stack>

                    <Typography sx={classes.viewallText} mt={"12px"}
                        onClick={() => setPaymentDetail({
                            bool: true,
                            data: paymentData?.initialData
                        })}>{t("View Details")}</Typography>
                </Stack>

                <Grid container sx={classes.tabBox} mt={2}>
                    {
                        tab?.map((e) => {
                            return (
                                <Grid item xs={6}>
                                    <Typography sx={selectedTab === e?.value ? classes.selectedTab : classes.unSelectedTab}
                                        onClick={() => handleChangeTab(e?.value)}>
                                        {e?.label}
                                    </Typography>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Box>
                    <Timeline
                        sx={{
                            paddingRight: "0px !important",
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                        }}
                    >{
                            loading ?
                                <>
                                    {
                                        [1, 2, 3].map((e, i, length) => {
                                            return (
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineDot sx={{
                                                            margin: "0px !important",
                                                            boxShadow: "none",
                                                            padding: "0px !important",
                                                            background: "transparent !important"
                                                        }}>
                                                            <PaymentTimeLineIcon />
                                                        </TimelineDot>
                                                        {length?.length - 1 !== i && <TimelineConnector sx={{ border: "2px dashed #CED3DD !important", background: "none" }} />}
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ padding: "0px 16px 6px 16px" }}>
                                                        <Stack spacing={"4px"}>
                                                            <LoderSimmer card count={1} width={"140px"} size={12} variant={"rounded"} animation={"wave"} />
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                <LoderSimmer card count={1} width={"140px"} size={12} variant={"rounded"} animation={"wave"} />
                                                                {/* <Box sx={classes.dot}></Box> */}
                                                                <LoderSimmer card count={1} width={"140px"} size={12} variant={"rounded"} animation={"wave"} />
                                                            </Stack>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={1} mb={2}>
                                                            <LoderSimmer card count={1} width={"140px"} size={12} variant={"rounded"} animation={"wave"} />
                                                            <LoderSimmer card count={1} width={"140px"} size={12} variant={"rounded"} animation={"wave"} />
                                                        </Stack>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            )
                                        })
                                    }
                                </>
                                :
                                <InfiniteScroll
                                    dataLength={paymentData?.data?.length ?? 0}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={220}
                                >
                                    { paymentData?.data?.length > 0 &&
                                        paymentData?.data?.map((e, i, length) => {
                                            return (
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineDot sx={{
                                                            margin: "0px !important",
                                                            boxShadow: "none",
                                                            padding: "0px !important",
                                                            background: "transparent !important"
                                                        }}>
                                                            <PaymentTimeLineIcon />
                                                        </TimelineDot>
                                                        {length?.length - 1 !== i && <TimelineConnector sx={{ border: "2px dashed #CED3DD !important", background: "none" }} />}
                                                    </TimelineSeparator>
                                                    {
                                                        language === "ar" ?
                                                            <TimelineOppositeContent>
                                                                <Typography sx={(e?.invoice?.length > 0 && e?.invoice?.[0]?.payment_status === "due") ? { ...classes.paidText, width: language === "ar" ? "40px" : "34px" } : { ...classes.unpaidText, width: language === "ar" ? "58px" : "52px" }} mb={1}>{(e?.invoice?.length > 0 && e?.invoice?.[0]?.payment_status === "due") ? t("Paid") : t("Unpaid")}</Typography>
                                                                <Stack spacing={"4px"}>
                                                                    <Typography sx={classes.secTitle}>{e?.total} {e?.currency?.symbol}</Typography>
                                                                    <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                                        <Typography sx={classes.secText}>{t("Scheduled on")} {moment(e?.due_date).format("DD MMM YY")}</Typography>
                                                                        <Box sx={classes.dot}></Box>
                                                                        <Typography sx={classes.secText}>{e?.schedule_no}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={1} mb={2}>
                                                                    <Typography sx={classes.viewallText}
                                                                        onClick={() => setPaymentDetail({
                                                                            bool: true,
                                                                            data: e
                                                                        })}
                                                                    >{t("View Details")}</Typography>
                                                                    {(e?.status === "Posted") && <Typography sx={classes.viewallText} onClick={() => getInvoicePdfData(e?.invoice?.[0]?.id)}>{t("View Invoice")}</Typography>}
                                                                </Stack>
                                                            </TimelineOppositeContent>
                                                            :
                                                            <TimelineContent sx={{ padding: "0px 10px 6px 16px" }}>
                                                                <Typography sx={(e?.invoice?.length > 0 && e?.invoice?.[0]?.payment_status === "due") ? classes.paidText : classes.unpaidText} mb={1}>{(e?.invoice?.length > 0 && e?.invoice?.[0]?.payment_status === "due") ? t("Paid") : t("Unpaid")}</Typography>
                                                                <Stack spacing={"4px"}>
                                                                    <Typography sx={classes.secTitle}>{e?.total} {e?.currency?.symbol}</Typography>
                                                                    <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                                        <Typography sx={classes.secText}>{t("Scheduled on")} {moment(e?.due_date).format("DD MMM YY")}</Typography>
                                                                        <Box sx={classes.dot}></Box>
                                                                        <Typography sx={{ ...classes.secText, ...classes.nameWidth }}>{e?.description}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={1} mb={2}>
                                                                    <Typography sx={classes.viewallText}
                                                                        onClick={() => setPaymentDetail({
                                                                            bool: true,
                                                                            data: e
                                                                        })}
                                                                    >{t("View Details")}</Typography>
                                                                    {(e?.status === "Posted") && <Typography sx={classes.viewallText} onClick={() => getInvoicePdfData(e?.invoice?.[0]?.id)}>{t("View Invoice")}</Typography>}
                                                                </Stack>
                                                            </TimelineContent>

                                                    }
                                                </TimelineItem>
                                            )
                                        })
                                    }
                                    {
                                        paymentData?.data?.length === 0 &&
                                        <center><NoDataShow /></center>
                                    }
                                </InfiniteScroll>
                        }
                    </Timeline>
                </Box>
            </Box>
            {console.log("paymentDetail ==>", paymentDetail)}
            <DialogDrawer
                maxWidth="sm"
                open={paymentDetail?.bool}
                header={t("Details")}
                padding="0px"
                onClose={() => setPaymentDetail({
                    bool: false,
                    data: ""
                })}
                height={paymentDetail?.data?.status === "Unposted" ? "300px" : "380px"}
                component={
                    <>
                        <Box p={2}>
                            <Stack spacing={2}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack spacing={"4px"}>
                                        <Typography sx={classes.unitTitle}>{paymentDetail?.data?.total} {paymentDetail?.data?.currency?.symbol}</Typography>
                                        <Typography sx={classes.secText}>{t("Scheduled on")} {moment(paymentDetail?.data?.due_date).format("DD MMM YY")}</Typography>
                                    </Stack>
                                    <Typography sx={(paymentDetail?.data?.invoice?.length > 0 && paymentDetail?.data?.invoice?.[0]?.payment_status === "due") ? classes.paidText : { ...classes.unpaidText, width: language === "ar" ? "58px" : "52px" }}>
                                        {(paymentDetail?.data?.invoice?.length > 0 && paymentDetail?.data?.invoice?.[0]?.payment_status === "due") ? t("Paid") : t("Unpaid")}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.secText}>{t("Schedule ID")}</Typography>
                                    <Typography sx={classes.iconText}>{paymentDetail?.data?.schedule_no ?? "-"}</Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.secText}>{t("Description")}</Typography>
                                    <Typography sx={{ ...classes.iconText, whiteSpace: "normal", width: "200px", textAlign: language === "ar" ? "left" : "right" }}>{paymentDetail?.data?.description ?? "-"}</Typography>
                                </Stack>
                            </Stack>

                            <Box py={2}><Divider></Divider></Box>
                            <Typography sx={classes.contactText} mb={"12px"}>{t("Post Dated Cheque")}</Typography>
                            <Stack spacing={2}>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.secText}>{t("Cheque No")}</Typography>
                                    <Typography sx={classes.iconText}>{paymentDetail?.data?.cheque_name ?? "-"}</Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.secText}>{t("Date")}</Typography>
                                    <Typography sx={classes.iconText}>{moment(paymentDetail?.data?.created_at).format("DD MMM YY")}</Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography sx={classes.secText}>{t("Bank")}</Typography>
                                    <Typography sx={classes.iconText}>{paymentDetail?.data?.bank_name ?? "-"}</Typography>
                                </Stack>
                            </Stack>


                            {
                                (paymentDetail?.data?.status === "Posted") &&
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={classes.propertyBox} mt={"20px"}
                                    onClick={() => getInvoicePdfData(paymentDetail?.data?.invoice?.[0]?.id)}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                        <InvoiceIcon />
                                        <Typography sx={classes.propertyTitle}>{t("View Invoice")}</Typography>
                                    </Stack>
                                    <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                </Stack>
                            }
                        </Box>
                    </>
                }
            />


            <DialogDrawer
                maxWidth="sm"
                open={invoiceData?.bool}
                header={t("View Invoice")}
                padding="0px"
                onClose={() => setInvoiceData({
                    bool: false,
                    data: ""
                })}
                height={"500px"}
                component={
                    <Box p={2}>
                        {
                            invoiceData?.data?.length === 0 ? <LoderSimmer card variant={"rounded"} width={"auto"} size={340} count={1} /> :
                                <DocumentViewer url={`data:application/pdf;base64,${invoiceData?.data}` ?? ""} />}
                    </Box>
                }
            />
        </>
    )
}
