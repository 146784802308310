import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, Button, Container, Divider, Stack, Typography } from "@mui/material";
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React from "react";
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import AgreementDetails from "../../assets/agreementDetails";
import { DialogDrawer, DocumentViewer, FileViewers, FooterComponent, FormGenerator, Loader, LoderSimmer, TopNavBar } from "../../components";
import { LandlordApprovalRequest } from "../../components/landlordApproval";
import NoData from "../../components/noData";
import { useLazyAgreementTemplateQuery, useLazyGetApprovalDetailsQuery, useLazySubmitApprovalQuery } from '../../redux/services';
import { SemiBold, stringAvatar } from '../../utils';
import ImageAndPdfViewer from "../requestView/components/imageAndPdfViewer";
import { landlordApprovalstyle } from "./style";
const LandlordApproval = ({
    t = () => false
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [declineDrawer, setDeclineDrawer] = React.useState(false)
    const [acceptDrawer, setAcceptDrawer] = React.useState(false)
    const [isView, setIsView] = React.useState({
        bool: false,
        data: ""
    })
    const [pdf, setPdf] = React.useState({
        pdf: "",
        bool: false
    })
    const sigCanvas = React.useRef({});
    const state = useLocation().state;
    const [imageViewer, setImageViewer] = React.useState({ bool: false, data: [] })
    const [data, setData] = React.useState({})
    const [getApprovalDetails] = useLazyGetApprovalDetailsQuery()
    const [getTemplate] = useLazyAgreementTemplateQuery()
    const [onSubmit] = useLazySubmitApprovalQuery()
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    const getLandLordApprovalDetails = async () => {

        const payload = {
            id: state?.id
        }
        const response = await getApprovalDetails(payload)
        setLoading(false)
        setData(response?.data?.data?.[0])
    }

    React.useEffect(() => {
        getLandLordApprovalDetails()
        //eslint-disable-next-line
    }, [])

    const updateState = (k, v) => {
        setData({ ...data, [k]: v })
    }
    // DECLINE DRAWER
    const outlinedonClick = () => {
        setDeclineDrawer(true)
    }

    const handleDeclineDrawerClose = () => {
        setDeclineDrawer(false)
    }

    // ACCEPT DRAWER
    const containedonClick = () => {
        setAcceptDrawer(true)
    }

    const handleAcceptDrawerClose = () => {
        setAcceptDrawer(false)
    }
    const filterdraweropen = async () => {
        await setPdf({
            ...pdf, bool: true, open: true
        });
        const pdfFile = await getTemplate({
            "id": state?.agreement_id,
            type_master_id:data?.type_master_id??undefined
        })
        if (pdfFile?.error?.originalStatus === 200) {
            await setPdf({
                ...pdf, bool: false, pdf: pdfFile?.error?.data, open: true
            });
        }
        else {
            await setPdf({
                ...pdf, bool: false, pdf: ""
            });
        }
    };
    const filterdrawerclose = () => {
        setPdf({
            ...pdf, open: false
        });
    };
    const onApproval = (type) => {
        if (sigCanvas.current.isEmpty()) {
            enqueueSnackbar(t(`Please fill Signature`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        }
        else {
            onSubmitRequest(type)
        }
    }
    const onSubmitRequest = async (request_type) => {
        let signature = request_type === "Approved" ? sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") : null
        let payload = {
            "id": state?.id,
            "status": request_type,
            "remarks": data?.remarks,
            "signature": signature,
        }
        const response = await onSubmit(payload)
        if (response?.data?.type === "success") {
            setIsDisableBtn(false)
            enqueueSnackbar(t(`${request_type} Successfully`), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            getLandLordApprovalDetails()
            if (request_type === "Approved") {
                handleAcceptDrawerClose()
            }
            else {
                handleDeclineDrawerClose()
            }
            return setTimeout(() => {
                closeSnackbar()
            }, 2000)
        } else {
            setIsDisableBtn(false)
            enqueueSnackbar(t('Something went wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            return setTimeout(() => {
                closeSnackbar()
            }, 2000)
        }
    }
    const handleClear = () => {
        sigCanvas.current.clear();
        // handleAcceptDrawerClose()
    }
    const onDecline = (type) => {
        onSubmitRequest(type)
    }
    console.log(imageViewer, "vvvv")
    return (
        <>
            <Container
                maxWidth="sm"
                sx={landlordApprovalstyle.container}>
                {/* topNavbar */}
                <Box sx={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                        <TopNavBar isDirect title={data?.agreements?.agreement_no} isBack t={t} />
                    </Container>
                </Box>
                {/* BODY */}
                {loading ? <Loader />
                    :
                    <>
                        <Box sx={landlordApprovalstyle.root}>
                            <LandlordApprovalRequest wrapAddress={true} data={{ ...state, status: data?.status, updated_at: data?.updated_at, remarks: data?.remarks }} t={t} isDetails={true} />
                            <Box sx={landlordApprovalstyle.detailsBox}>
                                {/* <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("CONTRACT DETAILS")}</Typography> */}
                                {/* <Box sx={landlordApprovalstyle.contractValueBox}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <ContractValue />
                                <Typography sx={landlordApprovalstyle.contractValueText}>{t("Contract Value")}</Typography>
                            </Stack>
                            <Typography sx={landlordApprovalstyle.contractValue}>$1,100 / Month</Typography>
                        </Stack>
                    </Box> */}
                                {/* CONTRACT DETAILS */}
                                {/* <Stack mt={"12px"} direction={"row"} alignItems={"center"}>
                            <ContractDetails />
                            <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                                <Typography sx={landlordApprovalstyle.detailsText}>CNT-123456</Typography>
                                <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                    <Typography sx={landlordApprovalstyle.detailsSubtext}>ACC-12412412</Typography>
                                    <Typography sx={landlordApprovalstyle.detailsSubtext}>22 Jan 22 - 22 Jan 23</Typography>
                                </Stack>
                            </Stack>
                        </Stack> */}
                                {/* <Divider sx={landlordApprovalstyle.lineDivider} /> */}
                                {/* AGREEMENT DETAILS */}
                                <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("AGREEMENT DETAILS")}</Typography>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack mt={"12px"} direction={"row"} alignItems={"center"}>
                                        <AgreementDetails />
                                        <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                                            <Typography sx={landlordApprovalstyle.detailsText}>{data?.agreements?.agreement_no}</Typography>
                                            <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                                <Typography sx={landlordApprovalstyle.detailsSubtext}>{data?.agreements?.agreement_type}</Typography>
                                                <Typography sx={landlordApprovalstyle.detailsSubtext} noWrap>{`${moment(data?.agreements?.lease_start_date).format("DD-MMM-YY")} - ${moment(data?.agreements?.lease_end_date).format("DD-MMM-YY")}`}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={() => filterdraweropen()}>
                                        <VisibilityIcon sx={{ color: "#F15A29" }} />
                                    </Box>
                                </Box>
                                <Divider sx={landlordApprovalstyle.lineDivider} />
                                {/* TENANT DETAILS */}
                                <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("CONTACT")}</Typography>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack mt={"12px"} direction={"row"} alignItems={"center"} width={"90%"}>
                                        <Avatar src={data?.agreements?.contact?.image_url} sx={landlordApprovalstyle.avatar} {...stringAvatar(data?.agreements?.contact?.first_name ?? null)}/>
                                        <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                                            <Typography sx={landlordApprovalstyle.detailsText}>{data?.agreements?.contact?.first_name}</Typography>
                                            <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                                <Typography sx={landlordApprovalstyle.detailsSubtext}>{data?.agreements?.contact?.mobile_no_country_code} {data?.agreements?.contact?.mobile_no}</Typography>
                                                <Typography sx={landlordApprovalstyle.detailsSubtext} noWrap>{data?.agreements?.contact?.email_id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={() => setIsView({ bool: true, data: {} })}>
                                        <VisibilityIcon sx={{ color: "#F15A29" }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {data?.status === null &&
                            <Box sx={{ position: "fixed", bottom: "0px", left: 0, right: 0, zIndex: 999 }}>
                                <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                                    <FooterComponent
                                        outlinedText={t("Decline")}
                                        outlinedonClick={outlinedonClick}
                                        containedText={t("Approve")}
                                        containedonClick={containedonClick}
                                        custom_styles={true}
                                    />
                                </Container>
                            </Box>
                        }
                    </>
                }
            </Container>
            <DialogDrawer
                maxWidth="sm"
                open={declineDrawer}
                onClick={handleDeclineDrawerClose}
                header={t("Decline Remarks")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Typography sx={landlordApprovalstyle.drawerText}>{t("Remarks")}</Typography>
                        <FormGenerator
                            components={[
                                {
                                    isActive: true,
                                    component: "text",
                                    // label: t("Problem Description"),
                                    value: data?.remarks,
                                    isRequired: false,
                                    placeholder: t("Remarks"),
                                    onChange: (e) => updateState("remarks", e?.target?.value),
                                    // error: data?.error?.problem_Description,
                                    multiline: true,
                                    size: {
                                        xs: 12,
                                    },
                                },
                            ]} />
                    </>
                }
                footer={
                    <>
                        <FooterComponent
                            single={true}
                            containedText={t("Submit")}
                            padding={"8px"}
                            containedonClick={() => onDecline("Declined")}
                        />
                    </>
                }
                onClose={() => handleDeclineDrawerClose()}
            />
            <DialogDrawer
                maxWidth="sm"
                open={acceptDrawer}
                onClick={handleAcceptDrawerClose}
                header={t("Approval Signature")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <Box position={"relative"}>
                        <Typography sx={landlordApprovalstyle.drawerText}>{t("Signature")}</Typography>
                        <Box position="relative" mt={2} sx={{ border: "1px solid #E4E8EE", borderRadius: "8px" }}>
                            <SignaturePad
                                canvasProps={
                                    {
                                        height: 400,
                                        width: 360
                                    }
                                }
                                ref={sigCanvas}
                                backgroundColor="white"
                                penColor="black"

                            />
                            <center>
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        onClick={handleClear}
                                        sx={landlordApprovalstyle.clearBtn}>{t("Clear")}</Button>
                                </Box>
                            </center>
                        </Box>
                    </Box>
                }
                footer={
                    <>
                        <FooterComponent
                            single={true}
                            padding={"8px"}
                            containedText={t("Submit")}
                            isDisableBtn={isDisableBtn}
                            containedonClick={() => onApproval("Approved")}
                        />
                    </>
                }
                onClose={() => handleAcceptDrawerClose()}
            />
            <DialogDrawer
                maxWidth="sm"
                open={isView?.bool}
                onClose={() => setIsView({ bool: false, data: "" })}
                header={t("View")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <Box>
                        <Box sx={landlordApprovalstyle?.contactDetails}>
                            <Stack direction={"row"} spacing={1} alignItems="center" p={2}>
                                <Avatar src={data?.agreements?.contact?.image_url} onClick={()=>data?.agreements?.contact?.image_url?.length>0?setImageViewer({ bool: true, data:[{src:data?.agreements?.contact?.image_url,type:"image"}] }):false} {...stringAvatar(data?.agreements?.contact?.first_name ?? null)}/>
                                <Box>
                                    <Typography sx={landlordApprovalstyle?.contactName}>{data?.agreements?.contact?.first_name}</Typography>
                                    <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                        <Typography sx={landlordApprovalstyle.detailsSubtext}>{data?.agreements?.contact?.mobile_no_country_code} {data?.agreements?.contact?.mobile_no}</Typography>
                                        <Typography sx={landlordApprovalstyle.detailsSubtext}>{data?.agreements?.contact?.email_id}</Typography>
                                    </Stack>
                                </Box>
                            </Stack>

                        </Box>
                        {data?.agreements?.contact?.know_your_client?.length > 0 &&
                            <>
                                <Box mt={2}>
                                    <Typography sx={landlordApprovalstyle?.kyctext}>{t("KYC DOCUMENTS")}</Typography>
                                </Box>
                                <Box maxHeight={"400px"} overflow="scroll">
                                    {data?.agreements?.contact?.know_your_client?.map((x) => {
                                        return (
                                            <Box mt={2} sx={landlordApprovalstyle?.kycDetails} p={2}>
                                                <Stack direction={"row"} spacing={1} alignItems="center">
                                                    {x?.url?.[0]?.type === "pdf" ?
                                                        <Box sx={landlordApprovalstyle?.documentImg}>
                                                           <FileViewers fileType={"pdf"} filePath={x?.url?.[0]?.src}/>
                                                        </Box>
                                                        : <Avatar src={x?.url?.[0]?.src} variant="square" sx={landlordApprovalstyle?.kycImage} {...stringAvatar(x?.name ?? null)}/>
                                                    }
                                                    <Box>
                                                        <Typography sx={landlordApprovalstyle?.contactName}>{x?.name}</Typography>
                                                        <Typography sx={landlordApprovalstyle?.contactSubText}>{x?.proof_no}</Typography>
                                                    </Box>
                                                </Stack>
                                                <Box onClick={() => setImageViewer({ bool: true, data: x?.url })}>
                                                    <VisibilityIcon sx={{ color: "#F15A29" }} />
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>

                            </>
                        }
                    </Box>
                }
            />
            <DialogDrawer
                maxWidth="sm"
                open={pdf?.open}
                header={t("View")}
                padding="0px"
                height_style={
                    { height: "500px !important" }
                }
                header_style={{
                    padding: "8px !important"
                }}
                footer_style={{
                    padding: "8px !important"
                }}
                component={
                    <Box sx={{ padding: '16px' }}>
                        {pdf?.bool ? <>   <>
                            <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} />
                        </></> : pdf?.pdf ? <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`} /> : <Box style={{ marginTop: '30px' }}><NoData /></Box>}
                    </Box>
                }
                footer={
                    <>
                        <FooterComponent
                            single={true}
                            containedText={t("Download")}
                            containedonClick={() => saveAs(`data:application/pdf;base64,${pdf?.pdf}`, `${state?.agreement_no}.pdf`)}
                        />
                    </>
                }
                onClose={() => filterdrawerclose()}
            />
            <DialogDrawer
                open={imageViewer?.bool}
                onClose={() => setImageViewer({ bool: false, data: [] })}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                height_style={{ height: "auto" }}
                component={
                    <ImageAndPdfViewer
                        title={t("Assets")}
                        assets={
                            (imageViewer?.data?.length > 0 && imageViewer?.data?.map((val) => {
                                return {
                                    meta_data: {
                                        file_type: val?.type,
                                    },
                                    file_url: val?.src,
                                };
                            })
                            )}
                        onClose={() => setImageViewer({ bool: false, data: [] })}
                    />
                }
            />
        </>

    )
}

export default withTranslation('approval')(LandlordApproval)