import { Box, FormControl, Grid, InputBase, Typography } from "@mui/material";
import React from "react";
import { SimpleSelect } from "./select";
import { countryCode, getMobileLimitBasedOnCC } from "../../utils";
import { MobileNumberInputStyle } from './style';
import { Label } from "../label";

export const MobileComponent = ({
  onChange = () => false,
  value = "",
  label = "",
  isRequired = false,
  isReadonly = false,
  onErrorOccured = false,
  errorValidation = false,
  autoFocus = false,
  placeholder = "",
  isError = false,
  errorMessage = "",
  error = "",
  helperText = "",
}) => {
  const   dial_code = localStorage.getItem("dial_code") || "";

  const [selectValue, setSelectValue] = React.useState({
    mobile: value?.mobile ?? "",
    mobile_code:dial_code,
  });

  const [limits, setLimits] = React.useState(10);
  const [isvalid, setIsvalid] = React.useState(true);

  const mobileNoValidation = (limitValue = limits) => {
    let inputLengthValue = selectValue?.mobile?.length;

    if (limitValue) {
      if (limitValue === inputLengthValue) {
        setIsvalid(true);
      } else if (limitValue > inputLengthValue) {
        setIsvalid(false);
      } else {
        setIsvalid(true);
      }
    }
  };

  const handleChangeSelect = (mobile_code = {}) => {
    let newState = {
      mobile: selectValue?.mobile,
      mobile_code,
    };

    setSelectValue(newState);
    onChange(newState);

    if (Object.keys(mobile_code)?.length > 0) {
      const limit = getMobileLimitBasedOnCC(mobile_code);
      setLimits(limit);
    }
  };

  const handleChange = (mobile) => {
    if (mobile?.length <= limits) {
      let newState = {
        mobile: mobile.replace(".", ""),
        mobile_code: selectValue?.mobile_code,
      };

      setSelectValue(newState);
      onChange(newState);
    }
  };

  React.useEffect(() => {
    const limit = getMobileLimitBasedOnCC(value?.mobile_code);
    mobileNoValidation(limit);

    // eslint-disable-next-line
  }, [selectValue]);

  React.useEffect(() => {
    if (value) {
      setSelectValue({
        ...selectValue,
        mobile: value?.mobile ?? "-",
        mobile_code: value?.mobile_code ?? dial_code,
      });
    }
    // eslint-disable-next-line
  }, [value]);

  React.useEffect(() => {
    if (onErrorOccured) {
      onErrorOccured(!isvalid);
    }

    // eslint-disable-next-line
  }, [isvalid]);
  return (

    < Box sx={MobileNumberInputStyle?.root} noValidate >
      {/* Input Label */}
      {label && <Label label={label} isRequired={isRequired} />}
      <FormControl>
        <div>
          <Box
            sx={MobileNumberInputStyle?.wrapper}
          >
            {/* Mobile No Country Code Select */}
            <Box>
              <Box sx={MobileNumberInputStyle?.simpleSelectBox}>
                <SimpleSelect
                  //   className={classes.select}   
                  sx={MobileNumberInputStyle?.numberBox}
                  options={countryCode}
                  handleChangeSelect={handleChangeSelect}
                  value={selectValue?.mobile_code}
                  disabled={isReadonly}
                  errorValidation={errorValidation}
                />
              </Box>
            </Box>
            {/* Mobile Number Input Field */}
            <Box>
              <InputBase
                sx={MobileNumberInputStyle?.numberBox}
                inputProps={{ maxLength: limits && limits, pattern: "[0-9]" }}
                type={"number"}
                autoFocus={autoFocus ? true : false}
                disabled={isReadonly}
                onInvalid={(e) => {
                  e.preventDefault();
                }}
                placeholder={placeholder}
                onChange={(e) => handleChange(e.target.value)}
                value={selectValue?.mobile}
                error={
                  errorValidation && errorValidation?.error
                    ? errorValidation?.error
                    : false
                }
                onKeyPress={(e) => {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                }}
              />
            </Box>
          </Box>
        </div>

        {/* Warning Message */}
        <Grid container direction={"row"}>
          {isvalid === false &&
            limits !== null &&
            selectValue?.mobile?.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" color="error">
                  {"Please enter " + limits + " digits"}
                </Typography>
              </Grid>
            )}

          {/* Error Message */}
          {errorValidation?.error && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="caption" color="error">
                {errorValidation?.errorMessage ?? ""}
              </Typography>
            </Grid>
          )}

          {/* Field required Message */}
          {isError && (
            <Typography variant={"caption"} color={"error"}>
              {errorMessage}
            </Typography>
          )}

          {error && (
            <Typography variant="caption" color="error">
              {helperText}
            </Typography>
          )}
        </Grid>
      </FormControl>
    </Box >
  );
};


