import React from 'react'
import { Box, Stack, Typography, Avatar, Divider } from "@mui/material"
import ContactIcon from '../utils/contactIcon'
import PhoneIcon from '../utils/phoneIcon'
import MessageIcon from '../utils/messageIcon'
import { ContactCardStyle } from './style'
import { stringAvatar } from '../../../utils'

export const ContactCard = ({
    data = {},
    t = () => false,
    subtitle = ""
}) => {
    const classes = ContactCardStyle
    return (
        <>
            <Box sx={classes.box}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={"12px"}>
                    <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                        <Avatar sx={classes.avatar} src={data?.image_url} {...stringAvatar(data?.first_name)}></Avatar>
                        <Stack>
                            <Typography sx={{...classes.name, ...classes.widthCorrection}}>{data?.first_name}</Typography>
                            <Typography sx={classes.subtitle}>{subtitle?.length > 0 ? subtitle : data?.department ? data?.is_relation_ship ? t("Relationship Manager / Sales man") : t("Occupants (Primary Contact)") : ""}</Typography>
                        </Stack>
                    </Stack>
                    <a
                        href={`tel:${data?.mobile_no_country_code ?? ""}${data?.mobile_no}`}
                        style={{ textDecoration: "none", color: "#071741" }}
                        target="_blank" rel="noreferrer"
                    >
                        <ContactIcon />
                    </a>
                </Stack>
                <Divider></Divider>
                <Stack direction={"row"} alignItems={"center"} justifyContent={data?.email_id ? "space-between" : "center"} p={"12px"}>
                    <Stack direction={"row"} columnGap={1}>
                        <PhoneIcon />
                        <Typography sx={classes.phoneMsgIcon}>{`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no}`}</Typography>
                    </Stack>
                    {data?.email_id && <Box sx={classes.dot}></Box>}
                    {data?.email_id && <Stack direction={"row"} columnGap={1}>
                        <MessageIcon />
                        <Typography sx={classes.phoneMsgIcon}>{data?.email_id}</Typography>
                    </Stack>}
                </Stack>
            </Box>
        </>
    )
}