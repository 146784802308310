import * as React from "react"
const BedRoomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.25} height={18} {...props}>
    <path
      fill="#e2ae24"
      stroke="#e2ae24"
      strokeWidth={0.25}
      d="M.758 1.899a.643.643 0 0 0-.633.652V14.98a.643.643 0 1 0 1.286 0v-.643h15.428v.643a.643.643 0 1 0 1.286 0v-3.429a1.51 1.51 0 0 0-1.5-1.5H6.982v-.43a2.581 2.581 0 0 0-2.571-2.57H2.696a2.49 2.49 0 0 0-1.286.4v-4.9a.643.643 0 0 0-.653-.652Zm1.939 6.438h1.714a1.276 1.276 0 0 1 1.285 1.284v.429H1.411v-.429a1.276 1.276 0 0 1 1.285-1.284Zm-1.286 3h4.823a.643.643 0 0 0 .208 0h10.183a.2.2 0 0 1 .214.214v1.5H1.411Z"
      data-name="Group 137942"
    />
  </svg>
)
export default BedRoomIcon
