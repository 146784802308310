import * as React from "react"
const ContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
    <g data-name="Group 138053">
      <g fill="#fff" stroke="#f15a29" data-name="Ellipse 130994">
        <circle cx={13} cy={13} r={13} stroke="none" />
        <circle cx={13} cy={13} r={12.5} fill="none" />
      </g>
      <path
        fill="#f15a29"
        d="m12.171 6.92.6 1.419a1.622 1.622 0 0 1-.36 1.8l-1.122 1.063a.531.531 0 0 0-.164.467 5.714 5.714 0 0 0 .721 1.948 6.135 6.135 0 0 0 1.356 1.634.535.535 0 0 0 .513.091l1.311-.438a1.424 1.424 0 0 1 1.631.574l.863 1.267a1.625 1.625 0 0 1-.181 2.041l-.573.575a2.114 2.114 0 0 1-2.136.545q-2.666-.832-4.9-4.938T8.15 7.991a2.282 2.282 0 0 1 1.5-1.683l.754-.241a1.444 1.444 0 0 1 1.767.853Z"
        data-name="icons8-call (2)"
      />
    </g>
  </svg>
)
export default ContactIcon
