import * as React from "react"
const NationAddIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#adb5bd"
      d="M8 0a8.065 8.065 0 1 0 7.889 6.73c-.078.107-.16.212-.247.312s-.429.483-.843.939v.018a6.757 6.757 0 0 1-.3 2H5.331a15.3 15.3 0 0 1 0-4h2.341a4.719 4.719 0 0 1-.395-1.2H5.548c.515-2.214 1.52-3.6 2.452-3.6.029 0 .059.005.088.008A4.815 4.815 0 0 1 9.2.1 7.966 7.966 0 0 0 8 0Zm4 0a3.985 3.985 0 0 0-4 3.952 3.913 3.913 0 0 0 .963 2.567s1.563 1.764 2.168 2.333a1.269 1.269 0 0 0 1.728 0c.677-.637 2.17-2.334 2.17-2.334A3.916 3.916 0 0 0 16 3.952 3.985 3.985 0 0 0 12 0Zm0 1.2a2.767 2.767 0 0 1 2.8 2.752 2.7 2.7 0 0 1-.668 1.78c-.006.007-1.562 1.755-2.089 2.251a.055.055 0 0 1-.084 0c-.429-.4-2.084-2.246-2.09-2.253A2.7 2.7 0 0 1 9.2 3.952 2.767 2.767 0 0 1 12 1.2Zm-6.468.47A9.453 9.453 0 0 0 4.323 4.8H2a6.836 6.836 0 0 1 3.532-3.13ZM12 3a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM1.5 6h2.627a15.964 15.964 0 0 0 0 4H1.5a6.766 6.766 0 0 1 0-4Zm.507 5.2H4.33a9.515 9.515 0 0 0 1.2 3.13 6.844 6.844 0 0 1-3.521-3.13Zm3.541 0h4.9C9.934 13.413 8.931 14.8 8 14.8s-1.936-1.385-2.451-3.6Zm6.123 0h2.319a6.844 6.844 0 0 1-3.523 3.13 9.476 9.476 0 0 0 1.206-3.13Z"
    />
  </svg>
)
export default NationAddIcon
