import * as React from "react"
const SellableAreaIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22.012} {...props}>
    <g data-name="Group 138170">
      <path
        fill="#4e5a6b"
        d="M3.972 0A3.986 3.986 0 0 0 0 3.972v14.056A3.986 3.986 0 0 0 3.972 22h5.35a.917.917 0 0 0 .3 0h8.409A3.986 3.986 0 0 0 22 18.028V3.972A3.986 3.986 0 0 0 18.028 0h-3.056a.917.917 0 1 0 0 1.833h3.056a2.126 2.126 0 0 1 2.139 2.139V11h-2.75a.917.917 0 1 0 0 1.833h2.75v5.194a2.126 2.126 0 0 1-2.139 2.139h-7.639v-7.333h2.139a.917.917 0 1 0 0-1.833H5.806a.917.917 0 1 0 0 1.833h2.75v7.333H3.972a2.126 2.126 0 0 1-2.139-2.139V3.972a2.126 2.126 0 0 1 2.139-2.139h2.762l4.039 2.885a.917.917 0 1 0 1.065-1.492L7.56.171A.917.917 0 0 0 7.028 0Z"
        data-name="icons8-floor-plan (1)"
      />
    </g>
  </svg>
)
export default SellableAreaIcon
