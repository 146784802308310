import * as React from "react"
const BalconyIconNew = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404.42)">
      <rect
        width={40}
        height={40}
        fill="#edf7f8"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404.42)"
      />
      <path
        fill="#428d92"
        stroke="#428d92"
        strokeWidth={0.25}
        d="M58.227 412.921a.817.817 0 0 0-.818.818v10.637h1.636v-9.819h10.91v9.818h1.636v-10.636a.817.817 0 0 0-.818-.818Zm-3.1 8.919a2.631 2.631 0 1 0 0 5.263h.061a7.461 7.461 0 0 0-.783 3 7.438 7.438 0 0 0 .773 2.978h-.222a.818.818 0 1 0 0 1.636l14.068.016a.818.818 0 0 0 .243 0l4.78.005a.818.818 0 1 0 0-1.636h-.239a7.46 7.46 0 0 0 .783-3 7.46 7.46 0 0 0-.783-3h.208a.818.818 0 0 0 .434-.118 2.608 2.608 0 0 0 2.05-2.513 2.644 2.644 0 0 0-2.631-2.631.818.818 0 1 0 0 1.636.995.995 0 1 1 0 1.99h-9.235a.818.818 0 0 0-.265 0h-9.238a1 1 0 1 1 0-1.99.818.818 0 1 0 0-1.636Zm1.843 5.263h2.094v5.983h-2.1a6.57 6.57 0 0 1-.916-2.98 6.586 6.586 0 0 1 .922-3.003Zm3.73 0h2.982v5.988H60.7Zm4.618 0h3.007v5.994h-3.007Zm4.644 0h2.068a6.586 6.586 0 0 1 .925 3 6.584 6.584 0 0 1-.924 3h-2.069Z"
      />
    </g>
  </svg>
)
export default BalconyIconNew
