import { Bold, FontSizeCalc, SemiBold } from "../../utils";

export const ImageSliderStyle = {
    image: {
        // height: "180px",
        width: "100%",
        borderRadius: "12px"
    },
    imageBox: {
        // height: "180px",
        width: "100%",
        position: "relative"
    },
    arrowBox: {
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "0px",
        left: "0px",
        padding: "12px 0px 8px 0px"
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        background: "#fff"
    },
    selectedDot: {
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        background: "#fff"
    },
    vacantText: {
        backgroundColor: "status.success",
        // borderTopRightRadius: "50px",
        // borderBottomRightRadius: "50px",
        color: "#fff",
        fontSize: FontSizeCalc(14),
        fontFamily: Bold,
        width: "72px",
        padding: "4px 10px"
    },
    occupeidText: {
        backgroundColor: "primary.main",
        // borderTopRightRadius: "50px",
        // borderBottomRightRadius: "50px",
        color: "#fff",
        fontSize: FontSizeCalc(14),
        fontFamily: Bold,
        // width: "90px",
        padding: "4px 10px"
    },
    viewAllBox: {
        border: "1px solid #fff",
        borderRadius: "4px",
        backgroundColor: "#000",
        position: "absolute",
        bottom: "8px",
        right: "8px",
        zIndex: 999,
        padding: "0px 4px",
        cursor: "pointer"
    },
    viewAllText: {
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        color: "#fff"
    }
}