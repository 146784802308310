import * as React from "react"
const LeaseStartIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M4.921.578a3.776 3.776 0 0 0-3.763 3.763v6.658h1.737V6.946h17.368v10.71a2.014 2.014 0 0 1-2.026 2.026H4.921a2.014 2.014 0 0 1-2.026-2.026v-2.604H1.158v2.605a3.777 3.777 0 0 0 3.763 3.763h13.316A3.777 3.777 0 0 0 22 17.657V4.341A3.777 3.777 0 0 0 18.237.578Zm0 1.737h13.316a2.014 2.014 0 0 1 2.026 2.026v.868H2.895v-.868a2.014 2.014 0 0 1 2.026-2.026Zm3.465 6.938a.869.869 0 0 0-.6 1.491l1.412 1.412H.868a.868.868 0 1 0 0 1.737h8.325l-1.412 1.412a.869.869 0 1 0 1.228 1.228l2.891-2.894a.869.869 0 0 0 0-1.228L9.005 9.516a.868.868 0 0 0-.623-.263Z"
      data-name="Group 138049"
    />
  </svg>
)
export default LeaseStartIcon
