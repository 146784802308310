import * as React from "react"
const FloorIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18.01} {...props}>
        <g data-name="Group 137942">
            <path
                fill="#c6981f"
                d="M3.25 0A3.262 3.262 0 0 0 0 3.25v11.5A3.262 3.262 0 0 0 3.25 18h4.377a.75.75 0 0 0 .243 0h6.88A3.262 3.262 0 0 0 18 14.75V3.25A3.262 3.262 0 0 0 14.75 0h-2.5a.75.75 0 1 0 0 1.5h2.5a1.739 1.739 0 0 1 1.75 1.75V9h-2.25a.75.75 0 1 0 0 1.5h2.25v4.25a1.739 1.739 0 0 1-1.75 1.75H8.5v-6h1.75a.75.75 0 1 0 0-1.5h-5.5a.75.75 0 1 0 0 1.5H7v6H3.25a1.739 1.739 0 0 1-1.75-1.75V3.25A1.739 1.739 0 0 1 3.25 1.5h2.26l3.3 2.36a.75.75 0 1 0 .871-1.221l-3.5-2.5A.75.75 0 0 0 5.75 0Z"
                data-name="icons8-floor-plan (2)"
            />
        </g>
    </svg>
)
export default FloorIcon
