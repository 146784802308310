import { Box, FormControlLabel, Grid, Radio, Typography, colors } from "@mui/material";
import { FormGenerator } from "../../components"
import { Regular, SemiBold } from "../../utils";

export const AlreadyPaidDrawer = ({
    updateState = () => null,
    data = {},
    selectedInvoice = {},
    paymentMode = [],
    t = () => false
}) => {
    return (
        <>
            <Grid container>
                {paymentMode?.payment_mode?.map((val) => {
                    return (
                        <Grid item xs={6} sm={4}>
                            <FormControlLabel
                                value={val?.value}
                                control={<Radio checked={val?.value === data?.type} />}
                                label={<Typography width="76%" fontFamily={Regular} color={'#071741'} noWrap>{t(val?.label)}</Typography>}
                                onChange={() => updateState("type", val?.value)}
                            />
                        </Grid>
                    );
                })}
                <Grid item xs={12}>
                    {data?.error?.type?.length ?
                        <Typography variant={"caption"} color={"error"}>
                            {data?.error?.type}
                        </Typography>
                        : ""}
                </Grid>
            </Grid>
            <Box height={'16px'} />
            <FormGenerator
                components={[
                    {
                        isActive: true,
                        component: "text",
                        type: "number",
                        label: t("Amount Paid"),
                        isRequired: true,
                        value: data?.amount_paid,
                        placeholder: t("Enter Amount Paid"),
                        onChange: (e) => updateState("amount_paid", e?.target?.value),
                        error: data?.error?.amount_paid,
                        size: {
                            xs: 12,
                        },
                    },
                    {
                        isActive: true,
                        component: "date",
                        label: t("Date"),
                        value: data?.date,
                        placeholder: t("Enter Date"),
                        onChange: (value) => updateState("date", value),
                        error: data?.error?.date,
                        isRequired: true,
                        size: {
                            xs: 12,
                        },

                        sx: {
                            border: "1.5px solid #e2e2e2",
                            borderRadius: "4px",
                            color: "#98A0AC",
                            fontFamily: SemiBold,
                            fontSize: "0.875rem",                                
                        }
                    },
                    {
                        isActive: true,
                        component: "text",
                        label: t("Bank Name/Location"),
                        isRequired: true,
                        value: data?.bank_location,
                        placeholder: t("Enter Bank Name/Location"),
                        onChange: (e) => updateState("bank_location", e?.target?.value),
                        error: data?.error?.bank_location,
                        size: {
                            xs: 12,
                        },
                    },
                    {
                        isActive: true,
                        component: "text",
                        label: t("Transaction ID"),
                        isRequired: true,
                        value: data?.transaction_id,
                        placeholder: t("Enter Transaction ID"),
                        onChange: (e) => updateState("transaction_id", e?.target?.value),
                        error: data?.error?.transaction_id,
                        size: {
                            xs: 12,
                        },
                    },
                    {
                        isActive: true,
                        component: "text",
                        label: t("Notes"),
                        isRequired: true,
                        value: data?.notes,
                        placeholder: t("Enter Notes"),
                        onChange: (e) => updateState("notes", e?.target?.value),
                        error: data?.error?.notes,
                        size: {
                            xs: 12,
                        },
                    },

                ]}

            />
        </>
    )
}