import * as React from "react"
const PaymentTimeLineIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.739}
    height={21.739}
    {...props}
  >
    <g data-name="Group 138298" transform="translate(-90.272 -430.271)">
      <g
        fill="none"
        stroke="#e9ecef"
        data-name="Rectangle 59015"
        transform="rotate(45 -468.812 337.225)"
      >
        <rect width={15.372} height={15.372} stroke="none" rx={5} />
        <rect width={14.372} height={14.372} x={0.5} y={0.5} rx={4.5} />
      </g>
      <rect
        width={11.956}
        height={11.956}
        fill="#ced4da"
        data-name="Rectangle 59014"
        rx={4}
        transform="rotate(45 -471.728 338.433)"
      />
    </g>
  </svg>
)
export default PaymentTimeLineIcon
