import * as React from "react"
const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="Group 138159">
      <path
        fill="#4e5a6b"
        d="M3.972 0A3.986 3.986 0 0 0 0 3.972v14.056A3.986 3.986 0 0 0 3.972 22h14.056A3.986 3.986 0 0 0 22 18.028V3.972A3.986 3.986 0 0 0 18.028 0Zm0 1.833h14.056a2.126 2.126 0 0 1 2.139 2.139v.917H1.833v-.917a2.126 2.126 0 0 1 2.139-2.139ZM1.833 6.722h18.334v11.306a2.126 2.126 0 0 1-2.139 2.139H3.972a2.126 2.126 0 0 1-2.139-2.139Zm3.972 2.444a1.528 1.528 0 1 0 1.528 1.528 1.528 1.528 0 0 0-1.528-1.528Zm5.194 0a1.528 1.528 0 1 0 1.528 1.528A1.528 1.528 0 0 0 11 9.167Zm5.194 0a1.528 1.528 0 1 0 1.528 1.528 1.528 1.528 0 0 0-1.527-1.527Zm-10.389 5.5a1.528 1.528 0 1 0 1.528 1.528 1.528 1.528 0 0 0-1.526-1.527Zm5.194 0a1.528 1.528 0 1 0 1.528 1.528A1.528 1.528 0 0 0 11 14.667Z"
        data-name="icons8-date (1)"
      />
    </g>
  </svg>
)
export default CalendarIcon
