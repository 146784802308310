import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { stopAudioRecorder } from './utils';

const AudioRecorder = ({
  t = () => false,
  styles = {},
  audioRef = {},
  mediaRecorderRef = {},
  setAudioBlob = () => false,
  recording = false,
  setRecording = () => false,
  handleAudioRecordingDialogClose = () => false,
  setIsAudioRecordDialogOpen = () => false,
  setIsReplayAudioDialogOpen = () => false,
}) => {
  let interval = null;
  let timeout = null;
  const [timer, setTimer] = useState(0);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      let audioChunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
        setAudioBlob(audioBlob);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
      timeout = setTimeout(handleStopRecording, 31000);
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setRecording(false);
      stopAudioRecorder({ audioRef, mediaRecorderRef });
      setIsAudioRecordDialogOpen(false);
      setIsReplayAudioDialogOpen(true);
      clearInterval(interval);
      clearTimeout(timeout);
    }
  };

  return (
    <Box>
      <Stack p={"24px"} rowGap={"16px"} justifyContent={"center"} alignItems={"center"}>
        <Typography sx={styles.audioTimer}>
          {`00:${timer > 9 ? timer?.toString() : `0${timer?.toString()}`}`}
        </Typography>
      </Stack>
      <Stack direction={"row"} columnGap={"8px"} p={"16px"} justifyContent={"space-between"} alignItems={"center"}>
        <Button sx={styles.cancelButton} fullWidth
          onClick={handleAudioRecordingDialogClose}>
          {t("Cancel")}
        </Button>
        <Button fullWidth sx={styles.recordStopButton}
          onClick={() => recording ? handleStopRecording() : handleStartRecording()}>
          {recording
            ? t("Stop")
            : t("Record")}
        </Button>
      </Stack>
    </Box>
  );
};

export default AudioRecorder;
