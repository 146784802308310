import React from "react";
import { withTranslation } from 'react-i18next';
import  LandlordApproval  from "./landlordApproval";
  
class LandlordApprovalParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
    
        }
      }
  render() {
    return <LandlordApproval {...this?.props}/> ;
  }
}

export default withTranslation('landlordApproval')(LandlordApprovalParent);