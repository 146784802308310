import * as React from "react"
const EmergencyContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={40} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y1={-3.384}
        y2={-3.391}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
    </defs>
    <g data-name="Group 138417">
      <g data-name="Group 138416">
        <g fill="#ffecec" stroke="#fff" strokeWidth={2} data-name="Image 41">
          <rect width={40} height={40} stroke="none" rx={20} />
          <rect width={38} height={38} x={1} y={1} fill="none" rx={19} />
        </g>
        <path
          fill="#d9414f"
          d="m29.9 21.084-1.481-4.444a1.429 1.429 0 0 0-1.353-.977h-6.114a1.428 1.428 0 0 0-1.429 1.429v4.761H10v3.333a.952.952 0 0 0 .952.952h18.1a.952.952 0 0 0 .948-.951v-3.5a1.9 1.9 0 0 0-.1-.603Z"
          data-name="Path 123743"
        />
        <path
          fill="#586169"
          d="M28.572 26.139a2.381 2.381 0 1 1-2.381-2.381 2.381 2.381 0 0 1 2.381 2.381"
          data-name="Path 123744"
        />
        <path
          fill="#586169"
          d="M17.143 26.139a2.381 2.381 0 1 1-2.381-2.381 2.381 2.381 0 0 1 2.381 2.381"
          data-name="Path 123745"
        />
        <path
          fill="#999"
          d="M27.138 26.139a.952.952 0 1 1-.948-.952.952.952 0 0 1 .952.952"
          data-name="Path 123746"
        />
        <path
          fill="#999"
          d="M15.71 26.139a.952.952 0 1 1-.948-.952.952.952 0 0 1 .952.952"
          data-name="Path 123747"
        />
        <path
          fill="#b31523"
          d="M28.096 20.902h-3.334a.476.476 0 0 1-.476-.476v-3.334a.476.476 0 0 1 .476-.476h2.381a.476.476 0 0 1 .452.326l.977 2.93v.554a.476.476 0 0 1-.476.476Z"
          data-name="Path 123748"
        />
        <path
          fill="url(#a)"
          d="M33.476 12H34.9a.476.476 0 0 1 .476.476v.476H33v-.476a.476.476 0 0 1 .476-.476Z"
          data-name="Path 123749"
          transform="translate(-8.714 2.711)"
        />
        <path
          fill="#b31523"
          d="M20.952 20.902h1.9a.476.476 0 0 0 .476-.476v-3.334a.476.476 0 0 0-.471-.476h-1.9a.476.476 0 0 0-.476.476v3.334a.476.476 0 0 0 .471.476Z"
          data-name="Path 123750"
        />
        <path
          fill="#cf1928"
          d="m13.81 17.612-3.81.974v3.268h3.81Z"
          data-name="Path 123751"
        />
        <path
          fill="#999"
          d="M10.476 18.991a.476.476 0 0 1-.476-.473v-3.016a.475.475 0 0 1 .325-.451l11.254-3.776a.475.475 0 0 1 .48.108l2.341 2.235a.476.476 0 0 1-.178.8l-13.6 4.548a.484.484 0 0 1-.146.025Zm.476-3.146v2.008l12.222-4.088-1.565-1.5Z"
          data-name="Path 123752"
        />
        <path
          fill="#999"
          d="M14.921 17.497a.468.468 0 0 1-.174-.033l-3.926-1.547a.476.476 0 1 1 .349-.886l3.526 1.389 1.587-3.068a.476.476 0 0 1 .627-.211l3.013 1.429 1.247-2.736a.476.476 0 1 1 .867.395l-1.447 3.174a.476.476 0 0 1-.637.233l-3.035-1.438-1.574 3.041a.477.477 0 0 1-.423.258Z"
          data-name="Path 123753"
        />
      </g>
      <g data-name="Group 138417">
        <g
          fill="#fff1cf"
          stroke="#fff"
          strokeWidth={2}
          data-name="Image 40"
          transform="translate(25)"
        >
          <rect width={40} height={40} stroke="none" rx={20} />
          <rect width={38} height={38} x={1} y={1} fill="none" rx={19} />
        </g>
        <g transform="translate(35 11.25)">
          <path
            fill="#ffc429"
            d="M10 0a5.381 5.381 0 0 0-5.305 4.482l-1.513 8.927h13.636l-1.513-8.928A5.381 5.381 0 0 0 10 0Z"
            data-name="Path 123734"
          />
          <path
            fill="#715712"
            d="M8.636 11.818h2.727a.909.909 0 0 1 .909.909v.682H7.727v-.682a.909.909 0 0 1 .909-.909Z"
            data-name="Path 123735"
          />
          <circle
            cx={1.364}
            cy={1.364}
            r={1.364}
            fill="#715712"
            data-name="Ellipse 131108"
            transform="translate(8.636 7.273)"
          />
          <path
            fill="#c6981f"
            d="M3.636 13.182h12.727A1.818 1.818 0 0 1 18.181 15v.909a.909.909 0 0 1-.909.909H2.727a.909.909 0 0 1-.909-.909V15a1.818 1.818 0 0 1 1.818-1.818Z"
            data-name="Path 123736"
          />
          <rect
            width={3.636}
            height={0.909}
            fill="#e2ae24"
            data-name="Rectangle 59029"
            rx={0.455}
            transform="translate(0 3.636)"
          />
          <rect
            width={3.636}
            height={0.909}
            fill="#e2ae24"
            data-name="Rectangle 59030"
            rx={0.455}
            transform="translate(16.364 3.636)"
          />
          <path
            fill="#e2ae24"
            d="M3.636 1.818a.453.453 0 0 1-.144-.024L2.129 1.34a.455.455 0 1 1 .289-.862l1.364.455a.454.454 0 0 1-.144.886Z"
            data-name="Path 123737"
          />
          <path
            fill="#e2ae24"
            d="M1.363 7.728a.454.454 0 0 1-.144-.886l1.364-.455a.454.454 0 0 1 .288.862l-1.362.455a.453.453 0 0 1-.146.024Z"
            data-name="Path 123738"
          />
          <path
            fill="#e2ae24"
            d="M16.363 1.818a.454.454 0 0 1-.144-.886l1.364-.455a.454.454 0 1 1 .288.862l-1.362.455a.453.453 0 0 1-.146.024Z"
            data-name="Path 123739"
          />
          <path
            fill="#e2ae24"
            d="M18.636 7.728a.453.453 0 0 1-.144-.024l-1.364-.455a.454.454 0 0 1 .288-.862l1.364.455a.454.454 0 0 1-.144.886Z"
            data-name="Path 123740"
          />
          <g data-name="Group 138415">
            <path
              d="m16.744 12.991-.036-.236a2.166 2.166 0 0 0-.344-.028H3.637a2.167 2.167 0 0 0-.345.027l-.036.236-.042.237a1.854 1.854 0 0 1 .423-.05h12.727a1.854 1.854 0 0 1 .423.05Z"
              data-name="Path 123741"
              opacity={0.05}
            />
            <path
              d="M16.744 12.99a2.091 2.091 0 0 0-.382-.036H3.637a2.091 2.091 0 0 0-.382.036l-.041.241a1.855 1.855 0 0 1 .423-.05h12.727a1.855 1.855 0 0 1 .423.05Z"
              data-name="Path 123742"
              opacity={0.07}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default EmergencyContactIcon
