import * as React from "react"
const InventoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17.881}
    data-name="Group 137856"
    {...props}
  >
    <path fill="none" d="M0 0h18v17.447H0z" data-name="Rectangle 58887" />
    <path
      fill="#f15a29"
      d="M8.69 0a.688.688 0 0 0-.267.068L.4 3.959a.688.688 0 0 0 .071 1.277v3.017a.917.917 0 0 0 .917.917h5.731a.917.917 0 0 0 .917-.917V1.786l.688-.333.688.333v6.467a.917.917 0 0 0 .917.917h5.731a.917.917 0 0 0 .917-.917V5.237a.688.688 0 0 0 .071-1.278L9.026.069A.688.688 0 0 0 8.69 0ZM3.909 4.585h.917a.688.688 0 1 1 0 1.375h-.917a.688.688 0 1 1 0-1.375Zm8.711 0h.917a.688.688 0 0 1 0 1.375h-.917a.688.688 0 0 1 0-1.375Zm-11.232 5.96a.917.917 0 0 0-.917.917v3.9a2.521 2.521 0 0 0 2.522 2.522h4.126a.917.917 0 0 0 .917-.917v-5.5a.917.917 0 0 0-.917-.917Zm8.941 0a.917.917 0 0 0-.917.917v5.5a.917.917 0 0 0 .917.917h4.126a2.521 2.521 0 0 0 2.521-2.519v-3.9a.917.917 0 0 0-.917-.917Zm-6.42 1.376h.917a.688.688 0 1 1 0 1.375h-.917a.688.688 0 1 1 0-1.375Zm8.711 0h.917a.688.688 0 0 1 0 1.375h-.917a.688.688 0 0 1 0-1.375Z"
    />
  </svg>
)
export default InventoryIcon
