import * as React from "react"
const ParkingSlotIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 137942">
      <path
        fill="#4c7015"
        d="M3.25 0A3.262 3.262 0 0 0 0 3.25v11.5A3.262 3.262 0 0 0 3.25 18h11.5A3.262 3.262 0 0 0 18 14.75V3.25A3.262 3.262 0 0 0 14.75 0Zm0 1.5h11.5a1.739 1.739 0 0 1 1.75 1.75v11.5a1.739 1.739 0 0 1-1.75 1.75H3.25a1.739 1.739 0 0 1-1.75-1.75V3.25A1.739 1.739 0 0 1 3.25 1.5ZM6.75 4a.75.75 0 0 0-.75.75v5.377a.75.75 0 0 0 0 .243v3.38a.75.75 0 1 0 1.5 0V11h2a3.5 3.5 0 0 0 0-7Zm.75 1.5h2a2 2 0 0 1 0 4h-2Z"
        data-name="icons8-parking (4)"
      />
    </g>
  </svg>
)
export default ParkingSlotIcon
