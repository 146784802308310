import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes";
import PrivateRouter from "./privateRouter";

import {
  NotFound,
  Login,
  CreateInvestment,
  Statistic,
  ProfileView,
  ProfileEdit,
  Property,
  AddressEdit,
  Home,
  KycInformation,
  GetSupport,
  GetSupportDetailed,
  VacantUnit,
  Invoices,
  KycAddId,
  Request,
  RequestView,
  MaintanceRequest,
  CompanySelect,
  Contracts,
  ContractDetails,
  Assets,
  GeneralRequest,
  Inspection,
  InspectionDetails,
  Utilities,
  CaseManagement,
  CorrespondenceType,
  AmenitiesBooking,
  Filemanager,
  Setup,
  ParkingSlot,
  Vehicle,
  VehicleView,
  AddVehicle,
  Investment,
  Spending,
  FilemanagerDocs,
  Announcement,
  AnnouncementView,
  Payment,
  AddOnsDetailedService,
  AddOnsService,
  ChooseAppliance,
  PayRequestInvoiceList,
  SurveyList,
  SurveyForm,
  PropertyUnitNew,
  PropertyUnitView,
  PropertyDetails,
  AssetViewPage
} from './../screens';
import UtilitiesList from "../screens/utilities/utiliesList";
import WelcomePage from "../screens/welcomePage";
import Contacts from "../screens/contacts";
import CreateContact from "../screens/createContact";
import RestoreErrorHandling from "../screens/restoreErrorHandling";
import LandlordApproval from "../screens/landlordApproval";
import ContractHistory from "../screens/contractHistory";
import ViewImage from "../screens/viewImage";
import QrCode from "../screens/qrCode";
import ExternalSite from "../screens/externalSite";
import BOMDetails from "../screens/requestView/components/costing"
import ApprovalList from "../screens/landlordApproval/approvalList";
import Documents from "../screens/propertyUnitNew/documents";
import Welcome from "../screens/welcome";

const RouterApp = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Home Route */}
        <Route
          path={AppRoutes.home}
          element={
            <PrivateRouter path={AppRoutes.home}>
              <Home />
            </PrivateRouter>
          }
        />

        {/* vaccant unit */}
        <Route
          path={AppRoutes.vacantunit}
          element={
            <PrivateRouter path={AppRoutes.vacantunit}>
              <VacantUnit />
            </PrivateRouter>
          }
        />

        {/* Case Management */}
        <Route
          path={AppRoutes.caseManagement}
          element={
            <PrivateRouter path={AppRoutes.caseManagement}>
              <CaseManagement />
            </PrivateRouter>
          }
        />

        {/* correspondence */}
        <Route
          path={AppRoutes.correspondence}
          element={
            <PrivateRouter path={AppRoutes.correspondence}>
              <CorrespondenceType />
            </PrivateRouter>
          }
        />

        {/* Invoice Route */}
        <Route
          path={AppRoutes.invoices}
          element={
            <PrivateRouter path={AppRoutes.invoices}>
              <Invoices />
            </PrivateRouter>
          }
        />


        {/* propereties route */}
        <Route
          path={AppRoutes.properties}
          element={
            <PrivateRouter path={AppRoutes.properties}>
              <Property />
            </PrivateRouter>
          }
        />

        {/* Statistic route */}
        <Route
          path={AppRoutes.statistics}
          element={
            <PrivateRouter path={AppRoutes.statistics}>
              <Statistic />
            </PrivateRouter>
          }
        />

        {/* Statistic route */}
        <Route
          path={AppRoutes.statistics}
          element={
            <PrivateRouter path={AppRoutes.statistics}>
              <Statistic />
            </PrivateRouter>
          }
        />

        {/* Create Investment */}
        <Route path={AppRoutes.createInvestment} element={
          <PrivateRouter path={AppRoutes.createInvestment}>
            <CreateInvestment />
          </PrivateRouter>
        } />

        {/* Create Spending */}
        <Route path={AppRoutes.createSpending} element={
          <PrivateRouter path={AppRoutes.createSpending}>
            <CreateInvestment type={"spending"} />
          </PrivateRouter>
        } />
        {/* Profile View */}
        <Route path={AppRoutes.profileView} element={
          <PrivateRouter path={AppRoutes.profileView}>
            <ProfileView />
          </PrivateRouter>
        } />
        {/* Profile Edit */}
        <Route path={AppRoutes.profileEdit} element={
          <PrivateRouter path={AppRoutes.profileEdit}>
            <ProfileEdit />
          </PrivateRouter>
        } />

        {/* Address Edit */}
        <Route path={AppRoutes.addressEdit} element={
          <PrivateRouter path={AppRoutes.addressEdit}>
            <AddressEdit />
          </PrivateRouter>
        } />

        {/* kycInformation */}
        <Route path={AppRoutes.kycInformation} element={
          <PrivateRouter path={AppRoutes.kycInformation}>
            <KycInformation />
          </PrivateRouter>
        } />
        {/* kycAddIdProof */}
        <Route path={AppRoutes.kycAddIdProof} element={
          <PrivateRouter path={AppRoutes.kycAddIdProof}>
            <KycAddId />
          </PrivateRouter>
        } />

        {/* Maintance Request */}
        <Route path={AppRoutes.maintanceRequest} element={
          <PrivateRouter path={AppRoutes.maintanceRequest}>
            <MaintanceRequest />
          </PrivateRouter>
        } />

        {/* General Request */}
        <Route path={AppRoutes.generalRequest} element={
          <PrivateRouter path={AppRoutes.generalRequest}>
            <GeneralRequest />
          </PrivateRouter>
        } />

        {/* Amenities Booking */}
        <Route path={AppRoutes.amenities} element={
          <PrivateRouter path={AppRoutes.amenities}>
            <AmenitiesBooking />
          </PrivateRouter>
        } />


        {/* Get Support */}
        <Route path={AppRoutes.getSupport} element={
          <PrivateRouter path={AppRoutes.getSupport}>
            <GetSupport />
          </PrivateRouter>
        } />

        {/* Get Support Detailed */}
        <Route path={AppRoutes.getSupportDetailed} element={
          <PrivateRouter path={AppRoutes.getSupportDetailed}>
            <GetSupportDetailed />
          </PrivateRouter>
        } />

        {/* Request */}
        <Route path={AppRoutes.request} element={
          <PrivateRouter path={AppRoutes.request}>
            <Request />
          </PrivateRouter>
        } />

        {/* RequestView */}
        <Route path={AppRoutes.requestView} element={
          <PrivateRouter path={AppRoutes.requestView}>
            <RequestView />
          </PrivateRouter>
        } />

        {/* Pay Request Invoice List */}
        <Route path={AppRoutes.pay_request_invoice_list} element={
          <PrivateRouter path={AppRoutes.pay_request_invoice_list}>
            <PayRequestInvoiceList />
          </PrivateRouter>
        } />
        {/*Inspection Orders */}
        <Route path={AppRoutes.inspection} element={
          <PrivateRouter path={AppRoutes.inspection}>
            <Inspection />
          </PrivateRouter>
        } />

        {/*Inspection Details */}
        <Route path={AppRoutes.inspectiondetails} element={
          <PrivateRouter path={AppRoutes.inspectiondetails}>
            <InspectionDetails />
          </PrivateRouter>
        } />


        {/* companyselect */}
        <Route path={AppRoutes.companySelect} element={<CompanySelect />} />

        {/* assets */}
        <Route path={AppRoutes.assets} element={
          <PrivateRouter path={AppRoutes.assets}>
            <Assets />
          </PrivateRouter>
        } />

        {/* contracts */}
        <Route path={AppRoutes.contracts} element={
          <PrivateRouter path={AppRoutes.contracts}>
            <Contracts />
          </PrivateRouter>
        } />


        {/* contractsDetails */}
        <Route path={AppRoutes.contractDetails} element={
          <PrivateRouter path={AppRoutes.contractDetails}>
            <ContractDetails />
          </PrivateRouter>
        } />
        {/* Utilities */}
        <Route path={AppRoutes.utilities} element={
          <PrivateRouter path={AppRoutes.utilities}>
            <Utilities />
          </PrivateRouter>
        } />

        {/* Utilities List */}
        <Route path={AppRoutes.utilitiesList} element={
          <PrivateRouter path={AppRoutes.utilitiesList}>
            <UtilitiesList />
          </PrivateRouter>
        } />


        {/* file Manager */}
        <Route path={AppRoutes.filemanager} element={
          <PrivateRouter path={AppRoutes.filemanager}>
            <Filemanager />
          </PrivateRouter>
        } />

        {/* filemanager Documents */}
        <Route path={AppRoutes.fileManagerDocs} element={
          <PrivateRouter path={AppRoutes.fileManagerDocs}>
            <FilemanagerDocs />
          </PrivateRouter>
        } />


        {/* contractsDetails */}
        {/* Welcome Page */}
        <Route path={AppRoutes.welcomepage} element={<WelcomePage />} />

        {/* Setup Page */}
        <Route path={AppRoutes.setup} element={<Setup />} />


        {/*Parking Slot Page */}
        <Route path={AppRoutes.parkingSlot} element={
          <PrivateRouter path={AppRoutes.parkingSlot}>
            <ParkingSlot />
          </PrivateRouter>
        } />

        {/*Vehicle Page */}
        <Route path={AppRoutes.vehicle} element={
          <PrivateRouter path={AppRoutes.vehicle}>
            <Vehicle />
          </PrivateRouter>
        } />

        {/*Vehicle View Page */}
        <Route path={AppRoutes.vehicleView} element={
          <PrivateRouter path={AppRoutes.vehicleView}>
            <VehicleView />
          </PrivateRouter>
        } />

        {/*Add Vehicle Page */}
        <Route path={AppRoutes.addVehicle} element={
          <PrivateRouter path={AppRoutes.addVehicle}>
            <AddVehicle />
          </PrivateRouter>
        } />


        {/* spending page */}
        <Route path={AppRoutes.spending} element={
          <PrivateRouter path={AppRoutes.spending}>
            <Spending />
          </PrivateRouter>
        } />

        {/* investment page */}
        <Route path={AppRoutes.investment} element={
          <PrivateRouter path={AppRoutes.investment}>
            <Investment />
          </PrivateRouter>
        } />

        {/* Contacts page */}
        <Route path={AppRoutes.contacts} element={
          <PrivateRouter path={AppRoutes.contacts}>
            <Contacts />
          </PrivateRouter>
        } />

        {/* Create Contacts page */}
        <Route path={AppRoutes.createContacts} element={
          <PrivateRouter path={AppRoutes.createContacts}>
            <CreateContact />
          </PrivateRouter>
        } />

        {/* Announcement */}
        <Route path={AppRoutes.announcement} element={
          <PrivateRouter path={AppRoutes.announcement}>
            <Announcement />
          </PrivateRouter>
        } />

        {/* Announcement View */}
        <Route path={AppRoutes.announcementView} element={
          <PrivateRouter path={AppRoutes.announcementView}>
            <AnnouncementView />
          </PrivateRouter>
        } />

        {/* payment View */}
        <Route path={AppRoutes.payment} element={
          <PrivateRouter path={AppRoutes.payment}>
            <Payment />
          </PrivateRouter>
        } />

        {/* Addons  */}
        <Route path={AppRoutes.addons} element={
          <PrivateRouter path={AppRoutes.addons}>
            <AddOnsService />
          </PrivateRouter>
        } />
        {/* Addons Detailed  */}
        <Route path={AppRoutes.addonsDetailed} element={
          <PrivateRouter path={AppRoutes.addonsDetailed}>
            <AddOnsDetailedService />
          </PrivateRouter>
        } />
        {/* Landlord Approval */}
        <Route path={AppRoutes.landlordApprovalView} element={
          <PrivateRouter path={AppRoutes.landlordApprovalView}>
            <LandlordApproval />
          </PrivateRouter>
        } />
        {/* Landlord Approval */}
        <Route path={AppRoutes.landLordApprovalList} element={
          <PrivateRouter path={AppRoutes.landLordApprovalList}>
            <ApprovalList />
          </PrivateRouter>
        } />
        {/* Choose Appliance */}
        <Route path={AppRoutes.chooseAppliance} element={
          <PrivateRouter path={AppRoutes.chooseAppliance}>
            <ChooseAppliance />
          </PrivateRouter>
        } />
        {/* Contract History */}
        <Route path={AppRoutes.contractHistory} element={
          <PrivateRouter path={AppRoutes.contractHistory}>
            <ContractHistory />
          </PrivateRouter>
        } />
        {/* View Image */}
        <Route path={AppRoutes.viewImage} element={
          <PrivateRouter path={AppRoutes.viewImage}>
            <ViewImage />
          </PrivateRouter>
        } />
        <Route path={AppRoutes.qrCode} element={
          <PrivateRouter path={AppRoutes.qrCode}>
            <QrCode />
          </PrivateRouter>
        } />
        <Route path={AppRoutes.BOMDetails} element={
          <PrivateRouter path={AppRoutes.BOMDetails}>
            <BOMDetails />
          </PrivateRouter>
        } />
        {/* EXTERNAL SITE */}
        <Route path={AppRoutes.explore} element={
          <PrivateRouter path={AppRoutes.explore}>
            <ExternalSite />
          </PrivateRouter>
        } />
        <Route path={AppRoutes.surveyList} element={
          <PrivateRouter path={AppRoutes.surveyList}>
            <SurveyList />
          </PrivateRouter>
        } />
        <Route path={AppRoutes.surveyForm} element={
          <PrivateRouter path={AppRoutes.surveyForm}>
            <SurveyForm />
          </PrivateRouter>
        } />
        <Route path={AppRoutes.propertyUnitNew} element={
          <PrivateRouter path={AppRoutes.propertyUnitNew}>
            <PropertyUnitNew />
          </PrivateRouter>
        } />

        <Route path={AppRoutes.propertyUnitView} element={
          <PrivateRouter path={AppRoutes.propertyUnitView}>
            <PropertyUnitView />
          </PrivateRouter>
        } />

        <Route path={AppRoutes.propertyDetails} element={
          <PrivateRouter path={AppRoutes.propertyDetails}>
            <PropertyDetails />
          </PrivateRouter>
        } />

        <Route path={AppRoutes.assetViewPage} element={
          <PrivateRouter path={AppRoutes.assetViewPage}>
            <AssetViewPage />
          </PrivateRouter>
        } />

        <Route path={AppRoutes.agreementDocument} element={
          <PrivateRouter path={AppRoutes.agreementDocument}>
            <Documents />
          </PrivateRouter>
        } />        

        {/* Login Route */}
        <Route path={AppRoutes.login} element={<Login />} />
        <Route path={AppRoutes.welcome} element={<Welcome />} />

        <Route path={AppRoutes.restoreErrorHandling} element={<RestoreErrorHandling />} />



        {/* For unknow/non-defined path */}
        <Route path="*" element={<NotFound />} />
      </Routes >
    </BrowserRouter >
  );
}

export default RouterApp;











