import { Regular, SemiBold } from "../../utils";


export const ImageCardStyles = {
  image: {
    width: "120px",
    height: "120px",
    borderRadius: "6px !important",
  },
  imgcard: {
    position: "relative",
    borderRadius: "6px !important",
    "&:hover": {
      opacity: "16",
      // backgroundColor: 'red'
    },
  },
  imgtext: {
    position: "absolute",
    bottom: 0,
    color: "primary.contrastText",
    top: "70%",
    marginLeft: "6px",
  },
  dot: {
    width: "4px",
    height: "4px",
    backgroundColor: "primary.contrastText",
    borderRadius: "50%",
    marginTop: "5px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  deleteBox: {
    borderRadius: "4px",
    border: "1px solid #CED3DD",
    padding: "0px 4px",
    height: "24px",
    width: "24px",
  },
  imgName: {
    fontSize: "0.875rem",
    color: "text.main",
    fontFamily: "SemiBold",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textName: {
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: Regular,
  },
};