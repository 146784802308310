import { Bold, Regular, SemiBold } from "../../utils";

export const RaisedOnStyles = {

    root: {
        border: "1px solid #E4E8EE",
        padding: "16px",
        borderRadius: "12px",
        cursor: "pointer",
        marginBottom: "16px",
        position: "relative"
    },
    arrowIcon: {
        color: "text.main",
        fontSize: "1rem"
    },
    title: {
        color: "text.main",
        fontFamily: Bold,
        fontSize: "1rem",
    },
    subTitle: {
        color: "color.secondary",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        marginBottom: "5px",
        width: {
            sm: "99%",
            xs: "80%"
        }

    },
    exampleText: {
        color: "primary.Tertiary",
        fontFamily: Regular,
        fontSize: "0.75rem",
        width: {
            sm: "99%",
            xs: "80%"
        }
    },
    heading: {
        color: "text.Teritary",
        fontSize: "0.875rem",
        fontFamily: Bold,
        marginBottom: "12px"
    },
    scanAssetBox: {
        backgroundImage: `url("../images/scanInspectionItemQR.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#FFF8E7",
        border: "1px solid #FFEBB7",
        borderRadius: "12px",
        height: "125px",
        padding: "12px",
        // boxShadow: "0px 3px 15px #4250682E",
        position: "relative"
    },
    searchAssetBox: {
        backgroundImage: `url("../images/searchInspectionItem.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#E6EFFF",
        border: "1px solid #C9D9F6",
        borderRadius: "12px",
        height: "125px",
        padding: "12px",
        // boxShadow: "0px 3px 15px #4250682E",
        position: "relative"
    },
    assettext: {
        color: "#031629",
        fontSize: "0.875rem",
        fontFamily: Bold
    },
    fixbtn: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        padding: "12px"
    }
}