import * as React from "react"
const UpfrontChargeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42} height={37.428} {...props}>
    <defs>
      <filter
        id="a"
        width={42}
        height={37.428}
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 138660" transform="translate(2311 21000)">
      <rect
        width={36}
        height={36}
        fill="#fff8e7"
        data-name="Rectangle 58896"
        rx={6}
        transform="translate(-2308 -20999)"
      />
      <g filter="url(#a)" transform="translate(-2311 -21000)">
        <path
          fill="#ffd158"
          d="M32.429 9h-.571a.572.572 0 0 1-1.143 0h-1.144a.572.572 0 1 1-1.143 0h-1.142a.572.572 0 1 1-1.143 0H25a.572.572 0 1 1-1.143 0h-1.143a.572.572 0 1 1-1.143 0h-1.142a.572.572 0 1 1-1.143 0h-1.143A.572.572 0 1 1 17 9h-1.143a.571.571 0 1 1-1.143 0h-1.143a.571.571 0 1 1-1.142 0h-1.143a.571.571 0 1 1-1.143 0h-.572A.572.572 0 0 0 9 9.571v17.715a1.143 1.143 0 0 0 1.143 1.143h21.714A1.143 1.143 0 0 0 33 27.286V9.571A.572.572 0 0 0 32.429 9Z"
          data-name="Path 123720"
        />
      </g>
      <path
        fill="#aa821b"
        d="M-2280.734-20985.285h-17.815c-.328 0-.594-.128-.594-.286v-.571c0-.158.266-.286.594-.286h17.815c.328 0 .594.128.594.286v.571c-.003.158-.266.286-.594.286Z"
        data-name="Path 123721"
      />
      <path
        fill="#aa821b"
        d="M-2284.297-20983h-14.252c-.328 0-.594-.128-.594-.286v-.571c0-.158.266-.286.594-.286h14.252c.328 0 .594.128.594.286v.571c0 .158-.266.286-.594.286Z"
        data-name="Path 123722"
      />
      <path
        fill="#aa821b"
        d="M-2291.143-20974.429a.761.761 0 0 1-.65-.346.99.99 0 0 1-.146-.549 4.584 4.584 0 0 1 .517-1.679 4.8 4.8 0 0 0-.515-1.636c-.168-.286-.3-.362-.349-.362-.6 0-1.042 1.253-1.154 1.824a.572.572 0 0 1-1.122-.22c.056-.28.591-2.746 2.276-2.746.858 0 1.4.843 1.721 1.814a1.657 1.657 0 0 1 1.136-.671c1.139 0 1.513 1.321 1.787 2.286l.03.1.069-.138c.365-.734.778-1.566 1.543-1.566a.572.572 0 0 1 .047 1.141 3.771 3.771 0 0 0-.567.933c-.33.666-.616 1.24-1.195 1.24-.63 0-.814-.65-1.027-1.4-.113-.4-.413-1.455-.687-1.455a3.023 3.023 0 0 0-.813 1.067 6.539 6.539 0 0 1 .042.707 3.191 3.191 0 0 1-.118.95.865.865 0 0 1-.825.706Z"
        data-name="Path 123724"
      />
    </g>
  </svg>
)
export default UpfrontChargeIcon
