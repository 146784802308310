import { Bold, FontSizeCalc, SemiBold } from "../../utils";

export const styles = {
    root: {
        // width: "100%",
        // padding:"0px"
    },
    fileroot: {
        width: "100%",
        color: "primary.main",
        cursor: "pointer",
        border: "2px dashed",
        borderColor: "border.secondary",
        borderRadius: "4px",
        padding: "28px",
        backgroundColor: "#FFEBE4",
        margin: "auto",
        textAlign: "center !important"
    },
    imgContainer: {
        width: "100%",
    },
    delete: {
        color: "red",
        position: "absolute",
        top: "4px",
        right: "8px",
        backgroundColor: "white",
    },
    uploadTittle: {
        fontSize: "0.875rem",
        color: "text.text",
        fontFamily: SemiBold,
        marginInlineStart: "16px"
    },
    uploadcount: {
        fontSize: "0.875rem",
        color: "text.text",
        fontFamily: SemiBold,
        paddingLeft: "16px",
    },
    overFlow: {
        overflow: "auto",
        overflowY: "hidden"
    },
    images:{
        // display:'flex',
        // flexWrap:'wrap',
        alignItems:"center",
        marginTop:'16px'
    },
    divider: {
        marginTop: "16px"
    },
    videoUploadBox: {
        height: "124.67px",
        borderRadius: '4px',
        background: "#FFEBE4",
        border: "2px dashed #E4E8EE",
        cursor: "pointer",
    },
    cancelButton: {
        border: "1px solid #CED3DD",
        borderRadius: "4px",
        padding: "8px",
        backgroundColor: "white",
        color: "#091B29",
        textTransform: "capitalize",
        fontFamily: SemiBold,
        "&:hover": {
            backgroundColor: "white",
        },
    },
    recordStopButton: {
        border: `1px solid #F15A29`,
        borderRadius: "4px",
        padding: "8px",
        backgroundColor: "#F15A29",
        color: "white",
        textTransform: "capitalize",
        fontFamily: SemiBold,
        "&:hover": {
            backgroundColor: "#F15A29",
        },
    },
    reTakeButton: {
        border: `1px solid #F15A29`,
        borderRadius: "4px",
        padding: "8px",
        backgroundColor: "white",
        color: "#F15A29",
        textTransform: "capitalize",
        fontFamily: SemiBold,
        "&:hover": {
            backgroundColor: "white",
        },
    },
    addVideoButton: {
        border: `1px solid #F15A29`,
        borderRadius: "4px",
        padding: "8px",
        backgroundColor: "#F15A29",
        color: "white",
        textTransform: "capitalize",
        fontFamily: SemiBold,
        "&:hover": {
            backgroundColor: "#F15A29",
        },
    },
    audioTimer: {
        fontSize: FontSizeCalc(40),
        fontFamily: Bold,
        color: "#091B29",
    },
}