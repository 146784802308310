
import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils";

export const landlordApprovalstyle = {
  container: {
    backgroundColor: "background.main",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    padding: "80px 0px 0px",
  },
  root: {
    padding: "0px 16px 16px 16px",
    position: "relative",
    width: '100%',
    height: '100%',
    overflow: "auto",
    backgroundColor: "background.main",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  detailsBox: {
    marginTop: "16px",
    padding: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px"
  },
  detialsBoxHeader: {
    fontSize: "0.75rem",
    color: "text.secondary",
    fontFamily: SemiBold
  },
  contractValueBox: {
    marginTop: "10px",
    backgroundColor: "#EDF7F8",
    padding: "8px",
    borderRadius: "6px"
  },
  contractValueText: {
    color: "#6DAFB3",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    marginInlineStart: "6px"
  },
  contractValue: {
    color: "#6DAFB3",
    fontSize: "0.75rem",
    fontFamily: Bold
  },
  detailsText: {
    color: "#071741",
    fontFamily: SemiBold,
    fontSize: "0.875rem"
  },
  detailsSubtext: {
    color: "text.Teritary",
    fontFamily: Regular,
    fontSize: "0.75rem"
  },
  divider: {
    borderRadius: "50%",
    height: "8px",
    width: "8px",
    backgroundColor: "#E4E8EE",
    border: "none",
    marginInline: "8px"
  },
  lineDivider: {
    marginTop: "16px",
    marginBottom: "16px",
    color: "#E4E8EE"
  },
  avatar: {
    height: "48px",
    width: "48px"
  },
  drawerText: {
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginBottom: "8px"
  },
  signRoot: {
    borderRadius: "8px",
    width: "100%"
    // width: "32.5rem"
    // [theme.breakpoints.up('sm')]: {
    //     width: "32.5rem",
    // },
    // [theme.breakpoints.down('sm')]: {
    //     width: "20.5rem",
    // },
  },
  clearBtn: {
    position: "absolute",
    bottom: "20px",
    textTransform: "capitalize"
  },
  signatureBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
  },
  contactDetails: {
    border: "1px solid #E4E8EE",
    borderRadius: "12px",
  },
  contactName: {
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    color: "#071741"
  },
  contactSubText: {
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: "text.secondary"
  },
  kyctext: {
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: "text.Teritary"
  },
  kycDetails: {
    border: "1px solid #E4E8EE",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  kycImage: {
    width: "48px",
    height: "48px",
    justifyContent: "center",
    display: "flex",
    borderRadius: "8px"
  },
  listCard: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    padding: "16px",
    backgroundColor: "#FFFFFF"
  },
  approvalImage: {
    width: "42px",
    height: "42px",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  agreementTitle:{
    fontSize:FontSizeCalc(14),
    fontFamily:Bold,
    color:"text.primary"
  },
  approvalStatus:{
    fontSize:FontSizeCalc(12),
    fontFamily:Bold,
  },
  approvalStatusBox:{
    borderRadius:"11px",
    padding:"4px 12px",
    height:"25px"
  },
  approvalDate:{
    fontSize:FontSizeCalc(12),
    fontFamily:Regular,
    color:'text.secondary'
  },
  documentImg:{
    height:"50px",
    width:"50px",
    border:"1px solid #CED3DD",
    borderRadius:"4px",
    padding:"4px",
    objectFit:"contain"
  },
}