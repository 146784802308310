import React from "react";
import { AddressEdit } from "./addressEdit";
import { withTranslation } from 'react-i18next';


class AddressEditParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <AddressEdit {...this?.props}/>;
  }
}


export default withTranslation('profile')(AddressEditParent);
