import * as React from "react"
const PropUnitIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      fill="#bf2839"
      d="M8.377 0a2.438 2.438 0 0 0-1.511.522L1.331 4.883A3.492 3.492 0 0 0 0 7.624v8.419a1.64 1.64 0 0 0 1.629 1.63h13.5a1.64 1.64 0 0 0 1.629-1.629v-8.42a3.492 3.492 0 0 0-1.335-2.741L9.887.522A2.438 2.438 0 0 0 8.377 0Zm0 1.392a1.044 1.044 0 0 1 .647.226l5.535 4.361a2.091 2.091 0 0 1 .8 1.644v8.42a.222.222 0 0 1-.233.233H1.629a.222.222 0 0 1-.229-.233V7.624a2.091 2.091 0 0 1 .8-1.644l5.535-4.361a1.044 1.044 0 0 1 .642-.226ZM3.956 8.83a.7.7 0 0 0-.7.7v3.723a.7.7 0 0 0 .7.7h2.792a.7.7 0 0 0 .7-.7V9.528a.7.7 0 0 0-.7-.7Zm6.05 0a.7.7 0 0 0-.7.7v3.723a.7.7 0 0 0 .7.7H12.8a.7.7 0 0 0 .7-.7V9.528a.7.7 0 0 0-.7-.7Zm-5.352 1.4h1.4v2.323h-1.4Zm6.05 0h1.4v2.323H10.7Z"
      data-name="Group 138394"
    />
  </svg>
)
export default PropUnitIcon
