import * as React from "react"
const HijirCalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g fill="#4e5a6b" data-name="Group 138194">
      <path
        d="M3.972 0A3.986 3.986 0 0 0 0 3.972v14.056A3.986 3.986 0 0 0 3.972 22h14.056A3.986 3.986 0 0 0 22 18.028V3.972A3.986 3.986 0 0 0 18.028 0Zm0 1.833h14.056a2.126 2.126 0 0 1 2.139 2.139v.917H1.833v-.917a2.126 2.126 0 0 1 2.139-2.139ZM1.833 6.722h18.334v11.306a2.126 2.126 0 0 1-2.139 2.139H3.972a2.126 2.126 0 0 1-2.139-2.139Z"
        data-name="icons8-date (1)"
      />
      <path d="M11.007 9a4 4 0 0 0 0 8 4.2 4.2 0 0 0 2.266-.65.3.3 0 0 0-.166-.55h-.3a2.8 2.8 0 0 1 0-5.6h.3a.3.3 0 0 0 .165-.55A4.2 4.2 0 0 0 11.007 9Zm2.3 2.4a.266.266 0 0 0-.238.147l-.377.753-.845.121a.267.267 0 0 0-.147.456l.609.585-.143.825a.266.266 0 0 0 .385.282l.756-.392.756.392a.265.265 0 0 0 .123.03.267.267 0 0 0 .263-.312l-.142-.825.609-.585a.267.267 0 0 0-.147-.456l-.846-.121-.377-.753a.267.267 0 0 0-.239-.147Z" />
    </g>
  </svg>
)
export default HijirCalendarIcon
