import * as React from "react"
const ContractSignedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M2.419 0A2.431 2.431 0 0 0 0 2.419v16.662A2.431 2.431 0 0 0 2.419 21.5h12.362a2.431 2.431 0 0 0 2.419-2.419V7.794a.806.806 0 0 0-.236-.57l-.008-.008-6.98-6.98A.806.806 0 0 0 9.406 0Zm0 1.612H8.6v4.569A2.431 2.431 0 0 0 11.019 8.6h4.569v10.481a.794.794 0 0 1-.806.806H2.419a.794.794 0 0 1-.806-.806V2.419a.794.794 0 0 1 .806-.807Zm7.794 1.14 4.235 4.235h-3.429a.794.794 0 0 1-.806-.806Zm-4.031 7.46a2.37 2.37 0 0 0-1.707.679 2.819 2.819 0 0 0-.712 2.008 5.643 5.643 0 0 0 .915 3.055 2.254 2.254 0 0 1-.647.17.806.806 0 1 0 0 1.613 4.046 4.046 0 0 0 1.946-.506 3.5 3.5 0 0 0 1.816.506 2.355 2.355 0 0 0 1.5-.525 3.094 3.094 0 0 0 .744-.983l.047-.091a2.276 2.276 0 0 0 .519 1.01 2.062 2.062 0 0 0 1.488.589 1.8 1.8 0 0 0 1.5-.907 2.323 2.323 0 0 0 .371-1.639.806.806 0 1 0-1.592.254.909.909 0 0 1-.134.511c-.1.158-.194.168-.145.168-.264 0-.271-.038-.313-.083a1.087 1.087 0 0 1-.173-.4 5.8 5.8 0 0 0-.233-.831 1.386 1.386 0 0 0-.372-.541 1.269 1.269 0 0 0-.792-.294 1.491 1.491 0 0 0-1.173.7 7.911 7.911 0 0 0-.428.808 1.69 1.69 0 0 1-.352.5.6.6 0 0 1-.466.147 1.756 1.756 0 0 1-.414-.11c.042-.053.115-.087.154-.141A5.236 5.236 0 0 0 8.6 12.9a2.812 2.812 0 0 0-.713-2.005 2.371 2.371 0 0 0-1.706-.682Zm0 1.613a.717.717 0 0 1 .553.194 1.176 1.176 0 0 1 .253.878 4.143 4.143 0 0 1-.756 2.027c-.054.074-.135.113-.193.182a4.223 4.223 0 0 1-.663-2.206 1.183 1.183 0 0 1 .253-.881.716.716 0 0 1 .552-.194Z"
      data-name="Group 138043"
    />
  </svg>
)
export default ContractSignedIcon
