import * as React from "react"
const BalconyIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.25} height={18} {...props}>
    <path
      fill="#e2ae24"
      stroke="#e2ae24"
      strokeWidth={0.25}
      d="M4.425.727a.613.613 0 0 0-.614.614v7.977h1.223V1.954h8.182v7.364h1.227V1.341a.613.613 0 0 0-.618-.614ZM2.099 7.416a1.974 1.974 0 1 0 0 3.947h.046a5.6 5.6 0 0 0-.587 2.251 5.579 5.579 0 0 0 .58 2.233h-.17a.614.614 0 1 0 0 1.227l10.551.012a.614.614 0 0 0 .182 0h3.585a.614.614 0 1 0 0-1.227h-.179a5.6 5.6 0 0 0 .587-2.25 5.6 5.6 0 0 0-.587-2.25h.156a.614.614 0 0 0 .325-.089 1.956 1.956 0 0 0 1.537-1.88 1.983 1.983 0 0 0-1.974-1.974.614.614 0 1 0 0 1.227.746.746 0 1 1 0 1.492H9.225a.614.614 0 0 0-.2 0H2.099a.747.747 0 1 1 0-1.493.614.614 0 1 0 0-1.227Zm1.379 3.947h1.57v4.487H3.472a4.928 4.928 0 0 1-.687-2.235 4.939 4.939 0 0 1 .693-2.252Zm2.8 0h2.236v4.491H6.278Zm3.464 0h2.256v4.5H9.742Zm3.483 0h1.551a4.939 4.939 0 0 1 .694 2.25 4.938 4.938 0 0 1-.693 2.248h-1.552Z"
      data-name="Group 138091"
    />
  </svg>
)
export default BalconyIcon
