import { Box, Stack } from "@mui/material";
import React from "react";
import { LoderSimmer } from "../../components";
import ScrollContainer from "react-indiana-drag-scroll";

export const classes = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
    },
    borderBox: {
        borderRadius: "12px",
        border: "1px solid #CED3DD",
        padding: "16px 16px 0px 16px"
    }
}

export const PropertyUnitViewPageSimmer = () => {
    return (
        <>
            <Box>
                <Box sx={classes?.bg}>
                    <LoderSimmer card count={1} width={"auto"} size={150} variant={"rounded"} animation={"wave"} />
                    <Stack direction={"row"} spacing={2}>
                        <LoderSimmer card count={1} width={"100px"} size={22} variant={"rounded"} animation={"wave"} />
                        <LoderSimmer card count={1} width={"100px"} size={22} variant={"rounded"} animation={"wave"} />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack>
                            <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                            <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                        </Stack>
                        <LoderSimmer card count={1} width={"50px"} size={12} variant={"rounded"} animation={"wave"} />
                    </Stack>
                    <ScrollContainer className="scroll-container">
                        <Stack direction={"row"} spacing={1}>
                            {
                                [1, 2, 3]?.map(() => {
                                    return (
                                        <Stack sx={{ ...classes.borderBox, width: "150px" }}>
                                            <LoderSimmer card count={1} width={"70px"} size={12} variant={"rounded"} animation={"wave"} />
                                            <Box mt={1}>
                                                <LoderSimmer card count={1} width={"120px"} size={12} variant={"rounded"} animation={"wave"} />
                                                <LoderSimmer card count={1} width={"120px"} size={12} variant={"rounded"} animation={"wave"} />
                                            </Box>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                    </ScrollContainer>

                    <Stack sx={classes.borderBox} direction={"row"} alignItems={"center"} spacing={1} mt={2}>
                        <LoderSimmer card count={1} width={"40px"} size={40} variant={"rounded"} animation={"wave"} />
                        <Stack>
                            <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                            <LoderSimmer card count={1} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                        </Stack>
                    </Stack>
                </Box>

                <Box mt={2} sx={classes.bg}>
                    <LoderSimmer card count={1} width={"70px"} size={12} variant={"rounded"} animation={"wave"} />

                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <LoderSimmer card count={1} width={"40px"} size={40} variant={"rounded"} animation={"wave"} />
                        <Stack>
                            <LoderSimmer card count={1} width={"180px"} size={12} variant={"rounded"} animation={"wave"} />
                            <LoderSimmer card count={1} width={"180px"} size={12} variant={"rounded"} animation={"wave"} />
                        </Stack>
                    </Stack>
                </Box>


                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={2} sx={classes.bg}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <LoderSimmer card count={1} width={"40px"} size={40} variant={"rounded"} animation={"wave"} />
                        <LoderSimmer card count={2} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                    </Stack>
                    <LoderSimmer card count={1} width={"20px"} size={12} variant={"rounded"} animation={"wave"} />
                </Stack>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={2} sx={classes.bg}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <LoderSimmer card count={1} width={"40px"} size={40} variant={"rounded"} animation={"wave"} />
                        <LoderSimmer card count={2} width={"150px"} size={12} variant={"rounded"} animation={"wave"} />
                    </Stack>
                    <LoderSimmer card count={1} width={"20px"} size={12} variant={"rounded"} animation={"wave"} />
                </Stack>


            </Box>
        </>
    )
}