import * as React from "react"
const EmiPaymentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42.023} height={42} {...props}>
    <defs>
      <filter
        id="a"
        width={42.023}
        height={42}
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.078} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 138659" transform="translate(2310.999 21002)">
      <rect
        width={36}
        height={36}
        fill="#eff5e6"
        data-name="Rectangle 58896"
        rx={6}
        transform="translate(-2308 -20999)"
      />
      <g transform="translate(-2304.98 -20996)">
        <g filter="url(#a)" transform="translate(-6.02 -6)">
          <path
            fill="#91bb51"
            d="M22.72 9.763a2.293 2.293 0 0 0 2.392.658l.1-.03a2.293 2.293 0 0 1 2.922 1.687l.013.057a2.294 2.294 0 0 0 1.736 1.736l.066.015a2.293 2.293 0 0 1 1.69 2.913l-.034.111a2.292 2.292 0 0 0 .657 2.378l.01.009a2.293 2.293 0 0 1 0 3.407l-.01.009a2.294 2.294 0 0 0-.657 2.378l.034.111a2.293 2.293 0 0 1-1.69 2.913l-.066.015a2.294 2.294 0 0 0-1.736 1.736l-.013.057a2.294 2.294 0 0 1-2.922 1.687l-.1-.03a2.3 2.3 0 0 0-2.392.658 2.293 2.293 0 0 1-3.415 0 2.293 2.293 0 0 0-2.392-.658l-.1.03a2.293 2.293 0 0 1-2.922-1.687l-.013-.057a2.294 2.294 0 0 0-1.736-1.736l-.066-.014a2.293 2.293 0 0 1-1.685-2.916l.034-.111a2.292 2.292 0 0 0-.657-2.378l-.01-.009a2.293 2.293 0 0 1 0-3.407l.01-.009a2.294 2.294 0 0 0 .657-2.378l-.034-.108a2.293 2.293 0 0 1 1.69-2.913l.066-.015a2.294 2.294 0 0 0 1.736-1.736l.013-.057a2.294 2.294 0 0 1 2.924-1.687l.1.03a2.3 2.3 0 0 0 2.392-.658 2.292 2.292 0 0 1 3.408-.001Z"
            data-name="Path 123716"
          />
        </g>
        <circle
          cx={9.143}
          cy={9.143}
          r={9.143}
          fill="#c0d89b"
          data-name="Ellipse 131002"
          transform="translate(5.849 5.857)"
        />
        <path
          fill="#4c7015"
          d="M12.166 15.396h-1.745v1.508h2.073v.953H9.272V12.17h3.21v.953h-2.061v1.344h1.745Z"
          data-name="Path 123717"
        />
        <path
          fill="#4c7015"
          d="m14.796 12.17 1.074 4.113 1.07-4.113h1.5v5.687h-1.151v-1.534l.106-2.371-1.137 3.91h-.781l-1.137-3.91.106 2.371v1.539h-1.149V12.17Z"
          data-name="Path 123718"
        />
        <path
          fill="#4c7015"
          d="M20.706 17.857h-1.143V12.17h1.143Z"
          data-name="Path 123719"
        />
      </g>
    </g>
  </svg>
)
export default EmiPaymentIcon
