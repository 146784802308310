import * as React from "react"
const AmenitiesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17.723}
    data-name="Group 137856"
    {...props}
  >
    <path fill="none" d="M0 0h18v17.723H0z" data-name="Rectangle 58887" />
    <path
      fill="#f15a29"
      d="M7.338 0a2 2 0 0 0-1.971 1.772H3.794A2 2 0 0 0 1.8 3.766v11.963a2 2 0 0 0 1.994 1.994h10.191a2 2 0 0 0 1.994-1.994V3.766a2 2 0 0 0-1.994-1.994h-1.574A2 2 0 0 0 10.44 0Zm0 1.329h3.1a.665.665 0 1 1 0 1.329h-3.1a.665.665 0 0 1 0-1.329ZM5.566 5.317h6.646a.665.665 0 1 1 0 1.329H5.566a.665.665 0 1 1 0-1.329Zm0 2.658h3.545a.665.665 0 1 1 0 1.329H5.566a.665.665 0 1 1 0-1.329Zm7.09 2.658a.665.665 0 0 1 .47 1.134l-3.1 3.1a.664.664 0 0 1-.885.049l-1.8-1.44-1.8 1.44a.665.665 0 0 1-.83-1.038l2.215-1.772a.666.666 0 0 1 .831 0l1.751 1.4 2.681-2.681a.662.662 0 0 1 .467-.191Z"
      data-name="icons8-report (1)"
    />
  </svg>
)
export default AmenitiesIcon
