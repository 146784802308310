import * as React from "react"
const BathroomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.257} height={18} {...props}>
    <path
      fill="#e2ae24"
      stroke="#e2ae24"
      strokeWidth={0.25}
      d="M5.045.91a2.871 2.871 0 0 0-2.866 2.861v4.088H.745a.613.613 0 0 0-.146 1.21l.886 5.166a2.665 2.665 0 0 0 1.926 2.115.613.613 0 0 0 1.185.093h9.059a.613.613 0 0 0 1.185-.093 2.666 2.666 0 0 0 1.927-2.116l.886-5.166a.613.613 0 0 0-.146-1.21H3.406V3.771a1.632 1.632 0 0 1 3.261-.1 1.844 1.844 0 0 0-1.17 1.321.347.347 0 0 0 .348.418h2.893a.347.347 0 0 0 .346-.411 1.843 1.843 0 0 0-1.187-1.328A2.869 2.869 0 0 0 5.045.91Zm-3.2 8.176h14.563l-.847 4.943a1.421 1.421 0 0 1-1.316 1.181h-.013a.613.613 0 0 0-.089.009H4.119a.613.613 0 0 0-.109-.009 1.421 1.421 0 0 1-1.311-1.18Z"
      data-name="Group 137942"
    />
  </svg>
)
export default BathroomIcon
