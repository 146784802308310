export const stopCamrecorder = ({
  videoRef = {},
  mediaRecorderRef = {},
}) => {
  const stream = videoRef.current.srcObject;
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    videoRef.current.srcObject = null;
    mediaRecorderRef.current = null;
  }
}

export const stopAudioRecorder = ({
  audioRef = {},
  mediaRecorderRef = {},
}) => {
  const stream = audioRef.current;
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    audioRef.current = null;
    mediaRecorderRef.current = null;
  }
}