import { Bold, FontSizeCalc, Italic, Regular, SemiBold } from "../../utils";

export const PropertyUnitNewStyle = {
    title: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "text.Teritary",
        // textTransform: "uppercase"
    },
    box: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 0px 20px #0000001A",
        padding: "16px",
        zIndex: 999,
        position: "relative"
    },
    unitTypeBox: {
        backgroundColor: "background.warningLight",
        color: "text.warningDark",
        padding: "4px",
        borderRadius: "4px"
    },
    unitAndSqrtText: {
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
    },
    sqrtBox: {
        backgroundColor: "background.successLight",
        color: "text.successDark",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        padding: "4px",
        borderRadius: "4px"
    },
    viewMoreText: {
        color: "primary.main",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    unitTitle: {
        color: "#000",
        fontSize: FontSizeCalc(16),
        fontFamily: SemiBold,
    },
    boldTitle: {
        color: "#000",
        fontSize: FontSizeCalc(16),
        fontFamily: Bold,
    },
    secTitle: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(16),
        fontFamily: Bold,
    },
    unitSubTitle: {
        color: "primary.Tertiary",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
    },
    propertyAvatar: {
        height: "40px",
        width: "58px",
        borderRadius: "8px"
    },
    propertyTitle: {
        color: "#000",
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
    },
    propertySubTitle: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
    },
    propertySubTitleWidth: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "165px"
        },
        '@media screen and (min-width: 375px)': {
            width: "215px"
        },
        '@media screen and (min-width: 425px)': {
            width: "245px"
        },
        '@media screen and (min-width: 768px)': {
            width: "445px"
        },
    },
    regTextWidth: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "215px"
        },
        '@media screen and (min-width: 375px)': {
            width: "255px"
        },
        '@media screen and (min-width: 425px)': {
            width: "295px"
        },
        '@media screen and (min-width: 768px)': {
            width: "490px"
        },
    },
    nameWidth: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "95px"
        },
        '@media screen and (min-width: 375px)': {
            width: "135px"
        },
        '@media screen and (min-width: 425px)': {
            width: "170px"
        },
    },
    propertyBox: {
        border: `1px solid`,
        borderColor: "border.Teritary",
        borderRadius: "12px",
        padding: "12px"
    },
    body: {
        height: "calc(100vh - 58px)",
        overflow: "auto",
    },
    scrollBox: {
        backgroundColor: "#FDECE7",
        marginTop: "-20px",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
        height: "130px"
    },
    iconBox: {
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        backgroundColor: "#FBDACF"
    },
    iconText: {
        color: "#000",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
    },
    secText: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: Regular,
    },
    descText: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: Regular,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "235px"
        },
        '@media screen and (min-width: 375px)': {
            width: "285px"
        },
        '@media screen and (min-width: 425px)': {
            width: "325px"
        },
    },
    secItalicText: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: Italic,
    },
    contactText: {
        color: "primary.Tertiary",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        textTransform: "uppercase"
    },
    viewallText: {
        color: "primary.main",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    contactAvatar: {
        height: "40px",
        width: "40px"
    },
    rightBorder: {
        borderRight: `1px solid`,
        borderColor: "border.Teritary",
    },
    leftBorder: {
        borderLeft: `1px solid`,
        borderColor: "border.Teritary",
    },
    valueText: {
        color: "#000",
        fontSize: FontSizeCalc(12),
        fontFamily: Bold,
    },
    valueTextDotted: {
        whiteSpace: "nowrap",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    priceBox: {
        backgroundColor: "#E6EFFF",
        marginTop: "-20px",
        borderBottomRightRadius: "12px",
        borderBottomLeftRadius: "12px",
        // height: "130px"
    },
    marketPriceText: {
        color: "#0944A8",
        fontSize: FontSizeCalc(14),
        fontFamily: Regular,
    },
    marketPriceTextBold: {
        color: "#0944A8",
        fontSize: FontSizeCalc(14),
        fontFamily: Bold,
    },
    mapImg: {
        height: "54px",
        width: "40px"
    },
    addressText: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        whiteSpace: "normal"
    },
    tertiaryText: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "text.secondary",
    },
    secondaryText: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "primary.secondary",
    },
    borderBox: {
        border: `1px solid`,
        borderColor: "border.Teritary",
        borderRadius: "12px",
        padding: "16px"
    },
    seeMoreLessTextStyle: {
        fontSize: FontSizeCalc(12),
        fontFamily: Regular,
        color: "primary.main",
    },
    timeLineAvatar: {
        height: "32px",
        width: "32px"
    },
    tertiaryTextReg: {
        fontSize: FontSizeCalc(12),
        fontFamily: Regular,
        color: "text.secondary",
    },
    tertiaryTextWidth: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "165px"
        },
        '@media screen and (min-width: 375px)': {
            width: "205px"
        },
        '@media screen and (min-width: 425px)': {
            width: "255px"
        },
        '@media screen and (min-width: 768px)': {
            width: "395px"
        },
    },
    normalBox: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 0px 20px #0000001A",
        padding: "16px",
    },
    dot: {
        height: "6px",
        width: "6px",
        backgroundColor: "background.light",
        borderRadius: "50%"
    },
    noDataText: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "text.Teritary",
    },
    facilityBox: {
        backgroundColor: "background.accordion",
        borderRadius: "8px",
        padding: "8px 12px"
    },
    upcomingText: {
        backgroundColor: "primary.main",
        padding: "4px",
        borderRadius: "4px",
        position: "absolute",
        left: "42%",
        top: "-10px",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        color: "#fff",
    },
    tabBox: {
        backgroundColor: "background.text",
        padding: "4px",
        borderRadius: "6px"
    },
    selectedTab: {
        backgroundColor: "#fff",
        color: "primary.main",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        width: "100%",
        borderRadius: "6px",
        boxShadow: "0px 0px 10px #43547224",
        textAlign: "center",
        padding: "4px",
        cursor: "pointer"
    },
    unSelectedTab: {
        color: "primary.secondary",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        padding: "4px",
        textAlign: "center",
        cursor: "pointer"
    },
    paidText: {
        backgroundColor: "status.success",
        padding: "4px",
        borderRadius: "4px",
        color: "#fff",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        width: "34px"
    },
    unpaidText: {
        backgroundColor: "error.Teritary",
        padding: "4px",
        borderRadius: "4px",
        color: "#fff",
        fontSize: FontSizeCalc(12),
        fontFamily: SemiBold,
        width: "52px"
    },
    locationBox: {
        background: "no-repeat",
        backgroundImage: `url('../images/regLocationBG.svg')`,
        backgroundPosition: "right center",
        backgroundOrigin: "content-box",
        border: `1px solid`,
        borderColor: "border.Teritary",
        padding: "16px",
        borderRadius: "12px"
    },
    amenityTitleWidth: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "105px"
        },
        '@media screen and (min-width: 375px)': {
            width: "125px"
        },
        '@media screen and (min-width: 425px)': {
            width: "155px"
        },
        '@media screen and (min-width: 768px)': {
            width: "245px"
        },
    },
    reactQuil: {
        fontFamily: Regular,
        fontSize: "0.75rem",
        marginBottom: '1px',
        whiteSpace: "normal",
        background: "red",
        cursor: 'pointer',
        '& p': {
            margin: '0px 0px 4px !important',
            whiteSpace: "normal"
        },
        '& .ql-editor': {
            padding: '0px 0px 0px 0px',
            maxWidth: '223px',
            width: '100%',
            // color: theme?.typography?.color?.tertiary,
            fontFamily: Regular,
            fontSize: "0.75rem !important",
            marginBottom: '1px',
            whiteSpace: "normal",
            "& .ql-align-justify": {
                whiteSpace: "normal"
            }
        },
        "& .ql-align-justify": {
            whiteSpace: "normal"
        },
        '& .ql-editor > *': {
            fontSize: "0.75rem !important",
        }
    },
    quillText: {
        "& p": {
            whiteSpace: "normal",
            fontSize: FontSizeCalc(12),
        }
    }
}