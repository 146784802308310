import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { PropertyUnitNewStyle } from './style'
import EmiPaymentIcon from './utils/emiPaymentIcon'
import UpfrontChargeIcon from './upfrontChargeIcon'
import SignedOnIcon from './utils/signedOnIcon'
import PeriodCalIcon from './utils/periodCalIcon'
import AmountLabelIcon from './utils/amountLabelIcon'
import RefIcon from './utils/refIcon'
import moment from 'moment'
import { stringAvatar } from '../../utils'

export const FinancingInfo = ({
    data = "",
    t = () => false
}) => {
    const classes = PropertyUnitNewStyle
    const language = localStorage.getItem("i18nextLng")
    return (
        <>
            <Box p={2}>
                <Box sx={{ ...classes.propertyBox, padding: "0px !important" }}>
                    <Grid container p={2}>
                        <Grid item xs={6}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <EmiPaymentIcon />
                                <Stack spacing={"4px"}>
                                    <Typography sx={classes.propertyTitle}>{data?.emi_amount ?? 0} {data?.quotation?.currency?.symbol}</Typography>
                                    <Typography sx={classes.tertiaryTextReg}>{t("EMI Amount")}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <UpfrontChargeIcon />
                                <Stack spacing={"4px"}>
                                    <Typography sx={classes.propertyTitle}>{data?.upfront_amount ?? 0} {data?.quotation?.currency?.symbol}</Typography>
                                    <Typography sx={classes.tertiaryTextReg}>{t("Upfront Payment")}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Divider></Divider>
                    <Stack spacing={2} p={2}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <PeriodCalIcon />
                                <Typography sx={classes.secondaryText}>{t("Period")}</Typography>
                            </Stack>
                            <Stack>
                                <Typography sx={{ ...classes.propertyTitle, textAlign: language === "ar" ? "left" : "right" }}>{data?.starting_from && moment(data?.starting_from).format("DD MMM YY")} - {data?.period && moment(data?.period).format("DD MMM YY")}</Typography>
                                <Typography sx={{ ...classes.secItalicText, textAlign: language === "ar" ? "left" : "right" }}>{`(${(data?.starting_from && data?.period) ? moment(data?.period).diff(moment(data?.starting_from), 'years') : 0} ${t("Year")}, ${(data?.starting_from && data?.period) ? moment(data?.period).diff(moment(data?.starting_from), 'months') % 12 : 0} ${t("Months")})`}</Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <PeriodCalIcon />
                                <Typography sx={classes.secondaryText}>{t("Installments")}</Typography>
                            </Stack>
                            <Typography sx={{ ...classes.propertyTitle, textAlign: language === "ar" ? "left" : "right" }}>{data?.installments ?? "-"}</Typography>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <SignedOnIcon />
                                <Typography sx={classes.secondaryText}>{t("Signed On")}</Typography>
                            </Stack>
                            <Typography sx={{ ...classes.propertyTitle, textAlign: "right" }}>{data?.signed_date ? moment(data?.signed_date).format("DD MMM YY") : "-"}</Typography>
                        </Stack>
                    </Stack>
                </Box>

                {
                    data?.financing?.label?.length > 0 &&
                    <Stack direction={"row"} alignItems={"center"} columnGap={1} py={1} mt={1}>
                        <Avatar src={data?.financing?.image_ur} {...stringAvatar(data?.financing?.label)}></Avatar>
                        <Typography sx={{ ...classes.propertyTitle }}>{data?.financing?.label ?? "-"}</Typography>
                    </Stack>
                }

                <Typography sx={{ ...classes.tertiaryTextReg, textTransform: "uppercase" }} mt={1} mb={1}>{t("Application Details")}</Typography>

                <Box sx={{ ...classes.propertyBox, padding: "0px !important" }}>
                    <Stack spacing={"6px"} p={2}>
                        <Typography sx={classes.secondaryText}>{data?.application_number ?? "-"}</Typography>
                        <Typography sx={classes.tertiaryTextReg}>{t("Applied on")} {data?.application_date ? moment(data?.application_date).format("DD MMM YY") : "-"}</Typography>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={2} p={2}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <AmountLabelIcon />
                                <Typography sx={classes.secondaryText}>{t("Amount")}</Typography>
                            </Stack>
                            <Typography sx={{ ...classes.propertyTitle, textAlign: "right" }}>{data?.approved_amount ?? 0} {data?.quotation?.currency?.symbol}</Typography>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <RefIcon />
                                <Typography sx={classes.secondaryText}>{t("Reference")}</Typography>
                            </Stack>
                            <Typography sx={{ ...classes.propertyTitle, textAlign: "right" }}>{data?.approval_reference ?? "-"}</Typography>
                        </Stack>

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                <PeriodCalIcon />
                                <Typography sx={classes.secondaryText}>{t("Approval date")}</Typography>
                            </Stack>
                            <Typography sx={{ ...classes.propertyTitle, textAlign: "right" }}>{data?.approval_date ? moment(data?.approval_date).format("DD MMM YY") : "-"}</Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}