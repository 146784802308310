import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { styles } from './style';
import { Label } from "../label";
import { ImageCard } from '../imageCard';
import UploadIcon from "../../assets/uploadIcon";
import { formatSizeUnits, Regular, SemiBold } from '../../utils';
import { useLazyImageMutationQuery } from "../../redux/services";
import { useDispatch } from "react-redux";
import { openBackdrop, closeBackdrop } from "../../redux/slices/backdrop"
import { useSnackbar } from "notistack";
import Compressor from "compressorjs";
import { CamRecorder } from "./camRecorder";
import { ReplayVideo } from "./replayVideo";
import VideocamIcon from '@mui/icons-material/Videocam';
import { stopAudioRecorder, stopCamrecorder } from "./utils";
import AudioRecorder from "./audioRecorder";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MicIcon from '@mui/icons-material/Mic';
import { ReplayAudio } from "./replayAudio";
import useWindowDimensions from "../../useDimension";
import { DialogDrawer } from "../dialogdrawer";

export const MultiImageUpload = (props) => {
    const { t = () => false } = props;
    const size = useWindowDimensions();
    const [isRecordOptionDialogOpen, setIsRecordOptionDialogOpen] = useState(false);
    const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
    const videoRef = useRef(null);
    const videoRecorderRef = useRef(null);
    const [isVideoRecording, setIsVideoRecording] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [isReplayVideoDialogOpen, setIsReplayVideoDialogOpen] = useState(false);
    const [isAudioRecorderDialogOpen, setIsAudioRecorderDialogOpen] = useState(false);
    const audioRef = useRef(null);
    const audioRecorderRef = useRef(null);
    const [isAudioRecording, setIsAudioRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [isReplayAudioDialogOpen, setIsReplayAudioDialogOpen] = useState(false);
    const [imageMutation] = useLazyImageMutationQuery()
    const { enqueueSnackbar  , closeSnackbar} = useSnackbar()
    const dispatch = useDispatch();
    // updateState Function
    const updateState = async (e) => {
        dispatch(openBackdrop("...Loading"))
        const fileData = []
        let files = e.target.files;

        let filesArray = Array.from(e.target.files).map((val, i) => {
            const formData = new FormData();
            formData.append("files", e?.target?.files[i]);

            const image = imageMutation(formData)
            return {
                url: image?.data,
                file_meta: {
                    name: files[i]?.name?.split(".")?.[0],
                    size: formatSizeUnits(files[i]?.size ?? 0),
                    type: files[i]?.name?.split(".")?.[1],
                }
            }
        });
        if (props?.onlyFiveFilesUpload) {
            if (e?.target?.files && props?.value?.length < 5) {

                if (props?.onlyFiveFilesUpload) {
                    if (filesArray?.length <= 5 && props?.value.concat(filesArray)?.length <= 5) {
                        for (let i = 0; i < files.length; i++) {
                            const res = await singleFileUpload(files[i])
                            if (res === true) {

                                const form_data = new FormData();
                                form_data.append("files", e?.target?.files[i]);

                                const img = await imageMutation(form_data)

                                const filesArray = {
                                    url: img?.data,
                                    file_meta: {
                                        name: files[i]?.name?.split(".")?.[0],
                                        size: formatSizeUnits(files[i]?.size ?? 0),
                                        type: files[i]?.name?.split(".")?.[1],
                                    }
                                }
                                fileData.push(filesArray)
                                dispatch(closeBackdrop(""))

                            }
                        }
                    }
                    else {
                        dispatch(closeBackdrop(""))
                        enqueueSnackbar("You can't upload more than 5 images", {
                            variant: "error",
                            anchorOrigin: { horizontal: "center", vertical: "top" },
                            className: { fontFamily: SemiBold }
                        })
                        return setTimeout(() => {
                            closeSnackbar()
                        }, 2000)
                    }
                } else {
                    for (let i = 0; i < files.length; i++) {
                        const res = await singleFileUpload(files[i])
                        if (res === true) {
                            const form_data = new FormData();
                            form_data.append("files", e?.target?.files[i]);

                            const img = await imageMutation(form_data)

                            const filesArray = {
                                url: img?.data,
                                file_meta: {
                                    name: files[i]?.name?.split(".")?.[0],
                                    size: formatSizeUnits(files[i]?.size ?? 0),
                                    type: files[i]?.name?.split(".")?.[1],
                                }
                            }

                            fileData.push(filesArray)
                            dispatch(closeBackdrop(""))

                        }
                    }
                }
            } else {
                dispatch(closeBackdrop(""))
                enqueueSnackbar("5 / 5 images are Uploaded , limit reached", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
                return setTimeout(() => {
                    closeSnackbar()
                }, 2000)
            }
        } else {
            for (let i = 0; i < files.length; i++) {
                const res = await singleFileUpload(files[i])
                if (res === true) {
                    const form_data = new FormData();
                    form_data.append("files", e?.target?.files[i]);

                    const img = await imageMutation(form_data)

                    const filesArray = {
                        url: img?.data,
                        file_meta: {
                            name: files[i]?.name?.split(".")?.[0],
                            size: formatSizeUnits(files[i]?.size ?? 0),
                            type: files[i]?.name?.split(".")?.[1],
                        }
                    }

                    fileData.push(filesArray)
                    dispatch(closeBackdrop(""))

                }
            }
        }
        props.onChange([...props?.value ?? [], ...fileData])

    }
    // Delete Image function
    const deleteImg = async (i) => {
        // eslint-disable-next-line
        const deletedArray = await props?.value?.map((x, index) => {
            if (index !== i) {
                return x
            }
        }).filter(Boolean)

        props.onDelete(deletedArray ?? [])
    };

    const singleFileUpload = async (file, allowed_image_size = 5) => {
        return new Promise(async (resolve, reject) => {
            try {
                let file_type = file?.type.split("/")?.[0]
                if (file_type === "image") {
                    new Compressor(file, {
                        quality: 0.8,
                        async success(result) {
                            let bytes = result?.size
                            let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                            if (finalSize <= allowed_image_size) {
                                resolve(true)
                            } else {
                                 enqueueSnackbar("Please upload less then 5 MB file", {
                                    variant: "error",
                                    anchorOrigin: { horizontal: "center", vertical: "top" },
                                    className: { fontFamily: SemiBold }
                                })
                                return      setTimeout(()=>{
                                    closeSnackbar()
                                },2000)
                            }
                        },
                        error(err) {
                             enqueueSnackbar("Something Went Wrong", {
                                variant: "error",
                                anchorOrigin: { horizontal: "center", vertical: "top" },
                                className: { fontFamily: SemiBold }
                            })
                            return      setTimeout(()=>{
                                closeSnackbar()
                            },2000)
                        }
                    })
                } else {
                    let bytes = file?.size
                    let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                    if (finalSize <= allowed_image_size) {
                        resolve(true)
                    } else {
                         enqueueSnackbar("Please upload less then 5 MB file", {
                            variant: "error",
                            anchorOrigin: { horizontal: "center", vertical: "top" },
                            className: { fontFamily: SemiBold },
                        })
                        return      setTimeout(()=>{
                            closeSnackbar()
                        },2000)
                    }
                }
            } catch (error) {
                 enqueueSnackbar("Something Went Wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold },
                })
                return      setTimeout(()=>{
                    closeSnackbar()
                },2000)
            }
        });
    };

    const handleCameraDialogOpen = () => {
        setIsCameraDialogOpen(true);
        setIsRecordOptionDialogOpen(false);
    }

    const handleCameraDialogClose = () => {
        setIsCameraDialogOpen(false);
        setIsVideoRecording(false);
        stopCamrecorder({ videoRef, mediaRecorderRef: videoRecorderRef });
    }

    const handleReplayVideoDialogClose = () => {
        setIsReplayVideoDialogOpen(false);
        setIsVideoRecording(false);
        setVideoBlob(null);
    }

    const handleRetakeVideo = () => {
        setIsReplayVideoDialogOpen(false);
        setIsVideoRecording(false);
        setVideoBlob(null);
        setIsCameraDialogOpen(true);
    }

    const handleAddVideo = async () => {
        setIsReplayVideoDialogOpen(false);
        if (videoBlob && props?.value?.length < 5) {
            dispatch(openBackdrop("...Loading"))
            const videoData = {
                url: URL.createObjectURL(videoBlob),
                is_active: true,
                blob: videoBlob,
                file_meta: {
                    name: "Recorded Video",
                    size: formatSizeUnits(videoBlob?.size ?? 0),
                    type: videoBlob?.type,
                    file_type: videoBlob?.type?.split("/")?.[1],
                }
            };
            if (props?.value.concat([videoData])?.length <= 5) {
                const res = await singleFileUpload(videoBlob, 50);
                if (res === true) {
                    const form_data = new FormData();
                    form_data.append("files", videoBlob);

                    const img = await imageMutation(form_data)

                    const filesArray = {
                        url: img?.data,
                        blob: videoBlob,
                        file_meta: {
                            name: "Recorded Video",
                            size: formatSizeUnits(videoBlob?.size ?? 0),
                            type: "mp4",
                        }
                    }
                    props.onChange([...props?.value ?? [], filesArray])
                    URL.revokeObjectURL(videoBlob);
                    dispatch(closeBackdrop(""))
                }
            }
            else {
                dispatch(closeBackdrop(""))
                enqueueSnackbar(t("You can't upload more than 5 media"), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold },
                })
                return setTimeout(() => {
                    closeSnackbar()
                }, 2000)
            }
        }
        else {
            enqueueSnackbar(t("5 / 5 media are Uploaded , limit reached"), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold },
            })
            return setTimeout(() => {
                closeSnackbar()
            }, 2000)
        }
    }

    const handleAudioRecorderDialogOpen = () => {
        setIsAudioRecorderDialogOpen(true);
        setIsRecordOptionDialogOpen(false);
    }

    const handleAudioRecorderDialogClose = () => {
        setIsAudioRecorderDialogOpen(false);
        setIsAudioRecording(false);
        stopAudioRecorder({ audioRef, mediaRecorderRef: audioRecorderRef });
    }

    const handleReplayAudioDialogClose = () => {
        setIsReplayAudioDialogOpen(false);
        setIsAudioRecording(false);
        setAudioBlob(null);
    }

    const handleRetakeAudio = () => {
        setIsReplayAudioDialogOpen(false);
        setIsAudioRecording(false);
        setAudioBlob(null);
        setIsAudioRecorderDialogOpen(true);
    }

    const handleAddAudio = async () => {
        setIsReplayAudioDialogOpen(false);
        if (audioBlob && props?.value?.length < 5) {
            dispatch(openBackdrop("...Loading"))
            const audioData = {
                url: URL.createObjectURL(audioBlob),
                is_active: true,
                file_meta: {
                    name: "Recorded Audio",
                    size: formatSizeUnits(audioBlob?.size ?? 0),
                    type: audioBlob?.type,
                    file_type: audioBlob?.type?.split("/")?.[1],
                }
            };
            if (props?.value.concat([audioData])?.length <= 5) {
                const res = await singleFileUpload(audioBlob, 50);
                if (res === true) {
                    const form_data = new FormData();
                    form_data.append("files", audioBlob);

                    const img = await imageMutation(form_data)

                    const filesArray = {
                        url: img?.data,
                        file_meta: {
                            name: "Recorded Audio",
                            size: formatSizeUnits(audioBlob?.size ?? 0),
                            type: "wav",
                        }
                    }
                    props.onChange([...props?.value ?? [], filesArray])
                    URL.revokeObjectURL(audioBlob);
                    dispatch(closeBackdrop(""))
                }
            }
            else {
                dispatch(closeBackdrop(""))
                enqueueSnackbar(t("You can't upload more than 5 media"), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold },
                })
                return setTimeout(() => {
                    closeSnackbar()
                }, 2000)
            }
        }
        else {
            enqueueSnackbar(t("5 / 5 media are Uploaded , limit reached"), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold },
            })
            return setTimeout(() => {
                closeSnackbar()
            }, 2000)
        }
    }

    return (
        <Box sx={styles.root}>
            {props?.label && <Label label={props?.label} labelStyle={props?.labelStyle} isRequired={props?.isRequired} />}
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={props?.isRecordingEnabled ? (size?.width > 300 ? 6 : 12) : props?.sizexs ?? 12}>
                        <label className="btn_label">
                            <Box sx={{ ...styles.fileroot, ...props?.customStyle }}>
                                <input
                                    accept={props?.accept}
                                    type="file"
                                    name="myImage"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        updateState(e);
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    multiple
                                />
                                <Stack spacing={2} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                    <UploadIcon />
                                    <Typography sx={styles.uploadTittle}>
                                        {props?.text ?? t("Upload image here")}
                                    </Typography>
                                </Stack>
                            </Box>
                        </label>
                    </Grid>
                    {props?.isRecordingEnabled &&
                        <Grid item xs={size?.width > 300 ? 6 : 12}>
                            <Box sx={styles.videoUploadBox} alignContent={"center"}
                                onClick={() => setIsRecordOptionDialogOpen(true)}>
                                <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                                    <RadioButtonCheckedIcon fontSize="large" htmlColor="#F15A29" />
                                    <Stack alignItems={"center"} justifyContent={"center"}>
                                        <Typography sx={{
                                            color: "#404E61",
                                            fontSize: "0.875rem",
                                            fontFamily: SemiBold,
                                        }}>
                                            {t("Record")}
                                        </Typography>
                                        <Typography sx={{
                                            color: "#404E61",
                                            fontSize: "0.875rem",
                                            fontFamily: SemiBold,
                                        }}>
                                            {t("Audio/Video")}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>}
                </Grid>
                {props?.value?.length > 0 ?
                    <Stack direction={"column"}>
                        {props?.value?.map((val, index) => {
                            return (
                                <>
                                    <Box sx={styles.images}>
                                        <Box sx={styles.imgContainer} key={index}>
                                            <ImageCard
                                                showText={true}
                                                image={val?.url ?? ""}
                                                blob={val?.blob}
                                                onClick={() => deleteImg(index)}
                                                imageName={val?.file_meta?.name ?? ""}
                                                storage={val?.file_meta?.size ?? ""}
                                                type={val?.file_meta?.type ?? ""}
                                                isVideoControlEnabled={false}
                                                videoStyle={{ width: "40px", height: "40px" }}
                                            />
                                            {index < props.value.length - 1 && <Divider sx={styles.divider} />}
                                        </Box>
                                    </Box>
                                </>
                            );
                        })}
                    </Stack>
                    : ""
                }
            </Box>
            {props?.isError && (
                <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                    {props?.errorMessage}
                </Typography>
            )}
            <DialogDrawer
                maxWidth="sm"
                header={t("Record")}
                open={isRecordOptionDialogOpen}
                onClose={() => setIsRecordOptionDialogOpen(false)}
                padding="0px"
                component={
                    <Box p={2}>
                        <Grid container spacing={"16px"}>
                            <Grid item xs={size?.width > 300 ? 6 : 12}>
                                <Box sx={styles.videoUploadBox} alignContent={"center"}
                                    onClick={handleAudioRecorderDialogOpen}>
                                    <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                                        <MicIcon fontSize="large" htmlColor="#F15A29" />
                                        <Typography sx={{
                                            color: "#404E61",
                                            fontSize: "0.875rem",
                                            fontFamily: SemiBold,
                                        }}>
                                            {t("Record Audio")}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={size?.width > 300 ? 6 : 12}>
                                <Box sx={styles.videoUploadBox} alignContent={"center"}
                                    onClick={handleCameraDialogOpen}>
                                    <Stack rowGap={"18px"} alignItems={"center"} justifyContent={"center"}>
                                        <VideocamIcon fontSize="large" htmlColor="#F15A29" />
                                        <Typography sx={{
                                            color: "#404E61",
                                            fontSize: "0.875rem",
                                            fontFamily: SemiBold,
                                        }}>
                                            {t("Record Video")}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />
            <DialogDrawer
                header={t("Record Video")}
                open={isCameraDialogOpen}
                onClose={handleCameraDialogClose}
                padding="0px"
                component={
                    <CamRecorder
                        t={t}
                        styles={styles}
                        videoRef={videoRef}
                        mediaRecorderRef={videoRecorderRef}
                        recording={isVideoRecording}
                        setRecording={setIsVideoRecording}
                        setVideoBlob={setVideoBlob}
                        setIsCameraDialogOpen={setIsCameraDialogOpen}
                        setIsReplayVideoDialogOpen={setIsReplayVideoDialogOpen}
                        handleCameraDialogClose={handleCameraDialogClose}
                    />
                }
            />
            <DialogDrawer
                header={t("Record Video")}
                open={isReplayVideoDialogOpen}
                onClose={handleReplayVideoDialogClose}
                padding="0px"
                component={
                    <ReplayVideo
                        t={t}
                        styles={styles}
                        videoBlob={videoBlob}
                        handleRetakeVideo={handleRetakeVideo}
                        handleReplayVideoDialogClose={handleReplayVideoDialogClose}
                        handleAddVideo={handleAddVideo}
                    />
                }
            />
            <DialogDrawer
                header={t("Record Audio")}
                open={isAudioRecorderDialogOpen}
                onClose={handleAudioRecorderDialogClose}
                padding="0px"
                component={
                    <AudioRecorder
                        t={t}
                        styles={styles}
                        audioRef={audioRef}
                        mediaRecorderRef={audioRecorderRef}
                        setAudioBlob={setAudioBlob}
                        recording={isAudioRecording}
                        setRecording={setIsAudioRecording}
                        handleAudioRecordingDialogClose={handleAudioRecorderDialogClose}
                        setIsAudioRecordDialogOpen={setIsAudioRecorderDialogOpen}
                        setIsReplayAudioDialogOpen={setIsReplayAudioDialogOpen}
                    />
                }
            />
            <DialogDrawer
                header={t("Record Audio")}
                open={isReplayAudioDialogOpen}
                onClose={handleReplayAudioDialogClose}
                padding="0px"
                component={
                    <ReplayAudio
                        t={t}
                        styles={styles}
                        audioBlob={audioBlob}
                        handleRetakeAudio={handleRetakeAudio}
                        handleReplayAudioDialogClose={handleReplayAudioDialogClose}
                        handleAddAudio={handleAddAudio}
                    />
                }
            />
        </Box>
    );
};
