import * as React from "react"
const UnitHandoverIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M15.52 0a3.506 3.506 0 0 0-3.43 4.224L9.659 6.655a.5.5 0 0 0-.147.354v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1h1a.5.5 0 0 0 .5-.5v-.843A3.5 3.5 0 1 0 15.52 0Zm.5 2a1 1 0 1 1-1 1 1 1 0 0 1 1-1Zm3.811 8.019a2.682 2.682 0 0 0-1.566.579l-3.357 2.506a2.259 2.259 0 0 0-2.141-1.592h-2.2a11.345 11.345 0 0 1-2.628-.234 8.323 8.323 0 0 0-2.018-.267 5.859 5.859 0 0 0-4.372 2.4 11.32 11.32 0 0 0-1.481 2.3.751.751 0 1 0 1.367.623 9.836 9.836 0 0 1 1.266-1.959 4.462 4.462 0 0 1 3.219-1.86 6.633 6.633 0 0 1 1.706.234 13.243 13.243 0 0 0 2.94.267h2.2a.752.752 0 0 1 .486 1.325l-.021.016-.042.035a.758.758 0 0 1-.422.125h-3.5a.751.751 0 1 0 0 1.5h3.5a2.229 2.229 0 0 0 1.272-.4.751.751 0 0 0 .09-.058s4.28-3.2 5.031-3.755a1.217 1.217 0 0 1 .683-.283.577.577 0 0 1 .461.211.736.736 0 0 1 .006 1.048c-2.681 2.254-4.367 3.756-5.481 4.637s-1.6 1.1-2.064 1.1c-1.829 0-3.855-.5-6.007-.5a3.237 3.237 0 0 0-2.6 1.3 3.917 3.917 0 0 0-.629 1.27.751.751 0 1 0 1.457.364 2.338 2.338 0 0 1 .373-.732 1.588 1.588 0 0 1 1.4-.7c1.905 0 3.922.5 6.007.5a4.942 4.942 0 0 0 2.995-1.419c1.185-.936 2.85-2.423 5.516-4.665a.751.751 0 0 0 .091-.075s0-.008.008-.012l.026-.027a.751.751 0 0 0 .165-.267 2.237 2.237 0 0 0-.2-2.882 2.173 2.173 0 0 0-1.535-.651Z"
      data-name="Group 138037"
    />
  </svg>
)
export default UnitHandoverIcon
