import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
import { BackendRoutes } from "../../../router/routes";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");
            headers.set("x-api", config?.public_listing_api_key)

        }
        return headers;
    },

});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const DashboardAPI = createApi({
    reducerPath: "DashboardAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["dashboard"],
    endpoints: (builder) => ({
        // Get Dashboard API
        dashboard: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `/owner/dashboard `, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return response;
                    }
                },
            }),
        // Get Dashboard Carousel API
        dashboardCarousel: builder.query({
            query: (payload) => {
                return {
                    url: `company-banner/list`, method: "POST", body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                if (response.type === "success") {
                    return response;
                }
            },
        }),
        // get device token API
        getDeviceToken: builder.query({
            query: (payload) => {
                return ({ url: `user-device-token/token`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
        // get Nudge List Api
        getNudgeLIst: builder.query({
            query: (payload) => {
                return ({ url: `nudge/list`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
        // WEATHER API
        getCurrentWeather: builder.query({
            query: (payload) => {
                return ({ url: BackendRoutes?.weather_get_current_weather, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response
            },
        }),
        // LISTINGS PORTAL
        getListingPortal: builder.query({
            query: (payload) => {
                return ({ url: `${config?.public_listing_api_url}/verify/client`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
        //quick links app count
         // LISTINGS PORTAL
         getControls: builder.query({
            query: (payload) => {
                return ({ url: `${config.api_url}/company_application_control/get`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
                // LandLord Approval
        getApprovalList: builder.query({
            query: (payload) => {
                return ({ url: `${config?.api_url}/owner/get_land_lord`, method: "POST", body: payload })
            },
            transformResponse: (response, meta, arg) => {
                return response

            },

        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyDashboardQuery,
    useLazyDashboardCarouselQuery,
    useLazyGetDeviceTokenQuery,
    useLazyGetNudgeLIstQuery,
    useLazyGetCurrentWeatherQuery,
    useLazyGetListingPortalQuery,
    useLazyGetControlsQuery,
    useLazyGetApprovalListQuery
} = DashboardAPI;
