import * as React from "react"
const PaymentPlanIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18.641} {...props}>
    <g data-name="Group 138054">
      <path fill="none" d="M0 .863h18v17.778H0z" data-name="Rectangle 58950" />
      <path
        fill="#f15a29"
        d="M10.8 0a.673.673 0 0 0-.335.089l-1.24.709-1.24-.709a.678.678 0 0 0-.757.06l-1.125.9a.673.673 0 0 0-.253.526V2.7h4.5a2.252 2.252 0 0 1 2.25 2.25h3.15V1.575a.674.674 0 0 0-.253-.527l-1.125-.9a.677.677 0 0 0-.757-.059l-1.24.709-1.24-.709A.673.673 0 0 0 10.8 0ZM2.475 3.6A2.475 2.475 0 0 0 0 6.075v10.35a.676.676 0 0 0 .253.527l1.125.9a.676.676 0 0 0 .758.059l1.24-.708 1.24.708a.672.672 0 0 0 .669 0l1.241-.708 1.24.708a.675.675 0 0 0 .756-.059l1.125-.9a.676.676 0 0 0 .253-.527V5.85a.9.9 0 0 1 .9-.9h.9a1.35 1.35 0 0 0-1.35-1.35ZM10.8 5.85V7.2h4.5a1.35 1.35 0 0 0 1.35 1.35v4.95a1.35 1.35 0 0 0-1.35 1.35h-4.5v1.35h6.075A1.135 1.135 0 0 0 18 15.075v-8.1a1.135 1.135 0 0 0-1.125-1.125H10.8Zm0 2.907v4.535a2.579 2.579 0 0 0 0-4.535Zm3.6 1.368a.9.9 0 1 0 .9.9.9.9 0 0 0-.9-.9Z"
      />
    </g>
  </svg>
)
export default PaymentPlanIcon
