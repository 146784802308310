import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useStyles } from "./styles";
import { saveAs } from "file-saver";
import { AuthContext } from "../../../contexts";
import { DocumentViewer } from "../../../components";

export const Slider = (props) => {
  const classes = useStyles(props);
  const [selected, setSelected] = React.useState(0)
  const auth = React.useContext(AuthContext)

  const next = () => {
    if ((props?.assets?.length - 1) > selected) {
      setSelected(selected + 1)
    }
  }

  const previous = () => {
    if (selected !== 0) {
      setSelected(selected - 1)
    }
  }

  React.useEffect(() => {
    setSelected(0)
  }, [props?.assets])


  const onDownload=()=>{
    saveAs(props?.assets[selected]?.src, props?.assets?.[selected]?.name??"asset");
  }

  return (
    <div >
      {/* carosel */}
      <Grid container alignItems="center" className={classes.caroselRoot}>
        <Grid item xs={1} sx={{paddingInlineStart:"12px"}}>

          <IconButton disabled={selected === 0} onClick={previous} size="small" className={classes.arrowBtn}>
            <ArrowBackIosIcon style={{ fontSize:"0.75rem", transform: auth?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }} />
          </IconButton >


        </Grid >
        <Grid item xs={10} onClick={() => props?.onClick(props?.assets[selected])}>
          {props?.assets[selected] &&
            < Box >
              {props?.assets[selected]?.type === "application/pdf" ?
                <Box height={props?.height??"220px"}>
                  {props?.assets[selected]?.src &&
                    <DocumentViewer url={props?.assets[selected]?.src} />}
                </Box>
                :
                <img
                  src={props.isInventory ? props?.assets[selected]?.url : props?.assets[selected]?.src}
                  alt=""
                  height={props?.height??"220px"}
                  width="100%"
                  style={{ borderRadius: "4px !important", objectFit: "contain" }}
                />
              }

             
            </Box>
          }
          {
            !props?.pageNumber &&
            <Typography sx={{ marginTop: "4px" }} textAlign="center" className={classes.title}>{selected + 1}/{props?.assets?.length}</Typography>
          }

        </Grid>
        <Grid item xs={1} sx={{paddingInlineEnd:"12px"}}>

          <IconButton disabled={(props?.assets?.length - 1) > selected ? false : true} onClick={next} size="small" className={classes.arrowBtn} sx={{float:"right"}}>
            <ArrowForwardIosIcon style={{ fontSize:"0.75rem", transform: auth?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }} />
          </IconButton >


        </Grid >
      
      </Grid >
      {
        props?.isDownload&&
      <Button fullWidth variant="contained" className={classes.downloadBtn} onClick={onDownload}>Download</Button>

      }

    </div >
  );
};
