import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { RightChevronIcon } from "../../assets/rightChevronIcon"
import useWindowDimensions from "../../utils/useWindowDimension"
import { useStyles } from "./style"

export const NudgeCard = ({
    t = () => false,
    nudge = {},
    list = [],
    card_index = 0
}) => {
    const size = useWindowDimensions();
    const classes = useStyles({ size, list });

    return <Stack className={classes.nudge_card}
        sx={{ background: nudge?.background }}
        direction={"column"} rowGap={"6px"}
        justifyContent={"center"}>
        <Stack direction={"row"} columnGap={"4px"}
            justifyContent={"space-between"} alignItems={"start"}>
            <Stack direction={"column"} rowGap={"20px"}>
                <Stack direction={"row"} columnGap={"2px"}>
                    {list?.map((_, i) => {
                        return <Box className={classes.nudge_pagnation_style}
                            sx={{
                                backgroundColor: i <= card_index
                                    ? nudge?.dark_dash_color
                                    : nudge?.light_dash_color,
                            }} />;
                    })}
                </Stack>
                {nudge?.title &&
                    <Typography className={classes.nudge_title_text}>
                        {t(nudge?.title)}
                    </Typography>}
            </Stack>
            {(nudge?.image || nudge?.icon) &&
                <img
                    className={nudge?.image ? classes.nudge_image : classes.nudge_icon}
                    src={nudge?.image ?? nudge?.icon} alt={t(nudge?.image ? "nudge_image" : "nudge_icon")} />
            }
        </Stack>
        <Stack direction={"column"} rowGap={"6px"} width={"100%"}>
            {nudge?.subject &&
                <Typography className={classes.nudge_subject_text}>
                    {t(nudge?.subject)}
                </Typography>}
            <Stack direction={"row"} justifyContent={"space-between"} columnGap={"4px"}>
                {Boolean(nudge?.onClick_text)
                    ? <Stack columnGap={"4px"} direction={"row"} alignItems={"center"}>
                        <Typography className={classes.nudge_CTA_text} textTransform={"capitalize"}
                            onClick={nudge?.onClick}>
                            {t(nudge?.onClick_text)}
                        </Typography>
                        {Boolean(nudge?.onClick) && <RightChevronIcon fill={"#FFFFFF"} />}
                    </Stack>
                    : <div />}
                {nudge?.onDismiss &&
                    <Typography className={classes.nudge_CTA_text} textTransform={"capitalize"}
                        onClick={nudge?.onDismiss}>
                        {t("dismiss")}
                    </Typography>}
            </Stack>
        </Stack>
    </Stack>
}