import { Box, Button, Stack } from "@mui/material"

export const ReplayAudio = ({
  t = () => false,
  styles = {},
  audioBlob = {},
  handleRetakeAudio = () => false,
  handleReplayAudioDialogClose = () => false,
  handleAddAudio = () => false,
}) => {
  return (
    <Stack rowGap={"16px"}>
      <Box p={"24px 24px 0px"} width={"100%"} alignItems={"center"} display={"flex"}>
        {audioBlob &&
          <audio controls style={{ width: "inherit" }}>
            <source src={URL.createObjectURL(audioBlob)} type="audio/mpeg" />
          </audio>}
      </Box>
      <Stack direction={"row"} columnGap={"8px"} p={"16px"} justifyContent={"space-between"} alignItems={"center"}>
        <Button sx={styles.cancelButton} fullWidth
          onClick={handleReplayAudioDialogClose}>
          {t("Cancel")}
        </Button>
        <Button fullWidth sx={styles.reTakeButton}
          onClick={handleRetakeAudio}>
          {t("Re-Take")}
        </Button>
        <Button fullWidth sx={styles.addVideoButton}
          onClick={handleAddAudio}>
          {t("Add")}
        </Button>
      </Stack>
    </Stack>
  )
}