import * as React from "react"
const LeaseEndIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M17.079.578a3.776 3.776 0 0 1 3.763 3.763v6.658H19.1V6.946H1.737v10.71a2.014 2.014 0 0 0 2.026 2.022h13.316a2.014 2.014 0 0 0 2.021-2.021v-2.606h1.737v2.605a3.776 3.776 0 0 1-3.763 3.763H3.763A3.776 3.776 0 0 1 0 17.657V4.341A3.776 3.776 0 0 1 3.763.578Zm0 1.737H3.763a2.014 2.014 0 0 0-2.026 2.026v.868H19.1v-.868a2.014 2.014 0 0 0-2.021-2.026Zm-3.465 6.938a.868.868 0 0 1 .6 1.491l-1.412 1.412h8.325a.868.868 0 1 1 0 1.737h-8.32l1.412 1.412a.868.868 0 1 1-1.228 1.228L10.1 13.639a.868.868 0 0 1 0-1.228l2.895-2.895a.868.868 0 0 1 .623-.263Z"
      data-name="Group 138046"
    />
  </svg>
)
export default LeaseEndIcon
