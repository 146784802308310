import * as React from "react"
const TitleDeedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M2.475 0A2.488 2.488 0 0 0 0 2.475v17.05A2.488 2.488 0 0 0 2.475 22h12.65a2.488 2.488 0 0 0 2.475-2.475V7.975a.825.825 0 0 0-.242-.583l-.009-.009L10.208.242A.825.825 0 0 0 9.625 0Zm0 1.65H8.8v4.675A2.488 2.488 0 0 0 11.275 8.8h4.675v10.725a.813.813 0 0 1-.825.825H2.475a.813.813 0 0 1-.825-.825V2.475a.813.813 0 0 1 .825-.825Zm7.975 1.167 4.333 4.333h-3.508a.813.813 0 0 1-.825-.825ZM8.8 9.35a2.375 2.375 0 0 0-1.467.508l-2.578 2.031a2.375 2.375 0 0 0-.9 1.864v3.073a1.869 1.869 0 0 0 1.856 1.856h6.188a1.869 1.869 0 0 0 1.856-1.856v-3.073a2.37 2.37 0 0 0-.9-1.863l-2.578-2.031A2.372 2.372 0 0 0 8.8 9.35Zm0 1.65a.713.713 0 0 1 .446.155l2.578 2.031a.717.717 0 0 1 .274.566v3.073a.194.194 0 0 1-.206.206H5.705a.194.194 0 0 1-.206-.206v-3.072a.72.72 0 0 1 .275-.567l2.577-2.031A.717.717 0 0 1 8.8 11Zm-.55 2.75a.55.55 0 0 0-.55.55v1.1a.55.55 0 0 0 .55.55h1.1a.55.55 0 0 0 .55-.55v-1.1a.55.55 0 0 0-.55-.55Z"
      data-name="Group 138164"
    />
  </svg>
)
export default TitleDeedIcon
