import * as React from "react"
const UtilitiesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="Group 137856">
      <path fill="none" d="M0 0h22v22H0z" data-name="Rectangle 58887" />
      <path
        fill="#f15a29"
        d="M2.798 6.271A2.441 2.441 0 0 0 .359 8.71v5.761a2.441 2.441 0 0 0 2.439 2.439h3.768V6.271Zm5.1 0v10.641h6.207V6.271Zm7.537 0v10.641h3.769a2.441 2.441 0 0 0 2.437-2.441V8.71a2.441 2.441 0 0 0-2.438-2.439ZM3.459 8.931a1.775 1.775 0 0 1 1.774 1.774v1.774a1.774 1.774 0 1 1-3.547 0v-1.774a1.775 1.775 0 0 1 1.773-1.774Zm7.537 0a1.775 1.775 0 0 1 1.774 1.774v1.774a1.774 1.774 0 1 1-3.547 0v-1.774A1.775 1.775 0 0 1 11 8.931Zm5.824 0h1.224a.655.655 0 0 0-.171.44.665.665 0 1 0 1.33 0 .655.655 0 0 0-.172-.44h1.228a1.756 1.756 0 0 1 .055.44 1.773 1.773 0 1 1-3.546 0 1.754 1.754 0 0 1 .056-.44ZM3.459 10.04a.666.666 0 0 0-.665.665v1.774a.665.665 0 0 0 1.33 0v-1.774a.666.666 0 0 0-.665-.665Zm7.537 0a.666.666 0 0 0-.665.665v1.774a.665.665 0 1 0 1.33 0v-1.774A.666.666 0 0 0 11 10.04Zm6.318 2h2.445a.555.555 0 0 1 .5.792l-.675 1.425h-1.225l.525-1.108h-1.566a.554.554 0 1 1 0-1.108Z"
      />
    </g>
  </svg>
)
export default UtilitiesIcon
