import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ImageCardStyles } from './style'
import { FileViewers } from "../fileViewer";
import { generateImageUrl, img_size } from "../../utils";
import Rubbish from "../../assets/rubbish";
import MicIcon from '@mui/icons-material/Mic';
import { VideoCard } from "./videoCard";


export const ImageCard = ({
    image = "",
    imageName = "",
    storage = "",
    type = "",
    onClick = () => false,
    isVideoControlEnabled = true,
    videoStyle = null,
    blob = {},
}) => {

    // const [showText, setShowtext] = useState(false);
    const imageFormat = ["jpg", "png", "webp", "jpeg"]
    return (
        <Box
            sx={ImageCardStyles.imgcard}
            // onMouseOver={() => setShowtext(true)}
            // onMouseLeave={() => setShowtext(false)}
        >
            <Stack direction={"row"} alignItems={"center"}>
                {type === "mp4" &&
                    (blob
                        ? <VideoCard
                            muted={true}
                            controls={isVideoControlEnabled}
                            style={videoStyle ?? ImageCardStyles.image}
                            videoBlob={blob} />
                        : <video
                            muted={true}                            
                            style={videoStyle ?? ImageCardStyles.image}                            
                            controls={isVideoControlEnabled}
                            playsInline>
                            <source src={image + "#t=0.001"} type="video/mp4" />
                        </video>)
                }
                {["wav", "mpeg"]?.includes(type) &&
                    <Box
                        style={{
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <MicIcon fontSize="large" htmlColor="#5078e1" />
                    </Box>
                }
                {type === "pdf" &&

                    <div style={{ width: "40px", height: "40px", borderRadius: "6px", overflow: "auto" }}>
                        <FileViewers fileType={"pdf"} filePath={image} />
                    </div>

                }
                {imageFormat.includes(type) &&
                    <>
                        <img style={{ width: "40px", height: "40px", borderRadius: "6px" }}
                            src={generateImageUrl(image, img_size.small_square)} sx={ImageCardStyles.image} alt={"images"} />
                    </>

                }
                <Stack width={"70%"} spacing={"4px"} marginInlineStart={1} flexGrow={1} direction={"column"}>
                    <Typography sx={ImageCardStyles.imgName}>
                        {imageName}.{type}
                    </Typography>
                    <Typography sx={ImageCardStyles.textName}>{storage}</Typography>
                </Stack>
                <Box onClick={onClick} sx={ImageCardStyles.deleteBox}>
                    <Rubbish />
                </Box>
            </Stack>
            {/* {showText === true && (
                <Box sx={ImageCardStyles.hoverCard}>
                    <Box>
                        <DeleteOutlineIcon
                            sx={ImageCardStyles.deleteicon}
                            onClick={onClick}
                        />
                    </Box>
                    <Box sx={ImageCardStyles.imgtext}>
                        <Typography sx={ImageCardStyles.imgName}>
                            {imageName}
                        </Typography>
                        <Box display={"flex"}>
                            <Typography sx={ImageCardStyles.textName}>
                                {storage}
                            </Typography>
                            <Box sx={ImageCardStyles.dot}></Box>
                            <Typography sx={ImageCardStyles.textName}>{type}</Typography>
                        </Box>
                    </Box>
                </Box>
            )} */}
        </Box>
    )
}