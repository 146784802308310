import { Box, Container, Grid, Stack, Typography } from "@mui/material"
import { TopNavBar } from "../../components"
import { AssetsStyle } from './style'
import AssetsIcon from "../../assets/assetsPage"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes"
import { useLazyGetAssetsQuery } from "../../redux/services"
import { useEffect, useState } from "react"
import { errorRoute } from "../../utils"

export const Assests = ({
    t = () => false
}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [getAssets, {  isError }] = useLazyGetAssetsQuery();
    const [state , setState] = useState([])


    const viewImagePage = (data) => {
        navigate(AppRoutes.viewImage, {
            state: {
                data: {
                    id:data?.value,
                    name:data?.label
                },
                id: location?.state,
            }
        })
    }
    const getAssetsAPI = async (initial) => {
        await getAssets({
            search: ""
        }).then((res) => {

            setState(res?.data)
        })
            .catch((err) => console.log(err, "errr"))


    }
    useEffect(() => {
        if (isError?.status === 423 ) return errorRoute()
    }, [isError])

    useEffect(()=>{
        getAssetsAPI()
        //  react-hooks/exhaustive-deps
    },[])
    return (
        <Container 
        maxWidth="sm"
        sx={AssetsStyle.container}>
            <Box sx={AssetsStyle.sticky}>
               <Container maxWidth="sm" sx={{ padding: '0px!important' }}>
               <TopNavBar isDirect title={t("Assets")} isBack />
               </Container>
            </Box>
            <Box sx={AssetsStyle.root}>
                <Grid spacing={1} container>
                    {state.map((item) => {
                        return (
                            <>
                                <Grid item xs={6}>
                                    <Box onClick={() => viewImagePage(item)} sx={AssetsStyle.assetsBox}>
                                        <Stack alignItems={"center"} direction={"row"}>
                                            <AssetsIcon />
                                            <Typography sx={AssetsStyle.assetstext}>{t(item?.label)}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid></>
                        )
                    })}
                </Grid>
            </Box>
        </Container>
    )
}