import * as React from "react"
const SignedOnIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16.001} height={16} {...props}>
    <path
      fill="#98a0ac"
      d="M13.231.839a1.851 1.851 0 0 0-1.608 1l-4.947 9.554a.505.505 0 0 0-.056.234l.011 3.035a.5.5 0 0 0 .28.451.51.51 0 0 0 .224.052.5.5 0 0 0 .3-.1l2.377-1.8a.5.5 0 0 0 .143-.17l4.306-8.316.555.288a.313.313 0 0 1 .137.432L13.37 8.565a.506.506 0 1 0 .9.464l1.588-3.068a1.337 1.337 0 0 0-.569-1.792l-.558-.287.178-.343a1.85 1.85 0 0 0-1.678-2.7ZM3.542 5.491a2.808 2.808 0 0 0-.363.044 3.774 3.774 0 0 0-2.57 1.911A4.371 4.371 0 0 0 .001 9.56a1.971 1.971 0 0 0 .63 1.471 1.793 1.793 0 0 0 1.63.335 1.987 1.987 0 0 0 .4 1.616 1.4 1.4 0 0 0 1.179.448 1.743 1.743 0 0 0 .4-.127 2.2 2.2 0 0 0 .322 1.021 1.678 1.678 0 0 0 .713.564.505.505 0 1 0 .319-.958s-.086-.017-.211-.192a1.506 1.506 0 0 1-.126-1.247.5.5 0 0 0-.851-.456 1.2 1.2 0 0 1-.668.391.328.328 0 0 1-.324-.12c-.179-.2-.326-.818.118-1.707a.5.5 0 0 0-.808-.582 1.055 1.055 0 0 1-1.423.253.922.922 0 0 1-.281-.73 3.441 3.441 0 0 1 .488-1.618 2.887 2.887 0 0 1 1.866-1.4 1.077 1.077 0 0 1 .693.058c.124.073.191.143.191.428a.504.504 0 1 0 1.009 0 1.494 1.494 0 0 0-.688-1.3 1.665 1.665 0 0 0-.68-.209 2.187 2.187 0 0 0-.357-.008Z"
      data-name="Group 138287"
    />
  </svg>
)
export default SignedOnIcon
