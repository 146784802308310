import * as React from "react"
const LivingRoomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404)">
      <rect
        width={40}
        height={40}
        fill="#eff5e6"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404)"
      />
      <path
        fill="#4c7015"
        stroke="#4c7015"
        strokeWidth={0.25}
        d="M64 414.75a4.868 4.868 0 0 0-3.172 1.173 1.236 1.236 0 0 1-.8.327H57.75a3.261 3.261 0 0 0-3.238 3.013A2.762 2.762 0 0 0 52 422a2.727 2.727 0 0 0 2 2.6v3.4a3.759 3.759 0 0 0 2 3.311v1.189a.75.75 0 1 0 1.5 0v-.763c.083.006.166.013.25.013h12.508c.082 0 .162-.007.242-.013v.763a.75.75 0 1 0 1.5 0v-1.185a3.757 3.757 0 0 0 2.008-3.306v-3.411a2.727 2.727 0 0 0 2-2.6 2.762 2.762 0 0 0-2.512-2.737 3.261 3.261 0 0 0-3.246-3.011h-2.279a1.235 1.235 0 0 1-.8-.327A4.868 4.868 0 0 0 64 414.75Zm0 1.5a3.352 3.352 0 0 1 2.2.812 2.742 2.742 0 0 0 1.771.688h2.279a1.732 1.732 0 0 1 1.734 1.6 3.263 3.263 0 0 0-2.484 3.15v1.557a2.716 2.716 0 0 0-1.25-.307h-8.5a2.716 2.716 0 0 0-1.25.307V422.5a3.263 3.263 0 0 0-2.484-3.15 1.732 1.732 0 0 1 1.734-1.6h2.279a2.738 2.738 0 0 0 1.771-.688 3.352 3.352 0 0 1 2.2-.812Zm-4.25 3a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75Zm4.25 0a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75Zm4.25 0a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75Zm-13.5 1.5h.377a.75.75 0 0 0 .193.007A1.735 1.735 0 0 1 57 422.5v5.5a.75.75 0 0 0 .75.75h12.5A.75.75 0 0 0 71 428v-5.5a1.735 1.735 0 0 1 1.68-1.743.75.75 0 0 0 .19-.007h.38a1.25 1.25 0 0 1 0 2.5.75.75 0 0 0-.748.751v4.007a2.238 2.238 0 0 1-2.25 2.242H57.75A2.238 2.238 0 0 1 55.5 428v-4a.75.75 0 0 0-.75-.75 1.25 1.25 0 1 1 0-2.5Zm7 .5a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75Zm4.5 0a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75Zm-6.5 4h8.5a1.239 1.239 0 0 1 1.25 1.25v.75h-11v-.75a1.239 1.239 0 0 1 1.25-1.25Z"
      />
    </g>
  </svg>
)
export default LivingRoomIcon
