import * as React from "react"
const UDSIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#4e5a6b"
      d="M6.36.001a.8.8 0 0 0-.791.534l-.334.9a3.242 3.242 0 0 0-.406-.083 3.292 3.292 0 0 0-.653.133l-.4-.883a.805.805 0 1 0-1.463.671l.4.873a3.423 3.423 0 0 0-.715.783l-.91-.337a.805.805 0 1 0-.56 1.509l.9.333a3.239 3.239 0 0 0-.083.407 3.371 3.371 0 0 0 .109.662l-.859.394a.805.805 0 1 0 .671 1.464l.831-.38a3.588 3.588 0 0 0 .9.81A13.03 13.03 0 0 0 .915 9.956a1.866 1.866 0 0 0-.376 1.128v8.512a1.891 1.891 0 0 0 1.878 1.878h17.704A1.891 1.891 0 0 0 22 19.596V6.263a1.885 1.885 0 0 0-1.585-1.856 33.266 33.266 0 0 0-5.658-.371 21.372 21.372 0 0 0-6.448.93c0-.042.009-.082.009-.125a3.292 3.292 0 0 0-.133-.653l.882-.4A.806.806 0 1 0 8.4 2.321l-.872.4a3.424 3.424 0 0 0-.783-.716l.337-.908a.8.8 0 0 0-.718-1.1ZM4.829 2.963a1.878 1.878 0 0 1 .659.132.8.8 0 0 0 .09.025 1.851 1.851 0 0 1 .939.923.8.8 0 0 0 .049.1 1.887 1.887 0 0 1 .139.7 1.865 1.865 0 0 1-.086.563.8.8 0 0 0-.036.192 15.366 15.366 0 0 0-2.116 1.123.8.8 0 0 0-.2-.09 1.847 1.847 0 0 1-1.184-2.453.8.8 0 0 0 .024-.086 1.851 1.851 0 0 1 .927-.941.8.8 0 0 0 .092-.046 1.887 1.887 0 0 1 .703-.142Zm9.927 2.683c.243 0 .408.008.63.01a16.8 16.8 0 0 0-1.924 1.588c-2.344 2.24-5.248 6.108-7.68 12.621H2.414a.256.256 0 0 1-.268-.268v-2.453A34.46 34.46 0 0 1 13.537 5.812a.8.8 0 0 0 .182-.136c.341-.014.681-.031 1.037-.031Zm3.665.136c.254.023.481.047.691.072a14.948 14.948 0 0 0-1.3 2.044 54.28 54.28 0 0 0-4.285 11.967H7.5c2.31-5.968 4.978-9.459 7.07-11.458a10.578 10.578 0 0 1 3.65-2.532.8.8 0 0 0 .2-.093Zm-8.211.31a36.759 36.759 0 0 0-8.063 8.121v-3.128a.252.252 0 0 1 .049-.155 12.522 12.522 0 0 1 8.013-4.838Zm10.18.738v12.766a.256.256 0 0 1-.268.268H15.2a52.053 52.053 0 0 1 4.035-11.2A13.372 13.372 0 0 1 20.39 6.83Z"
      data-name="Group 138173"
    />
  </svg>
)
export default UDSIcon
