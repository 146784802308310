import { KycCardStyles } from "./style"
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import KycBadgeIcon from "../../assets/kycBadgeIcon";
import { SingleImageUpload } from "../singleImageUpload";

export const KycCard = ({

    subText1 = "",
    subText2 = "",
    text = "",
    dot = false,
    status = false,
    hidden = "",
    onClick = () => false,
    data = {},
    assets = [],
    verified=false,
    type

}) => {
    return (
        <>
            <Box sx={KycCardStyles?.root} onClick={() => onClick(data)}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Stack>
                            <SingleImageUpload
                                avatarStyle={{
                                    height: "48px !important",
                                    width: "48px !important"
                                }}
                                batchAvatarStyle={{
                                    marginTop: "38px !important",
                                    backgroundColor: "transparent !important",
                                    borderColor: "none !important",
                                    border: "none !important",
                                    height: "16px !important",
                                    width: "16px !important",
                                    marginLeft: "-16px !important",
                                    marginRight: "-16px !important"
                                }}
                                badgeIcon={verified ? <KycBadgeIcon /> :""}
                                value={assets}
                                type={type}
                                name={text}
                            />
                        </Stack>
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography sx={KycCardStyles?.title} noWrap>{text}</Typography>
                            <Typography sx={KycCardStyles?.subText} noWrap>{subText1} <span style={{ margin: "0px 4px" }} > {dot && dot ? "●" : ""} </span> {subText2}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography sx={status && status === "Valid" ? KycCardStyles?.valid : KycCardStyles?.expired}>{status}</Typography>
                    </Stack>

                </Stack>
                {hidden && <Box sx={KycCardStyles?.divider} />}

            </Box>

        </>




    );
}