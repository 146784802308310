import * as React from "react"
const KitchenIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404.42)">
      <rect
        width={40}
        height={40}
        fill="#f6f1fe"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404.42)"
      />
      <path
        fill="#9165d3"
        stroke="#9165d3"
        strokeWidth={0.25}
        d="M71.215 412.422a2.534 2.534 0 0 0-.738.081 2.444 2.444 0 0 0-1.3.843C67.214 415.811 66 419.737 66 425.097v.514a3.619 3.619 0 0 0 2 3.233l.529.265v4.784a2.527 2.527 0 0 0 5.054 0v-19.009a2.49 2.49 0 0 0-1.656-2.331 2.437 2.437 0 0 0-.712-.131Zm-16.282.61a.948.948 0 0 0-.933.962v6.317a.948.948 0 0 0 .043.3 4.681 4.681 0 0 0 2.136 3.631c-.171 4.228-.284 8.281-.284 8.865a3.1 3.1 0 0 0 2.843 3.317 3.1 3.1 0 0 0 2.843-3.317c0-.584-.113-4.64-.284-8.865a4.681 4.681 0 0 0 2.136-3.636.948.948 0 0 0 .043-.291v-6.321a.95.95 0 1 0-1.9 0v3.79a.95.95 0 0 1-1.9 0v-3.79a.95.95 0 1 0-1.9 0v3.79a.95.95 0 1 1-1.9 0v-3.79a.948.948 0 0 0-.962-.961Zm16.2 1.27a.766.766 0 0 1 .168.041.51.51 0 0 1 .385.542v19.013a.611.611 0 0 1-.632.627.617.617 0 0 1-.632-.632v-5.37a.948.948 0 0 0-.523-.848l-1.054-.527a1.714 1.714 0 0 1-.945-1.537v-.514c0-5.07 1.18-8.585 2.764-10.569a.506.506 0 0 1 .472-.226Zm-12.4 5.563a2.811 2.811 0 0 0 1.9.761 2.757 2.757 0 0 0 .909-.185 2.821 2.821 0 0 1-1.609 2.444.948.948 0 0 0-.548.9c.19 4.5.3 8.994.3 9.318 0 .9-.541 1.421-.948 1.421s-.948-.523-.948-1.421c0-.323.111-4.816.3-9.318a.948.948 0 0 0-.548-.9 2.819 2.819 0 0 1-1.609-2.444 2.757 2.757 0 0 0 .909.185 2.811 2.811 0 0 0 1.897-.76Z"
      />
    </g>
  </svg>
)
export default KitchenIcon
