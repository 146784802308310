

export const CreateContact_style = {
    
        root: {
            padding: "16px",
            width: '100%',
            overflow: "auto",
            backgroundColor: '#F8F8F8',
            "&::-webkit-scrollbar": {
                display: "none",
            },
            margin:"70px 0px 88px"
        },
        container: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            position: "relative",
        },
        footer: {
            position: 'sticky',
            width: "100%",
            bottom: "0"

        }

    


}