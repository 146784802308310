import * as React from "react"
const OrientationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.25}
    height={18.25}
    {...props}
  >
    <path
      fill="#e2ae24"
      stroke="#e2ae24"
      strokeWidth={0.25}
      d="M9.125.125a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 1.256a7.744 7.744 0 1 1-7.744 7.744 7.735 7.735 0 0 1 7.744-7.744Zm0 .419a.628.628 0 1 0 .628.628.628.628 0 0 0-.628-.629Zm-4.6 2.092a.628.628 0 1 0 .628.628.628.628 0 0 0-.628-.628Zm9.209 0a.628.628 0 1 0 .628.628.628.628 0 0 0-.637-.628Zm-1.339 1.333a.628.628 0 0 0-.258.056l-4.5 2.046a.628.628 0 0 0-.312.312l-2.046 4.5a.628.628 0 0 0 .831.831l4.5-2.046a.628.628 0 0 0 .312-.312l2.046-4.5a.628.628 0 0 0-.573-.887Zm-1.262 1.892L9.878 9.878l-2.761 1.255 1.255-2.761Zm-8.708 1.38a.628.628 0 1 0 .63.628.628.628 0 0 0-.63-.628Zm13.4 0a.628.628 0 1 0 .628.628.628.628 0 0 0-.628-.628Zm-11.3 4.6a.628.628 0 1 0 .628.628.628.628 0 0 0-.628-.623Zm8.791 0a.628.628 0 1 0 .628.628.628.628 0 0 0-.633-.623Zm-4.191 2.098a.628.628 0 1 0 .628.628.628.628 0 0 0-.628-.628Z"
      data-name="Group 138091"
    />
  </svg>
)
export default OrientationIcon
