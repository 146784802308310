import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material"
import ClearIcon from '@mui/icons-material/Clear'
import { Avatar, Box, Button, Container, Divider, Grid, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material"
import moment from "moment-timezone"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Building, Calendar, LocationMap, Phone, Schedule } from "../../assets"
import { AcknowledgeIcon } from "../../assets/acknowledgeIcon"
import { ApprovalStartIcon } from "../../assets/approvalStart"
import BillingAmountIcon from "../../assets/billingamtIcon"
import { BOMIcon } from "../../assets/bomIcon"
import { CompletionIcon } from "../../assets/completionIcon"
import { FirstResponseIcon } from "../../assets/firstResponse"
import { InfoIcon } from "../../assets/infoIcon"
import { JobIcon } from "../../assets/jobIcon"
import BigGreenTick from "../../assets/request/bigGreenTick"
import RecordActualExpense from "../../assets/request/recordActualExpense"
import RecordExpenseIcon from "../../assets/request/recordExpenseIcon"
import { RescheduleIcon } from "../../assets/resscheduleIco"
import { UpfrontChargesIcon } from "../../assets/upfrontChargesIcon"
import { DatePickerNew, DialogDrawer, FooterComponent, LoderSimmer, TextBox, TopNavBar } from "../../components"
import { DatePickerTimeNew } from "../../components/datepickernewtime"
import { useLazyAcceptCostingQuery, useLazyCancelRequestQuery, useLazyCostingApprovalQuery, useLazyGetCostingQuery, useLazyGetJobDetailsQuery, useLazyGetRequestInvoiceListQuery, useLazyGetRequestViewQuery, useLazyGetServiceLevelAgreementQuery, useLazyGetSlotMasterQuery, useLazyUpdateActualExpenseQuery, useLazyUpdateRescheduleQuery } from "../../redux/services"
import { AppRoutes } from "../../router/routes"
import { Bold, LocalStorageKeys, SemiBold, concat_string, generateImageUrl, img_size, reqCheckindata } from "../../utils"
import { Appliance } from "../chooseAppliance/component"
import { Track } from "./components"
import ImageAndPdfViewer from "./components/imageAndPdfViewer"
import { RequestViewStyle } from "./style"
import { InvoiceIcon } from "./assets/invoiceIcon"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { RescheduleTextIcon } from "../../assets/reScheduleIcon"
import MicIcon from '@mui/icons-material/Mic';

export const RequestView = ({
    t = () => false
}) => {
    const { state } = useLocation();
    const match = useMediaQuery('(min-width:602px)')
    const id = state?.id;
    const currency = localStorage?.getItem(LocalStorageKeys?.currency) ? JSON?.parse(localStorage?.getItem(LocalStorageKeys?.currency)) : "";
    const { enqueueSnackbar , closeSnackbar } = useSnackbar();
    const [cancelDrawer, setCancelDrawer] = useState(false)
    const [imageViewer, setImageViewer] = React.useState(false);
    const [actualExpense, setActualExpense] = React.useState("")
    const [recordExpenseDrawer, setRecordExpenseDrawer] = React.useState(false)
    const clientCurrency = JSON.parse(localStorage.getItem(LocalStorageKeys.currency))
    const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]
    const [open, setOpen] = React.useState({
        trackOpen: false,
        showService: false
    })
    const [acceptDrawer, setAcceptDrawer] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [rejectDrawer, setRejectDrawer] = React.useState(false)
    const [showCharges, setShowCharges] = React.useState(false)
    const [jobData, setJobData] = React.useState([])
    const [rescheduleData, setRescheduleData] = useState({ visitDate: new Date() })
    const [invoice_state, set_invoice_state] = useState({
        total_invoice_count: 0,
        total_invoice_amount: 0,
    });
    const [requestDetails, setRequestDetails] = useState({
        id: "",
        request_type: "",
        bool: true,
        viewDetails: {},
        remarks: "",
        error: {
            remarks: ""
        }
    })
    const [serviceLevelData, setServiceLevelData] = React.useState([])
    const [isVisit, setIsVisit] = React.useState({
        bool: false,
        type: "",
        data: {},
        rescheduleDateTime: new Date()
    })
    const [itemDetails, setItemDetails] = React.useState({
        list: [],
        costing_status: "Pending"
    })
    const [showMoreBom, setShowMoreBom] = React.useState(false)
    const [showNote, setShowNote] = React.useState(false)
    const [notesData, setNotesData] = React.useState("")
    const navigate = useNavigate()
    const isBack = () => {
        navigate(-1)
    }
    const [GetRequestViewAPI] = useLazyGetRequestViewQuery();
    const [GetCancelRequestAPI] = useLazyCancelRequestQuery();
    const [GetServiceLevelAgreementAPI] = useLazyGetServiceLevelAgreementQuery();
    const [AcceptCostingRequest] = useLazyAcceptCostingQuery();
    const [updateActualExpense] = useLazyUpdateActualExpenseQuery();
    const [updateReschedule] = useLazyUpdateRescheduleQuery();
    const [GetCostingData] = useLazyGetCostingQuery();
    const [GetJobDetails] = useLazyGetJobDetailsQuery();
    const [UpdateCostingApprovalRequest] = useLazyCostingApprovalQuery();
    const [getRequestInvoiceList] = useLazyGetRequestInvoiceListQuery();
    const [GetSlotMasterAPI] = useLazyGetSlotMasterQuery();
    const [slotsData, setSlotsData] = React.useState([])
    const updateState = (k, v) => {
        let error = requestDetails?.error;
        error[k] = "";
        setRequestDetails({ ...requestDetails, [k]: v, error });
    };

    const requestView = async () => {
        let client = await GetRequestViewAPI({
            id: state?.id,
            type: state?.request_type
        })
        const timeline = reqCheckindata(t, client?.data?.data[0]?.status)
        setRequestDetails({
            ...requestDetails,
            bool: false,
            id: state?.id,
            request_type: state?.request_type,
            viewDetails: { ...client?.data?.data[0], timeline },
        })
    }
    const serviceLevelAgreementDetails = async () => {
        let client = await GetServiceLevelAgreementAPI({
            request_id: state?.id,
            type: state?.request_type
        })
        setServiceLevelData(client?.data?.data)
    }
    //get Costing Item details
    const getCostingDetails = async () => {
        let response = await GetCostingData({
            "request_id": state?.id,
            "type": state?.request_type,
            "bom_type": "Projected"

        })
        setItemDetails({ list: response?.data?.data?.[0]?.costing?.[0], costing_status: response?.data?.data?.[0]?.costing_status })
    }
    //get Job details
    const getJobDetails = async () => {
        let response = await GetJobDetails({
            "maintenance_request_id": state?.id
        })
        setJobData(response?.data?.data)
    }
    // drawerOpen
    const cancelRequestOpen = () => {
        setCancelDrawer(true)
    }
    const handleChangeNotes = (val) => {
        setNotesData(val)
    }
    // drawerClose
    const cancelRequestClose = () => {
        setCancelDrawer(false)
    }
    const getSlotMaster = async (offset = 0, limit = 10, search = "") => {
        let response = await GetSlotMasterAPI({
            offset: offset,
            limit: limit,
            search: search

        })
        const result = response?.data?.data?.map((x, i) => {
            return {
                ...x,
                time: TimeIn24HrFormat[i]
            }
        })
        setSlotsData(result)
    };
    // submit function
    const cancelRequestSubmit = async () => {
        if (validate()) {
            const payload = {
                type: "Closed",
                remarks: requestDetails?.remarks,
                closed_on: new Date(),
                is_active: true,
                request_type: requestDetails?.request_type === "Maintenance" ? "Maintenance" : "General"
            }

            if (requestDetails?.request_type === "Maintenance") {
                payload["maintenance_id"] = state?.id
            }
            else {
                payload["general_id"] = state?.id
            }
            let getCancelRequest = await GetCancelRequestAPI(payload)

            if (getCancelRequest?.data?.type === "success") {
                requestView()
                enqueueSnackbar(requestDetails?.request_type === "Maintenance" ?
                    t("Maintenance Request Cancelled") : t("General Request Cancelled"), {
                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })

                setRequestDetails({
                    ...requestDetails,
                    remarks: "",
                    error: {
                        remarks: ""
                    },
                })
                setCancelDrawer(false)

            } else {
                enqueueSnackbar(requestDetails?.request_type === "Maintenance" ?
                    t("Maintenance Request Cancelled") : t("General Request Cancelled"), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }

        }
    }
    // remarks validate
    const validate = () => {
        let isValid = true;
        let error = requestDetails.error;

        if (requestDetails?.remarks?.length === 0) {
            isValid = false;
            error.remarks = t("Remarks is Required");
        }
        setRequestDetails({
            ...requestDetails,
            error
        });
        return isValid;
    }
    const updateRequest = async (costing_status) => {
        const payload = {
            "request_id": state?.id,
            "type": state?.request_type,
            "comments": notesData,
            "costing_status": costing_status,
            "bom_type": "Projected"
        }
        await UpdateCostingApprovalRequest(payload).then((data) => {
            setShowMoreBom(false)
            if (state?.request_type === "Maintenance") {
                getCostingDetails()
            }
            enqueueSnackbar(`Costing Request is ${costing_status} Successfully`, {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })

        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
                // Request made and server responded

            }
            else {
                enqueueSnackbar("Something went wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
        })
    }
    //accept costing request
    const acceptCostingRequest = async (status) => {
        const payload = {
            "maintenance_request_id": state?.request_type === "Maintenance" ? state?.id : undefined,
            "general_request_id": state?.request_type === "General" ? state?.id : undefined,
            "costing_status": status,
            "description": reason
        }
        let acceptCostingRequest = await AcceptCostingRequest(payload)
        if (status === "Approved") {
            if (acceptCostingRequest?.data?.type === "success") {
                setAcceptDrawer(true)
                requestView()
                setShowCharges(false)
            }
        }
        else {
            enqueueSnackbar("Costing Request Rejected Successfully", {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            requestView()
            setShowCharges(false)
            setRejectDrawer(false)
        }

    }
    //add/update actual expense
    const UpdateActualExpense = async () => {
        const payload = {
            request_id: state?.id,
            type: state?.request_type,
            actual_expense: parseFloat(actualExpense)
        }
        let ActualExpense = await updateActualExpense(payload)
        if (ActualExpense?.data?.type === "success") {
            enqueueSnackbar(requestDetails?.viewDetails?.actual_expense === null ? t("Added Successfully") : t("Updated Successfully"), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            requestView()
            setRecordExpenseDrawer(false)
        }
        else {
            enqueueSnackbar("Something went wrong", {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            setRecordExpenseDrawer(false)
        }

    }
    const handleChangeSlot = (val) => {
        if (val?.time !== null && moment(rescheduleData?.visitDate).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
            const currentTime = moment(new Date())
            let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
            let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
            if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
                setRescheduleData({ ...rescheduleData, selectedSlot: val })
            } else {
                setRescheduleData({ ...rescheduleData, selectedSlot: "" })
                enqueueSnackbar("Please Choose time Slot greater than Current Time", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
        }
        else {
            setRescheduleData({ ...rescheduleData, selectedSlot: val })
        }
    }
    const handleReschedule = (type) => {
        if (type === "Rescheduled") {
            if (rescheduleData?.selectedSlot?.label?.length > 0) {
                handleJobSchedule(type)
            }
            else {
                enqueueSnackbar("Please Choose time Slot", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
        }
        else {
            handleJobSchedule(type)
        }
    }
    const handleJobSchedule = async (type) => {
        const payload = {
            "id": isVisit?.data?.id,
            "maintenance_request_id": state?.id,
            "job_id": isVisit?.data?.job_id,
            "job_no": isVisit?.data?.job_no,
            "rescheduled_datetime": type === "Rescheduled" ? isVisit?.rescheduleDateTime : null,
            "slot_id": type === "Rescheduled" ? rescheduleData?.selectedSlot?.value : null,
            "slot_name": type === "Rescheduled" ? rescheduleData?.selectedSlot?.label : null,
            "status": type
        }
        let response = await updateReschedule(payload)
        if (response?.data?.type === "success") {
            setIsVisit({ ...isVisit, bool: false, data: {}, rescheduleDateTime: new Date(), type: "" })
            getJobDetails()
            enqueueSnackbar(type === "Rescheduled" ? t("Rescheduled Successfully") : t("Approved Successfully"), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }
        else {
            enqueueSnackbar("Something went wrong", {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }
    }
    const getInvoiceDetails = async () => {
        let payload = { id };

        let response = await getRequestInvoiceList(payload);

        let temp_invoice_state = {
            total_invoice_count: response?.data?.count?.[0]?.count ?? 0,
            total_invoice_amount: response?.data?.total_invoice_amount ?? 0,
        }

        set_invoice_state(temp_invoice_state);
    }
    useEffect(() => {
        requestView()
        serviceLevelAgreementDetails()
        getJobDetails()
        getSlotMaster()
        if (state?.request_type === "Maintenance") {
            getCostingDetails()
            getInvoiceDetails();
        }
        // eslint-disable-next-line
    }, [state])
    const openExpenseDrawer = (actualexpense) => {
        setActualExpense(actualexpense)
        setRecordExpenseDrawer(true)
    }

    const closeExpenseDrawer = () => {
        setActualExpense("")
        setRecordExpenseDrawer(false)
    }
    const handleBomDetails = () => {
        navigate(AppRoutes?.BOMDetails, {
            state: {
                id: state?.id,
                requestNo: state?.id,
                request_type: state?.request_type,
                itemDetails: itemDetails
            }
        })
        // history.push({
        //     pathname: AppRoutes?.BOMDetails,
        //     state: {
        //         requestId: state?.id,
        //         requestNo: state?.id,
        //         requestType: state?.request_type,
        //         itemDetails: itemDetails
        //     }
        // })
    }
    const footerHide = [
        requestDetails?.viewDetails?.status?.[requestDetails?.viewDetails?.status?.length - 1]?.type !== "Closed"
    ]
    const handleInvoiceNudge = () => {
        navigate(AppRoutes?.pay_request_invoice_list, { state: { id: state?.id } });
    }
    const handlePayNow = (e) => {
        e?.stopPropagation();
    }
    return (

        <Container maxWidth={"sm"} sx={RequestViewStyle?.container}>
            <Box sx={RequestViewStyle?.top}>
                <TopNavBar isDirect title={requestDetails?.viewDetails?.request_no}
                    isBack={isBack} />
            </Box>
            < Box sx={footerHide ? RequestViewStyle?.main : RequestViewStyle?.mainheight}>
                {requestDetails?.viewDetails?.visiting_charge_request?.length > 0 &&
                    <>
                        <Box sx={RequestViewStyle.requestPendingBox} p={2} onClick={() => setShowCharges(true)}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <UpfrontChargesIcon />
                                    <Box>
                                        <Typography sx={RequestViewStyle.notesTitle}>{`${requestDetails?.viewDetails?.visiting_charge_request?.[0]?.amount} ${clientCurrency?.code ?? ""}`}</Typography>
                                        <Typography sx={RequestViewStyle.subText}>{t("Upfront Charges")}</Typography>
                                    </Box>
                                </Stack>
                                {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Pending" ?
                                    <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                                    :
                                    <Typography sx={RequestViewStyle.costingStatusText} style={{ backgroundColor: requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status}</Typography>
                                }
                            </Box>
                            {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Pending" &&
                                <Box mt={1.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" sx={RequestViewStyle.rejectbtn}>{t("Reject")}</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" sx={RequestViewStyle.approvebtn}>{t("Approve")}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                        <Box height={'16px'} />
                    </>
                }
                {itemDetails?.list?.items?.length > 0 && itemDetails?.costing_status !== null &&
                    <>
                        <Box sx={RequestViewStyle.requestPendingBox} p={2} onClick={() => handleBomDetails()}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <BOMIcon />
                                    <Box>
                                        {state?.request_type === "Maintenance" &&
                                            <Box display={"flex"}>
                                                <Typography sx={RequestViewStyle.notesTitle}>{`${itemDetails?.list?.symbol} ${itemDetails?.list?.total_amount_after_tax ?? 0}`}</Typography>
                                                <Box ml={1}>
                                                    <Tooltip title={t("Note: Usage of Material and labor will be updated periodically. Upon completion of work, the Actual The cost of labour, materials, and Other Expenses will be shared.")}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        }
                                        <Typography sx={RequestViewStyle.subText}>{t("Projected BOM")}</Typography>
                                    </Box>
                                </Stack>
                                {itemDetails?.costing_status === "Pending" ?
                                    <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                                    :
                                    <Typography sx={RequestViewStyle.costingStatusText} style={{ backgroundColor: itemDetails?.costing_status === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{itemDetails?.costing_status}</Typography>
                                }
                            </Box>
                            {itemDetails?.costing_status === "Pending" &&
                                <Box mt={1.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" sx={RequestViewStyle.rejectbtn}>{t("Reject")}</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth variant="contained" sx={RequestViewStyle.approvebtn}>{t("Approve")}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                        <Box height={'16px'} />
                    </>
                }
                {jobData?.length > 0 && jobData?.map((val) => {
                    return (
                        <>
                            <Box sx={RequestViewStyle.requestPendingBox} p={2} onClick={() => val?.status !== "Pending" ? setIsVisit({ ...isVisit, bool: true, type: "reschedule", data: val, rescheduleDateTime: val?.execution }) : false}>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <RescheduleIcon />
                                        <Box>
                                            <Box display={"flex"}>
                                                <Typography sx={RequestViewStyle.notesTitle}>{`${moment(val?.execution)?.format("DD MMM YYYY hh:mm a")}`}</Typography>
                                            </Box>
                                            <Typography sx={RequestViewStyle.subText} style={{ textTransform: "capitalize" }}>{t(`Upcoming ${val?.request_type} Job Visit`)}</Typography>
                                        </Box>
                                    </Stack>
                                    {val?.status === "Pending" ?
                                        <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                                        :
                                        <Typography sx={RequestViewStyle.costingStatusText} style={{ backgroundColor: val?.status === "Rescheduled" ? "#5078e1" : "#5AC782" }}>{val?.status}</Typography>

                                    }
                                </Box>
                                {val?.status === "Pending" &&
                                    <Box mt={1.5}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button fullWidth variant="contained" sx={RequestViewStyle.reschedulebtn} onClick={() => setIsVisit({ ...isVisit, bool: true, type: "reschedule", data: val, rescheduleDateTime: val?.execution })}>{t("Reschedule")}</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth variant="contained" sx={RequestViewStyle.approvebtn} onClick={() => setIsVisit({ ...isVisit, bool: true, type: "approve", data: val, rescheduleDateTime: val?.execution })}>{t("Approve")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Box>
                            <Box height={'16px'} />
                        </>
                    )
                })
                }
                {(invoice_state?.total_invoice_amount > 0 && invoice_state?.total_invoice_count > 0) &&
                    <Box sx={RequestViewStyle?.nudge_card} onClick={handleInvoiceNudge}>
                        <Stack direction={"row"} columnGap={"12px"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} columnGap={"12px"} alignItems={"center"}>
                                <InvoiceIcon />
                                <Stack direction={"column"} rowGap={"4px"}>
                                    <Typography sx={RequestViewStyle?.invoice_total_amount}>
                                        {concat_string(
                                            {
                                                amount: invoice_state?.total_invoice_amount?.toString(),
                                                currency_code: currency?.code,
                                            },
                                            ["amount", "currency_code"],
                                            " ",
                                        )}
                                    </Typography>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <Typography sx={RequestViewStyle?.invoice_count}>
                                            {`${invoice_state?.total_invoice_count} Invoice Total Amount`}
                                        </Typography>
                                        <KeyboardArrowRightIcon fontSize="small" htmlColor="#4E5A6B" />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Button sx={RequestViewStyle?.pay_now_button}
                                onClick={(e) => handlePayNow(e)}>
                                {t("Pay Now")}
                            </Button>
                        </Stack>
                    </Box>
                }
                <Box sx={RequestViewStyle?.root}>

                    <Stack direction="row" alignItems="center" sx={RequestViewStyle?.badgeStack}>
                        {
                            requestDetails?.viewDetails?.category_name && requestDetails?.bool ?
                                <LoderSimmer card count={1} size={30} width={50} />
                                : <Typography
                                    sx={RequestViewStyle?.badge}>
                                    {requestDetails?.request_type === "General" ? requestDetails?.viewDetails?.category_master_name : requestDetails?.viewDetails?.category_name}</Typography>
                        }
                        {
                            requestDetails?.viewDetails?.sub_category_name && requestDetails?.bool ?
                                <LoderSimmer card count={1} size={30} width={50} />
                                : <Typography
                                    sx={RequestViewStyle?.badge}>
                                    {requestDetails?.request_type === "General" ? requestDetails?.viewDetails?.sub_category_master_name : requestDetails?.viewDetails?.sub_category_name}
                                </Typography>
                        }
                    </Stack>
                    <Box my={2}>
                        {requestDetails?.viewDetails?.inspection_mapping?.length > 0 ?
                            <Appliance
                                t={t}
                                serial_number={requestDetails?.viewDetails?.inspection_mapping?.[0]?.serial_number}
                                model_number={requestDetails?.viewDetails?.inspection_mapping?.[0]?.model_number}
                                last_service={requestDetails?.viewDetails?.inspection_mapping?.[0]?.last_service?.[0]?.created_at}
                                condition={requestDetails?.viewDetails?.inspection_mapping?.[0]?.item_condition}
                                unit_name={requestDetails?.viewDetails?.inspection_mapping?.[0]?.name}
                                unit_no={requestDetails?.viewDetails?.inspection_mapping?.[0]?.manufaturer_name ?? "-"}
                                product
                            />
                            :
                            <Appliance
                                unit
                                unit_name={requestDetails?.viewDetails?.units?.unit?.name}
                                unit_no={requestDetails?.viewDetails?.units?.unit?.unit_no}
                                unit_type={requestDetails?.viewDetails?.units?.unit?.unit_type_name}
                            />
                        }
                    </Box>

                    {requestDetails?.bool ?
                        <LoderSimmer card count={1} size={30} width={140} />
                        :
                        <Typography noWrap sx={RequestViewStyle?.head}>
                            {requestDetails?.viewDetails?.subject}</Typography>
                    }
                    {requestDetails?.bool ?
                        <LoderSimmer card count={1} size={25} width={140} />
                        :
                        <Typography noWrap sx={RequestViewStyle?.para}>
                            {requestDetails?.viewDetails?.description}
                        </Typography>
                    }
                    <Box mt={2}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {
                                    requestDetails?.viewDetails?.units?.unit?.name && <Box sx={RequestViewStyle?.gridBox}>
                                        {requestDetails?.bool ?
                                            <LoderSimmer card count={1} size={25} width={80} />
                                            :
                                            <Typography noWrap>{t("Property And Unit Details")}</Typography>
                                        }
                                        {requestDetails?.bool ?
                                            <LoderSimmer card count={1} size={25} width={80} />
                                            :
                                            <Stack direction="row" alignItems="center">
                                                <Building />
                                                <Typography sx={RequestViewStyle?.para}>
                                                    {requestDetails?.viewDetails?.units?.unit?.property_name} - {requestDetails?.viewDetails?.units?.unit?.name}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Box>
                                }

                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2} sx={RequestViewStyle.borderBottom} />
                    <Box mt={2}>
                        <Grid container alignItems={"center"}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Box>
                                    {
                                        requestDetails?.viewDetails?.raised_on && <Box sx={RequestViewStyle?.gridBox}>
                                            {requestDetails?.bool ?
                                                <LoderSimmer card count={1} size={25} width={80} />
                                                :
                                                <Typography noWrap>{t("Raised On")}</Typography>
                                            }
                                            {requestDetails?.bool ?
                                                <LoderSimmer card count={1} size={25} width={80} />
                                                :
                                                <Stack direction="row" alignItems="center">
                                                    <Calendar />
                                                    <Typography sx={RequestViewStyle?.para} noWrap>&#x202a;{moment(requestDetails?.viewDetails?.raised_on).format(
                                                        "DD MMM YYYY,hh:mm A"
                                                    )}&#x202c;</Typography>
                                                </Stack>
                                            }
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} style={{ borderInlineStart: "1px solid #E4E8EE" }}>
                                {
                                    requestDetails?.viewDetails?.problem_since &&
                                    <Box sx={RequestViewStyle?.gridBox} style={{ marginInlineStart: "16px" }}>
                                        {requestDetails?.bool ?
                                            <LoderSimmer card count={1} size={25} width={80} />
                                            :
                                            <Typography noWrap>{t("Problem Since")}</Typography>
                                        }
                                        {requestDetails?.bool ?
                                            <LoderSimmer card count={1} size={25} width={80} />
                                            :
                                            <Stack direction="row" alignItems="center">
                                                <Calendar />
                                                <Typography sx={RequestViewStyle?.para} noWrap>
                                                    &#x202a;{moment(requestDetails?.viewDetails?.problem_since).format(
                                                        "DD MMM YYYY,hh:mm A"
                                                    )}&#x202c;</Typography>
                                            </Stack>
                                        }
                                    </Box>
                                }

                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={RequestViewStyle.borderBottom}></Box>
                    {requestDetails?.viewDetails?.asserts?.length > 0 &&
                        <Box sx={RequestViewStyle.borderBottom}>
                            <Typography sx={RequestViewStyle?.commonHead} style={{ marginBottom: "8px" }}>{t("Attached Images")}</Typography>
                            <Grid container spacing={1}>
                                {requestDetails?.viewDetails?.asserts?.map((src, index) => {
                                    return (
                                        <Grid item xs={2} key={index} onClick={() => setImageViewer(true)}>
                                            {[src?.file_meta?.file_type, src?.file_meta?.type].includes("mp4") ? (
                                                <video style={RequestViewStyle.imageStyle}>
                                                    <source src={src?.url + "#t=0.001"} type="video/mp4" />
                                                </video>
                                            ) : ["wav", "mpeg"]?.includes(src?.file_meta?.type) ? (
                                                    <Box
                                                        style={{
                                                            border: "1px solid #CED3DD",
                                                            borderRadius: "10px",
                                                            height: "75px",
                                                            width: "75px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}>
                                                        <MicIcon fontSize="large" htmlColor="#5078e1" />
                                                    </Box>
                                            ) : (
                                                <img
                                                    src={generateImageUrl(src.url, img_size.medium_square)}
                                                    width="300"
                                                    style={RequestViewStyle.imageStyle}
                                                    alt=""
                                                />
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    }
                    {requestDetails?.viewDetails?.location !== null &&
                        <Box mt={2} sx={RequestViewStyle.gridBox}>
                            <Typography sx={RequestViewStyle.requsetsubs}>
                                {t("Location")}
                            </Typography>
                            <Stack direction={"row"} alignItems={"center"}>
                                <LocationMap />
                                <Typography sx={RequestViewStyle?.para}>{requestDetails?.viewDetails?.location?.name}</Typography>
                            </Stack>
                        </Box>
                    }
                    <Box sx={RequestViewStyle.borderBottom}></Box>
                    {
                        requestDetails?.bool && requestDetails?.preferred_time !== null ?
                            <LoderSimmer card count={1} size={105} /> : requestDetails?.viewDetails?.preferred_time &&
                            <Box sx={RequestViewStyle.borderBottom}>
                                <Typography noWrap sx={RequestViewStyle?.commonHead}>
                                    {t("Preferred Visit Date And Time")}
                                </Typography>
                                <Stack mb={2} direction="row" alignItems="center" sx={RequestViewStyle?.calendardiv}>
                                    <Schedule />
                                    <Typography marginInlineStart={"6px"} sx={RequestViewStyle?.para} noWrap>
                                        &#x202a;{moment(requestDetails?.viewDetails?.preferred_time).format(
                                            "DD MMM YYYY,"
                                        )}&#x202c;</Typography>
                                    <Typography sx={RequestViewStyle?.para}>{` ${requestDetails?.viewDetails?.slot_name}`}</Typography>
                                </Stack>
                            </Box>
                    }

                    <Box>
                        {
                            requestDetails?.viewDetails?.contact_name &&
                                requestDetails?.viewDetails?.contact_mobile ?
                                <Box sx={RequestViewStyle.borderBottom}>
                                    <Typography mb={1} sx={RequestViewStyle.commonHead}>
                                        {t("Contact Details")}
                                    </Typography>
                                    <Stack mb={2} direction="row" alignItems="center">
                                        {requestDetails?.bool ?
                                            <LoderSimmer card count={1} size={55} width={35} />
                                            :
                                            <Box sx={RequestViewStyle?.svg}>
                                                <Phone />
                                            </Box>
                                        }
                                        <Box sx={RequestViewStyle?.contactSec}>
                                            {requestDetails?.bool ?
                                                <LoderSimmer card count={1} size={25} width={80} />
                                                :
                                                <Typography noWrap sx={RequestViewStyle?.commonHead}>
                                                    {requestDetails?.viewDetails?.contact_name}
                                                </Typography>
                                            }
                                            {requestDetails?.bool ?
                                                <LoderSimmer card count={1} size={25} width={80} />
                                                :
                                                <Stack direction="row" alignItems="center"
                                                    sx={RequestViewStyle?.contact}>
                                                    <Typography noWrap>{requestDetails?.viewDetails?.contact_mobile}</Typography>
                                                    <Divider orientation="vertical" />
                                                    <Typography noWrap>{requestDetails?.viewDetails?.contact_alternative_mobile}</Typography>
                                                </Stack>
                                            }
                                        </Box>
                                    </Stack>
                                </Box> : <></>
                        }
                    </Box>

                    <Box mt={2}>
                        {requestDetails?.bool ?
                            <LoderSimmer card count={1} size={30} />
                            :
                            <Typography sx={RequestViewStyle?.commonHead}>{t("Latest Update")}</Typography>
                        }
                        {requestDetails?.bool ?
                            <LoderSimmer card count={1} size={100} />
                            :
                            <Box sx={RequestViewStyle?.statusCard} mt={1}>
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap sx={RequestViewStyle?.statusPoint}>{t("Status")}:  </Typography>
                                    <Typography noWrap sx={RequestViewStyle?.status}>
                                        {requestDetails?.viewDetails?.status && requestDetails?.viewDetails?.status[0]?.type
                                        }</Typography>
                                </Stack>
                                {requestDetails?.viewDetails?.status?.[0]?.notes?.length > 0 &&
                                    <Box>
                                        {requestDetails?.viewDetails?.status && <Typography sx={RequestViewStyle?.notesStatus}>{requestDetails?.viewDetails?.status?.[0]?.notes?.[requestDetails?.viewDetails?.status?.[0]?.notes?.length - 1]?.notes}</Typography>
                                        }
                                        <Typography noWrap sx={RequestViewStyle?.statusText}>
                                            {requestDetails?.viewDetails?.statusText}</Typography>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>

                    {/* {requestDetails?.viewDetails?.visiting_charge_request?.length > 0 && requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Pending" &&
                        <>
                            <Box height={"10px"} />
                            <Typography sx={RequestViewStyle.commonHead}>
                                {t("Costing Details")}
                            </Typography>
                            <Box height={"10px"} />
                            <Box sx={RequestViewStyle.costingBox}>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography sx={RequestViewStyle.label}>{t("Upfront Charges And")}</Typography>
                                    <Typography sx={RequestViewStyle.total}>{t("Total Amount")}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography sx={RequestViewStyle.label}>{t("Payments")}</Typography>
                                    <Typography sx={RequestViewStyle.cost}>{`${clientCurrency?.symbol} ${requestDetails?.viewDetails?.visiting_charge_request?.[0]?.amount}`}</Typography>
                                </Stack>

                                <Box height={"10px"} />
                                <Box sx={RequestViewStyle.divider} />

                                <Box height={"10px"} />
                                {state?.request_status !== "Closed" &&
                                    <Stack direction={"row"} spacing={1}>
                                        <Button fullWidth sx={RequestViewStyle.reject}
                                            onClick={() => setRejectDrawer(true)}
                                        >
                                            {t("Reject")}
                                        </Button>
                                        <Button fullWidth sx={RequestViewStyle.accept}
                                            onClick={() => acceptCostingRequest("Approved")}
                                        >
                                            {t("Accept")}
                                        </Button>
                                    </Stack>
                                }
                            </Box>
                        </>
                    }
                    {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Rejected" && <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                        <Stack direction={"column"} spacing={"6px"}>
                            <Typography sx={RequestViewStyle.lineTwo}>{t("Upfront Charge Request Is Rejected")}</Typography>
                            <Typography sx={RequestViewStyle.lineOne}>{moment(requestDetails?.viewDetails?.visiting_charge_request?.[0]?.created_at).format("DD MMM YY HH:mm")}</Typography>
                        </Stack>
                        <RedCross />
                    </Stack>} */}

                    {/* {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.notes?.length > 0 &&
                        <>
                            <Box mt={1}>
                                <Typography sx={RequestViewStyle.commonHead}>{t("Notes")}</Typography> */}
                    {/* <ShowMoreText
                                  lines={2}
                                  more={t("Show More")}
                                  less={t("Show Less")}
                                  className={classes.value}
                                  anchorClass={classes.seeMoreLessTextStyle}
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                > */}
                    {/* <Typography>{request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.notes}</Typography> */}
                    {/* <Box sx={RequestViewStyle.notesHeight}>
                                    {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.notes?.split('\n').map((item, i) => <Typography sx={RequestViewStyle.para} key={i}>{item}</Typography>)}
                                </Box> */}
                    {/* </ShowMoreText> */}
                    {/* </Box>
                        </>
                    } */}

                </Box>
                <Box sx={RequestViewStyle?.timeLine}>
                    {requestDetails?.bool ?
                        <LoderSimmer card count={1} size={20} />
                        :
                        <Box display={"flex"} justifyContent={"space-between"} onClick={() => setOpen({ ...open, trackOpen: !open?.trackOpen })} p={2}>
                            <Typography sx={RequestViewStyle.text}>{t("Track")}</Typography>
                            {open?.trackOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Box>
                    }
                    {requestDetails?.bool ?
                        <LoderSimmer card count={1} size={140} />
                        :
                        open?.trackOpen &&
                        <Box sx={RequestViewStyle?.timeLineComp} p={2}>
                            {requestDetails?.viewDetails?.timeline && requestDetails?.viewDetails?.timeline.reverse().map((x, index) => {
                                return (
                                    <Box key={index} >
                                        <Track
                                            date={x?.date}
                                            purpose={x?.purpose}
                                            t={t}
                                            image={x?.image}
                                            line={x?.line}
                                            created_at={x?.created_at}
                                            closed_by={x?.closed_by}
                                            progressDetails={x?.progressDetails ?? x?.progressDetails}
                                            titleBold={x?.titleBold}
                                            show={requestDetails?.viewDetails?.timeline?.length === index + 1 ? true : true}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    }
                </Box>
                <Box sx={RequestViewStyle?.timeLine}>
                    <Box onClick={() => setOpen({ ...open, showService: !open?.showService })} p={2}>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}

                        // onClick={() => setOpen(false)}
                        >
                            <Typography sx={RequestViewStyle.text}>{t("KPI")}</Typography>
                            {open?.showService ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Stack>
                    </Box>

                    {open?.showService &&
                        <>
                            <Divider />
                            <Box p={2} m={2} sx={RequestViewStyle.totaltimeBox}>
                                <Stack direction="row" spacing={2} alignItems={"center"}>
                                    <img src={"../images/timer.png"} alt="timer" />
                                    <Box>
                                        <Typography sx={RequestViewStyle.timetaken}>{`${serviceLevelData?.total_date_diff} total time taken`}</Typography>
                                        {serviceLevelData?.closed_at !== null &&
                                            <Typography sx={RequestViewStyle.notes}>{`${moment(serviceLevelData?.created_at).format("DD MMM YYY")} to ${moment(serviceLevelData?.closed_at).format("DD MMM YYY")}`}</Typography>
                                        }
                                    </Box>
                                </Stack>
                            </Box>
                            <Box p={2}>
                                {serviceLevelData?.closed_at &&
                                    <>
                                        <Stack direction="row" spacing={1} alignItems={"center"}>
                                            <Box>
                                                <CompletionIcon />
                                                {/* <Box className={classes.verticalborder}></Box> */}
                                            </Box>
                                            <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#EDF7F8", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                                <Box>
                                                    <Typography sx={RequestViewStyle.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.closed_at).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                                    <Typography sx={RequestViewStyle.lineOne}>{t("Completion")}</Typography>
                                                </Box>
                                                <Box sx={{ backgroundColor: "#A6CFD2" }} style={RequestViewStyle.kpibox}>
                                                    <Typography sx={RequestViewStyle.subLine} style={{ color: "#6DAFB3" }} noWrap>{serviceLevelData?.total_date_diff}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Box sx={RequestViewStyle.verticalborder}></Box>
                                    </>
                                }
                                {serviceLevelData?.approval_date &&
                                    <>
                                        <Stack direction="row" spacing={1} alignItems={"center"}>
                                            <Box>
                                                <ApprovalStartIcon />
                                                {/* <Box className={classes.verticalborder}></Box> */}
                                            </Box>
                                            <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#FFFCEB", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                                <Box>
                                                    <Typography sx={RequestViewStyle.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.approval_date).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                                    <Typography sx={RequestViewStyle.lineOne}>{t("Approval For Start")}</Typography>
                                                </Box>
                                                <Box sx={{ backgroundColor: "#E6DEC2" }} style={RequestViewStyle.kpibox}>
                                                    <Typography sx={RequestViewStyle.subLine} style={{ color: "#B3A16D" }} noWrap>{serviceLevelData?.approval_time_diff}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Box sx={RequestViewStyle.verticalborder}></Box>
                                    </>
                                }

                                {serviceLevelData?.first_response &&
                                    <>
                                        <Stack direction="row" spacing={1} alignItems={"center"}>
                                            <Box>
                                                <FirstResponseIcon />
                                                {/* <Box className={classes.verticalborder}></Box> */}
                                            </Box>
                                            <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#FEF4F4", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                                <Box>
                                                    <Typography sx={RequestViewStyle.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.first_response).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                                    <Typography sx={RequestViewStyle.lineOne}>{t("1st Response")}</Typography>
                                                </Box>
                                                <Box sx={{ backgroundColor: "#ECD7D4" }} style={RequestViewStyle.kpibox}>
                                                    <Typography sx={RequestViewStyle.subLine} style={{ color: "#B3776D", textWrap: "wrap" }}>{serviceLevelData?.first_response_time_diff}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Box sx={RequestViewStyle.verticalborder}></Box>
                                    </>
                                }
                                {serviceLevelData?.acknowleged_date &&
                                    <>
                                        <Stack direction="row" spacing={1} alignItems={"center"}>
                                            <Box>
                                                <AcknowledgeIcon />
                                                {/* <Box className={classes.verticalborder}></Box> */}
                                            </Box>
                                            <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#F6F1FE", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                                <Box>
                                                    <Typography sx={RequestViewStyle.lineTwo}>&#x202a;{moment(serviceLevelData?.acknowleged_date).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                                    <Typography sx={RequestViewStyle.lineOne}>{t("Acknowledged On")}</Typography>
                                                </Box>
                                                <Box sx={{ backgroundColor: "#D7CDEA" }} style={RequestViewStyle.kpibox}>
                                                    <Typography sx={RequestViewStyle.subLine} style={{ color: "#896DB3", textWrap: "wrap" }}>{serviceLevelData?.acknowleged_time_diff}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Box sx={RequestViewStyle.verticalborder}></Box>
                                    </>
                                }
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    <Box>
                                        <CompletionIcon />
                                        {/* <Box className={classes.verticalborder}></Box> */}
                                    </Box>
                                    <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#F2F4F7", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                        <Box>
                                            <Typography sx={RequestViewStyle.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.created_at).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                            <Typography sx={RequestViewStyle.lineOne}>{t("Date Opened On")}</Typography>
                                        </Box>
                                    </Box>
                                </Stack>

                            </Box>

                        </>
                    }
                </Box>
                <Box>
                    <>
                        {state?.request_status !== "Closed" && requestDetails?.viewDetails?.actual_expense === null &&
                            <Box sx={RequestViewStyle?.timeLine} p={2}>
                                <Stack
                                    direction={"row"} spacing={"12px"}
                                    alignItems={"center"}>
                                    <RecordActualExpense />
                                    <Box flexGrow={1}>
                                        <Typography sx={RequestViewStyle.text}>{t("Record Actual Expenses")}</Typography>
                                    </Box>
                                    <Typography onClick={() => setRecordExpenseDrawer(true)} sx={RequestViewStyle.add}>{t("Add")}</Typography>
                                </Stack>
                            </Box>
                        }
                        {requestDetails?.viewDetails?.actual_expense !== null &&

                            <Box sx={RequestViewStyle?.timeLine} p={2}>
                                <Box sx={RequestViewStyle.expenseBox}>
                                    <Grid alignItems={"center"} container justifyContent={"space-between"} display={"flex"}>
                                        <Grid display={"flex"}>
                                            <Typography sx={RequestViewStyle.lineTwo}>{requestDetails?.viewDetails?.actual_expense}</Typography>
                                            <Typography sx={RequestViewStyle.currency}>{clientCurrency?.symbol}</Typography>
                                        </Grid>
                                        <Grid textAlign={"right"}>
                                            <Typography sx={RequestViewStyle.update} onClick={() => openExpenseDrawer(requestDetails?.viewDetails?.actual_expense)}>{t("Update")}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* <Typography className={classes.actualExpense} mt={1}>{t("Last Update on")} 22 Jan 23</Typography>  */}
                            </Box>}
                    </>
                </Box>
            </Box>
            {(requestDetails?.viewDetails?.status?.[requestDetails?.viewDetails?.status?.length - 1]?.type === "Open"
                || requestDetails?.viewDetails?.status?.[requestDetails?.viewDetails?.status?.length - 1]?.type === "Reopened")
                &&
                <Box sx={RequestViewStyle?.foot}>
                    <FooterComponent
                        single
                        btn_styles={{
                            border: "1px solid #F15A29",
                            background: "#fff !important",
                            boxShadow: "none !important",
                            "& p": {
                                color: "#F15A29",
                                fontFamily: Bold
                            }
                        }}
                        containedText={t("Cancel Request")}
                        containedonClick={() => cancelRequestOpen()}
                    />
                </Box>
            }
            <DialogDrawer
                open={showMoreBom}
                onClose={() => setShowMoreBom(false)}
                header={t("Projected BOM Details")}
                component={
                    <>
                        <Box position={"relative"} sx={RequestViewStyle.requestDetailsBox}>
                            <Box>
                                <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <BillingAmountIcon />
                                        <Box>
                                            <Typography sx={RequestViewStyle.requestTitle}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0} </Typography>
                                            <Typography sx={RequestViewStyle.requestNo}>{t("Final Amount")}</Typography>
                                        </Box>
                                    </Stack>
                                    <Typography sx={RequestViewStyle.BOMstatusBox} style={{ backgroundColor: itemDetails?.costing_status === "Pending" ? "#78B1FE" : itemDetails?.costing_status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{itemDetails?.costing_status}</Typography>
                                </Box>
                            </Box>
                            <Box mt={1} mb={1}>
                                <Divider />
                            </Box>
                            <Box mt={2} maxHeight={"200px"} overflow={"scroll"}>
                                {itemDetails?.list?.items?.map((val) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center" mb={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={RequestViewStyle.itemName} noWrap>{val?.inspection_item_name}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={RequestViewStyle.requestNo}>{`${val?.quatity} ${t("QTY")}`}</Typography>
                                            </Grid>
                                            <Grid item xs={3} justifyContent={"end"} display={"flex"}>
                                                <Typography sx={RequestViewStyle.itemName}>{itemDetails?.symbol} {val?.line_amount}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {itemDetails?.list?.deduction?.length > 0 &&

                                    <>
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                        <Box>
                                            <Typography sx={RequestViewStyle.BillingAmountTxt}>{t("Deduction")}</Typography>
                                        </Box>
                                        {itemDetails?.list?.deduction?.map((x) => {
                                            return (
                                                <Grid container spacing={2} alignItems="center" mb={1}>
                                                    <Grid item xs={6}>
                                                        <Typography sx={RequestViewStyle.itemName} noWrap>{x?.description}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography sx={RequestViewStyle.requestNo}>-</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                        <Typography sx={RequestViewStyle.itemName}>{" - "} {itemDetails?.list?.symbol} {x?.amount}</Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        <Box mt={1} mb={1}>
                                            <Divider />
                                        </Box>
                                    </>
                                }
                            </Box>

                            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                                <Typography sx={RequestViewStyle.finalamttext}>{t("Final Amount")}</Typography>
                                <Typography sx={RequestViewStyle.finalamttext}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0}</Typography>
                            </Box>

                        </Box>
                        {itemDetails?.costing_status === "Pending" && showNote ?
                            <Box>
                                <Box mt={2} sx={RequestViewStyle.notesBox} mb={2}>
                                    <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                        <Typography sx={RequestViewStyle.notesLabel}>{t("Notes")}</Typography>
                                        <Box onClick={() => setShowNote(false)}><Typography sx={RequestViewStyle.removeTxt}>{t("Remove")}</Typography></Box>
                                    </Box>
                                    <Divider />
                                    <Box p={1}>
                                        <TextBox
                                            label=""
                                            placeholder={t("Type Notes here")}
                                            value={notesData}
                                            onChange={(e) => handleChangeNotes(e?.target?.value)}
                                            border={0}
                                            multiline
                                            sx={RequestViewStyle?.textbox}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            : itemDetails?.costing_status === "Pending" &&
                            <Box mt={2} onClick={() => setShowNote(true)} mb={2}>
                                <Typography sx={RequestViewStyle.addNote}>+ {t("Add Note")}</Typography>
                            </Box>
                        }
                        {itemDetails?.costing_status === "Pending" &&
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth sx={RequestViewStyle.rejectbtn} onClick={() => updateRequest("Rejected")}>{t("Reject")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth sx={RequestViewStyle.approvebtn} onClick={() => updateRequest("Approved")}>{t("Approve")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </>
                }
            />
            <DialogDrawer
                maxWidth="sm"
                open={cancelDrawer}
                header={"Cancel Request"}
                padding={"16px 16px 0px"}
                height_style={
                    { height: "250px !important" }
                }
                component={
                    <>
                        <TextBox
                            label={"Remarks"}
                            placeholder={"Enter Remarks"}
                            multiline={true}
                            value={requestDetails?.remarks}
                            onChange={(e) => updateState("remarks", e.target.value)}
                            error={requestDetails?.error?.remarks}
                            isRequired={true}
                            isError={requestDetails?.error?.remarks?.length > 0}
                        />
                    </>
                }
                footer={
                    <>
                        <FooterComponent
                            single
                            variant="contained"
                            containedText={t("Submit")}
                            containedonClick={() => cancelRequestSubmit()}
                        />
                    </>
                }

                onClose={() => cancelRequestClose()}
            />


            <DialogDrawer
                open={imageViewer}
                onClose={() => setImageViewer(false)}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                height_style={{ height: "auto" }}
                component={
                    <ImageAndPdfViewer
                        title={"Images"}
                        assets={
                            (requestDetails?.viewDetails?.asserts
                                ? requestDetails.viewDetails.asserts?.map((val) => {
                                    return {
                                        meta_data: {
                                            file_type: val?.file_meta?.type ?? "image",
                                        },
                                        file_url: val?.url,
                                    };
                                })
                                : []
                            )}
                        onClose={() => setImageViewer(false)}
                    />
                }
            />
            {/* RECORD EXPENSE DRAWER */}
            <DialogDrawer
                open={recordExpenseDrawer}
                onClose={closeExpenseDrawer}
                header={requestDetails?.viewDetails?.actual_expense === null ? t("Add expense") : t("Update Expense")}
                component={
                    <>
                        <Stack direction={"row"} spacing={1} pb={3}>
                            <RecordExpenseIcon />
                            <Stack direction={"column"}>
                                <Typography sx={RequestViewStyle.cost}>{t("Add Your Expense")}</Typography>
                                <Typography sx={RequestViewStyle.addSpending}>{t("Your expense will be added to your spendings")}</Typography>
                            </Stack>
                        </Stack>
                        <TextBox
                            placeholder={t("Add expense")}
                            endAdornment={(
                                <Typography sx={RequestViewStyle.currency}>{clientCurrency?.symbol}</Typography>
                            )}
                            value={actualExpense}
                            onChange={(e) => setActualExpense(e?.target?.value)}
                        />
                    </>
                }
                footer={
                    <>
                        <FooterComponent
                            single
                            variant="contained"
                            containedText={requestDetails?.viewDetails?.actual_expense === null ? t("Add expense") : t("Update expense")}
                            containedonClick={() => UpdateActualExpense()}
                        />
                    </>
                }
            />
            {/* {costing accepted} */}
            <DialogDrawer
                open={acceptDrawer}
                onClose={() => setAcceptDrawer(false)}
                isnotTitle={true}
                component={
                    <Box p={2}>
                        <Box mb={3} textAlign={"right"} onClick={() => setAcceptDrawer(false)}>
                            <ClearIcon />
                        </Box>
                        <Box textAlign={"center"}>
                            <BigGreenTick />
                        </Box>
                        <Box textAlign={"center"}>
                            <Typography sx={RequestViewStyle.recentNote}>{t("Thank You For accepting the costing")} <br /> {t("We will schedule your work soon")}</Typography>
                        </Box>
                    </Box>
                }
            />
            {/* REJECT DRAWER */}
            <DialogDrawer
                open={rejectDrawer}
                onClose={() => setRejectDrawer(false)}
                header="Reject"
                component={
                    <>
                        <TextBox
                            isRequired
                            color
                            labelColor={"#98A0AC"}
                            label={t("Reason for Reject")}
                            placeholder={t("Reason")}
                            value={reason}
                            onChange={(value) => setReason(value.target.value)}
                            multiline />

                    </>
                }
                footer={
                    <FooterComponent
                        single
                        variant="contained"
                        containedText={t("Submit")}
                        containedonClick={() => acceptCostingRequest("Rejected")}
                    />
                }
            />
            <DialogDrawer
                open={showCharges}
                onClose={() => setShowCharges(false)}
                header={t("Upfront Charges")}
                component={
                    <>
                        <Box position={"relative"}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <UpfrontChargesIcon />
                                    <Box>
                                        <Typography sx={RequestViewStyle.notesTitle}>{`${requestDetails?.viewDetails?.visiting_charge_request?.[0]?.amount} ${clientCurrency?.code ?? ""}`}</Typography>
                                        <Typography sx={RequestViewStyle.subText}>{t("Upfront Charges")}</Typography>
                                    </Box>
                                </Stack>
                                <Typography sx={RequestViewStyle.costingStatusText} style={{ backgroundColor: requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status}</Typography>
                                {/* <KeyboardArrowRight sx={{ color: "#98A0AC" }} /> */}
                            </Box>
                            <Box mt={2} height="200px">
                                <Typography sx={RequestViewStyle.productTitle}>{t("Summary")}</Typography>
                                <Box mt={1}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Typography sx={RequestViewStyle.requestNo}>{t("Upfront Charges and Payments")}</Typography>
                                        </Grid>
                                        <Grid item xs={4} textAlign={"right"}>
                                            <Typography sx={RequestViewStyle.requestNo}>{`${requestDetails?.viewDetails?.visiting_charge_request?.[0]?.amount} ${clientCurrency?.code ?? ""} `}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={1} mb={1} sx={RequestViewStyle.dotted} />
                                <Box mt={1}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Typography sx={RequestViewStyle.productTitle}>{t("Total Amount")}</Typography>
                                        </Grid>
                                        <Grid item xs={4} textAlign={"right"}>
                                            <Typography sx={RequestViewStyle.productTitle}>{`${requestDetails?.viewDetails?.visiting_charge_request?.[0]?.amount} ${clientCurrency?.code ?? ""} `}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                        {requestDetails?.viewDetails?.visiting_charge_request?.[0]?.costing_status === "Pending" &&
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth sx={RequestViewStyle.rejectbtn} onClick={() => setRejectDrawer(true)}>{t("Reject")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth sx={RequestViewStyle.approvebtn} onClick={() => acceptCostingRequest("Approved")}>{t("Approve")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </>
                }
            />
            <DialogDrawer
                open={isVisit?.bool}
                onClose={() => { return (setIsVisit({ ...isVisit, bool: false }), setRescheduleData({ visitDate: new Date() })) }}
                header={t("Upcoming Visit")}
                component={
                    <>
                        <Box position={"relative"} height={isVisit?.type === "reschedule" ? (match ? (isVisit?.data?.status === "Rescheduled" ? "auto" : "500px") : "auto") : (isVisit?.data?.status === "Approved" ? "auto" : "400px")} overflow="scroll">
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <RescheduleIcon />
                                    <Box>
                                        {state?.request_type === "Maintenance" &&
                                            <Box display={"flex"}>
                                                <Typography sx={RequestViewStyle.notesTitle}>{`${moment(isVisit?.data?.execution).format("DD MMM YYYY hh:mm a")}`}</Typography>
                                            </Box>
                                        }
                                        <Typography sx={RequestViewStyle.subText}>{`Upcoming ${isVisit?.data?.request_type} job`}</Typography>
                                    </Box>
                                </Stack>
                                {isVisit?.data?.status !== "Pending" &&
                                    <Typography sx={RequestViewStyle.costingStatusText} style={{ backgroundColor: isVisit?.data?.status === "Rescheduled" ? "#5078e1" : "#5AC782" }}>{isVisit?.data?.status}</Typography>

                                }
                            </Box>
                            <Box mt={2} sx={RequestViewStyle.newReschedulebox}>
                                {isVisit?.data?.rescheduled_datetime !== null &&
                                    <Box mb={1}>
                                        <Typography sx={RequestViewStyle.label}>{t("Rescheduled Date & Time")}</Typography>
                                        <Box mt={0.5}>
                                            <Typography sx={RequestViewStyle.notesTitle}>{moment(isVisit?.data?.rescheduled_datetime).format("DD MMM YYYY")} , {isVisit?.data?.slot_name}</Typography>

                                        </Box>
                                    </Box>
                                }
                                <Typography sx={RequestViewStyle.label}>{t("Resource Name")}</Typography>
                                <Box mt={1}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Avatar src={isVisit?.data?.image_url} />
                                        <Box>
                                            <Typography sx={RequestViewStyle.notesTitle}>{isVisit?.data?.resource_name}</Typography>
                                            <Typography sx={RequestViewStyle.label}>{isVisit?.data?.resource_job}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box mt={1}>
                                    <Typography sx={RequestViewStyle.label}>{t("Job Detail")}</Typography>
                                    <Box mt={1}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <JobIcon />
                                            <Box>
                                                <Typography sx={RequestViewStyle.notesTitle} style={{ textTransform: "capitalize" }}>{isVisit?.data?.request_type}</Typography>
                                                <Typography sx={RequestViewStyle.label}>{isVisit?.data?.job_no}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Box>
                                {/* {isVisit?.data?.status === "Pending" &&
                                    <Box mt={1}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={12}>
                                                <DatePickerTimeNew
                                                    label={isVisit?.type === "reschedule" ? t("New visit date and time") : t("Visit date and time")}
                                                    backgroundColor={"#FFFFFF"}
                                                    onChange={(value) => setIsVisit({ ...isVisit, rescheduleDateTime: value })}
                                                    value={new Date(isVisit?.rescheduleDateTime)}
                                                    format={"dd/MM/yy hh:mm a"}
                                                    isReadOnly={(isVisit?.data?.status !== "Pending" && isVisit?.type === "reschedule") ? true : isVisit?.type === "approve" ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                } */}
                            </Box>
                            {isVisit?.data?.status === "Pending" && isVisit?.type === "reschedule" ?
                                <Box mt={2} sx={RequestViewStyle.newReschedulebox} mb={6}>
                                    <Box>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <RescheduleTextIcon />
                                            <Typography sx={RequestViewStyle.rescheduleText}>{t("To reschedule, Please select your preferred date and time")}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box mt={1}>
                                        <Box mt={1}>
                                            <DatePickerNew
                                                isRequired
                                                // isTime
                                                disableFuture={true}
                                                disablePast={false}
                                                minDate={new Date()}
                                                label={t("Date")}
                                                placeholder={t("Choose Date")}
                                                value={rescheduleData?.visitDate}
                                                onChange={(value) =>
                                                    setRescheduleData({ ...rescheduleData, visitDate: value, selectedSlot: "" })
                                                }
                                                size={{
                                                    xs: 12,
                                                }}
                                                sx={{
                                                    border: "1.5px solid #e2e2e2",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#fff"
                                                }}
                                            // isError={props?.request?.error?.preferredTime?.length > 0}
                                            // errorMessage={props?.request?.error?.preferredTime}
                                            />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography sx={RequestViewStyle.slotTitle}>{t("Time Slots")}<Typography variant="caption" color="error" sx={{ marginLeft: "px" }}>*</Typography></Typography>
                                        </Box>
                                        <Box mt={1} mb={2}>
                                            <Grid container spacing={1} alignItems={"center"}>
                                                {slotsData?.map((x) => {
                                                    return (
                                                        <Grid item xs={6} sm={6} md={3} lg={3} onClick={() => handleChangeSlot(x)}>
                                                            <Box sx={rescheduleData?.selectedSlot?.value === x?.value ? RequestViewStyle.selectedSlotBox : RequestViewStyle.slotBox}>
                                                                <Typography sx={RequestViewStyle.slotTime} style={{ color: rescheduleData?.selectedSlot?.value === x?.value ? "#5078E1" : "#091B29" }}>{x?.label}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>
                                        </Box>
                                        {/* <Box height={"30px"}></Box> */}
                                    </Box>
                                </Box>
                                : isVisit?.data?.status === "Pending" &&
                                <Box mt={2}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            <DatePickerTimeNew
                                                label={isVisit?.type === "reschedule" ? t("New visit date and time") : t("Visit date and time")}
                                                backgroundColor={"#FFFFFF"}
                                                onChange={(value) => setIsVisit({ ...isVisit, rescheduleDateTime: value })}
                                                value={new Date(rescheduleData?.visitDate)}
                                                format={"dd/MM/yyyy hh:mm a"}
                                                isReadOnly={(isVisit?.data?.status !== "Pending" && isVisit?.type === "reschedule") ? true : isVisit?.type === "approve" ? true : false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                        {isVisit?.data?.status === "Pending" &&
                            <Box sx={RequestViewStyle.bottomfix}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button variant="contained" fullWidth sx={isVisit?.type === "approve" ? RequestViewStyle.approvebtn : RequestViewStyle.reschedulebtn} onClick={() => handleReschedule(isVisit?.type === "approve" ? "Approved" : "Rescheduled")}>{isVisit?.type === "approve" ? t("Approve") : t("Reschedule")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </>
                }
            />
        </Container >
    )
}
