import { Avatar, Box } from "@mui/material"
import CameraIcon from "../../assets/cameraIcon";
import { ProfilePictureEditStyle } from "./style";
import { openBackdrop, closeBackdrop } from "../../redux/slices/backdrop"
import { useDispatch } from "react-redux";
import { useLazyImageMutationQuery } from "../../redux/services";
import { useSnackbar } from "notistack";
import Compressor from "compressorjs";
import { generateImageUrl, img_size, SemiBold } from "../../utils";

export const SingleImageUpload = ({
    onChange = () => false,
    value = {},
    avatarStyle = {},
    batchAvatarStyle = {},
    badgeIcon = null,
    type,
    name,
    ...props
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar , closeSnackbar} = useSnackbar()
    const [imageMutation] = useLazyImageMutationQuery()
    const handleChange = async (e) => {
        dispatch(openBackdrop("...Loading"))
        if (e?.target?.files) {
            const res = await singleFileUpload(e.target.files[0])
            if (res === true) {
                const formData = new FormData();
                formData.append("files", e?.target?.files[0]);
                formData.append("companyId", 1);
                const image = await imageMutation(formData)
                onChange(image?.data)
                dispatch(closeBackdrop(""))          
            }


        }

    }

    const singleFileUpload = async (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const allowed_image_size = 5;
                let file_type = file?.type.split("/")?.[0]
                if (file_type === "image") {
                    new Compressor(file, {
                        quality: 0.8,
                        async success(result) {
                            let bytes = result?.size
                            let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                            if (finalSize <= allowed_image_size) {
                                resolve(true)
                            } else {
                                 enqueueSnackbar("Please upload less then 5 MB file", {
                                    variant: "error",
                                    anchorOrigin: { horizontal: "center", vertical: "top" },
                                    className: { fontFamily: SemiBold }
                                })
                                return      setTimeout(()=>{
                                    closeSnackbar()
                                },2000)
                            }
                        },
                        error(err) {
                             enqueueSnackbar("Something Went Wrong", {
                                variant: "error",
                                anchorOrigin: { horizontal: "center", vertical: "top" },
                                className: { fontFamily: SemiBold }
                            })
                            return      setTimeout(()=>{
                                closeSnackbar()
                            },2000)
                        }
                    })
                } else {
                    let bytes = file?.size
                    let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                    if (finalSize <= allowed_image_size) {
                        resolve(true)
                    } else {
                         enqueueSnackbar("Please upload less then 5 MB file", {
                            variant: "error",
                            anchorOrigin: { horizontal: "center", vertical: "top" },
                            className: { fontFamily: SemiBold }
                        })
                        return      setTimeout(()=>{
                            closeSnackbar()
                        },2000)
                    }
                }
            } catch (error) {
                 enqueueSnackbar("Something Went Wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
                return      setTimeout(()=>{
                    closeSnackbar()
                },2000)
            }
        });
    };
    return (
        <Box sx={ProfilePictureEditStyle?.profilePicturePadding}>
            {type === "pdf" ?
                <img
                    height={48}
                    width={48}
                    sx={ProfilePictureEditStyle?.image}
                    src={"/images/PDFImage.svg"}
                    alt="profile_image"
                />
                :
                <>                
                    {value?.length > 0 ?
                        <Avatar
                            variant="circular"
                            sx={{ ...ProfilePictureEditStyle?.avatarOuter, ...avatarStyle }}
                            src={generateImageUrl(value, img_size.small_square)}
                            height={167}
                            width={167}
                        />
                    
                        :

                        <Avatar
                        height={167}
                        width={167}
                            src={"/images/profileAvatar.svg"}
                            alt="profile_image"
                            variant="circular"
                            sx={{ ...ProfilePictureEditStyle?.avatarOuter, ...avatarStyle }}

                        />
                        // <img src="/images/PDFImage.svg" />
                    }
                </>
            }

            <label htmlFor="uplodebtn">
                <Avatar
                    variant="circular"
                    sx={{ ...ProfilePictureEditStyle?.avatarInner, ...batchAvatarStyle }}

                >
                    {badgeIcon ?? <CameraIcon />}
                </Avatar>
            </label>
            <input
                type="file"
                name="img"
                accept="image/*"
                style={{ display: "none" }}
                id="uplodebtn"
                onChange={(e) => handleChange(e)}
                onClick={(event) => {
                    event.target.value = null
                }}
            />
        </Box>
    )
}