import { Typography } from "@mui/material";
import { Box, Container, Stack, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { UnitVehicle, NoPlate } from "../../assets";
import { CarSvg } from "../../assets/vehicle/carSvg";
import { FooterComponent, TopNavBar } from "../../components";
import { useLazyParkingCreationQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { errorRoute, generateImageUrl, img_size } from "../../utils";
import { VehileViewStyle } from "./style"

export const VehicleView = ({ t = () => false }) => {
    const location = useLocation()
    const language = localStorage.getItem("i18nextLng")
    let data = location?.state
    const [ParkingCreation, { isLoading , error }] = useLazyParkingCreationQuery()
    const navigate = useNavigate()
    const [state, setState] = React.useState({
        isEdit: false,
        Editdata: {}
    })
    const ParkingCreationAPI = async (data) => {
        await ParkingCreation(data)
    }
    const handleDelete = () => {
        const payload = {
            id: data?.id,
            is_active: false,
            owner_unit_id: data?.unit_id
        }
        ParkingCreationAPI(payload)
        navigate(-1)
    }
    const handleDrawerEdit = () => {
        navigate(AppRoutes?.addVehicle, { state: state })
    }

    React.useEffect(() => {
        setState({
            ...state,
            isEdit: true,
            Editdata: data
        })
        // eslint-disable-next-line
    }, [])

    React.useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error])
    return (
        <Container
            maxWidth="sm"
            sx={VehileViewStyle?.containerSm}>
           <Box sx={{position:"fixed" , top:0 , left:0 , right:0 , zIndex:1}}>
           <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <TopNavBar isDirect title={data?.parking_name} isBack />
            </Container>
           </Box>
               <Box sx={{overflow:"auto" , height:"100%"}}>
               <Box sx={VehileViewStyle?.subRoot}>
                    <Typography nowrap sx={VehileViewStyle?.details}>{t("Details")}</Typography>
                    {
                        data?.vehicle_image && <Box sx={VehileViewStyle?.vehicleImg}>
                            <img src={generateImageUrl(data?.vehicle_image, img_size.small_square)} alt="vehicleImg" width="100%" height="100%" style={{ borderRadius: "4px" , objectFit:"contain" }} />
                        </Box>
                    }
                    <Box>
                        <Stack sx={VehileViewStyle?.UnitStack} direction="row">
                            <Box sx={VehileViewStyle?.Unit}>
                                <Typography nowrap sx={VehileViewStyle?.headText}>{t("Unit ID")}</Typography>
                                <Typography nowrap sx={VehileViewStyle?.subText}>{data?.unit_name}</Typography>
                            </Box>
                            <Box>
                                <UnitVehicle />
                            </Box>
                        </Stack>
                        <Grid container alignItems="center">
                            <Grid item xs={6} >
                                <Stack sx={VehileViewStyle?.UnitStack} direction="row">
                                    <Box sx={VehileViewStyle?.Unit}>
                                        <Typography noWrap sx={VehileViewStyle?.headText}>{t("Vehicle")}</Typography>
                                        <Typography noWrap sx={VehileViewStyle?.subText}>{data?.vehicle_type}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <CarSvg />
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} >
                                <Stack sx={VehileViewStyle?.UnitStack} style={{ marginInlineStart: 8 }} direction="row">
                                    <Box sx={VehileViewStyle?.Unit}>
                                        <Typography nowrap sx={VehileViewStyle?.headText}>{t("Vehicle No")}.
                                        </Typography>
                                        <Typography nowrap sx={VehileViewStyle?.subText}>{data?.number_plate}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <NoPlate />
                                    </Box>
                                </Stack>

                            </Grid>
                        </Grid>
                        <Box >
                            <Stack direction="row" sx={language === "ar" ? VehileViewStyle?.imgStackArabic : VehileViewStyle?.imgStack} >
                                <Box>
                                    <Typography noWrap sx={VehileViewStyle?.headText}>{t("Parking Slot No")}.</Typography>
                                    <Typography noWrap sx={VehileViewStyle?.subBigText}>{data?.parking_name}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
               </Box>

            <Box sx={{ position: "fixed", bottom: "0", width: "100%" , left:0,right:0}}>
                <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                    <FooterComponent
                         btnLoading={isLoading}
                         outlinedText={t("Delete")}
                         containedText={t("Edit")}
                         outlinedonClick={() => handleDelete()}
                         containedonClick={() => handleDrawerEdit()}
                    />
                </Container>
            </Box>
        </Container >
    )
}