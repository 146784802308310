import React from 'react'
import { Piechart } from '../../components/piechart'
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import { stringAvatar } from '../../utils'
import { PropertyUnitNewStyle } from './style'

export const Shareholder = ({
    data = []
}) => {
    const classes = PropertyUnitNewStyle
    return (
        <>
            <Piechart data={data}
                width={200}
                isTotal={true}
                totalText={"Shareholders"}
                total={data?.length}
            />

            <Stack spacing={2}>
                {
                    data?.map((i, index) => {
                        return (
                            <>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mb={"12px"}>
                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                            <Box sx={{ ...classes.dot, backgroundColor: `${i?.fill} !important`, height: "10px", width: "10px" }} mr={1}></Box>
                                            <Avatar {...stringAvatar(i?.contact?.first_name)} src={i?.contact?.image_url} sx={{height: "40px", width: "40px"}}></Avatar>
                                        </Stack>
                                        <Stack>
                                            <Typography sx={{...classes.propertyTitle, ...classes.propertySubTitleWidth}}>{i?.contact?.first_name}</Typography>
                                            <Typography sx={classes.secText}>{`${i?.contact?.mobile_no_country_code} ${i?.contact?.mobile_no}`}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Typography sx={classes.propertyTitle}>{i?.value} %</Typography>
                                </Stack>
                                {data?.length - 1 !== index && <Divider></Divider>}
                            </>
                        )
                    })
                }
            </Stack>
        </>
    )
}