import React from 'react'
import { FileComponent } from '../filemanager/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyGetAgreementAssetQuery } from '../../redux/services'
import { saveAs } from 'file-saver';
import { DialogDrawer, DocumentViewer, TopNavBar } from '../../components';
import { Box, Container, Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';

const Documents = ({ t = () => false }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [assets, setAssets] = React.useState([])
    const [data, setData] = React.useState({
        drawer: false,
        selectedAsset: ""
    })

    const [getAgreementAsset] = useLazyGetAgreementAssetQuery()

    const getAssets = async () => {
        const res = await getAgreementAsset({
            agreement_id: location?.state?.agreement_id
        })
        await setAssets(res?.data?.data?.data ?? [])
    }

    React.useEffect(() => {
        getAssets()
        // eslint-disable-next-line
    }, [location])

    const download = (data) => {
        var ext = data?.url.substring(data?.url?.lastIndexOf(".") + 1);
        saveAs(data?.url, `${data?.name}.${ext}`);
    }

    const viewImage = (value) => {
        setData({
            drawer: true,
            selectedAsset: value
        })
    }

    return (
        <>
            <TopNavBar title={"Document"} isDirect route={() => navigate(-1)} t={t} />
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: "0px !important", height: "calc(100vh - 58px)" }}>
                <Box p={2}>
                    <Grid container spacing={2}>
                        {
                            assets?.map((data) => {
                                return (
                                    <Grid item xs={6}>
                                        <FileComponent
                                            t={t}
                                            data={{
                                                fileNmae: data?.file_meta?.name,
                                                // createdAt: moment(val?.created_at).tz(moment.tz.guess()).format("DD MMM YY"),
                                                data: data
                                            }}
                                            viewDetails={(value) => viewImage(value)}
                                            type={2}
                                            viewPdf={(value) => viewImage(value)}
                                            download={download}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>

                <DialogDrawer
                    maxWidth="sm"
                    open={data?.drawer}
                    padding={"0"}
                    header={data?.selectedAsset?.file_meta?.name ?? "View Asset"}
                    onClose={() => setData({
                        ...data,
                        drawer: false,
                        selectedAsset: ""
                    })}
                    height={"470px"}
                    component={
                        <>
                            {

                                <Box sx={{ height: "470px", width: "100%" }} p={2}>
                                    {data?.selectedAsset?.file_meta?.type === "pdf" ?
                                        <DocumentViewer url={data?.selectedAsset?.url} />
                                        :
                                        <img src={data?.selectedAsset?.url} alt='' style={{ height: "470px", width: "100%", objectFit: "contain" }} />
                                    }
                                </Box>

                            }
                        </>
                    }
                />
            </Container>
        </>
    )
}

export default withTranslation('filemanager')(Documents)