import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Link, Container, Box } from "@mui/material";
import animation from './assets/500.json'
import Lottie from "react-lottie";
import { Bold } from './utils/constants';
import { AppRoutes } from "./router/routes";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F6F6",
    padding: '0px !important'
  },
  link: {
    fontFamily: Bold,
    backgroundColor: '$F15A29',
    borderRadius: "8px",
    opacity: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    width: "fit-content",
    padding: "10px",
    margin: "0 auto",
    cursor: "pointer"

  }
});
class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log('info:', info)
    console.log('error:', error)

    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service

    //TODO:
    // logErrorToMyService(error, info);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      // here is where we will declare lottie animation
      // "animation" is what we imported before animationData: animation,
      animationData: animation,

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container id="error_catcher_root" maxWidth="sm" className={this.props.classes.root} sx={{ textAlign: "center", height: '100vh', display: "flex", justifyContent: "center" }}>
          <Box display={'block'} textAlign={'center'}>
            <Lottie options={defaultOptions} height={300} width={300} />
            <Link d="error_catcher_link" href={AppRoutes?.home} className={this.props.classes.link} sx={{
              fontFamily: Bold,
              backgroundColor: '#F15A29',
              borderRadius: "8px",
              opacity: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              width: "fit-content",
              padding: "10px",
              margin: "0 auto",
              cursor: "pointer",
              textDecoration:"none"

            }}>
              Oops! Something went wrong.
            </Link>
          </Box>
        </Container >

      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(AppErrorBoundary);
