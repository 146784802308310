import { SemiBold } from "../../utils";

// MobileNumber Input Styles
export const MobileNumberInputStyle = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: 0,
  },
  wrapper: {
    borderRadius: '4px',
    height: 48,
    backgroundColor: '#fff',
    alignItems: "center",
    display: "flex",
    border: "1.5px solid ",
    borderColor: "border.main",
    "&:hover": {
      border: `1.5px solid`,
      borderColor: "primary.main"
    },

  },
  numberBox:{
    color:"text.main",
    fontFamily:SemiBold,
    fontSize: "0.875rem",
    padding:"6px"
  },
  simpleSelectBox: {
    borderInlineEnd: "1px solid",
    borderColor: "border.light",
    color:"text.main",
    fontFamily:SemiBold,
  }
};


// Input Base Styles
export const BootstrapInput = {
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    width: "100%",
    marginLeft: "4px",
    fontFamily: SemiBold,
    fontSize: "0.875rem"
  },
  Label: {
    color: "primary.Tertiary",
    fontSize: "0.75rem",
  },
}

// select File Styles
export const SelectStyles = {
  margin: {
    margin: "0px",
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      paddingRight: "20px !important",
    },
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
    margin: "0px 8px",
  },
  autocomplete: {
    width: 85,
    "& fieldset": {
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "0px 9px",
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
      fontFamily:SemiBold,
  },
  },
}


