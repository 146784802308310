import React from 'react'
import { Container, Box, Typography, Stack, Avatar } from "@mui/material"
import { ImageSlider } from '../../components/imageSlider'
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from '../../router/routes';
import { DialogDrawer, DocumentViewer, LoderSimmer, TopNavBar } from '../../components';
import { PropertyUnitNewStyle } from './style';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SQRTICon from './utils/sqrtIcon';
import UnitIcon from './utils/unitIcon';
import AmenitiesIcon from './utils/amenitiesIcon';
import UtilitiesIcon from './utils/utilitiesIcon';
import InventoryIcon from './utils/inventoryIcon';
import ServiceReqIcon from './utils/serviceReqIcon';
import ScrollContainer from "react-indiana-drag-scroll";
import AgreementIcon from './utils/agreementIcon';
import { AgreementDetailCard } from './components/agreementDetailCard';
import LeaseStartIcon from './utils/leaseStartIcon';
import ContractSignedIcon from './utils/contractSignedIcon';
// import ContractStartIcon from './utils/contractStartIcon';
import UnitHandoverIcon from './utils/unitHandoverIcon';
import WarrentyEndIcon from './utils/warrentyEndIcon';
import ContactIcon from './utils/contactIcon';
import InvoiceIcon from './utils/invoiceIcon';
import DocumentIcon from './utils/documentIcon';
import PaymentPlanIcon from './utils/paymentPlanIcon';
import { ProjectProgressCard } from './components/projectProgressCard';
import { ContactCard } from './components/contactCard';
import { useLazyGetOwnerUnitQuery } from '../../redux/services';
import moment from 'moment';
import { NetWorkCallMethods, NetworkCall, checkYouTubeLink, stringAvatar } from '../../utils';
import NoAgreementImg from './utils/noAgreementImg';
import { PaymentPlan } from './paymentPlan';
import { FinancingInfo } from './financingInfo';
import { PropertyUnitPageSimmer } from './propertyUnitPageSimmer';
import NoTimelineImg from './utils/noTimelineImg';
import { config } from '../../config';
import ParkingSlotIconView from './utils/parkingSlotIconView';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarIcon from './utils/calendarIcon';
import LeaseEndIcon from './utils/leaseEndIcon';

const PropertyUnitNew = ({
    t = () => false
}) => {
    const navigate = useNavigate();
    const classes = PropertyUnitNewStyle
    let location = useLocation();
    const language = localStorage.getItem("i18nextLng")
    const [contactView, setContactView] = React.useState(false)
    const [unitData, setUnitData] = React.useState("")
    const [paymentPlan, setPaymentPlan] = React.useState(false)
    const [financeBool, setFinanceBool] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [invoiceData, setInvoiceData] = React.useState({
        data: "",
        bool: false
    })

    const [getOwnerUnit] = useLazyGetOwnerUnitQuery();
    // const [getAgreementAsset] = useLazyGetAgreementAssetQuery()

    const scrollData = [
        {
            icon: <AmenitiesIcon />,
            title: t("Amenities"),
            subtitle: "",
            onClick: () => navigate(AppRoutes.amenities, { state: unitData?.property?.id })
        },
        {
            icon: <UtilitiesIcon />,
            title: t("Utilities"),
            subtitle: "",
            onClick: () => navigate(AppRoutes.utilities, { state: unitData?.id })
        },
        {
            icon: <InventoryIcon />,
            title: t("Inventory"),
            subtitle: "",
            onClick: () => navigate(AppRoutes.addons, {
                state: {
                    id: unitData?.id,
                    property_name: unitData?.property?.name,
                    logo: unitData?.logo,
                    area: unitData?.address?.area,
                    unit_name: unitData?.name
                }
            })
        },
        {
            icon: <ServiceReqIcon />,
            title: t("Service"),
            subtitle: t("Requests"),
            onClick: () => navigate(AppRoutes?.request, { state: unitData?.id })
        },
        // {
        //     icon: <ContractHistoryIcon />,
        //     title: "Contract",
        //     subtitle: "History",
        // },
        // {
        //     icon: <AmenitiesIcon />,
        //     title: "Service",
        //     subtitle: "Report",
        // }
    ]

    const agreementCardData = [
        {
            icon: <LeaseStartIcon />,
            title: moment(unitData?.agreement?.[0]?.lease_start_date).format("DD MMM YYYY"),
            subtitle: t("Lease start"),
            isActive: unitData?.agreement?.[0]?.agreement_type !== "Sale Agreement" && unitData?.agreement?.[0]?.lease_start_date
        },
        {
            icon: <LeaseEndIcon />,
            title: moment(unitData?.agreement?.[0]?.lease_end_date).format("DD MMM YYYY"),
            subtitle: t("Lease end"),
            isActive: unitData?.agreement?.[0]?.agreement_type !== "Sale Agreement" && unitData?.agreement?.[0]?.lease_end_date
        },
        {
            icon: <CalendarIcon />,
            title: moment(unitData?.agreement?.[0]?.contract_start_date).format("DD MMM YYYY"),
            subtitle: t("Contract Date"),
            isActive: unitData?.agreement?.[0]?.contract_start_date
        },
        {
            icon: <ContractSignedIcon />,
            title: moment(unitData?.agreement?.[0]?.signed_date).format("DD MMM YYYY"),
            subtitle: t("Contract Signed"),
            isActive: unitData?.agreement?.[0]?.signed_date
        },
        // {
        //     icon: <ContractStartIcon />,
        //     title: moment(unitData?.agreement?.[0]?.contract_start_date).format("DD MMM YYYY"),
        //     subtitle: t("Contract Start"),
        //     isActive: unitData?.agreement?.[0]?.contract_start_date
        // },
        {
            icon: <UnitHandoverIcon />,
            title: moment(unitData?.agreement?.[0]?.unit_handover).format("DD MMM YYYY"),
            subtitle: t("Unit handover"),
            isActive: unitData?.agreement?.[0]?.unit_handover
        },
        {
            icon: <WarrentyEndIcon />,
            title: moment(unitData?.agreement?.[0]?.warranty_date).format("DD MMM YYYY"),
            subtitle: t("Warranty End"),
            isActive: unitData?.agreement?.[0]?.warranty_date
        }
    ]

    const agreementScrollData = [
        // {
        //     icon: <OccumentsIcon />,
        //     title: "Occupants",
        //     subtitle: "",
        //     route: ""
        // },
        {
            icon: <InvoiceIcon />,
            title: t("Invoice"),
            subtitle: "",
            onClick: () => navigate(AppRoutes?.invoices, { state: unitData?.agreement?.[0]?.id })
        },
        {
            icon: <DocumentIcon />,
            title: t("Documents"),
            subtitle: "",
            onClick: () => handleGetAgreementAssets(unitData?.agreement?.[0]?.id)
        },
        {
            icon: <ParkingSlotIconView />,
            title: t("Parking"),
            subtitle: t("Slot"),
            onClick: () => navigate(AppRoutes?.parkingSlot, { state: unitData?.agreement?.[0]?.id })
        },
        {
            icon: <AmenitiesIcon />,
            title: t("Financing"),
            subtitle: "",
            onClick: () => setFinanceBool(true)
        },
        {
            icon: <PaymentPlanIcon />,
            title: t("Payment"),
            subtitle: t("Plan"),
            onClick: () => setPaymentPlan(true)
        }
    ]

    React.useEffect(() => {
        getOwnerUnitData()
        // eslint-disable-next-line
    }, [])

    const getOwnerUnitData = async () => {
        setLoading(true)
        const response = await getOwnerUnit({
            unit_id: location?.state?.id,
            is_purchased: location?.state?.is_purchased
        })
        await setLoading(false)
        setUnitData(response?.data?.data ?? "")
    }

    const getUnitStatusType = () => {
        // if (!location?.state?.is_purchased && location?.state?.status === "Occupied") {
        //     return t("Occupied")
        // } else if (location?.state?.is_purchased && location?.state?.status === "Occupied") {
        //     return t("Purchased")
        // } else {
        //     return t("Vacant")
        // }
        return location?.state?.is_purchased ? "Purchased" : location?.state?.status
    }

    const filterUnitImage = () => {
        let images = unitData?.unit_assets?.map((i) => {
            if (!checkYouTubeLink(i?.url) && i?.url?.slice(i?.url?.length - 3, i?.url?.length) !== "pdf") {
                return i ?? false
            }
        })

        return images?.filter((i) => i !== undefined)
    }

    const handleGetAgreementPdf = async (id) => {

        setInvoiceData({
            bool: true,
            data: "",
            type: "agreement"
        })
        const payload = {
            id: id,
        }
        NetworkCall(
            `${config.api_url}agreement/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setInvoiceData({
                bool: true,
                data: res?.data ?? "",
                type: "agreement"
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetAgreementAssets = async (id) => {
        // const response = await getAgreementAsset({
        //     agreement_id: id
        // })
        await navigate(AppRoutes.agreementDocument, { state: { agreement_id: id } })
    }

    const handleViewAllImage = () => {
        navigate(AppRoutes.assetViewPage, { state: { assets: unitData?.unit_assets } })
    }

    const handleViewProgressAsset = (asset) => {
        navigate(AppRoutes.assetViewPage, { state: { assets: asset, hideVideoTab: true } })
    }

    return (
        <>
            <TopNavBar title={location?.state?.unit_name ?? unitData?.name} isDirect route={() => navigate(AppRoutes.properties)} t={t} />
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: "0px !important" }}>
                {
                    loading ?
                        <Box p={2} sx={{ height: "calc(100vh - 55px)", overflow: "auto" }}>
                            <PropertyUnitPageSimmer />
                        </Box>
                        :
                        <Box p={2} sx={classes.body}>
                            <Typography sx={classes.title}>{t("Unit Details")}</Typography>
                            <Box sx={classes.box} mt={1}>
                                <ImageSlider type={getUnitStatusType()} image={filterUnitImage()} handleViewAll={() => handleViewAllImage()} />

                                <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} mt={"20px"}>
                                    {
                                        unitData?.unit_type?.name &&
                                        <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} sx={classes.unitTypeBox}>
                                            <UnitIcon />
                                            <Typography sx={classes.unitAndSqrtText}>{unitData?.unit_type?.name}</Typography>
                                        </Stack>
                                    }

                                    <Stack direction={"row"} columnGap={"6px"} alignItems={"center"} sx={classes.sqrtBox}>
                                        <SQRTICon />
                                        <Typography sx={classes.unitAndSqrtText}>{`${unitData?.total_area ?? 0} ${unitData?.uom?.name}`}</Typography>
                                    </Stack>
                                </Stack>


                                <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} mt={"12px"}>
                                    <Stack spacing={"4px"}>
                                        <Typography sx={classes.unitTitle}>{unitData?.name}</Typography>
                                        <Typography sx={classes.unitSubTitle}>{unitData?.unit_no}</Typography>
                                    </Stack>
                                    <Typography sx={classes.viewMoreText} onClick={() => {
                                        navigate(AppRoutes.propertyUnitView, {
                                            state: {
                                                id: unitData?.id,
                                                type: "unit"
                                            }
                                        })
                                    }}>{t("View more")}</Typography>
                                </Stack>
                                {
                                    (unitData?.block?.name || unitData?.floor?.name) &&
                                    <Typography sx={classes.propertySubTitle} mt={"6px"}>
                                        {unitData?.block?.name} {unitData?.floor?.name && ", "} {unitData?.floor?.name}
                                    </Typography>
                                }

                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={"16px"} sx={classes.propertyBox}>
                                    <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                        <Avatar sx={classes.propertyAvatar} src={unitData?.property?.logo ?? ""} {...stringAvatar(unitData?.property?.name)}></Avatar>
                                        <Stack>
                                            <Typography sx={{ ...classes.propertyTitle, ...classes.propertySubTitleWidth }}>{unitData?.property?.name}</Typography>
                                            <Typography sx={{ ...classes.propertySubTitle, ...classes.propertySubTitleWidth }}>
                                                {unitData?.property?.address?.street_1 ?? ""}{unitData?.property?.address?.city && ", "}{unitData?.property?.address?.city ?? ""}{unitData?.property?.address?.state && ", "} {unitData?.property?.address?.state ?? ""}{unitData?.property?.address?.zipcode && " - "}{unitData?.property?.address?.zipcode}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Box onClick={() => {
                                        navigate(AppRoutes.propertyUnitView, {
                                            state: {
                                                id: unitData?.property?.id,
                                                type: "property"
                                            }
                                        })
                                    }}>
                                        {
                                            language === "ar" ?
                                                <ArrowBackIosIcon sx={{ color: "#98A0AC" }} /> :
                                                <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                        }
                                    </Box>
                                </Stack>

                            </Box>
                            <Box sx={classes.scrollBox} p={"12px 16px 0px"}>
                                <ScrollContainer className="scroll-container">
                                    <Stack direction={"row"} columnGap={"20px"} mt={"24px"}>
                                        {
                                            scrollData?.map((e) => {
                                                return (
                                                    <Stack alignItems={"center"} spacing={1} onClick={e?.onClick}>
                                                        <Box sx={classes.iconBox}>
                                                            {e?.icon}
                                                        </Box>
                                                        <Stack alignItems={"center"}>
                                                            <Typography sx={classes.iconText}>{e?.title}</Typography>
                                                            <Typography sx={classes.iconText}>{e?.subtitle}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            })
                                        }

                                    </Stack>
                                </ScrollContainer>
                            </Box>

                            {/* <Typography sx={classes.title} mt={"22px"}>Landlord Approval Required</Typography>

                    <Box mt={"12px"}>
                        <ScrollContainer className="scroll-container">
                            <Stack direction={"row"} spacing={"12px"}>
                                <ApprovalCard />
                                <ApprovalCard />
                                <ApprovalCard />
                                <ApprovalCard />
                            </Stack>
                        </ScrollContainer>
                    </Box> */}

                            <Typography sx={classes.title} mt={"22px"}>{t("Agreement Details")}</Typography>

                            {
                                (getUnitStatusType() !== "Vacant" && unitData?.agreement) && 
                                <Box sx={classes.box} mt={1}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Stack direction="row" columnGap={1} alignItems={"center"}>
                                            <AgreementIcon />
                                            <Stack>
                                                <Typography sx={classes.propertyTitle}>{unitData?.agreement?.[0]?.agreement_no}</Typography>
                                                <Typography sx={classes.secText}>{unitData?.agreement?.[0]?.agreement_type}</Typography>
                                            </Stack>
                                        </Stack>
                                        <Box onClick={() => handleGetAgreementPdf(unitData?.agreement?.[0]?.id)}>
                                            {
                                                // language === "ar" ?
                                                    // <ArrowBackIosIcon sx={{ color: "#98A0AC" }} /> :
                                                    // <ArrowForwardIosIcon sx={{ color: "#98A0AC" }} />
                                            }
                                        </Box>
                                    </Stack>

                                    <Box mt={"16px"}>
                                        <ScrollContainer className="scroll-container">
                                            <Stack direction={"row"} columnGap={"12px"}>
                                                {
                                                    agreementCardData?.map((e) => {
                                                        return (
                                                            <>
                                                                {
                                                                    e?.isActive &&
                                                                    <AgreementDetailCard
                                                                        icon={e?.icon}
                                                                        title={e?.title}
                                                                        subtitle={e?.subtitle}
                                                                    />
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }

                                            </Stack>
                                        </ScrollContainer>
                                    </Box>

                                    <Box mt={"20px"}>
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography sx={classes.contactText}>{t("Contact")}</Typography>
                                            <Typography sx={classes.viewallText} onClick={() => setContactView(true)}>{t("View all")}</Typography>
                                        </Stack>

                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={classes.propertyBox} mt={1}>
                                            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                                <Avatar sx={classes.contactAvatar} src={unitData?.agreement?.[0]?.contact?.image_url} {...stringAvatar(unitData?.agreement?.[0]?.contact?.first_name)}></Avatar>
                                                <Stack spacing={"4px"}>
                                                    <Typography sx={{ ...classes.propertyTitle, ...classes.propertySubTitleWidth }}>{unitData?.agreement?.[0]?.contact?.first_name}</Typography>
                                                    <Typography sx={classes.secText}>{t("Primary Contact")}</Typography>
                                                </Stack>
                                            </Stack>
                                            <a
                                                href={`tel:${unitData?.agreement?.[0]?.contact?.mobile_no_country_code ?? ""}${unitData?.agreement?.[0]?.contact?.mobile_no}`}
                                                style={{ textDecoration: "none", color: "#071741" }}
                                                target="_blank" rel="noreferrer"
                                            ><ContactIcon /></a>

                                        </Stack>
                                    </Box>
                                </Box>
                            }
                            {
                                (getUnitStatusType() === "Vacant" || unitData?.agreement === null) &&
                                <Box sx={{ ...classes.normalBox, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} mt={1}>
                                    <NoAgreementImg />
                                    <Stack spacing={"4px"} alignItems={"center"} mt={1}>
                                        <Typography sx={classes.secondaryText}>{getUnitStatusType() === "Vacant" ? t("This Unit is Vacant") : t("Agreement not yet Live")}</Typography>
                                        <Typography sx={classes.tertiaryTextReg}>{getUnitStatusType() === "Vacant" ? t("No data to agreement details") : t("The agreement data would be visible only post Live")}</Typography>
                                    </Stack>
                                </Box>
                            }
                            {
                                (getUnitStatusType() !== "Vacant" && unitData?.agreement) && 
                                <Box sx={classes.scrollBox} p={"12px 16px 0px"}>
                                    <ScrollContainer className="scroll-container">
                                        <Stack direction={"row"} columnGap={"20px"} mt={"24px"}>
                                            {
                                                agreementScrollData?.map((e) => {
                                                    return (
                                                        <Stack alignItems={"center"} spacing={1} onClick={e?.onClick}>
                                                            <Box sx={classes.iconBox}>
                                                                {e?.icon}
                                                            </Box>
                                                            <Stack alignItems={"center"}>
                                                                <Typography sx={classes.iconText}>{e?.title}</Typography>
                                                                <Typography sx={classes.iconText}>{e?.subtitle}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })
                                            }

                                        </Stack>
                                    </ScrollContainer>
                                </Box>
                            }

                            <Typography sx={classes.title} mt={"22px"}>{t("Project Progress")}</Typography>

                            <Box mt={"12px"}>
                                {
                                    unitData?.project_progress?.length > 0 ?
                                        <ScrollContainer className="scroll-container">
                                            <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                                                {
                                                    unitData?.project_progress?.map((e) => {
                                                        return (
                                                            <Box>
                                                                <ProjectProgressCard
                                                                    data={e}
                                                                    property={unitData?.property}
                                                                    t={t}
                                                                    onNavigate={() => navigate(AppRoutes.propertyDetails, { state: { id: e?.project_id, data: e, type: "project" } })}
                                                                    handleViewAllAsset={() => handleViewProgressAsset(e?.images?.map((e) => { return { url: e?.asset_url } }) ?? [])} />
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </ScrollContainer> :
                                        <Stack sx={classes.box} spacing={2} alignItems={"center"} justifyContent={"center"}>
                                            <NoTimelineImg />
                                            <Typography sx={classes.noDataText}>{t("No Data Found")}</Typography>
                                        </Stack>
                                }
                            </Box>
                        </Box >
                }

            </Container >

            <DialogDrawer
                maxWidth="sm"
                open={contactView}
                header={t("All Contacts")}
                padding="0px"
                onClose={() => setContactView(false)}
                component={
                    <Box sx={{ height: "500px" }}>
                        {/* <Box p={2}>
                            <ContactCard
                                data={unitData?.agreement?.[0]?.contact}
                                t={t}
                            />
                        </Box>
                        <Divider></Divider> */}
                        <Stack p={2} spacing={1}>
                            {
                                unitData?.users?.map((e) => {
                                    return (
                                        <ContactCard data={e} t={t} subtitle={e?.type} />
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                }
            />


            <DialogDrawer
                maxWidth="sm"
                open={paymentPlan}
                header={t("Payment Plan")}
                padding="0px"
                onClose={() => setPaymentPlan(false)}
                height={"auto"}
                component={<PaymentPlan agreement={unitData?.agreement?.[0]} t={t} />}
            />

            <DialogDrawer
                maxWidth="sm"
                open={financeBool}
                header={t("Financing Info")}
                padding="0px"
                onClose={() => setFinanceBool(false)}
                height={"500px"}
                component={<FinancingInfo data={unitData?.agreement?.[0]} t={t} />}
            />

            <DialogDrawer
                maxWidth="sm"
                open={invoiceData?.bool}
                header={invoiceData?.type === "agreement" ? t("View Agreement") : t("View Invoice")}
                padding="0px"
                onClose={() => setInvoiceData({
                    bool: false,
                    data: ""
                })}
                height={"500px"}
                component={
                    <Box p={2}>
                        {
                            invoiceData?.data?.length === 0 ? <LoderSimmer card variant={"rounded"} width={"auto"} size={480} count={1} /> :
                                <DocumentViewer url={`data:application/pdf;base64,${invoiceData?.data}` ?? ""} />}
                    </Box>
                }
            />

        </>
    )
}

export default PropertyUnitNew