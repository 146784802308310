import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
import styled from '@mui/material/styles/styled';
import { Avatar, Typography } from "@mui/material";



export const dashBoardStyle = {
  root: {
    padding: "0.3rem 0rem 0.8rem",
    overflow: "auto",
    // height: "calc(100vh - 129px)"
  },
  landLordTitle: {
    textAlign: "-webkit-match-parent",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
    opacity: 1,
  },
  main: {
    padding: "16px 16px 0px",
  },
  username: {
    fontSize: "1rem",
    fontFamily: ExtraBold,
    color: "text.main",
  },
  msg: {
    fontSize: "0.875rem",
    fontFamily: Regular,
    marginTop: "5px",
    color: "text.main",
  },
  favTitle: {
    textAlign: "-webkit-match-parent",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
    opacity: 1,
  },
  favParent: {
    display: "flex",
    alignItems: "center",
    overflowY: "none",
    overflowX: "auto",
    gap:"16px",
    marginTop:"12px",
    backgroundColor: "#FFFFFF",
    padding: "12px",
    borderRadius: "9px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  lanlordParent: {
    display: "flex",
    alignItems: "center",
    overflowY: "none",
    overflowX: "auto",
    gap: "12px",
    borderRadius: "9px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  favDivider: {
    width: "1px",
    height: "30px",
    backgroundColor: "#E4E8EE",
  },
  conParent: {
    display: "flex",
    alignItems: "center",
  },
  contracts: {
    height: "128px",
    backgroundColor: "error.contrastText",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    opacity: 1,
    padding: '16px'
  },
  contractTitle: {
    textAlign: "-webkit-match-parent",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
    textTransform: "capitalize",
    opacity: 1,
    marginBottom: "10px"
  },
  nudgeBox: {
    padding: "16px 16px 0px 16px",
  },
  viewAllText:{
    fontSize:"0.75rem",
    fontFamily:Bold,
    color:"#5078E1",
    cursor:"pointer"
  },
  invoiceTitle: {
    fontSize: "0.875rem",
    fontFamily:Bold,
    color:"text.main",
  },
};

export const ProfileImageOuterDiv = styled('div')({
  alignSelf: "center"
})

export const ProfileImageInnerDiv = styled('div')({
  position: "relative",
  display: "flex",
  justifyContent: "center",
})

export const Image = styled(Avatar)((props) => ({
  cursor: "pointer",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  border: `1.5px solid #F15A29`
}))

export const UserID = styled(Typography)((props) => ({
  padding: "1px 4px",
  borderRadius: "7px",
  backgroundColor: "#F15A29",
  color: "white",
  fontSize: "0.75rem",
  fontFamily: Bold,
  position: "absolute",
  bottom: "0px",
  textAlign: "center",
  display: "inline",
}))

export const exploreProperty = {
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "16px 16px 12px 12px",
    marginBottom: "16px",
    cursor: "pointer",
  },
  title: {
    fontSize: "1rem",
    fontFamily: ExtraBold,
    color: "#091B29",
    marginBottom: "6px",
  },
  subTitle: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: "#4E5A6B",
  },
  img: {
    width: "68px",
    height: "68px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    objectFit: "contain",
  },
  avatar: {
    width: "300px",
    height: "60px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    objectFit: "contain",
    color: "#091B29",
    fontFamily: Bold
  },
  img2: {
    '& .MuiAvatar-img': {
      objectFit: "contain",
    },
    width: "100%",
    height: "60px",
    borderRadius: "4px",
    
  },
  img3: {
    '& .MuiAvatar-img': {
      objectFit: "contain",
    },
    width: "100%",
    height: "60px",
    borderRadius: "4px",
  },
}