import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils";


export const WelcomePage_style = {
  root: {
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    padding: "0px",
    height: '100vh',
  },
  welcomeText: {
    fontSize: "1.625rem",
    fontFamily: Bold,
    color: "white",
  },
  mob_logo: {
    height: "32px",
    objectFit: "contain",
  },
  mob_description: {
    fontSize: FontSizeCalc(20),
    fontFamily: SemiBold,
    color: "#FEF4F4",
    textShadow: "0px 6px 10px #00000033",
    whiteSpace: "inherit !important",
    overflow: "inherit !important",
    textOverflow: "inherit !important",
  },
  btn: {
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: "#FFFFFF",
    fontSize: "0.875rem",
    color: "#F15A29",
    fontFamily: Bold,
    "&:hover": {
      color: '#F15A29',
      backgroundColor: "#FFFFFF",
    },
  },
  setup_button: {
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: "#F15A29",
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    "&:hover": {
      color: '#FFFFFF',
      backgroundColor: "#F15A29",
    },
  },
  login_button: {
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: "#ffffff75",
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    "&:hover": {
      color: '#FFFFFF',
      backgroundColor: "#ffffff75",
    },
  },
  btnGrid: {
    position: 'fixed',
    bottom: '16px !important',
    left: "0px",
    right: "0px",
    // textAlign: "center",
    width: "100%"
  },
  termsAndConditionText: {
    textAlign: "center",
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: Regular,
    cursor: "pointer"
  },
  termsAndConditionUnderLineText: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: Bold,
    cursor: "pointer"
  },
  btnBox: {
    padding: "16px",
    borderTop: `1px solid #000`
  },
  tcText: {
    fontSize: "1rem",
    color: 'color.primary',
    fontFamily: Bold
  },
  getStartBtn: {
    height: 48,
    fontFamily: Bold,
    color: "#fff"
  },

  imgContainer: {
    width: "100%",
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow: "0px 0px 4px #00000029",
    height: "125px",
    margin: "4px"
  },
  img: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    borderRadius: 12,
  },

  detailsBox: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    backgroundColor: "#E3EBFE"
  },
  pdfText: {
    padding: "4px",
    color: "#fff",
    fontFamily: Bold,
    fontSize: "0.75rem",
    backgroundColor: 'text.btn',
    borderRadius: "8px"
  },
  pdfName: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    wordBreak: "break-all",
    whiteSpace: "nowrap",
    width: '95%',
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
}