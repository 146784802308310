import { useEffect, useState } from "react";

export const VideoCard = ({
  style = {},
  videoBlob = {},
  controls = false,
  playsInline = true,
  autoPlay = false,
  muted = false,
}) => {
  const [posterImage, setPosterImage] = useState("");

  useEffect(() => {
    videoBlob && blobToImage?.(videoBlob)?.then?.((image) => {
      document?.body?.appendChild?.(image);
      const canvas = document?.createElement?.('canvas');
      canvas.width = image?.width;
      canvas.height = image?.height;
      const ctx = canvas?.getContext?.('2d');
      ctx?.drawImage?.(image, 0, 0);
      const pngDataUrl = canvas?.toDataURL?.('image/png');
      setPosterImage(pngDataUrl);
      document?.body?.removeChild?.(image);
    });
  }, [videoBlob])

  const blobToImage = (blob) => {
    return new Promise((resolve) => {
      const url = URL?.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL?.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;
      img.style.display = "none";
    });
  };

  return (
    videoBlob &&
    <video
      style={style}
      controls={controls}
      muted={muted}
      autoPlay={autoPlay}
      playsInline={playsInline}
      poster={posterImage}>
      <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
    </video>
  )
}