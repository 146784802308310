import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterComponent, MultiImageUpload, TopNavBar } from "../../components";
import { AppRoutes } from "../../router/routes";
import { Step1, Step2, Step4 } from "./steps";
import { MaintanceRequestStyle } from './style';
import { useLazyMaintainanceRequestCreationQuery, useLazyUserProfileQuery } from "../../redux/services";
import { LocalStorageKeys, SemiBold, errorRoute } from "../../utils";
import { useSnackbar } from 'notistack';
import ServiceDetails from "../../assets/serviceDetails";
import { Appliance } from "../chooseAppliance/component";


export const MaintanceRequest = ({ t = () => false }) => {

    const location = useLocation();
    const initial = {
      location: location?.state?.product?.location_id
        ? {
            value: location.state.product.location_id,
            label: location.state.product.location_name,
          }
        : null,
      responseData: {},
      category: "",
      sub_Category: "",
      select_Unit: "",
      problem_Title: "",
      problem_Description: "",
      problem_Since: new Date(),
      preferred_Date: new Date(),
      images: [],
      name: "",
      mobile_no: "",
      alternative_mobile_no: "",
      selectedSlot: "",
      error: {
        location: {
          value: "",
          label: "",
        },
        category: "",
        sub_Category: "",
        select_Unit: "",
        problem_Title: "",
        problem_Description: "",
        problem_Since: new Date(),
        preferred_Date: "",
        images: "",
        name: "",
        mobile_no: "",
        alternative_mobile_no: "",
      },
    };
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [data, setData] = useState({ ...initial });
    const [step, setStep] = useState(1);
    const [isDisableBtn, setIsDisableBtn] = useState(false)

    const [RequestCreation, { isLoading, error }] = useLazyMaintainanceRequestCreationQuery()
    const [userProfile, { error: isError }] = useLazyUserProfileQuery()

    const updateState = (k, v,k1,v1) => {
        let error = data?.error;
        error[k] = "";
        if (k === "category") {
            error["sub_Category"]=v?.maintenance_sub_category?.length>0?"":data?.error?.sub_Category
            setData({
                ...data,
                [k]: v,
                sub_Category:v?.maintenance_sub_category?.length>0?v?.maintenance_sub_category?.[0]:"", 
                error,
            });
        } else {
            setData({
                ...data,
                [k]: v,
                [k1]:v1,
                error,
            });
        }
    };

    const userProfileAPI = async () => {
        const profileRes = await userProfile()
        setData({

            ...data,
            name: profileRes?.data?.data?.[0]?.first_name ?? "",
            mobile: { mobile: profileRes?.data?.data?.[0]?.mobile_no ?? '', mobile_code: profileRes?.data?.data?.[0]?.mobile_no_country_code ?? "+91" },
            alternative_mobile: { mobile: profileRes?.data?.data?.[0]?.alternative_mobile_no ?? "", mobile_code: profileRes?.data?.data?.[0]?.alternative_mobile_no_country_code ?? "+91" }
        })

    }

    React.useEffect(() => {
        userProfileAPI()
        // eslint-disable-next-line
    }, [])


    const MaintainanceRequestCreationAPI = async () => {
        setIsDisableBtn(true)
        const payload = {
            category: data?.category?.value,
            contact_alternative_mobile: data?.alternative_mobile?.mobile,
            contact_mobile: data?.mobile?.mobile,
            contact_name: data?.name,
            description: data?.problem_Description,
            image_urls: data?.images,
            mobile_country_code: data?.alternative_mobile?.mobile_code,
            preferred_time: data?.preferred_Date,
            problem_since: data?.problem_Since,
            sub_category: data?.sub_Category?.value,
            subject: data?.problem_Title,
            type: 2,
            units: [location?.state?.unit?.unit_id ?? location?.state?.product?.unit_id],
            userProfileId: localStorage.getItem(LocalStorageKeys.userProfileId),
            is_active: true,
            location_id: data?.location?.value,
            inspection_mapping_id: location?.state?.product?.id ?? null,
            propertyId: location?.state?.unit?.property_id ?? location?.state?.product?.property_id,
            companyId: location?.state?.unit?.company_id ?? location?.state?.product?.company_id,
            priority: "medium",
            slot_id:data?.selectedSlot?.value??null,
            slot_name:data?.selectedSlot?.label??null,
            agreement_unit_id:location?.state?.unit?.agreement_unit_id??location?.state?.product?.agreement_unit_id??null
        }
        const CreationRes = await RequestCreation(payload)

        if (CreationRes?.data?.type === "success") {
            setIsDisableBtn(false)
            enqueueSnackbar(t(`Maintainance request Created Successfully`), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        } else {
            setIsDisableBtn(false)
            enqueueSnackbar(t(`Maintainance request Created Unsuccessfully`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        }
        navigate(AppRoutes?.request)
    }

    const validate = () => {
        let isValid = true;
        let error = data.error

        if (data?.category.length === 0) {
            isValid = false
            error.category = t("Category is required")
        }
        if (data?.sub_Category?.length === 0) {
            isValid = false
            error.sub_Category = t("Sub Category is Required")
        }
        if (data?.location?.label?.length === 0) {
            isValid = false
            error.location = t("Location is Required")
        }
        // if (data?.select_Unit?.length === 0) {
        //     isValid = false
        //     error.select_Unit = t("Select Unit is Required")
        // }
        if (data?.problem_Title?.length === 0) {
            isValid = false
            error.problem_Title = t("Problem Title is Required")
        }
        if(data?.selectedSlot===""){
            isValid = false
            enqueueSnackbar(t(`Please Choose Preferred Time Slot`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        }
        setData({ ...data, error })
        return isValid
    }

    // Previous
    const previous = () => {
        setStep(step - 1)
    };

    // submit function
    const submit = () => {
        MaintainanceRequestCreationAPI()
        // setDisable(true);
        goBack();
    }

    const goBack = () => {
        navigate(AppRoutes.request);
    };

    // next function
    const next = () => {

        if (step === 0) {
            setStep(1)
        }
        if (step === 1) {
            if (validate()) {
                setStep(2)
            } else { }
        }
        if (step === 2) {
            setStep(3);

        }
        if (step === 3) {
            setStep(4);
        } else { }
    };

    //   -----------------------------steps---------------------------------------
    const components = {
        1: <Step1 updateState={updateState} data={data} t={t} />,
        2: <Step2 data={data} updateState={updateState} t={t} />,
        3: <MultiImageUpload
            t={t}   
            value={data?.images}
            onChange={(e) => updateState("images", e)}
            error={data?.error?.type}
            multiline={true}
            onDelete={(e) => updateState("images", e)}
            onlyFiveFilesUpload
            isRecordingEnabled
        />,
        4: <Step4
            request={data}
            uploadedImages={data?.images}
            t={t}
            state={location?.state}
        />,

    };
    // title
    const titleType = {
        1: t("Service Details"),
        2: t("Contact Details"),
        3: t("Upload Images & Videos"),
        4: t("Details"),
    };
    // propgressvalue
    // const progressValue = {
    //     1: 25,
    //     2: 50,
    //     3: 75,
    //     4: 100,
    // };

    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423) return errorRoute()
    }, [error, isError])

    return (
        <Container
            sx={MaintanceRequestStyle.container}
            maxWidth="sm"
            style={{ padding: '0px 0px 56px !important' }}
        >
            <Box style={{
                padding: "57px 0px 87px"
            }}>
                {/* topNavbar */}
                <Box sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 999 }}>
                    <Container maxWidth="sm"
                        sx={{ padding: '0px !important' }}>
                        <TopNavBar isDirect title={t("Create Service Request")} isBack t={t} />
                        {/* stepper */}
                        {/* <Grid
                    container
                    sx={MaintanceRequestStyle?.stepperBody}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Grid item xs={1.5}>
                        <Box sx={MaintanceRequestStyle?.circle}>
                            <Stack sx={MaintanceRequestStyle?.stepper} >
                                {step}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={10.5}>
                        <Typography sx={MaintanceRequestStyle?.steps} noWrap>
                            {t("Step")}
                            {step} / 4
                        </Typography>
                        <Typography sx={MaintanceRequestStyle?.details} noWrap>
                            {titleType[step]}
                        </Typography>
                    </Grid>
                </Grid> */}
                        {/* <Box sx={MaintanceRequestStyle?.linearProgress}>
                    <LinearProgress variant="determinate"
                        value={progressValue[step]}
                        color="warning" />
                </Box> */}
                    </Container>
                </Box>
                {/* Create components  */}
                <Box sx={MaintanceRequestStyle?.root}>
                    <Box sx={MaintanceRequestStyle.stepsBox}>
                        <Stack alignItems={"center"} direction={"row"}>
                            <ServiceDetails />
                            <Stack spacing={"4px"} marginInlineStart={"12px"} direction={"column"}>
                                <Typography sx={MaintanceRequestStyle?.details} noWrap>
                                    {titleType[step]}
                                </Typography>
                                <Typography sx={MaintanceRequestStyle?.steps} noWrap>
                                    {t("Step ")}
                                    {step} / 4
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box sx={MaintanceRequestStyle.stepsBox2}>
                        {(titleType?.[step] === "Service Details" || titleType?.[step] === "Details") &&
                            <Box sx={MaintanceRequestStyle.productBox}>
                                {location?.state?.product &&
                                    <Appliance
                                        t = {t}
                                        location_name={location?.state?.product?.location_name}
                                        goBack={() => navigate(AppRoutes.chooseAppliance)}
                                        unit_name = {location?.state?.product?.name ?? "-"}
                                        unit_no = {location?.state?.product?.manufaturer_name ?? "-"}
                                        serial_number=  {location?.state?.product?.serial_number ?? "-"}
                                        model_number={location?.state?.product?.model_number ?? "-"}
                                        request
                                        product />
                                }
                                {location?.state?.unit &&
                                    <Appliance  
                                        t = {t}
                                        goBack={() => navigate(AppRoutes.chooseAppliance)}
                                        unit_name = {location?.state?.unit?.unit_name ?? "-"}
                                        unit_no = {location?.state?.unit?.unit_no ?? "-"}
                                        request
                                        unit />
                                }
                            </Box>
                        }
                        {components[step]}
                    </Box>
                </Box>

                <Box sx={MaintanceRequestStyle.footer}>
                    <Container maxWidth="sm"
                        sx={{ padding: '0px !important' }}>
                        {/* footer Component */}
                        <FooterComponent
                            btnLoading={isLoading}
                            containedonClick={step === 4 ? () => submit() : () => next()}
                            outlinedonClick={() => previous()}
                            outlinedText={t("Previous")}
                            containedText={step === 4 ? t("Submit") : t("Next")}
                            border={true}
                            isDisableBtn={isDisableBtn}
                            outlinedDisabled={step <= 1 ? true : false}
                        />
                    </Container>
                </Box>
            </Box>



        </Container>

    );

}