import * as React from "react"
const TerraceIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 137940" transform="translate(-44 -404.42)">
      <rect
        width={40}
        height={40}
        fill="#fef4f4"
        data-name="Image 33"
        rx={12}
        transform="translate(44 404.42)"
      />
      <path
        fill="#c96d5e"
        stroke="#c96d5e"
        strokeWidth={0.25}
        d="M64.367 412.5a4.391 4.391 0 0 0-2.7.932l-6.356 4.968a2.746 2.746 0 0 0-.169.133l-.169.133a.878.878 0 0 0-.226.265 3.2 3.2 0 0 0-.747 2.053v14.626a.878.878 0 0 0 .878.878h12.713a.878.878 0 0 0 .778-.257l.009-.009 5.842-5.846a.878.878 0 0 0 .257-.62v-14.038a3.183 3.183 0 0 0-.5-1.714.878.878 0 0 0-.157-.223 3.217 3.217 0 0 0-2.56-1.281Zm0 1.755h6.893a1.484 1.484 0 0 1 .581.117l-4.72 3.962a3.119 3.119 0 0 0-1.712-.568h-6.436l3.77-2.95a2.635 2.635 0 0 1 1.624-.561Zm8.355 1.668v13.469l-4.1 4.1v-12.508a3.141 3.141 0 0 0-.325-1.351Zm-1.555 5.2a.439.439 0 0 0-.351.112l-.688.631a1.028 1.028 0 0 0-.331.754v.98a.44.44 0 0 0 .262.4.447.447 0 0 0 .177.037.438.438 0 0 0 .3-.115l.688-.631a1.028 1.028 0 0 0 .331-.754v-.986a.435.435 0 0 0-.385-.435Zm-13.949-1.6h8.191a1.454 1.454 0 0 1 1.024.419l.026.035a1.45 1.45 0 0 1 .413 1.008v13.748H55.755v-13.749a1.456 1.456 0 0 1 .411-1.022l.293-.229a1.466 1.466 0 0 1 .759-.212Zm1.463 4.522a.585.585 0 0 0-.585.585v1.17a.585.585 0 0 0 .585.585h1.17a.585.585 0 0 0 .585-.585v-1.17a.585.585 0 0 0-.585-.585Zm4.1 0a.585.585 0 0 0-.585.585v1.17a.585.585 0 0 0 .585.585h1.17a.585.585 0 0 0 .585-.585v-1.17a.585.585 0 0 0-.585-.585Zm8.391 3.087a.438.438 0 0 0-.351.112l-.688.631a1.028 1.028 0 0 0-.331.754v.986a.44.44 0 0 0 .262.4.447.447 0 0 0 .177.037.438.438 0 0 0 .3-.115l.688-.631a1.028 1.028 0 0 0 .331-.754v-.986a.435.435 0 0 0-.385-.435Zm-12.491 2.92a.585.585 0 0 0-.585.585v1.17a.585.585 0 0 0 .585.585h1.17a.585.585 0 0 0 .585-.585v-1.17a.585.585 0 0 0-.585-.585Zm4.1 0a.585.585 0 0 0-.585.585v1.17a.585.585 0 0 0 .585.585h1.17a.585.585 0 0 0 .585-.585v-1.17a.585.585 0 0 0-.585-.585Z"
        data-name="Group 138184"
      />
    </g>
  </svg>
)
export default TerraceIcon
