import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const AnnouncementViewStyle = {
    root: {
        padding: "82px 16px 16px",
        position: "relative",
        width: '100%',
        height: '100% !important',
        overflow: "auto",
        backgroundColor: "background.main",
        "&::-webkit-scrollbar": {
            display: "none",
        },

    },

    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative"
    },
    health: {
        fontSize: "0.75rem",
        color: "text.secondary",
        fontFamily: Bold,
        backgroundColor: "border.secondary",
        padding: "1px 8px",
        borderRadius: "4px",
    },
    posted: {
        fontSize: "0.75rem",
        color: "primary.Tertiary",
        fontFamily: Bold,
    },
    postedLine: {
        fontSize: "0.75rem",
        color: "primary.Tertiary",
        fontFamily: Bold,
        marginInline: "16px"
    },
    // title: {
    //     marginBottom: "8px",
    // },
    reactQuil: {
        "& .ql-editor": {
            padding: "0px 0px 10px 0px !important"
            // color:"red",
            // textAlign: "right !important"
            ,
            // "& .ql-align-right": {
            //     textAlign:"right"
            // },
        },
        "& .ql-clipboard": {
            display: "none"
        },

    },
    heading: {
        "& .ql-editor": {
            fontFamily: Bold,
            fontSize: "1.125rem",
            color: "red",
        },
    },
    dis: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "color.secondary",
        cursor: "pointer",
        whiteSpace: "normal",
        wordWrap: "break-word",
        "& .ql-editor": {
            textAlign: "right !important",
        },
    },
    imageCard: {
        height: "125px",
        width: "100%",
        // display: 'flex'
        textAlign: "center"
    },
    TopNavBar: {
        position: "fixed",
        top: "0",
        left: '0',
        zIndex: 1,
        width: '100%'
    },
    content: {
        backgroundColor: '#fff',
        padding: "16px",
        borderRadius: "4px"
    }

}

export const useStyles = makeStyles((theme) => ({
    reactQuil: {
        "& .ql-editor": {
            padding: "0px 0px 10px 0px !important",
            '& p':{
                whiteSpace: "normal !important",
    
                }
        },
            // color:"red",
            // textAlign: "right !important"
            
            // "& .ql-align-right": {
            //     textAlign:"right"
            // },
        
        "& .ql-clipboard": {
            display: "none"
        },

    },
}))

