import * as React from "react"
const WarrentyEndIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="Group 138034">
      <path
        fill="#4e5a6b"
        d="M10.291 0a2.285 2.285 0 0 0-1.54.579l-.515.459-.674-.149a2.3 2.3 0 0 0-2.671 1.518l-.216.656-.659.207a2.3 2.3 0 0 0-1.554 2.651l.138.673-.466.509a2.3 2.3 0 0 0-.019 3.073l.459.515-.149.674a2.291 2.291 0 0 0 .651 2.133L.225 16.352a.768.768 0 0 0 .313 1.275l3 .943.826 2.873a.768.768 0 0 0 1.28.331l4.316-4.315a.768.768 0 0 0 .071-.082 2.31 2.31 0 0 0 .383-.006l4.4 4.4a.768.768 0 0 0 1.28-.331l.826-2.873 3-.943a.768.768 0 0 0 .313-1.275l-2.835-2.835a2.291 2.291 0 0 0 .6-2.047l-.141-.676.467-.509a2.3 2.3 0 0 0 .019-3.073l-.45-.515.149-.673a2.3 2.3 0 0 0-1.519-2.672l-.656-.216-.207-.658A2.3 2.3 0 0 0 13.008.924l-.676.141-.508-.471A2.288 2.288 0 0 0 10.291 0Zm-.01 1.529a.756.756 0 0 1 .507.2l.8.732a.768.768 0 0 0 .676.185l1.059-.22a.747.747 0 0 1 .875.513l.326 1.033a.768.768 0 0 0 .492.5l1.028.339a.747.747 0 0 1 .5.882l-.235 1.057a.768.768 0 0 0 .177.678l.722.808a.747.747 0 0 1-.007 1.014l-.732.8a.768.768 0 0 0-.185.675l.22 1.06a.746.746 0 0 1-.5.871q-.037.01-.073.024l-.97.306a.768.768 0 0 0-.5.492l-.339 1.028a.747.747 0 0 1-.882.5l-1.057-.235a.768.768 0 0 0-.677.177l-.809.721a.747.747 0 0 1-1.013-.006l-.8-.731a.768.768 0 0 0-.675-.186l-1.059.221a.748.748 0 0 1-.876-.514l-.326-1.033a.768.768 0 0 0-.492-.5l-1.028-.34a.747.747 0 0 1-.5-.882l.235-1.057a.768.768 0 0 0-.177-.677l-.721-.808a.747.747 0 0 1 .006-1.013L4 7.344a.768.768 0 0 0 .186-.675L3.965 5.61a.748.748 0 0 1 .514-.875l1.033-.326a.768.768 0 0 0 .5-.492l.34-1.028a.747.747 0 0 1 .882-.5l1.057.235a.768.768 0 0 0 .677-.177l.808-.721a.752.752 0 0 1 .505-.197Zm3.062 5.059a.768.768 0 0 0-.528.232l-3.433 3.434-1.729-1.729A.768.768 0 1 0 6.568 9.61l2.272 2.273a.768.768 0 0 0 1.086 0L13.9 7.905a.768.768 0 0 0-.558-1.317Zm-8.823 7.64.081.027.207.659a2.3 2.3 0 0 0 2.651 1.555l.676-.141.412.378-3.064 3.063-.583-2.018a.768.768 0 0 0-.508-.52L2.2 16.543Zm11.467.04 2.276 2.276-2.191.688a.768.768 0 0 0-.508.52l-.58 2.019-3.1-3.1.35-.312.674.149a2.3 2.3 0 0 0 2.672-1.519l.216-.656Z"
        data-name="icons8-guarantee (1)"
      />
    </g>
  </svg>
)
export default WarrentyEndIcon
